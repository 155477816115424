/**
 * Add Days to  date
**/
Date.prototype.addDays = function(days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

/**
 * Add Months to  date
**/
Date.prototype.addMonths = function(months) {
  let date = new Date(this.valueOf());
  date.setMonth(date.getMonth() + months);
  return date;
}

/**
 * Add Years to the date
**/
Date.prototype.addYears = function(years) {
  let date = new Date(this.valueOf());
  date.setFullYear(date.getFullYear() + years);
  return date;
}

/**
 * Gets the start this day
**/
Date.prototype.startOfDay = function() {
  return new Date(this.getFullYear(),this.getMonth(),this.getDate());
}

/**
 * Gets the start of this week (Monday)
**/
Date.prototype.startOfWeek = function(years) {
  return this.startOfDay().addDays(this.getDay()*-1);
}

/**
 * Gets the start of this month
**/
Date.prototype.startOfMonth = function(years) {
  return new Date(this.getFullYear(),this.getMonth(),1);
}

/**
 * Add Years to the date
**/
Date.prototype.startOfYear = function(years) {
  return new Date(this.getFullYear(),0,1);
}

/**
 * Gets the start of tomorrow
**/
Date.prototype.tomorrow = function() {
  return this.startOfDay().addDays(1);
}

/**
 * Gets a date from a YYYY/MM/DD string with current date's hours,
 * minutes, and seconds
**/
Date.prototype.withYMD = function(y,m,d) {
  return new Date(y >= 0 ? y : this.getFullYear(), m >= 0 ? m : this.getMonth(), d > 0 ? d : this.getDate());
}

/**
 * Returns a simple date string for this year
**/
Date.prototype.datestr = function() {
  let m = this.getMonth()+1;
  let d = this.getDate();
  return `${this.getFullYear()}-${m<10?'0'+m:m}-${d<10?'0'+d:d}`;
}

/**
 * Gets the UTC mysql string
**/
Date.prototype.srvstr = function(opts={}) {
  let m; let d; let y; let hh; let mm; let ss;
  if (opts.utc) {
    m = this.getUTCMonth()+1;
    d = this.getUTCDate();
    y = this.getUTCFullYear();
    hh = this.getUTCHours();
    mm = this.getUTCMinutes();
    ss = this.getUTCSeconds();
  }
  else {
    m = this.getMonth()+1;
    d = this.getDate();
    y = this.getFullYear();
    hh = this.getHours();
    mm = this.getMinutes();
    ss = this.getSeconds();
  }
  m = m.toString().padStart(2,"0");
  d = d.toString().padStart(2,"0");
  y = y.toString().padStart(4,"0");
  hh = hh.toString().padStart(2,"0");
  mm = mm.toString().padStart(2,"0");
  ss = ss.toString().padStart(2,"0");

  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // WORKS
}

/**
 * Gets a date from a YYYY/MM/DD string with current hours,
 * minutes, and seconds
**/
Date.parseYMDStr = function(str) {
  if (!str) { return; }
  let p = str.split(/\-|\//g);
  let date = new Date();

  // Date first to avoid strange month auto change issues
  let d = parseInt(p[2]);
  d = d || date.getDate()

  // Then the month
  let m = parseInt(p[1]);
  m = m > 0 ? (m-1) : date.getMonth();

  // Then the year
  let y = parseInt(p[0]);
  y = y || date.getFullYear();

  date.setFullYear(y,m,d);
  return date;
}

/**
 * Taks a string and converts to a date in SRV format
**/
Date.srvtify = function(str, opts) {
  if (!str) { return; }
  return (new Date(str)).srvstr(opts);
}

/**
 * Taks a UTC string date and returns a date to be used in a date
 * input
**/
Date.utcTransDateInput = function(str, opts) {
  if (!str || !(str.length >= 0)) { return; }
  let d = new Date(str);
  return this.srvtify(d).substr(0,10)
}
