import React from 'react';
import { withRouter } from 'react-router'
import Moment from 'react-moment';
import Layout from './Layout';
import Input from '../elements/Input';
import CheckControl from '../controls/CheckControl';
import './FilterLine.css';

import { financial, capitalize } from '../../lib/formats';
import { dot, deepCopy } from '../../lib/obj';

class FilterLine extends Layout {

  constructor(props) {
    super(props);
    this.state = {
      config: deepCopy(props.config)
    };
  }

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  onTermChange(term) {
    let state = this.state;
    dot(state,"config.term.value", term);
    this.setState(state);
    this.onConfigChange(state.config);
  }

  onFilterChange(filter, options, index, filterIndex) {
    let state = this.state;
    dot(state,["config","filters",filterIndex,"options"], options);
    this.setState(state);
    this.onConfigChange(state.config);
  }

  onConfigChange(config) {
    if (this.props.onConfigChange) {
      this.props.onConfigChange(config);
    }
  }

  getConfig() {
    return this.state.config;
  }

  render() {
    let config = this.state.config || {};
    let term = config.term || {};
    let filters = config.filters || [];
    let className = this.props.className || "";
    return (
      <div ref="root" className={`FilterLine ${className} Line`} onClick={(evt)=>{this.onClick(evt)}} >
        <Input label={this.props.label || term.label} placeholder={term.placeholder} value={term.value} clearable="true" onChange={(value)=>{this.onTermChange(value)}}/>
        {filters.map((filter, filterIndex)=>{
          return <CheckControl key={`filter${filterIndex}`} options={filter.options} value={filter.label} type={filter.type} onChange={(options, index)=>{this.onFilterChange(filter, options, index, filterIndex);}}/>
        })}
      </div>
    );
  }
}

const RoutedFilterLine = withRouter(FilterLine)
export default RoutedFilterLine;
