import React from 'react';
import Element from './Element';
import BareInput from './BareInput';
import { _ } from '../../lib/underscore';

class DateRangeInput extends Element {
  constructor(props) {
    super(props);
    this.state = { startAt: props.defaultStartAt, endAt: props.defaultEndAt };
  }

  setStateWithEvent(e, cb) {
    let target = e.target;
    let key = target.dataset.key;
    let change = {[key]:target.value};
    this.setState(change, cb);
    return change;
  }

  onEnter(e) {
    this.onActionChange(e, "onEnter", this.setStateWithEvent(e));
  }

  onBlur(e) {
    this.onActionChange(e, "onBlur", this.setStateWithEvent(e));
  }

  onChange(e) {
    this.onActionChange(e, "onChange", this.setStateWithEvent(e));
  }

  onActionChange(e, name, change) {
    let props = this.props;
    let state = this.state;
    if (props[name]) { props[name](e, {...state, ...change}); }
  }

  render() {
    let props = this.props;
    let state = this.state;
    let dirty = props.defaultStartAt != state.startAt || props.defaultEndAt != state.endAt;
    return (
      <div className="DateRangeInput" data-dirty={dirty} data-default-start={props.defaultStartAt} data-default-end={props.defaultEndAt} >
        <BareInput data-key="startAt" type="date" defaultValue={ props.defaultStartAt } onChange={(e)=>this.onChange(e)} onEnter={(e)=>this.onEnter(e)} onBlur={(e)=>this.onBlur(e)} />
        <BareInput data-key="endAt" type="date" defaultValue={ props.defaultEndAt } onChange={(e)=>this.onChange(e)} onEnter={(e)=>this.onEnter(e)} onBlur={(e)=>this.onBlur(e)} />
      </div>
    );
  }
}

export default DateRangeInput;
