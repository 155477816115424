import React from 'react';
import Container from './Container';
import { financial } from '../../lib/formats';
import './MoneyVal.css';

class MoneyVal extends Container {
  render() {
    let str = financial(this.props.children);
    let className = this.props.className || "";
    return (
      <div className={`MoneyVal Container ${className}`}>
        {this.props.prefix} {str} {this.props.suffix}
      </div>
    );
  }
}

export default MoneyVal;
