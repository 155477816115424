import React from 'react';
import Container from './Container';
import './StateVal.css';

import { PO_STATE_MAP } from '../../constants/inventory/PO.js';
import { TRANSFER_STATUS_MAP } from '../../constants/inventory/Transfer.js';
import { dot } from '../../lib/obj';

class StateVal extends Container {

  getVal() {
    return this.props.val || this.props.children;
  }

  getName() {
    let value = this.props.children;
    let type = this.props.type;
    switch (type) {
      case "po":
      case "pos":
        return dot(PO_STATE_MAP,[value,"name"]);
      case "transfer":
      case "transfers":
        return dot(TRANSFER_STATUS_MAP,[value,"name"]);
      default:
        return value;
    }
  }

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt);
    }
  }

  render() {
    let className = this.props.className || "";
    return (
      <div className={`StateVal Container ${className}`} data-val={this.getVal()} data-type={this.props.type} onClick={(evt)=>this.onClick(evt)}>
        {this.getName()}
      </div>
    );
  }
}

export default StateVal;
