import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import DropControl from '../controls/DropControl';
import DateControl from '../controls/DateControl';
import Input from '../elements/Input';
import Readout from '../elements/Readout';

import MoneyVal from '../containers/MoneyVal';
import ActionVal from '../containers/ActionVal';
import TypeVal from '../containers/TypeVal';
import ImageLayout from '../layouts/ImageLayout';
import ProductLayout from '../layouts/ProductLayout';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dot } from '../../lib/obj';
import { setItemField } from '../../actions/InventoryActions';
import { stateForDoc } from '../../lib/inventory-util';

import { PAYMENT_TYPES, PAYMENT_TYPE_MAP } from '../../constants/inventory/Payment.js';

import './Form.css';
import './ProductItemForm.scss';

class ProductItemForm extends Form {

  // Edit the given field
  editField(field, value) {
    let opts = this.props.opts;
    this.props.setItemField({field:field, value:value, ...opts});
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.productItem,"data") || {};
    let itemData = dot(this.props.item,"data") || dot(data,"item.data") || {};
    let type = itemData.type;
    let sku = itemData.sku;
    let choices = ( dot(this.props.inventory,"products.list.docs") || [] ).map((doc)=>{return doc.data;});

    // Check if this is a item
    let isItem = type == "item" || type == "assembly";

    // Get the quantity required
    let quantity = data.quantity || 0;

    // Calcualte the cost
    let productionCost = data.unitCost || dot(itemData,"cogs") || 0;
    let cost = quantity * productionCost;

    // Determine the name field
    let nameField = null;
    if (itemData.id) {
      // let nameLabel = <div><TypeVal type="products" bare="true">{type}</TypeVal> {sku}</div>
      // nameField = <Readout className="Name" label={nameLabel} onClick={(e,h)=>{this.onProductClick(e,h)}}>{itemData.name}</Readout>;
      nameField = <ProductLayout className="Name" product={this.props.item} onClick={(e,h)=>{this.onProductClick(e,h)}}/>
    }
    else {
      nameField = <Input className="Name" label="Name" value={data.name} onChange={(val)=>{this.editField("name",val)}}/>
    }

    // Determine the Price field
    let priceField = null;
    if (itemData.id) {
      priceField = <Readout className="Price" label="Price" type="money"><MoneyVal>{productionCost}</MoneyVal></Readout>;
    }
    else {
      priceField = <Input className="Price" label="Price" value={data.unitCost} onChange={(val)=>{this.editField("unitCost",parseFloat(val))}}/>
    }

    return (
      <div className="Form ProductItemForm" ref="main" data-size={dot(this.state,"size")}>
        <ImageLayout className="Icon" file={this.props.image}/>
        {nameField}
        <Input className="Quantity" label="Quantity" type="number" value={quantity} onChange={(val)=>{this.editField("quantity",parseFloat(val))}}/>
        {priceField}
        <Readout className="Total" label="Total" type="money"><MoneyVal>{cost}</MoneyVal></Readout>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let inventory = state.inventory;
  let opts = props.opts || {};
  let productItem = stateForDoc(state.inventory,{
    type: "products",
    id: "new",
    childType: "items",
    ...opts
  });
  let vendor = dot(productItem,"data.vendor");
  let itemId = dot(productItem,"data.itemId")
  let item = dot(productItem,"data.item") || dot(inventory,["products",itemId]);
  return {
    opts: opts,
    inventory: inventory,
    productItem: productItem,
    item: item,
    image: dot(productItem,"data.image") || dot(item,"data.image")
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ProductItemForm)
