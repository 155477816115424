import React from 'react';
import Container from './Container';
import './ClearableVal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ClearableVal extends Container {

  onClear(evt) {
    if (this.props.onClear) {
      this.props.onClear(evt);
    }
  }

  render() {
    let className = this.props.className || "";
    return (
      <div className={`ClearableVal Container ${className}`}>
        {this.props.children}
        <div className="Icon" onClick={(evt)=>this.onClear(evt)}><FontAwesomeIcon icon={["far","times"]} /></div>
      </div>
    );
  }
}

export default ClearableVal;
