import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';

import './PaymentLineHeader.css';

class PaymentLineHeader extends Layout {
  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }
  render() {
    return (
      <div className="PaymentLineHeader" onClick={(evt)=>{this.onClick(evt)}} >
        <div className="Name">{this.props.col1 || "Name"}</div>
        <div className="Type">Type</div>
        <div className="Amount">Amount</div>
        <div className="Paid">Paid</div>
      </div>
    );
  }
}

const RoutedPaymentLineHeader = withRouter(PaymentLineHeader)
export default RoutedPaymentLineHeader;
