import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import ProductLayout from '../layouts/ProductLayout';
import DateVal from '../containers/DateVal';
import PaddedArea from '../areas/PaddedArea';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import Separator from '../elements/Separator';

import Input from '../elements/Input';
import ThrottledInput from '../elements/ThrottledInput';
import BareInput from '../elements/BareInput';
import DateControl from '../controls/DateControl';
import ModelLayout from '../layouts/ModelLayout';

import Section from '../elements/Section';
import QueryLine from '../layouts/QueryLine';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openPopup, closePopup } from '../../actions/PopupActions';
import { startDraftItem, purgeDraftItem, setItemField } from '../../actions/InventoryActions';
import { loadItem, meldItems, queryItems, setItemQuery, pushItem, deleteItem, purgeItem } from '../../actions/InventoryActions';
import { setAppField, pushAppNotification } from '../../actions/AppActions';

import Draft from '../../lib/draft';
import { dot, deepCopy, mapk2k, mappify, sfc } from '../../lib/obj';
import { financial, capitalize, commafy, money } from '../../lib/formats';
import { configToNestedQuery } from '../../lib/inventory-util';
import { mDownload } from '../../lib/file';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

import './Page.css';
import './SearchPage.scss';
import './ReturnsPage.scss';
import  '../../lib/date-util';

import { PRODUCT_SEARCH } from '../../constants/inventory/Product';
import { RETURN_SEARCH, RETURN_BASE_SEARCH, RETURN_DESCRIPTION_SEARCH, RETURN_STATES, RETURN_STATES_MAP, RETURN_REASONS,
  RETURN_REASONS_MAP, RETURN_FULFILLMENT, RETURN_FULFILLMENT_MAP, RETURN_CARRIERS,
  RETURN_CARRIERS_MAP, RETURN_RESTOCKED, RETURN_RESTOCKED_MAP,
  RETURN_RESOLUTIONS, RETURN_RESOLUTIONS_MAP, RETURN_DESCRIPTIONS,
  RETURN_DESCRIPTION_REASONS_MAP, RETURN_ORIGINS } from '../../constants/inventory/OrderReturn';

export const USER_VIEWS = [
  { value:"comprehensive",  name: "Comprehensive",  desc: "View all available information"},
  { value:"support",        name: "Support",        desc: "View information relevant to support"},
  { value:"shipping",       name: "Shipping",       desc: "View informatino relevant to shipping"}
];
export const USER_VIEWS_MAP = mapk2k(USER_VIEWS,"value","name")

const ORDER_SEARCH = {
  type: "orders",
  limit: 50,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["number","name","email"] // TODO - Fernando Mora: Only serch for number or name
  },
  sort: [
    { field: "createdAt",       name: "Createds (Asc)",     direction:"asc"},
    { field: "createdAt",       name: "Created (Desc)",     direction:"desc",  selected: true},
    { field: "updatedAt",       name: "Updated (Asc)",      direction:"asc"},
    { field: "updatedAt",       name: "Updated (Desc)",     direction:"desc"}
  ]
}

const STATUS_WORKING = {
  creating: true,
  saving: true,
  deleting: true
}

const CUST_PRODUCT_SEARCH = deepCopy(PRODUCT_SEARCH);
// dot(CUST_PRODUCT_SEARCH,"filters.0.options.0.selected",true);
// dot(CUST_PRODUCT_SEARCH,"filters.0.options.1.selected",true);
dot(CUST_PRODUCT_SEARCH,"limit",50);

const DEFAULT_RETURN_DATA = {
  quantity: 1,
  reason: RETURN_REASONS[0].value,
  fulfillmentStatus: RETURN_FULFILLMENT[0].value,
  carrier: RETURN_CARRIERS[0].value,
  restocked: RETURN_RESTOCKED[0].value,
  status: RETURN_STATES[0].value,
  resolution: RETURN_RESOLUTIONS[0].value,
  origin: RETURN_ORIGINS[0].value
};


const today = (new Date()).startOfDay();
const tomorrow = today.tomorrow();
const RETURN_RANGES = [
  { value: "all",         start:today.startOfYear(),  end:tomorrow,  name: "All",           desc: "Show all returns"},
  { value: "custom",      start:today,                end:tomorrow,  name: "Custom",        desc: "Custom range"},
  { value: "today",       start:today,                end:tomorrow,  name: "Today",         desc: "Today's returns"},
  { value: "last7days",   start:today.addDays(-7),    end:tomorrow,  name: "Last 7 days",   desc: "Last 7 days of returns"},
  { value: "last30days",  start:today.addDays(-30),   end:tomorrow,  name: "Last 30 days",  desc: "Last 30 days of returns"},
  { value: "last90days",  start:today.addDays(-90),   end:tomorrow,  name: "Last 90 days",  desc: "Last 90 days of returns"},
  { value: "wtd",         start:today.startOfWeek(),  end:tomorrow,  name: "This week",     desc: "This week"},
  { value: "mtd",         start:today.startOfMonth(), end:tomorrow,  name: "This month",    desc: "This month"},
  { value: "ytd",         start:today.startOfYear(),  end:tomorrow, name: "This year",     desc: "This year"}
];
const RETURN_RANGES_MAP = mapk2k(RETURN_RANGES,"value","name")

class ReturnsPage extends Page {

  constructor() {
    super();
    this.state.notes = {};
    this.setResultsTableRef = (el) =>{ this.resultsTable = el; }
    this.setSearchRef = (el) =>{ this.searchSection = el; }

    let range = RETURN_RANGES[0];
    this.state.range = range.value;
    this.state.startstr = range.start.datestr();
    this.state.endstr = range.end.datestr();
  }

  componentWillMount() {
    let props = this.props;
    let searchConfig = deepCopy(RETURN_SEARCH);

    // Get search from query string
    const qparams = new URLSearchParams(window.location.search);
    searchConfig.term.value = qparams.get('search');

    props.setItemQuery({
      type: "order-returns",
      query: searchConfig
    });
    // Load returns
    this.loadReturnsWithConfig(searchConfig);
  }

  componentDidMount() {
    // Monitors the page
    this.monitor = setInterval(()=>{
      const tableOffset = this.resultsTable.getBoundingClientRect();
      const searchOffset = this.searchSection.refs.root.getBoundingClientRect();
      let expand = (tableOffset.x - searchOffset.x ) <= -20;
      if (this.state.expand != expand) {
        this.props.setAppField("menu.left.expand",!expand);
        this.setState({expand:expand});
      }
    },500);
  }

  componentWillUnmount() {
    if (this.monitor) {
      clearInterval(this.monitor);
      delete this.monitor;
    }
  }

  onExpand(evt, history) {
    let expanded = !this.state.expanded;
    if (this.props.onExpand) {
      this.props.onExpand(expanded);
    }
    this.setState({expanded:expanded});
  }

  onDownload() {
    let date = new Date();
    let items = this.props.returns;
    let emails = {}
    let csvContent = items.filter((item)=>{
      let email = dot(item,"data.order.data.email");
      if (!email || emails[email]) { return false; }
      emails[email] = true;
      return true;
    }).map((item, index)=>{
      let data = item.data || {};
      return '"' + [
        dot(data,"order.data.email"),
        data.refName,
        dot(data,"order.data.name"),
        dot(data,"item.data.name"),
        dot(data,"item.data.sku"),
        data.resolution,
        sfc(data.completedAt,(o)=>o.toString())
      ].join('","') + '"';
    });
    csvContent.unshift('"email","name","order","product","sku","resolution","completed"');
    let fname = `Returns ${date.toDateString()} ${date.toTimeString().split(" ")[0]}.csv`;
    mDownload(csvContent.join("\n"),fname,'text/csv;encoding:utf-8');
  }

  // Get the query config
  onConfigChange(config, throttle) {
    // Load returns
    this.loadReturnsWithConfig(config, throttle);
  }

  genReturnBaseConditions() {
    let conditions = [{deleted:{neq:1}}];

    // Set range condition if necessary
    if (this.state.range && this.state.range != "all") {
      let state = this.state;
      let startstr = state.startstr;
      let endstr = state.endstr;
      conditions.push({"createdAt": {"between":[startstr,endstr]}});
    }

    return conditions;
  }

  loadReturnsWithConfig(config, throttle) {
    // Prepare base conditions
    let conditions = this.genReturnBaseConditions();

    // Send the returns query
    let query = configToNestedQuery(deepCopy(config), deepCopy(conditions));
    query.limit = config.limit;
    query.offset = config.offset;
    this.props.meldItems({
      type: "order-returns",
      query: query
    }).then(()=>{
      if (throttle) {
        setTimeout(()=>{throttle.done();},100)
      }
      console.log("DONE LOADING!");
    });
    this.setState({sci: null, notes: {}});

    // Send the stats query
    this.loadReturnsMetrics(conditions);
  }

  loadReturnsMetrics(conditions) {
    conditions = conditions || this.genReturnBaseConditions();
    this.props.meldItems({
      type: "order-returns",
      typeAlias: "order-returns-status-metrics",
      query: {
        where: deepCopy(conditions),
        group: ["status"],
        calcs: ["count.id"]
      }
    });
  }

  onViewChange(value) {
    this.setState({view:value});
  }

  onShowLog(title, conditions) {
    this.props.openPopup({
      name:"ActionLog",
      props:{
        title: title || "Return Actions Log",
        type: "actions",
        typeAlias: "return-actions",
        limit: 100,
        ignoreDelete: true,
        conditions: [{subjectType: {eq:"orderReturn"}}].concat(conditions)
      }
    });
  }

  onAdd(evt) {
    this.setState({sci: null, notes: {}});
    this.props.startDraftItem({
      type:"order-returns",
      index: -1,
      data: deepCopy(DEFAULT_RETURN_DATA)
    });
  }

  genMeticDiv(name, count, key="foo") {
    let status = this.props.metricsList.status || "loading";
    return <table className="Card StickyHeader" key={`metrics${key}`} animate={status}>
      <thead>
        <tr><th className="Name" data-hype={key}>{name}</th></tr>
      </thead>
      <tbody>
        <tr><td className="Count">{count}</td></tr>
      </tbody>
    </table>
  }

  renderMetrics() {
    let metricsList = this.props.metricsList;
    let total = 0;
    let map = mappify(metricsList.docs,(doc)=>{
      total += dot(doc,"data.calcs.countId") || 0;
      return dot(doc,"data.status") || "unknown";
    });
    let unknownDiv;
    if (map["unknown"]) {
      let doc = map["unknown"];
      unknownDiv = this.genMeticDiv("Unknown", commafy(dot(doc,"data.calcs.countId") || 0), "unknown");
    }
    return (<div className="StatTables MetricStats">
      {this.genMeticDiv("Total", commafy(total), "total")}
      {RETURN_STATES.map((rs,i)=>{
        let doc = map[rs.value];
        return this.genMeticDiv(rs.name, commafy(dot(doc,"data.calcs.countId") || 0), rs.value);
      })}
      {unknownDiv}
    </div>);
  }

  onFilterAlike(item, index) {
    let cors = [];
    if (item.data.orderId) {
      cors.push({orderId:{eq:item.data.orderId}});
    }
    if (item.data.refName) {
      cors.push({refName:{eq:item.data.refName}});
    }
    if (cors.length == 0) { return; }
    this.props.meldItems({
      type: "order-returns",
      query: {
        links: ["order","item"],
        limit: 50,
        where: [{deleted:{neq:1}},{or:cors}]
      }
    });
    this.setState({sci: null, notes: {}});
  }

  onCopyLink(item, index) {
    navigator.clipboard.writeText(`${window.location.href}?search=id:${item.data.id}`).then(()=>{
      this.props.pushAppNotification("Copied link...",{timeout: 2000});
    },()=>{
      /* clipboard write failed */
    });
  }

  onCopyInfo(item, index) {
    navigator.clipboard.writeText(JSON.stringify({'order-return':item})).then(()=>{
      this.props.pushAppNotification("Copied return...",{timeout: 2000});
    },()=>{
      /* clipboard write failed */
    });
  }

  onPasteInfo(item, index) {
    navigator.permissions.query({
      name: "clipboard-read",
    }).then((permission)=>{
      if (permission.state === "denied") { throw new Error("Not allowed to read clipboard.");}
      navigator.clipboard.readText().then((text)=>{
        try {
          let map = JSON.parse(text);
          let clip = map['order-resolution'] || map['order-return'];
          if (!clip) { return; }
          this.pasteItemFromClip(index,clip);
        }
        catch(e) {
          console.error("Paste info error: ", e);
        }
      });
    });
  }

  pasteItemFromClip(index, clip) {
    let data = clip.data || {};
    let draft = Draft.deep(dot(this.props.returns,[index,"data"]));
    draft.val("createdAt", Date.srvtify(data.createdAt,{utc:true}));
    draft.val("refName",data.refName);
    draft.val("orderId",data.orderId);
    draft.val("itemId",data.itemId);
    draft.val("reason",data.reason);
    draft.val("description",data.description);
    draft.val("notes",data.notes);
    this.props.pushItem({
      type: "order-returns", id: draft.val("id"), index: index,
      docs: { orderReturn: draft.updates },
      query: {links: "order,item", promptError:true}
    });
  }

  genControlsDiv(item, index) {
    let data = item.data || {};
    let showLogButton;
    let softActions;
    if (data.id > 0) {
      softActions = <React.Fragment>
        <Separator collapsed={true}/>
        <div className="IconAction" onClick={()=>{this.onCopyLink(item, index)}}>
          <FontAwesomeIcon icon={["far","link"]} /> Copy Link
        </div>
        <div className="IconAction" onClick={()=>{this.onCopyInfo(item, index)}}>
          <FontAwesomeIcon icon={["far","clipboard"]} /> Copy Info
        </div>
        { (data.refName || data.orderId) &&
          <div className="IconAction" onClick={()=>{this.onFilterAlike(item, index)}}>
            <FontAwesomeIcon icon={["far","filter"]} /> Filter Alike
          </div>
        }
        <div className="IconAction" onClick={()=>{this.onShowLog(`Return ${data.id} Actions Log`,[{subjectId:{eq:data.id}}])}}>
          <FontAwesomeIcon icon={["far","square-list"]} /> Show Log
        </div>
      </React.Fragment>
    } else {
      softActions = <React.Fragment>
        <Separator collapsed={true}/>
        <div className="IconAction" onClick={()=>{this.onPasteInfo(item, index)}}>
          <FontAwesomeIcon icon={["far","paste"]} /> Paste Info
        </div>
      </React.Fragment>
    }
    return <details className="FloatDetails" open={this.state.sci == index && this.state.scf == "controls"}>
      <summary><FontAwesomeIcon className="ControlIcon" icon={["fal","ellipsis-v"]}/></summary>
      <div className="DetailsList">
        <div className="Header"><h4>Resolution Actions</h4><aside>{index+1}</aside></div>
        <Separator collapsed={true}/>
        <ModelLayout>
          <div className="Name">{data.refName || RETURN_REASONS_MAP[data.reason]}</div>
          <div className="Label">ID: {data.id}</div>
          <DateVal className="Sublabel">{data.createdAt}</DateVal>
        </ModelLayout>
        {softActions}
        <Separator collapsed={true}/>
        <RectButton theme="bw" onClick={(e,history)=>{this.onDuplicateItem(e, index)}}>Duplicate</RectButton>
        <RectButton working={item.status == "deleting"} theme="red" onClick={(e,history)=>{this.onDeleteItem(e, item.data.id, index)}}>Delete</RectButton>
      </div>
    </details>
  }

  genOrderDiv(order, index) {
    if (order && order.data) {
      let shopId = order.data.externalId ? order.data.externalId.split("-")[1] : null;
      let shopUrl = `https://1lss-store.myshopify.com/admin/orders/${shopId}`;
      return <details className="FloatDetails" open={this.state.sci == index && this.state.scf == "order"}>
        <summary>{ order.data.name }</summary>
        <div className="DetailsList">
          <h4><a className="ShopifyIcon" href={shopUrl} target="_blank"><FontAwesomeIcon icon={["fab","shopify"]} /></a>&nbsp;&nbsp; Order Info</h4>
          <ModelLayout>
            <div className="Name">{order.data.email}</div>
            <div className="Label">{order.data.name}</div>
            <DateVal className="Sublabel">{order.data.createdAt}</DateVal>
          </ModelLayout>
          <RectButton theme="blue" onClick={(e)=>this.onSelectOrder(index, order)}>Change</RectButton>
        </div>
      </details>
    } else {
      return <div className="BlankOrder" onClick={()=>this.onSelectOrder(index)}> &ndash; </div>
    }
  }

  genProductDiv(product, index, order) {
    if (product) {
      let hype = !product.data.sku ? "error" : "";
      return <details className="FloatDetails" open={this.state.sci == index && this.state.scf == "product"}>
        <summary><span data-hype={hype}>{ product.data.sku || `ID-${product.data.id}` }</span></summary>
        <div className="DetailsList">
          <h4>Product Info</h4>
          <PlainButton className="CloseButton" theme="whisper"><FontAwesomeIcon className="ControlIcon" icon={["far","times"]}/></PlainButton>
          <ProductLayout key="product" product={product}/>
          <RectButton theme="blue" onClick={(e)=>this.onSelectProduct(index, product, order)}>Change</RectButton>
        </div>
      </details>
    } else {
      return <div className="BlankProduct" onClick={()=>this.onSelectProduct(index, product, order)}> &ndash; </div>
    }
  }

  genNotesDiv(item, index) {
    if (item) {
      let data = item.data;
      let notes = dot(this.state,['notes',index]);
      let edited = notes != null && notes != data.notes;
      return <details className="FloatDetails" open={this.state.sci == index && this.state.scf == "notes"}>
        <summary>
          <FontAwesomeIcon className="Icon" icon={["far","memo-pad"]} data-filled={data.notes ? true : false} />
        </summary>
        <div className="DetailsList">
          <h4>Order Return Notes</h4>
          <textarea name="notes" style={{width:"100%",minHeight:"10em"}} defaultValue={data.notes} onChange={(e)=>this.onNotesChange(e,index)}></textarea>
          <RectButton theme="blue" lock={!edited} working={STATUS_WORKING[item.status]} onClick={(e)=>this.saveField("notes",notes,index)}>Save</RectButton>
        </div>
      </details>
    }
  }

  onNotesChange(e, index) {
    let notes = this.state.notes;
    notes[index] = e.target.value;
    this.setState({notes:notes});
  }

  saveField(field, value, index) {
    this.softEditField(field, value, index);
    let id = dot(this.props.returns,[index,"data","id"]);
    let data = deepCopy(dot(this.props.returns,[index,"data"]) || {});
    data[field] = value;
    this.props.pushItem({
      type: "order-returns",
      id: id,
      index: index,
      docs: {
        orderReturn: {sets:{[field]:value}, data:data}
      },
      query: {links: "order,item", promptError:true}
    });
  }

  softEditField(field, value, index) {
    let id = dot(this.props.returns,[index,"data","id"]);
    this.props.setItemField({
      type:"order-returns",
      id:id,
      index: index,
      field:field,
      value:value
    });
  }

  saveTextField(field, value, orig, index) {
    if (value == orig || (value == "" && orig == null)) { return; }
    this.saveField(field, value, index);
  }

  saveDescriptionField(value, orig, index) {
    if (value == orig || (value == "" && orig == null)) { return; }
    let reason = RETURN_DESCRIPTION_REASONS_MAP[value];
    console.log("REASON: ", value,reason);
    if (value == "" || reason) {
      reason ||= "unknown";
      this.softEditField("description", value, index);
      let id = dot(this.props.returns,[index,"data","id"]);
      let data = deepCopy(dot(this.props.returns,[index,"data"]) || {});
      let draft = {sets:{},clears:{},data:data};
      draft.sets.description = value;
      draft.sets.reason = reason;
      data.description = draft.sets.description;
      data.reason = draft.sets.reason;
      // Push the changes
      this.props.pushItem({
        type: "order-returns", id: id, index: index,
        docs: { orderReturn: draft },
        query: {links: "order,item", promptError:true}
      });
    }
  }

  saveDeadlineField(value, index) {
    this.softEditField("deadlineAt", value, index);
    let draft = Draft.deep(dot(this.props.returns,[index,"data"]));
    // Check if setting or clearing
    if (value && value.length > 0) {
      let date = Date.parseYMDStr(value);
      draft.val("deadlineAt",date.srvstr({utc:true}));
      let receivedDate = Date.parseYMDStr(draft.val("receivedAt"));
      if (date && receivedDate) {
        if (date < receivedDate) {
          draft.val("resolution", "late");
        }
        else {
          draft.val("resolution", "on-time");
        }
      }
    }
    else {
      draft.clear("deadlineAt");
    }
    // Push the changes
    this.props.pushItem({
      type: "order-returns", id: draft.val("id"), index: index,
      docs: { orderReturn: draft.updates },
      query: {links: "order,item", promptError:true}
    });
  }

  saveReceivedField(value, index) {
    this.softEditField("receivedAt", value, index);
    let id = dot(this.props.returns,[index,"data","id"]);
    let data = deepCopy(dot(this.props.returns,[index,"data"]) || {});
    let draft = {sets:{},clears:{},data:data};


    // Check if setting or clearing
    if (value && value.length > 0) {
      let date = Date.parseYMDStr(value);
      if (data.status == "initiated") {
        draft.sets.status = "received";
        data.status = draft.sets.status;
      }
      // Set if package was received on time
      let deadlineVal = dot(data,"deadlineAt");
      let deadlineDate = Date.parseYMDStr(deadlineVal);
      if (date && deadlineDate) {
        if (date > deadlineDate) {
          draft.sets.resolution = "late";
        }
        else {
          draft.sets.resolution = "on-time";
        }
        date.resolution = draft.sets.resolution;
      }
      draft.sets.receivedAt = date.srvstr({utc:true})
      data.receivedAt = draft.sets.receivedAt;
    }
    else {
      if (data.status == "received") {
        draft.sets.status = "initiated";
        data.status = draft.sets.status;
      }
      if (data.resolution == "on-time" || data.resolution == "late") {
        draft.sets.resolution = "pending";
        data.resolution = draft.sets.resolution;
      }
      draft.clears.receivedAt = true;
      delete data.receivedAt;
    }
    // Push the changes
    this.props.pushItem({
      type: "order-returns", id: id, index: index,
      docs: { orderReturn: draft },
      query: {links: "order,item", promptError:true}
    }).then(()=>{
      this.loadReturnsMetrics();
    });
  }

  saveProcessedField(value, index) {
    this.softEditField("processedAt", value, index);
    let id = dot(this.props.returns,[index,"data","id"]);
    let data = deepCopy(dot(this.props.returns,[index,"data"]) || {});
    let draft = {sets:{},clears:{},data:data};

    // Check if setting or clearing
    if (value && value.length > 0) {
      let date = Date.parseYMDStr(value);
      if (data.status == "initiated" || data.status == "received") {
        draft.sets.status = "processed";
        data.status = draft.sets.status;
      }
      draft.sets.processedAt = date.srvstr({utc:true})
      data.processedAt = draft.sets.processedAt;
    }
    else {
      if (data.status == 'processed') {
        if (data.receivedAt) {
          draft.sets.status = "received";
        }
        else {
          draft.sets.status = "initiated";
        }
      }
      data.status = draft.sets.status;
      draft.clears.processedAt = true;
      delete data.processedAt;
    }
    // Push the changes
    this.props.pushItem({
      type: "order-returns", id: id, index: index,
      docs: { orderReturn: draft },
      query: {links: "order,item", promptError: true}
    }).then(()=>{
      this.loadReturnsMetrics();
    });
  }

  saveResoutionField_legacy(value, index) {
    this.softEditField("resolution", value, index);

    // Check if value changed
    let id = dot(this.props.returns,[index,"data","id"]);
    let data = deepCopy(dot(this.props.returns,[index,"data"]) || {});

    // Do nothing if no change
    if (value == data.resolution) { return; }
    let draft = {sets:{resolution:value},clears:{},data:data};

    // Check if resolution is being reverted
    if (value == "pending" || value == "other") {
      if (data.processedAt) {
        draft.sets.status = "processed";
      }
      else if (data.receivedAt) {
        draft.sets.status = "received";
      }
      else {
        draft.sets.status = "initiated";
      }
      data.status = draft.sets.status;
      draft.clears.completedAt = true;
      delete data.completedAt;
    }
    else {
      draft.sets.status = "complete";
      data.status = draft.sets.status;
      draft.sets.completedAt = (new Date()).srvstr({utc:true});
      data.completedAt = draft.sets.completedAt;
    }
    // Push the changes
    this.props.pushItem({
      type: "order-returns", id: id, index: index,
      docs: { orderReturn: draft },
      query: {links: "order,item", promptError: true}
    }).then(()=>{
      this.loadReturnsMetrics();
    });
  }


  saveResoutionField(value, index) {
    this.softEditField("resolution", value, index);

    // Do nothing if no change
    let draft = Draft.deep(dot(this.props.returns,[index,"data"]));
    if (value == draft.val("resolution")) { return; }
    draft.val("resolution",value);

    // Check if resolution is being reverted
    if (value == "pending" || value == "other") {
      if (draft.val("processedAt")) {
        draft.val("status","processed");
      }
      else if (draft.val("receivedAt")) {
        draft.val("status","received");
      }
      else {
        draft.val("status","initiated");
      }
      draft.clear("completedAt");
      draft.clear("completedById");
    }
    else {
      draft.val("status","complete");
      draft.val("completedAt",(new Date()).srvstr({utc:true}));
      draft.reg("completedById",dot(this.props,"user.id"));
    }
    // Push the changes
    this.props.pushItem({
      type: "order-returns", id: draft.val("id"), index: index,
      docs: { orderReturn: draft.updates },
      query: {links: "order,item", promptError: true}
    }).then(()=>{
      this.loadReturnsMetrics();
    });
  }

  onSelectProduct(index, selectedProduct, order) {
    if (order) {
      this.loadOrderProductIds(order,(skus)=>{
        this.openProductPopup(index, selectedProduct, skus);
      });
    }
    else {
      this.openProductPopup(index, selectedProduct)
    }
  }

  onSelectProduct(index, selectedProduct, order) {
    let searchConfig = deepCopy(CUST_PRODUCT_SEARCH);
    searchConfig.term.value = "";

    // Run load helper
    let runLoad = (load) => {
      if (order) {
        this.loadOrderProductIds(order,(ids)=>{
          if (ids && ids.length > 0) { searchConfig.term.value = `id:${ids.join(",")}`; }
          load(searchConfig);
        });
      }
      else {
        load(searchConfig);
      }
    }

    // Open Popup
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Product",
        type: "products",
        typeAlias: "return-products",
        limit: 25,
        query: searchConfig,
        delayLoad: runLoad,
        radio: true,
        selections: selectedProduct ? {[selectedProduct.data.id]:{ordering:0,item:selectedProduct}} : undefined,
        renderItem: (item, index, optionProps)=>{
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        onOk:(items)=>{
          if (items.length > 0) {
            this.saveField("itemId",items[0].data.id,index);
          }
        }
      }
    });

  }

  loadOrderProductIds(order, onDone) {
    this.props.meldItems({
      type: "order-items",
      query: {
        links:["item"],
        group:['itemId'],
        where: [
          {deleted: {neq: 1}},
          {orderId: {eq: dot(order,"data.id")} }
        ]
      }
    }).then((res)=>{
      if (res.orderItems && onDone) {
        onDone(res.orderItems.map((oi)=>dot(oi,"data.item.data.id")));
      }
    });
  }

  openProductPopup(index, selectedProduct, skus) {
    let searchConfig = deepCopy(CUST_PRODUCT_SEARCH);
    if (skus && skus.length > 0) { searchConfig.term.value = `sku:${skus.join(",")}`; }
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Product",
        type: "products",
        typeAlias: "return-products",
        limit: 25,
        query: searchConfig,
        radio: true,
        selections: selectedProduct ? {[selectedProduct.data.id]:{ordering:0,item:selectedProduct}} : undefined,
        renderItem: (item, index, optionProps)=>{
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        onOk:(items)=>{
          if (items.length > 0) {
            this.saveField("itemId",items[0].data.id,index);
          }
        }
      }
    });
  }

  onSelectOrder(index, selectedOrder) {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select an Order",
        type: "orders",
        typeAlias: "return-orders",
        limit: 25,
        query: ORDER_SEARCH,
        radio: true,
        selections: selectedOrder ? {[selectedOrder.data.id]:{ordering:0,item:selectedOrder}} : undefined,
        renderItem: (item, index, optionProps)=>{
          return [
            <ModelLayout>
              <div className="Name">{item.data.email}</div>
              <div className="Label">{item.data.name}</div>
              <DateVal className="Sublabel">{item.data.createdAt}</DateVal>
            </ModelLayout>
          ]
        },
        onOk:(items)=>{
          if (items.length > 0) {
            let draft = Draft.deep(dot(this.props.returns,[index,"data"]));
            draft.val("orderId",items[0].data.id);
            draft.val("refName",items[0].data.customerName);
            this.props.pushItem({
              type: "order-returns", id: draft.val("id"), index: index,
              docs: { orderReturn: draft.updates },
              query: {links: "order,item", promptError:true}
            });
          }
        }
      }
    });
  }

  onDeleteItem(e, id, index) {
    if (id > 0) {
      this.props.deleteItem({type:"order-returns",id: id,index: index})
      .then(()=>{
        this.props.purgeItem({type:"order-returns", id: id, index: index});
      });
    }
    else {
      this.props.purgeItem({type:"order-returns", index: index});
    }
    this.setState({sci: null, notes: {}});
  }

  onDuplicateItem(e, index) {
    this.setState({sci: null, notes: {}});
    let data = deepCopy(dot(this.props.returns,[index,"data"]) || DEFAULT_RETURN_DATA);
    delete data.id;
    delete data.updatedAt;
    delete data.deductAmount;
    data.createdAt  = Date.srvtify(data.createdAt,{utc:true});
    data.deadlineAt  = Date.srvtify(data.deadlineAt,{utc:true});
    data.receivedAt  = Date.srvtify(data.receivedAt,{utc:true});
    data.processedAt = Date.srvtify(data.processedAt,{utc:true});
    data.completedAt = Date.srvtify(data.completedAt,{utc:true});
    this.props.startDraftItem({
      type:"order-returns",
      //index: -1,
      index: index,
      insert: true,
      data: data
    });
    // Quick hack to save the item as soon as it is duplicated
    setTimeout(()=>{ this.saveField("reason", data.reason, index); },50);
  }

  genRangeDiv() {
    return <div className="DateRange">
      <input key="start" type="date" defaultValue={this.state.startstr} onChange={(e)=>this.onStartChange(e)}/>
      &nbsp;to&nbsp;
      <input key="end" type="date" defaultValue={this.state.endstr} onChange={(e)=>this.onEndChange(e)}/>
    </div>
  }

  onStartChange(e) {
    let d = e.target.value ? new Date(e.target.value+" 00:00:00") : new Date();
    this.onRangeChange("custom", d.srvstr({utc:true}), this.state.endstr);
  }

  onEndChange(e) {
    let d = e.target.value ? new Date(e.target.value+" 00:00:00") : new Date();
    this.onRangeChange("custom", this.state.startstr, d.addDays(1).srvstr({utc:true}));
  }

  onRangeChange(value, startstr, endstr) {
    // Get the default range config
    let rangeConfig = RETURN_RANGES.find(r=>r.value==value);

    // Get the range start and end dates
    startstr = startstr || rangeConfig.start.srvstr({utc:true});
    endstr = endstr || rangeConfig.end.srvstr({utc:true});

    // Prepare condition and update state
    this.setState({
      range: rangeConfig.value,
      startstr: startstr,
      endstr: endstr
    },()=>{
      // Load stats
      this.loadReturnsWithConfig(this.props.query);
    });
  }

  onTogglePin(index) {
    this.setState({pin:index});
  }

  render() {
    let props = this.props;
    let returnsList = props.returnsList;
    let returns = props.returns;
    let view = this.state.view || USER_VIEWS[0].value;

    let header = <tr>
      <th className="Controls"><FontAwesomeIcon className="ControlIcon" icon={["fal","plus"]} onClick={(e)=>{this.onAdd(e)}}/></th>
      <th className="Created">Created</th>
      <th className="RefName">Customer Name</th>
      <th className="Origin">Origin</th>
      <th className="Order">Order</th>
      <th className="Product">Product</th>
      <th className="Quantity">Quantity</th>
      <th className="Reason">Reason</th>
      <th className="Description">Description</th>
      <th className="Deadline">Return Deadline</th>
      <th className="Carrier">Carrier</th>
      <th className="Tracking">Tracking #</th>
      <th className="State">Status</th>
      <th className="Received">Received</th>
      <th className="Processed">Processed</th>
      <th className="Completed">Completed</th>
      <th className="Resolution">Resolution</th>
      <th className="ReturnAmount">Amount $</th>
      <th className="DeductAmount">Deduct $</th>
      <th className="Restocked">Restocked</th>
      <th className="Notes">Notes</th>
    </tr>;

    const renderItem = (item, index)=>{
      let data = item.data;
      let pin = data.id ? data.id : `${index}i`;
      let deadlineAt = Date.utcTransDateInput(data.deadlineAt);
      let receivedAt = Date.utcTransDateInput(data.receivedAt);
      let processedAt = Date.utcTransDateInput(data.processedAt);
      return <tr key={`item${pin}-${data.version}`}
        data-deleted={data.deleted}
        data-working={STATUS_WORKING[item.status]}
        data-creating={data.id == null}
        data-expanded={this.state.sci == index}
        data-pinned={this.state.pin == pin}
        onClick={()=>{this.onTogglePin(pin)}}
        >
        <td className="Controls">
          {this.genControlsDiv(item,index)}
        </td>
        <td className="Created">
          <DateVal>{data.createdAt}</DateVal>
        </td>
        <td className="RefName">
          <BareInput defaultValue={ data.refName } onBlur={(e)=>{this.saveTextField("refName", e.target.value, data.refName, index)}}/>
        </td>
        <td className="Origin">
          <select defaultValue={data.origin} onChange={(e)=>{this.saveField("origin",e.target.value,index)}} data-hype={data.origin || 'other'}>
            {RETURN_ORIGINS.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="Order">
          {this.genOrderDiv(data.order, index)}
        </td>
        <td className="Product">
          {this.genProductDiv(data.item, index, data.order)}
        </td>
        <td className="Quantity">
          <BareInput type="number" defaultValue={ data.quantity } onBlur={(e)=>{this.saveTextField("quantity", e.target.value, data.quantity, index)}}/>
        </td>
        <td className="Reason">
          <div data-hype={data.reason || 'unknown'}>{RETURN_REASONS_MAP[data.reason]}</div>
        </td>
        <td className="Description">
          <BareInput list="return-descriptions" defaultValue={ data.description } onChange={(e)=>{this.saveDescriptionField(e.target.value, data.description, index)}}/>
        </td>
        <td className="Deadline">
          <BareInput type="date" data-hype={deadlineAt ? "" : "blank"} defaultValue={ deadlineAt } onChange={(e)=>{this.saveDeadlineField(e.target.value, index)}}/>
        </td>
        <td className="Carrier">
          <select defaultValue={data.carrier} onChange={(e)=>{this.saveField("carrier",e.target.value,index)}} data-hype={data.carrier || 'undefined'}>
            {RETURN_CARRIERS.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="Tracking">
          <BareInput defaultValue={ data.tracking } onBlur={(e)=>{this.saveTextField("tracking", e.target.value, data.tracking, index)}}/>
        </td>
        <td className="State">
          <div data-hype={data.status || 'undefined'}>{ RETURN_STATES_MAP[data.status] }</div>
        </td>
        <td className="Received">
          <BareInput type="date" data-hype={receivedAt ? "" : "blank"} defaultValue={ receivedAt } onChange={(e)=>{this.saveReceivedField(e.target.value, index)}}/>
        </td>
        <td className="Processed">
          <BareInput type="date" data-hype={processedAt ? "" : "blank"} defaultValue={ processedAt } onChange={(e)=>{this.saveProcessedField(e.target.value, index)}}/>
        </td>
        <td className="Completed">
          <DateVal raw={data.completedAt}>{data.completedAt}</DateVal>
        </td>
        <td className="Resolution">
          <select defaultValue={data.resolution} onChange={(e)=>{this.saveResoutionField(e.target.value,index)}} data-hype={data.resolution || 'undefined'}>
            {RETURN_RESOLUTIONS.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="ReturnAmount">
          <BareInput type="number" defaultValue={ money(data.amount) } onBlur={(e)=>{this.saveTextField("amount", e.target.value, data.amount, index)}}/>
        </td>
        <td className="DeductAmount">
          <BareInput type="number" defaultValue={ money(data.deductAmount) } onBlur={(e)=>{this.saveTextField("deductAmount", e.target.value, data.deductAmount, index)}}/>
        </td>
        <td className="Restocked">
          <select defaultValue={data.restocked ? 1 : 0} onChange={(e)=>{this.saveField("restocked",e.target.value,index)}} data-hype={data.restocked ? "yes" : "no"}>
            {RETURN_RESTOCKED.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="Notes">
          {this.genNotesDiv(item, index)}
        </td>
      </tr>
    };

    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add Return</RectButton>;

    // Get the range to layout
    let range = this.state.range || RETURN_RANGES[0].value;
    let customRange = range == "custom" ? this.genRangeDiv() : null;

    // Get the docs
    return (
      <div className="Page SearchPage ReturnsPage" data-view={view}>
        <PageHeaderOutline title={this.props.title || "Returns"} aside={addButton} top={this._backButton()}>
          <select className="ViewSelector" defaultValue={view} onChange={(e)=>{this.onViewChange(e.target.value)}}>
            {USER_VIEWS.map((v,i)=>{return <option key={`view${i}`} value={v.value}>{v.name}</option>})}
          </select>
          <Link theme="blue" to="/returns/stats"><FontAwesomeIcon icon={["far","chart-line"]} /> Stats</Link>
          <Link theme="blue" to="#" onClick={()=>{this.onDownload()}}><FontAwesomeIcon icon="download" /> CSV</Link>
          <Link theme="blue" to="#" onClick={()=>{this.onShowLog()}}><FontAwesomeIcon icon={["far","square-list"]} /> Log</Link>
          <Link theme="blue" to="/returns/kpi"><FontAwesomeIcon icon={["far","chart-line"]} /> KPI</Link>
          <select className="RangeSelector" defaultValue={range} onChange={(e)=>{this.onRangeChange(e.target.value)}}>
            {RETURN_RANGES.map((v,i)=>{return <option key={`range${i}`} value={v.value}>{v.name}</option>})}
          </select>
          {customRange}
        </PageHeaderOutline>
        {this.renderMetrics()}
        <Section ref={this.setSearchRef} className="SearchSection" expanded={this.state.expanded}>
          <PaddedArea className="QuerySubsection">
            <QueryLine type="order-returns" throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QuerySummaryLine type="order-returns" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QueryOptionsPresentation className="Settings" type="order-returns" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          </PaddedArea>
        </Section>
        <table ref={this.setResultsTableRef} data-sticky={true} className="Card StickyHeader Results" animate={returnsList.status}>
          <thead>
            {header}
          </thead>
          <tbody>
          {returns.map(renderItem)}
          </tbody>
        </table>
        <QueryPaginatePresentation type="order-returns" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
        <datalist id="return-descriptions">
          {RETURN_DESCRIPTIONS.map((rd,i)=>{
            return <option key={`option${i}`} value={rd.value}>{`${RETURN_REASONS_MAP[rd.reason]}`}</option>;
          })}
        </datalist>
      </div>
    );
  }


}

const mapState = (state, props) => {
  return {
    app: state.app,
    query: dot(state.inventory,"order-returns.list.query") || {},
    returnsList: dot(state.inventory,"order-returns.list") || {},
    returns: dot(state.inventory,"order-returns.list.docs") || [],
    metricsList: dot(state.inventory,"order-returns-status-metrics.list") || {},
    user: dot(state,"session.user")
  }
};

const mapDispatch = (dispatch) => {
  return {
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    queryItems: opts => dispatch(queryItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    loadItem: opts => dispatch(loadItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    closePopup: opts => dispatch(closePopup(opts)),
    setAppField: (f,v)=> dispatch(setAppField(f,v)),
    pushAppNotification: (t,opts) => dispatch(pushAppNotification(t,opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ReturnsPage)
