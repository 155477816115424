import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import HeaderLayout from '../layouts/HeaderLayout';
import SheetLayout from '../layouts/SheetLayout';
import ControlLine from '../layouts/ControlLine';
import ProductLayout from '../layouts/ProductLayout';
import OptionLayout from '../layouts/OptionLayout';
import ImageLayout from '../layouts/ImageLayout';
import DateVal from '../containers/DateVal';
import MoneyVal from '../containers/MoneyVal';
import NameVal from '../containers/NameVal';
import QtyVal from '../containers/QtyVal';
import StateVal from '../containers/StateVal';
import TypeVal from '../containers/TypeVal';
import ActionVal from '../containers/ActionVal';
import SubVal from '../containers/SubVal';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import PlainButton from '../elements/PlainButton';
import ItemSearchSection from '../sections/ItemSearchSection';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRODUCT_SEARCH, PRODUCT_TYPE_MAP } from '../../constants/inventory/Product'
import { openPopup, closePopup } from '../../actions/PopupActions';
import { startDraftItem, purgeDraftItem } from '../../actions/InventoryActions';
import { loadItem, meldItems, setItemQuery } from '../../actions/InventoryActions';
import { dot, deepCopy } from '../../lib/obj';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

import './Page.css';
import './HomePage.css';

// The max number of produts to list
const PRODUCT_LIST_LIMIT = 50;
const CUST_PRODUCT_SEARCH = deepCopy(PRODUCT_SEARCH);
dot(CUST_PRODUCT_SEARCH,"filters.0.options.1.selected",true);
dot(CUST_PRODUCT_SEARCH,"limit",50);

// The item lists to check for loading status
const CHECK_LIST_STATUS = [
  "home-locations.list",
  "home-location-Items.list",
  "received-transfer-Items.list",
  "expected-transfer-Items.list",
  "sent-transfer-Items.list",
  "total-received-transfer-Items.list",
  "total-sent-transfer-Items.list"
];

class HomePage extends Page {

  componentWillMount() {
    // Prepare the query
    let query = {where:[{ deleted: {eq: 0} }]};
    this.props.meldItems({
      type: "locations",
      typeAlias: "home-locations",
      query: query
    }).then(()=>{
      console.log("Loaded");
    });
  }

  onLegacy(evt,history) {
    let id = this.props.id;
    history.push(`/dashboard`);
  }

  onAdd(evt, history) {
    this.props.purgeDraftItem({type:"products",id:"new"});
    history.push("/products/new");
  }

  onViewProduct(evt, history, item) {
    if (item) {
      history.push(`/products/${item.data.id}`);
    }
  }

  onViewLocation(evt, history, location) {
    if (location) {
      history.push(`/locations/${location.data.id}`);
    }
  }

  onViewTransfer(evt, history, transfer) {
    if (transfer) {
      this.props.closePopup();
      history.push(`/transfers/${transfer.data.id}`);
    }
  }

  onItemsLoaded(items) {
    // if (items.length > 0) {
    //   this.loadReceivedInventory(items,()=>{
    //     this.loadSentInventory(items,()=>{
    //       this.loadExpectingInventory(items,()=>{
    //         this.loadTotalReceivedInventory(items,()=>{
    //           this.loadTotalSentInventory(items,()=>{
    //             console.log("Loaded inventory")
    //           });
    //         });
    //       });
    //     });
    //   });
    // }

    if (items.length > 0) {
      this.loadReceivedInventory(items,()=>{
        console.log("Loaded loadReceivedInventory")
      });

      this.loadSentInventory(items,()=>{
        console.log("Loaded loadSentInventory")
      });

      this.loadExpectingInventory(items,()=>{
        console.log("Loaded loadExpectingInventory")
      });

      this.loadTotalReceivedInventory(items,()=>{
        console.log("Loaded loadTotalReceivedInventory")
      });

      this.loadTotalSentInventory(items,()=>{
        console.log("Loaded loadTotalSentInventory")
      });
    }
  }

  genLocationsCondition(field='location') {
    return this.props.locations.map((location) => {
      return {[field]:{eq:location.data.id}};
    });
  }

  genItemsCondition(items) {
    return items.map((item) => {
      return {itemId:{eq:item.data.id}};
    });
  }

  loadReceivedInventory(items, onDone) {
    let query = {
      links:["transfer"],
      fields: ["bookId","id","itemId","transfer.id","transfer.bookId","transfer.status","transfer.destinationId"],
      group:['itemId','transfer.destinationId','transfer.status'],
      where: [
        {deleted: {neq: 1}},
        {'transfer.deleted': {neq: 1}},
        {or: this.genItemsCondition(items)},
        {or: this.genLocationsCondition('transfer.destinationId')},
        {'transfer.status':{eq:"received"}}
      ],
      calcs: [
        "sum.quantity"
      ]
    };

    this.props.meldItems({
      type: "transfer-items",
      typeAlias: "received-transfer-Items",
      query: query
    }).then(()=>{
      if (onDone) { onDone(); }
    });
  }

  loadSentInventory(items, onDone) {
    let query = {
      links:["transfer"],
      fields: ["bookId","id","itemId","transfer.id","transfer.bookId","transfer.status","transfer.sourceId"],
      group:['itemId','transfer.sourceId'],
      where: [
        {deleted: {neq: 1}},
        {'transfer.deleted': {neq: 1}},
        {or: this.genItemsCondition(items)},
        {or: this.genLocationsCondition('transfer.sourceId')},
        {or:[{'transfer.status':{eq:"sent"}},{'transfer.status':{eq:"received"}}]}
      ],
      calcs: [
        "sum.quantity"
      ]
    };

    this.props.meldItems({
      type: "transfer-items",
      typeAlias: "sent-transfer-Items",
      query: query
    }).then(()=>{
      if (onDone) { onDone(); }
    });
  }

  loadExpectingInventory(items, onDone) {
    let query = {
      links:["transfer"],
      fields: ["bookId","id","itemId","transfer.id","transfer.bookId","transfer.status","transfer.destinationId"],
      group:['itemId','transfer.destinationId','transfer.status'],
      where: [
        {deleted: {neq: 1}},
        {'transfer.deleted': {neq: 1}},
        {or: this.genItemsCondition(items)},
        {or: this.genLocationsCondition('transfer.destinationId')},
        {'transfer.status':{eq:"sent"}}
      ],
      calcs: [
        "sum.quantity"
      ]
    };

    this.props.meldItems({
      type: "transfer-items",
      typeAlias: "expected-transfer-Items",
      query: query
    }).then(()=>{
      if (onDone) { onDone(); }
    });
  }

  loadTotalReceivedInventory(items, onDone) {
    let query = {
      links:["transfer"],
      fields: ["bookId","id","itemId","transfer.id","transfer.bookId"],
      group:['itemId'],
      where: [
        {deleted: {neq: 1}},
        {'transfer.deleted': {neq: 1}},
        {'transfer.sourceId': {null: true}},
        {or: this.genItemsCondition(items)},
        {or:[{"transfer.status":{eq:"sent"}},{"transfer.status":{eq:"received"}}]}
      ],
      calcs: [
        "sum.quantity"
      ]
    };

    this.props.meldItems({
      type: "transfer-items",
      typeAlias: "total-received-transfer-Items",
      query: query
    }).then(()=>{
      if (onDone) { onDone(); }
    });
  }


  loadTotalSentInventory(items, onDone) {
    let query = {
      links:["transfer"],
      fields: ["bookId","id","itemId","transfer.id","transfer.bookId"],
      group:['itemId'],
      where: [
        {deleted: {neq: 1}},
        {'transfer.deleted': {neq: 1}},
        {'transfer.destinationId': {null: true}},
        {or: this.genItemsCondition(items)},
        {or:[{"transfer.status":{eq:"sent"}},{"transfer.status":{eq:"received"}}]}
      ],
      calcs: [
        "sum.quantity"
      ]
    };

    this.props.meldItems({
      type: "transfer-items",
      typeAlias: "total-sent-transfer-Items",
      query: query
    }).then(()=>{
      if (onDone) { onDone(); }
    });
  }

  onQuantityClick(location, item, evt) {
    this.props.openPopup({
      name:"List",
      props:{
        title: `${item.data.sku} | ${location.data.name} Transfers`,
        type: "transfer-items",
        typeAlias: "lti",
        query: {
          links: ["transfer"],
          where: [
            {deleted:{neq:1}},
            {itemId:{eq:item.data.id}},
            {"transfer.deleted":{neq:1}},
            {or:[
              {and:[
                {"transfer.sourceId":{eq:location.data.id}},
                {or:[{"transfer.status":{eq:"sent"}},{"transfer.status":{eq:"received"}}]}
              ]},
              {and:[
                {"transfer.destinationId":{eq:location.data.id}},
                {"transfer.status":{eq:"received"}}
              ]},
            ]}
          ],
          limit: 25
        },
        renderItem: (ti, index, optionProps)=>{
          let type = dot(item,"data.type");
          optionProps.image = dot(item,"data.image") || {};
          let subtract = dot(ti,"data.transfer.data.sourceId") == location.data.id;
          return [
            <TypeVal className="Type" type="transfers">{dot(ti,"data.transfer.data.type")}</TypeVal>,
            <ActionVal key="name" onClick={(e,h)=>{this.onViewTransfer(e,h,ti.data.transfer)}}>{dot(ti,"data.transfer.data.name")}</ActionVal>,
            <SubVal className={subtract ? "Subract" : ""} key="available">{dot(ti,"data.quantity")}</SubVal>,
            <StateVal className="Status" type="transfers" bare="true">{dot(ti,"data.transfer.data.status")}</StateVal>
          ]
        },
      }
    });
  }

  onExpectedClick(location, item, evt) {
    this.props.openPopup({
      name:"List",
      props:{
        title: `${item.data.sku} | ${location.data.name} Transfers`,
        type: "transfer-items",
        typeAlias: "lti",
        query: {
          links: ["transfer"],
          where: [
            {deleted:{neq:1}},
            {itemId:{eq:item.data.id}},
            {"transfer.deleted":{neq:1}},
            {"transfer.destinationId":{eq:location.data.id}},
            {"transfer.status":{eq:"sent"}}
          ],
          limit: 25
        },
        renderItem: (ti, index, optionProps)=>{
          let type = dot(item,"data.type");
          optionProps.image = dot(item,"data.image") || {};
          let subtract = dot(ti,"data.transfer.data.sourceId") == location.data.id;
          return [
            <TypeVal className="Type" type="transfers">{dot(ti,"data.transfer.data.type")}</TypeVal>,
            <ActionVal key="name" onClick={(e,h)=>{this.onViewTransfer(e,h,ti.data.transfer)}}>{dot(ti,"data.transfer.data.name")}</ActionVal>,
            <SubVal className={subtract ? "Subract" : ""} key="available">{dot(ti,"data.quantity")}</SubVal>,
            <StateVal className="Status" type="transfers" bare="true">{dot(ti,"data.transfer.data.status")}</StateVal>
          ]
        },
      }
    });
  }

  render() {

    let lim = this.props.locationItemsMap;
    let rtim = this.props.receivedTIDocMap;
    let etim = this.props.expectedTIDocMap;
    let stim = this.props.sentTIDocMap;
    let trtim = this.props.totalReceivedTIDocMap;
    let tstim = this.props.totalSentTIDocMap;
    let locations = this.props.locations;

    let layout = null;
    if (locations.length == 0) {
      layout = <div>Loading...</div>
    }
    else {
      layout = <ItemSearchSection type="products" query={CUST_PRODUCT_SEARCH} onQuerySuccess={(items)=>{this.onItemsLoaded(items)}} renderItem={(item, index)=>{
        let type = item.data.type;
        let quantity = (dot(trtim[item.data.id],"data.calcs.sumQuantity") || 0) - (dot(tstim[item.data.id],"data.calcs.sumQuantity") || 0);
        let rate = dot(item,"data.depletionRate") || 0;
        let image = dot(item,"data.image");
        return <SheetLayout key={`item${index}`}>
          <div className="ProductInfo">
            <ImageLayout className="Image" file={image}/>
            <ProductLayout product={item} onClick={(e,h)=>this.onViewProduct(e,h,item)}/>
          </div>
          {locations.map((location)=>{
            let lkey = `${dot(item,"data.id")}-${dot(location,"data.id")}`;
            let lidoc = lim[lkey];
            let rtidoc = rtim[lkey];
            let etidoc = etim[lkey];
            let stidoc = stim[lkey];
            return <div className="RowGroup" key={lkey}>
              <QtyVal key="qty" onClick={(evt)=>{this.onQuantityClick(location, item, evt)}}>{ (dot(rtidoc,"data.calcs.sumQuantity") || 0) - (dot(stidoc,"data.calcs.sumQuantity") || 0)  }</QtyVal>
              <QtyVal key="exp" onClick={(evt)=>{this.onExpectedClick(location, item, evt)}} className="Expected">{dot(etidoc,"data.calcs.sumQuantity") || 0}</QtyVal>
            </div>
          })}
          <QtyVal>{quantity}</QtyVal>
        </SheetLayout>
      }}>
        <SheetLayout className="Header">
          <div>Product</div>
          {locations.map((location)=>{
            let lkey = `location${dot(location,"data.id")}`;
            return <div><ActionVal key={lkey} onClick={(e,h)=>this.onViewLocation(e,h,location)}>{dot(location,"data.name")}</ActionVal> </div>
          })}
          <div>Total Inventory</div>
        </SheetLayout>
      </ItemSearchSection>
    }


    // Get the docs
    return (
      <div className="Page HomePage">
        <PageHeaderOutline title={this.props.title || "Inventory Summary"} top={this._backButton()}>
          <PlainButton theme="blue" onClick={(evt,history)=>{this.onLegacy(evt,history)}}><FontAwesomeIcon icon="eye" /> Dashboard</PlainButton>
        </PageHeaderOutline>
        { layout }
        <QueryPaginatePresentation type="products" onQuerySuccess={(items)=>{this.onItemsLoaded(items)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {

  let list = dot(state.inventory,"products.list") || {};
  let locationDocs = dot(state.inventory,"home-locations.list.docs") || [];

  let locationItemDocs = dot(state.inventory,"home-location-Items.list.docs") || [];
  let liDocMap = {};
  locationItemDocs.forEach((doc, i) => {
    liDocMap[`${dot(doc,"data.itemId")}-${dot(doc,"data.locationId")}`] = doc;
  });

  let receivedTIDocs = dot(state.inventory,"received-transfer-Items.list.docs") || [];
  let receivedTIDocMap = {};
  receivedTIDocs.forEach((doc, i) => {
    receivedTIDocMap[`${dot(doc,"data.itemId")}-${dot(doc,"data.transfer.data.destinationId")}`] = doc;
  });

  let expectedTIDocs = dot(state.inventory,"expected-transfer-Items.list.docs") || [];
  let expectedTIDocMap = {};
  expectedTIDocs.forEach((doc, i) => {
    expectedTIDocMap[`${dot(doc,"data.itemId")}-${dot(doc,"data.transfer.data.destinationId")}`] = doc;
  });

  let sentTIDocs = dot(state.inventory,"sent-transfer-Items.list.docs") || [];
  let sentTIDocMap = {};
  sentTIDocs.forEach((doc, i) => {
    sentTIDocMap[`${dot(doc,"data.itemId")}-${dot(doc,"data.transfer.data.sourceId")}`] = doc;
  });

  let totalReceivedTIDocs = dot(state.inventory,"total-received-transfer-Items.list.docs") || [];
  let totalReceivedTIDocMap = {};
  totalReceivedTIDocs.forEach((doc, i) => {
    totalReceivedTIDocMap[dot(doc,"data.itemId")] = doc;
  });

  let totalSentTIDocs = dot(state.inventory,"total-sent-transfer-Items.list.docs") || [];
  let totalSentTIDocMap = {};
  totalSentTIDocs.forEach((doc, i) => {
    totalSentTIDocMap[dot(doc,"data.itemId")] = doc;
  });

  return {
    list: list,
    locations: locationDocs,
    products: list.docs || [],
    locationItems: locationItemDocs,
    locationItemsMap: liDocMap,
    receivedTIDocMap: receivedTIDocMap,
    expectedTIDocMap: expectedTIDocMap,
    sentTIDocMap: sentTIDocMap,
    totalReceivedTIDocMap: totalReceivedTIDocMap,
    totalSentTIDocMap: totalSentTIDocMap
  }
};

const mapDispatch = (dispatch) => {
  return {
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    loadItem: opts => dispatch(loadItem(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(HomePage)
