import React from 'react';
import Area from './Area';
import './FooterArea.scss';

class FooterArea extends React.Component {
  render() {
    let className = this.props.className || "";

    return (
      <div className={`FooterArea Area ${className}`} data-fit={this.props.fit}>
        {this.props.children}
      </div>
    );
  }
}

export default FooterArea;
