import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'

import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './Dashboard.scss';

import { meldItems, queryItems } from '../../actions/InventoryActions';
import { _ } from '../../lib/underscore';
import { dot, reg, deepCopy, mapk2k, mappify } from '../../lib/obj';
import { financial, abbr } from '../../lib/formats';
import moment from 'moment';

const FIVE_MIN_MS = 5*60*1000;
//const FIVE_MIN_MS = 15*1000;

const WALLET_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {"item.sku": {begins:"MBWLT"}}
  ]
};

const CASE_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {or: [
      {"item.sku": {begins:"MBC"}}
    ]}
  ]
}

const SAMSUNG_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {"item.sku": {begins:"MBS2"}}
  ]
}

const PIXEL_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {"item.sku":{in: ["MBP6P-BKS1","MBP7P-BKS1","MBP8P-BKS1"]}}
  ]
}

const MULTI_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {"item.sku": {eq:"MBQIML-DGUS"}}
  ]
}

const TESLA_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {or: [
      {"item.sku": {begins:"MBT3Y"}},
      {"item.sku": {begins:"MBTCT"}},
      {"item.sku": {begins:"MBT24"}},
    ]}
  ]
}

const RIMCASE_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {"item.sku": {begins:"RPT"}}
  ]
}

const KEYTAG_BASE_QUERY = {
  type: "order-items",
  links: ["item"],
  fields: ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"],
  calcs:["sum.quantity","sum.totalCost"],
  where: [
    {deleted: {neq:1}},
    {"item.sku":{in: ["MBKH-DG","MBKH-RG"]}}
  ]
}

class Dashboard extends Page {

  constructor(props) {
    super(props);
    this.setTableRef = (el) =>{ this.table = el; }
    this.state = {};
  }

  componentWillMount() {
    this.queryInfo();
    this.refreshInterval = setInterval(()=>{
      this.queryInfo();
    },FIVE_MIN_MS);
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  }

  queryInfo() {
    let now = new Date();
    let ago = now.addYears(-1);

    this.queryStats(now, WALLET_BASE_QUERY, "wallets")
    .then(()=>{
      this.queryStats(ago, WALLET_BASE_QUERY, "wallets-last");
    });

    this.queryStats(now, CASE_BASE_QUERY, "cases")
    .then(()=>{
      this.queryStats(ago, CASE_BASE_QUERY, "cases-last");
    });

    this.queryStats(now, SAMSUNG_BASE_QUERY, "samsung")
    .then(()=>{
      this.queryStats(ago, SAMSUNG_BASE_QUERY, "samsung-last");
    });

    this.queryStats(now, PIXEL_BASE_QUERY, "pixel")
    .then(()=>{
      this.queryStats(ago, PIXEL_BASE_QUERY, "pixel-last");
    });

    this.queryStats(now, MULTI_BASE_QUERY, "multi")
    .then(()=>{
      this.queryStats(ago, MULTI_BASE_QUERY, "multi-last");
    });

    this.queryStats(now, TESLA_BASE_QUERY, "tesla")
    .then(()=>{
      this.queryStats(ago, TESLA_BASE_QUERY, "tesla-last");
    });

    this.queryStats(now, RIMCASE_BASE_QUERY, "rimcase")
    .then(()=>{
      this.queryStats(ago, RIMCASE_BASE_QUERY, "rimcase-last");
    });

    this.queryStats(now, KEYTAG_BASE_QUERY, "keytag")
    .then(()=>{
      this.queryStats(ago, KEYTAG_BASE_QUERY, "keytag-last");
    });

    this.setState({now:now,ago:ago});
  }

  queryStats(date, bq, group) {
    let ytd = deepCopy(bq);
    //ytd.group = ["year:createdAt:us_la"];
    ytd.where.push({
      createdAt: {
        between: [date.startOfYear().srvstr({utc:true}), date.srvstr({utc:true})]
      }
    });

    let mtd = deepCopy(bq);
    //mtd.group = ["month:createdAt:us_la"];
    mtd.where.push({
      createdAt: {
        between: [date.startOfMonth().srvstr({utc:true}), date.srvstr({utc:true})]
      }
    });

    let dth = deepCopy(bq); // Might have issues with this one
    //dth.group = ["date:createdAt:us_la"];
    dth.where.push({
      createdAt: {
        between: [date.addDays(-30).srvstr({utc:true}), date.srvstr({utc:true})]
      }
    });

    return this.props.queryItems({queries:[
      {
        ...ytd,
        typeAlias: "dashboard",
        idAlias: group,
        childTypeAlias: "ytd",
      },
      {
        ...mtd,
        typeAlias: "dashboard",
        idAlias: group,
        childTypeAlias: "mtd",
      },
      {
        ...dth,
        typeAlias: "dashboard",
        idAlias: group,
        childTypeAlias: "dth",
      }
    ]})
  }

  reanderHeader() {
    let now = this.state.now;
    let ago = this.state.ago;
    return <tr>
      <th className="Category"></th>
      <th className="YTD">
        YTD
        <div className="Sub">{moment(now.startOfYear()).format("MMM D")} - {moment(now).format("MMM D")}</div>
      </th>
      <th className="MTD">
        MTD
        <div className="Sub">{moment(now.startOfMonth()).format("MMM D")} - {moment(now).format("MMM D")}</div>
      </th>
      <th className="DTH">
        30 Days
        <div className="Sub">{moment(now).format("MMM D LT")}</div>
      </th>
    </tr>;
  }

  renderCard(group, period) {
    let curr = this.props.dashboard[group];
    let last = this.props.dashboard[`${group}-last`]
    let currData = dot(curr,[period,"list","docs",0,"data"]) || {};
    let lastData = dot(last,[period,"list","docs",0,"data"]) || {};

    let currCost = dot(currData,"calcs.sumTotalCost") || 0;
    let lastCost = dot(lastData,"calcs.sumTotalCost") || 0;
    let currQty = dot(currData,"calcs.sumQuantity") || 0;
    let lastQty = dot(lastData,"calcs.sumQuantity") || 0;

    let change = lastCost > 0 ? (((currCost-lastCost)/lastCost) * 100) : "*";
    let trend = "flat";
    if (change > 0) { trend = "positive"; }
    if (change < 0) { trend = "negative"; }

    let currStatus = dot(curr,[period,"list","status"]) || "loading";
    let lastStatus = dot(last,[period,"list","status"]) || "loading";

    let blank = currData.id > 0 || lastData.id > 0 ? "false" : "true";

    return <div className="RevCard" blank={blank} trend={trend} animate={currStatus == "loading" || lastStatus == "loading" ? "loading" : "done"}>
      <div className="Change" trend={trend}>{change != "*" ? Math.round(change) : change}</div>
      <div className="Last">
        <div className="Revenue">{abbr(lastCost,{prefix:"$"})}</div>
        <div className="Quantity">{abbr(lastQty)}</div>
      </div>
      <div className="Current">
        <div className="Revenue">{abbr(currCost,{prefix:"$"})}</div>
        <div className="Quantity">{abbr(currQty)}</div>
      </div>
    </div>;
  }

  renderRows() {
    return <React.Fragment>
      <tr>
        <td className="Category">Wallets</td>
        <td className="YTD">
          {this.renderCard("wallets","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("wallets","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("wallets","dth")}
        </td>
      </tr>
      <tr>
        <td className="Category">iPhone<br/>Case</td>
        <td className="YTD">
          {this.renderCard("cases","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("cases","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("cases","dth")}
        </td>
      </tr>

      <tr>
        <td className="Category">Samsung<br/>Case</td>
        <td className="YTD">
          {this.renderCard("samsung","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("samsung","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("samsung","dth")}
        </td>
      </tr>

      <tr>
        <td className="Category">Pixel<br/>Case</td>
        <td className="YTD">
          {this.renderCard("pixel","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("pixel","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("pixel","dth")}
        </td>
      </tr>

      <tr>
        <td className="Category">MultiCharger</td>
        <td className="YTD">
          {this.renderCard("multi","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("multi","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("multi","dth")}
        </td>
      </tr>
      <tr>
        <td className="Category">Tesla<br/>Charger</td>
        <td className="YTD">
          {this.renderCard("tesla","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("tesla","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("tesla","dth")}
        </td>
      </tr>
      <tr>
        <td className="Category">RimCase</td>
        <td className="YTD">
          {this.renderCard("rimcase","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("rimcase","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("rimcase","dth")}
        </td>
      </tr>
      <tr>
        <td className="Category">KeyTag</td>
        <td className="YTD">
          {this.renderCard("keytag","ytd")}
        </td>
        <td className="MTD">
          {this.renderCard("keytag","mtd")}
        </td>
        <td className="DTH">
          {this.renderCard("keytag","dth")}
        </td>
      </tr>
    </React.Fragment>
  }

  render() {

    // Handle to the state
    let state = this.state;
    let now = this.state.now;
    let ago = this.state.ago;

    return (
      <div className="Page Dashboard">
        <PageHeaderOutline title="Revenues">

        </PageHeaderOutline>
        <table ref={this.setTableRef} data-sticky={true}>
          <thead>
            <tr><th></th><th colspan="3">{moment(ago).format("YYYY")} VS {moment(now).format("YYYY")}</th></tr>
            {this.reanderHeader()}
          </thead>
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let inventory = state.inventory;
  return {
    dashboard: inventory.dashboard || {},
    inventory: inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    meldItems: opts => dispatch(meldItems(opts)),
    queryItems: opts => dispatch(queryItems(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(Dashboard)
