import React from 'react';
import Element from './Element';
import './Input.css';

import { _ } from '../../lib/underscore';

class Input extends Element {

  constructor(props) {
    super(props);
    let value = this.props.value;
    this.state = {
      focused:false,
      filled: value != null && value.toString().length > 0,
      value: value,
      orig: value
    };
  }

  onKeyDown(evt, type) {
    if (evt.key === 'Enter') {
      if (this.props.onEnter) {
        this.props.onEnter(evt);
        this.setState({orig:this.state.value});
      }
    }
  }

  onFocus(evt, type) {
    this.setState({focused:true, orig:this.state.value});
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  }

  onBlur(evt, type) {
    let orig = this.state.orig;
    this.setState({focused:false, orig:this.state.value});
    if (this.props.onBlur) {
      this.props.onBlur(evt, this.state.value, orig);
    }
  }

  onChange(evt, type) {
    let value = evt.target.value;

    // Update State
    this.setState({
      value: value,
      filled: value !== null && value !== undefined && value.toString().length > 0
    });

    // Check for onChange callback
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  onClear(evt) {

    // Force to clear the input
    this.refs.input.value = "";  

    // Update State
    this.setState({
      value: "",
      filled: false,
      orig: null
    });

    // Check for onChange callback
    if (this.props.onChange) {
      this.props.onChange("");
    }

    // Check for onClear callback
    if (this.props.onClear) {
      this.props.onClear(evt);
    }
  }

  render() {

    // Get the input type
    let type = this.props.type || "text";

    // Check for label
    let label;
    if (this.props.label && !this.props.placeholder) {
      label = <div className="Label">{this.props.label}</div>
    }

    // Get the value
    //let value = this.state.focused ? undefined : (this.props.value === undefined || this.props.value === null ? '' : this.props.value);
    let value = this.props.value;

    // Determine if the input is filled
    let filled = value ? value.toString().length > 0 : this.state.filled;

    // Check if value is dirty
    let orig = this.state.orig;

    let className = this.props.className || "";
    return (
      <div className={`Input ${className}`} ref="root"
        data-labeled={label ? true : undefined}
        data-focused={this.state.focused}
        data-filled={filled}
        data-lock={this.props.lock}
        data-error={this.props.error}
        data-clearable={this.props.clearable}
        data-type={this.props.type || "text"}
        data-dirty={orig != this.state.value}>
        {label}
        <input key="field" className="Field" ref="input"
          name={this.props.name}
          onKeyDown={(evt)=>this.onKeyDown(evt, type)}
          onFocus={(evt)=>this.onFocus(evt, type)}
          onBlur={(evt)=>this.onBlur(evt, type)}
          onChange={(evt)=>this.onChange(evt, type)}
          type={type}
          autoComplete="off"
          placeholder={this.props.placeholder}
          defaultValue={value}
          />
        <div key="control" className="Control" onClick={(evt)=>this.onClear(evt)}>&times;</div>
      </div>
    );
  }
}

export default Input;
