import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import PaddedArea from '../areas/PaddedArea';
import RectButton from '../elements/RectButton';
import ProductLayout from '../layouts/ProductLayout';
import ImageLayout from '../layouts/ImageLayout';
import FooterArea from '../areas/FooterArea';
import { connect } from 'react-redux'

import './Popup.css';
import './FormPopup.css';

import { closePopup } from '../../actions/PopupActions';
import { dot } from '../../lib/obj';

class FormPopup extends Popup {

  constructor() {
    super();
    this.state = {};
  }

  onCancel(evt, history) {
    this.props.closePopup();
  }

  onOk(evt, history) {
    let form = this.refs.form;
    if (this.props.onOk) {
      let fd = new FormData(form);
      let data = {};
      for (const [key, value] of fd) {data[key] = value;}
      this.props.onOk(data, this);
    }
    else {
      this.props.closePopup();
    }
  }

  render() {
    // Setup buttons
    let cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>
      {this.props.cancelLabel || "Cancel"}
    </RectButton>;

    // Setup buttons
    let okButton = <RectButton theme={this.props.okTheme || "blue"} working={this.state.working} onClick={(evt,history)=>{this.onOk(evt, history)}}>
      { this.props.okLabel  || "Ok" }
    </RectButton>;

    // Check for render function
    let rendered;
    if (this.props.onRender) {
      rendered = this.props.onRender(this);
    }

    return (
      <div className="Popup FormPopup" ref="root">
        <Section>
          <PaddedArea>
            <h3 className="Title">{this.props.title || "Form"}</h3>
            <Separator/>
            <form className="Form" ref="form" onSubmit={(e)=>this.handleSubmit(e)}>
              {rendered}
              {this.props.children}
            </form>
            <Separator/>
            <FooterArea>
              {cancelButton}
              {okButton}
            </FooterArea>
          </PaddedArea>
        </Section>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return props.onMapState ? (props.onMapState(state) || {}) : {};
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(FormPopup)
