import { mapk2k } from '../../lib/obj'

export const ACTION_TYPES = [
  { value:"update",    name: "Update",    desc: "Update action"},
  { value:"create",    name: "Create",    desc: "Create action"},
  { value:"delete",    name: "Delete",    desc: "Delete action"}
];

export const ACTION_SEARCH = {
  type: "actions",
  links: ["user"],
  limit: 50,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["user.email","user.firstName","user.lastName"],
    keymap: {
      id: "subjectId",
      email: "user.email"
    }
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "type",
      options: ACTION_TYPES
    }
  ],
  sort: [
    { field: "createdAt",       name: "Action (Oldest to Newest)",      direction:"asc"},
    { field: "createdAt",       name: "Action (Newest to Oldest)",     direction:"desc",  selected: true}
  ]
}

export const ACTION_TYPES_MAP = mapk2k(ACTION_TYPES,"value","name");
