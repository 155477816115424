import React from 'react';

class Menu extends React.Component {
  render() {
    return (
      <div>Hello menu!</div>
    );
  }
}

export default Menu;
