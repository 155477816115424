import * as ACTIONS from '../actions/ActionTypes';
import { dot, deepCopy } from '../lib/obj';



// Edits a specific form
const config = function(state, action) {
  let newState = deepCopy(state);
  if (newState && action.params) {
    newState.params = {...state.params, ...action.params};
  }
  return newState;
}

// Edits a specific form
const edit = function(state, action) {
  let newState = deepCopy(state);
  if (newState && action.field) {
    dot(newState.data, action.field, action.value);
  }
  return newState;
}

/**
 * Main forms reducer
 */
export const forms = function(state = {}, action = {}) {
  switch (action.type) {
    case ACTIONS.START_FORM: {
      let newState = {...state};
      if (!newState[action.name] || action.force) {
        newState[action.name] = {
          params: action.params || {},
          data: action.data || {}
        }
      }
      return newState;
    }
    case ACTIONS.CLEAR_FORM: {
      let newState = {...state};
      delete newState[action.name];
      return newState;
    }
    case ACTIONS.CONFIG_FORM: {
      let newState = {...state};
      newState[action.name] = config(newState[action.name], action);
      return newState;
    }
    case ACTIONS.EDIT_FORM: {
      let newState = {...state};
      newState[action.name] = edit(newState[action.name], action);
      return newState;
    }
    default:
      return state;
  }
};
