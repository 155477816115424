import * as ACTIONS from './ActionTypes';

let nextPopupId = 1;

export const openPopup = (opts={}) => {
  opts.id = ++nextPopupId;
  return {
    type: ACTIONS.OPEN_POPUP,
    opts: opts
  }
}

export const closePopup = (opts={}) => {
  return {
    type: ACTIONS.CLOSE_POPUP,
    opts: opts
  }
}
