import * as ACTIONS from '../actions/ActionTypes';
import { dot } from '../lib/obj.js'
import { createReducer } from 'redux-starter-kit'

/**
 * Set the session login
**/
const setSessionLogin = function(state, action) {
  state.login = dot(action.opts.login,"data");
  state.user = dot(action.opts.user,"data");
};

/**
 * Set the session book
**/
const setSessionBook = function(state, action) {
  var book = action.opts.book;
  state.book = dot(book,"data");
};

/**
 * Logout session
**/
const logoutSession = function(state, action) {
  delete state.login;
};

/*
 * Main inventory reducer
 * NOTE: Users Immer to more easily handle immutable state updates
 * Ref: https://redux-starter-kit.js.org/api/createreducer
**/
export const session = createReducer({}, {
  [ACTIONS.SET_SESSION_LOGIN]: setSessionLogin,
  [ACTIONS.SET_SESSION_BOOK]: setSessionBook,
  [ACTIONS.LOGOUT_SESSION]: logoutSession
})
