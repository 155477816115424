import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Separator from '../elements/Separator';
import CollectionForm from '../forms/CollectionForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import LoadableArea from '../areas/LoadableArea';
import HeaderLine from '../layouts/HeaderLine';

import ProductLayout from '../layouts/ProductLayout';
import TableSearchSection from '../wrappers/TableSearchSection';

import { dot, reg, hasKeys, deepCopy } from '../../lib/obj';
import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

import { loadItem, listItems, pushItems, deleteItem, startDraftItem, setItemAction, purgeItem, setItemQuery } from '../../actions/InventoryActions';
import { openPopup } from '../../actions/PopupActions';

import { PRODUCT_SEARCH } from '../../constants/inventory/Product'
import { COLLECTION_SEARCH, COLLECTION_ITEM_SEARCH } from '../../constants/inventory/Collection'

import './Page.css';
import './CollectionPage.css';

class CollectionPage extends Page {

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      this.props.loadItem({type:"collections",id:id});
    }
  }

  onSave (evt, history) {

    // 1st, attempt to push the changes
    let collection = this.props.collection;
    this.props.pushItems([{type:"collections",
      id: this.props.id,
      docs: {collection:collection},
      action: collection.action
    }]).then((res)=>{

      // Get the id
      let resCollection = dot(res,"0.collection");
      let id = dot(resCollection,"data.id") || this.props.id;

    });
  }

  needsSave() {
    // Check po for sets
    if( hasKeys(dot(this.props.collection,"sets")) ) {
      return true;
    }
    return false;
  }

  onCancel(evt,history) {
    history.goBack();
  }

  onDelete(evt,history) {
    this.props.openPopup({name:"Confirm",
      props:{
        title: "Delete Collection",
        message: "Are you sure you want to delete this Collection? You will NOT be able to undo this action.",
        onOk:()=>{
          this.props.deleteItem({type:"collections", id: this.props.id}).then(()=>{
            history.replace("/collections");
          });
        },
        okTheme: "red",
        okLabel: "Delete Collection"
    }});
  }

  onAddItem(f) {
    this.props.openPopup({name:"Search",
      props:{
        title: "Select products.",
        type: "products",
        query: deepCopy(PRODUCT_SEARCH),
        renderItem: (item, index, optionProps)=>{
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        onOk:(items)=>{
          f(items.map((doc)=>{
            return {
              collectionId: this.props.id,
              itemId: doc.data.id,
              item: doc,
              image: doc.data.image
            }
          }));
        }
      }
    });
  }

  onViewItem(item, history, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (item && item.data.itemId) {
      history.push(`/products/${item.data.itemId}`);
    }
  }

  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";
    let collection = this.props.collection;

    // Check if we need to save
    let needsSave = this.needsSave()

    // Get handle to the Items
    let items = dot(collection, "items.list.docs") || [];
    let itemsStatus = dot(collection, "items.list.status");

    // Check to see if sending collection
    let status = dot(collection, "status");
    let isSending = status == "saving" || status == "creating";
    if (!isSending) {
      for (var i = 0; i < items.length; i++) {
        status = dot(items[i],"status");
        isSending = status == "saving" || status == "creating";
        if (isSending) { break; }
      }
    }

    // Setup buttons
    let cancelButton = null;
    let saveButton = null;
    if (isNew) {
      cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}}>Create</RectButton>;
    }
    else {
      cancelButton = <RectButton theme="red" onClick={(evt,history)=>{this.onDelete(evt,history)}}>Delete</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}} lock={!needsSave}>Save</RectButton>;
    }

    return (
      <div className="Page CollectionPage">
        <Prompt
          when={needsSave}
          message='Are you sure you want to leave? You have unsaved changes.'
        />
        <PageHeaderOutline title={`${dot(collection,"data.name") || "Collection"}`} aside={saveButton} top={this._backButton()}></PageHeaderOutline>
        <Section key="details" shout="Details" working={status == "loading"}>
          <HeaderLine>Details</HeaderLine>
          <LoadableArea working={status == "loading"}>
            <CollectionForm collection={collection}/>
          </LoadableArea>
        </Section>

        <TableSearchSection
        label="Components" className="Components" controllable="true"
        type="collection-items" typeAlias="collections" idAlias={id} childTypeAlias="items"
        query={deepCopy(COLLECTION_ITEM_SEARCH)}
        conditions={id ? [{collectionId:{eq:id}}] : undefined}
        reload={true}
        onAddItem={(f)=>{this.onAddItem(f)}}
        layout={[
          {title:" ",         field:"image.data.presignedS3GetUrl", type:"image", className:"Icon"},
          {title:"Name",      type:"custom", render:(d,i)=>{return <ProductLayout key="product" product={d.data.item} onClick={(e,h)=>this.onViewItem(d,h,e)}/> } },
          {title:"Price",     field:"item.data.cogs", type:"plain", default:"0" }
        ]}/>

        <Separator/>
        <Row align="space-between">
          {cancelButton}
          {saveButton}
        </Row>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let collection = dot(state.inventory,["collections",props.id]) || {};
  return {
    collectionName: dot(collection,"data.name"),
    collection: collection,
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    loadItem: opts => dispatch(loadItem(opts)),
    listItems: opts => dispatch(listItems(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    pushItems: arr => dispatch(pushItems(arr)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    setItemAction: opts => dispatch(setItemAction(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(CollectionPage)
