import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import LoginForm from '../forms/LoginForm'
import { connect } from 'react-redux'

import '../elements/RectButton.css';
import './Popup.css';
import './UploadPopup.css';

import { closePopup } from '../../actions/PopupActions';
import { createItem, loadItem, listItems, setItemQuery } from '../../actions/InventoryActions';
import { postFile } from '../../actions/AWSActions';
import { awsPostS3File } from '../../apis/AWS';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot } from '../../lib/obj';
import FilterLine from '../layouts/FilterLine';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

class UploadPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      uploading:false,
      files: []
    };
  }

  componentWillMount() {

  }

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onUpload(evt, history) {
    this.setState({uploading:true});

    let files = this.state.files;
    let file = files[0].file || {};
    this.props.createItem({
      type: "files",
      id: "new",
      docs: {
        file: {
          data: {
            type: "book",
            name: file.name,
            size: file.size,
            mime: file.type
          }
        }
      }
    }).then((res)=>{
      console.log("RES:",res);
      this.setState({uploading:true});
      // this.props.postFile({file:file, prereq:dot(res,"file.data.prereq")}).then((pres)=>{
      //   this.setState({uploading:false});
      //   if (this.props.onUpload) {
      //     this.props.onUpload([res.file]);
      //   }
      // });
      awsPostS3File({
        file: file,
        prereq: dot(res,"file.data.prereq"),
        // onStart: ()=>{this.setItemStatus(item,"started")},
        // onAbort: ()=>{this.setItemStatus(item,"aborted")},
        // onError: ()=>{this.setItemStatus(item,"failed")},
        onLoad: ()=> {
          this.props.onUpload([res.file]);
        },
        //onProgress: (evt)=>{this.setItemProgress(item,evt.loaded, evt.total)},
      });
    });
  }

  onFileChanged(evt) {
    console.log("EVT",evt.target.value);
  }

  onChange(evt) {
    console.log("onChange",evt.target.files[0]);
    let files = this.state.files;
    files.push({
      file: evt.target.files[0]
    });
    this.setState({files:files});
  }

  render() {

    // Get handle for the files
    let files = this.state.files || [];

    // Setup buttons
    let cancelButton = <RectButton className="Cancel" theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>
      {this.props.cancelLabel || "Cancel"}
    </RectButton>;

    // Setup buttons
    let okButton = <RectButton className="Ok" theme={this.props.okTheme || "blue"} lock={files.length == 0} working={this.state.uploading} onClick={(evt,history)=>{this.onUpload(evt, history)}}>
      {this.props.okLabel || "Upload"}
    </RectButton>;

    return (
      <div className="Popup UploadPopup" ref="root">
        <div className="Title">
          {this.props.title || "Upload Files"}
        </div>
        <div className="Files">
          {files.map((data,index)=>{
            return <div key={`file${index}`}>
              {`File(${index}): ${data.file.name}`}
            </div>;
          })}
          <input type="file" id="avatar" name="+" onChange={(evt)=>this.onChange(evt)}/>
        </div>
        {cancelButton}
        {okButton}
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {

  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    listItems: opts => dispatch(listItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    createItem: opts => dispatch(createItem(opts)),
    postFile: opts => dispatch(postFile(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(UploadPopup)
