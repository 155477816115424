import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './ControlLine.scss';
import ImageLayout from './ImageLayout';
import ActionVal from '../containers/ActionVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ControlLine extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  onSettings(evt) {
    if (this.props.onSettings) {
      this.props.onSettings(evt, this.props.history);
    }
  }

  render() {
    let children = this.props.children;
    let clickable = this.props.onClick != undefined;
    let settable = this.props.onSettings != undefined;
    let image = this.props.image;
    let className = this.props.className || "";
    return (
      <div className={`ControlLine Line ${className}`} onClick={(evt)=>{this.onClick(evt)}} data-image={image != undefined} data-clickable={clickable} data-settable={settable}>
        <ImageLayout className="Image" file={image}/>
        <div className="Content">
          {children}
        </div>
        <ActionVal className="Settings">
          <FontAwesomeIcon className="Icon" icon={["fal","ellipsis-v"]} onClick={evt=>this.onClick(evt)}/>
        </ActionVal>
      </div>
    );
  }
}

export default withRouter(ControlLine);
