import React from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Layout from './Layout';
import Input from '../elements/Input';
import CheckControl from '../controls/CheckControl';
import PlainButton from '../elements/PlainButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './QueryLine.scss';

import { setItemQuery } from '../../actions/InventoryActions';
import { _ } from '../../lib/underscore';
import { financial, capitalize } from '../../lib/formats';
import { dot, deepCopy } from '../../lib/obj';
import { stateForList } from '../../lib/inventory-util';

class QueryLine extends Layout {

  onTermChange(term, force) {
    if (this.props.progressive === true || force) {
      let props = this.props;
      let config = deepCopy(props.config);
      dot(config,"term.value", term);
      if (!term || term.length > 1 || term.length == 0) {
        this.onConfigChange(config);
      }
    }
  }

  onFilterChange(filter, options, index, filterIndex) {
    let props = this.props;
    let config = deepCopy(props.config);
    dot(config,["filters",filterIndex,"options"], options);
    this.onConfigChange(config);
  }

  onSortChange(options, index) {
    let props = this.props;
    let config = deepCopy(props.config);
    dot(config,"sort", options);
    this.onConfigChange(config);
  }

  onConfigChange(config) {

    // Make sure config offset is cleared
    delete config.offset;

    // Set the item query
    this.props.setItemQuery({
      type: this.props.type,
      typeAlias: this.props.typeAlias,
      id: this.props.id,
      idAlias: this.props.idAlias,
      childType: this.props.childType,
      childTypeAlias: this.props.childTypeAlias,
      query: config
    });

    // Report config chagne
    if (this.props.throttle) {
      if (!this._queryThrottle) {
        this._queryThrottle = _.enqueue((config) => {
          if (this.props.onConfigChange) {
            this.props.onConfigChange(config, this._queryThrottle);
          }
        }, this.props.timeout || 5000);
      }
      this._queryThrottle(config)
    }
    else {
      if (this.props.onConfigChange) {
        this.props.onConfigChange(config, this._queryThrottle);
      }
    }
  }

  onExpand(evt, history) {
    if (this.props.onExpand) {
      this.props.onExpand(evt, history);
    }
  }

  renderFilter(filter, filterIndex) {
    return <CheckControl key={`filter${filterIndex}`} className={`Filter Filter${filterIndex}`} slave={true} options={filter.options} value={filter.label} type={filter.type} radio={filter.radio} onChange={(options, index)=>{this.onFilterChange(filter, options, index, filterIndex);}}/>
  }

  render() {
    let config = this.props.config || {};
    let term = config.term || {};
    let filters = config.filters || [];
    let className = this.props.className || "";
    return (
      <div ref="root" className={`QueryLine ${className} Line`} data-filtered={filters.length > 0} data-filters={filters.length}>
        <div className="Icon"><FontAwesomeIcon icon="search" /></div>
        <Input label={this.props.label || term.label} placeholder={this.props.placeholder || "Search"} value={term.value} clearable="true" onChange={(value)=>{this.onTermChange(value)}} onEnter={(evt)=>{this.onTermChange(evt.target.value, true)}} onClear={(evt)=>{this.onTermChange("", true)}}/>
        {filters.map((filter, index)=>{
          return this.renderFilter(filter,index);
        })}
        <PlainButton className="FilterButton Setting" theme="blue" onClick={(evt,history)=>this.onExpand(evt,history)}><FontAwesomeIcon icon={["far","filter"]} /></PlainButton>
        <CheckControl className="Setting Sort" options={config.sort} value="" icon={<FontAwesomeIcon icon="sort-alt" />} radio="true" onChange={(options, index)=>{this.onSortChange(options, index);}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = stateForList(state.inventory,props);
  return {
    config: dot(list,"query") || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemQuery: opts => dispatch(setItemQuery(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(QueryLine);
