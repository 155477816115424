import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import DropControl from '../controls/DropControl';
import DateControl from '../controls/DateControl';
import Input from '../elements/Input';
import Readout from '../elements/Readout';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dot } from '../../lib/obj';
import { setItemField } from '../../actions/InventoryActions';
import { stateForDoc } from '../../lib/inventory-util';

import { PAYMENT_TYPES, PAYMENT_TYPE_MAP } from '../../constants/inventory/Payment.js';

import './Form.css';
import './VendorItemForm.css';

class VendorItemForm extends Form {

  // Edit the given field
  editField(field, value) {
    let opts = this.props.opts;
    this.props.setItemField({field:field, value:value, ...opts});
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.vendorItem,"data") || {};
    let vendorData = dot(this.props.vendor,"data") || {};
    let productData = dot(this.props.product,"data") || {};
    return (
      <div className="Form VendorItemForm">
        <Readout className="Vendor" label="Vendor">{vendorData.name}</Readout>
        <Readout className="Product" label="Product">{productData.name}</Readout>
        <Input className="URL" label="URL" type="text" value={data.url} onChange={(val)=>{this.editField("url",val)}}/>
        <Input className="LeadTime" label="Lead Time (days)" type="number" value={data.leadTime} onChange={(val)=>{this.editField("leadTime",val)}}/>
        <Input className="Price" label="Price ($)" type="number" value={data.unitCost} onChange={(val)=>{this.editField("unitCost",val)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let inventory = state.inventory;
  let opts = props.opts || {};
  let vendorItem = stateForDoc(state.inventory,{
    type: "vendors",
    id: "new",
    childType: "items",
    ...opts
  });
  let vendor = dot(vendorItem,"data.vendor");
  let productId = dot(vendorItem,"data.itemId")
  let product = dot(vendorItem,"data.item") || dot(inventory,["products",productId]);
  return {
    opts: opts,
    inventory: inventory,
    vendorItem: vendorItem,
    vendor: vendor,
    product: product
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(VendorItemForm)
