import React from 'react';
import Element from './Element';
import { _ } from '../../lib/underscore';

class BareInput extends Element {
  constructor(props) {
    super(props);
    this.state = { value: props.defaultValue };
  }

  onKeyDown(e) {
    let props = this.props;
    if (props.onKeyDown) {
      props.onKeyDown(e)
    }
    if (e.key === 'Enter') {
      if (props.onEnter) {
        props.onEnter(e);
      }
      if (props.onBlurOrEnter) {
        props.onBlurOrEnter(e)
      }
    }
    if(e.key === "Escape") {
      let target = e.target;
      target.value = props.defaultValue !== undefined ? props.defaultValue : "" ;
      this.setState({value:props.defaultValue},()=>{
        target.blur();
      });
    }
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
    if (this.props.onBlurOrEnter) {
      this.props.onBlurOrEnter(e)
    }
  }

  onChange(e) {
    this.setState({value:e.target.value})
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    let props = _.omit(this.props,["onBlurOrEnter","onEnter"]);
    let state = this.state;
    return (
      <input data-dirty={state.value != props.defaultValue} {...props} onKeyDown={(e)=>this.onKeyDown(e)} onBlur={(e)=>this.onBlur(e)} onChange={(e)=>this.onChange(e)}/>
    );
  }
}

export default BareInput;
