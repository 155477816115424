import { _ } from './underscore';

export const fetch2 = function(url, options={}) {
  console.log('fetch2 options: ', url, options)
  let promptError = false;
  if(options.queryParams) {
    promptError = options.queryParams.promptError;
    url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.queryParams);
    delete options.queryParams;
  }
  return new Promise((resolve, reject)=>{
    fetch(url, options)
    .then(response=>response.json())
    .then((json)=>{
      if (json.error) {
        if (promptError) {
          try {
            window.alert(`Oops! Something whent wrong.\n\nPlease share this with info with IT:\n\n${prettifyError(json)}`);
          } catch (e) {
            console.warn("Unable to alrt error");
          }
        }
        reject(json);
      }
      else {
        resolve(json);
      }
    }).catch((err)=>{
      reject(err);
    });
  })
}

function queryParams(params) {
  let keys = Object.keys(params);
  let parts = [];
  keys.forEach((k)=>{
    if (params[k] !== undefined) {
      parts.push(encodeURIComponent(k) + '=' + encodeURIComponent(params[k]));
    }
  });
  return parts.join('&');
}

function prettifyError(json) {
  let lines = [];
  _.keys(json).forEach((key) => {
    let val = json[key];
    if (_.isString(val)) {
      lines.push(`${key.toUpperCase()}: ${val}`);
    }
    else {
      lines.push(`${key.toUpperCase()}`);
      _.keys(val).forEach((sub) => {
        let subval = val[sub];
        lines.push(`  - ${sub.toUpperCase()}: ${subval}`);
      });
    }
  });
  return lines.join("\n");
}
