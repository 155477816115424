import React from 'react';
import Container from './Container';
import './NameVal.css';

class NameVal extends Container {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`NameVal Container ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default NameVal;
