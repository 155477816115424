import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import PaddedArea from '../areas/PaddedArea';
import QueryLine from '../layouts/QueryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import Section from '../elements/Section';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { loadItem, meldItems, queryItems, setItemQuery, pushItem } from '../../actions/InventoryActions';
import { dot, reg, deepCopy, mapk2k, mappify } from '../../lib/obj';
import { configToNestedQuery } from '../../lib/inventory-util';
import { percentify, commafy, financial } from '../../lib/formats';

import { ORDER_ITEM_BASE_SEARCH } from '../../constants/inventory/OrderItem';

import './Page.css';
import './SearchPage.scss';
import './OrderStatsPage.scss';

const TYPE_NAME = "order-items";
const TYPE_ALIAS = "order-item-stats";

class OrderStatsPage extends Page {
  constructor() {
    super();
  }

  componentWillMount() {
    let props = this.props;
    let baseConfig = deepCopy(ORDER_ITEM_BASE_SEARCH);
    props.setItemQuery({
      type: "order-items",
      typeAlias: TYPE_ALIAS,
      query: baseConfig
    });

    // Load stats
    this.loadStatsWithBaseConfig(baseConfig);
  }

  loadStatsWithBaseConfig(config, throttle) {
    let conditions = [{deleted:{neq:1}}];

    // Build the stats queries
    console.log("config: ", config)
    let skuQ = configToNestedQuery(deepCopy(config), deepCopy(conditions));
    skuQ.links = ["item"]; // Override links
    skuQ.fields = ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","quantity","totalCost"];
    skuQ.calcs  = ["sum.quantity","sum.totalCost"];
    skuQ.group  = ["item.id"];
    this.props.queryItems({queries:[
      {
        ...skuQ,
        type: "order-items",
        typeAlias: TYPE_ALIAS
      }
    ]}).then(()=>{
      if (throttle) {
        setTimeout(()=>{throttle.done();},100)
      }
      console.log("DONE MELDING!");
    });

  }

  // Get the query config
  onConfigChange(config, throttle) {
    // Load stats
    this.loadStatsWithBaseConfig(config, throttle);
  }

  renderSkuStats() {
    let stats = this.props.stats; ;
    let docs = dot(stats,"list.docs") || [];
    let status = dot(stats,"list.status") || "loading";
    let ttQty = 0;
    let ttCost = 0;

    // Generate the stats per row
    let fstats = docs.map((doc)=>{
      let data = doc.data;
      let name = dot(data,"item.data.sku");
      let qty = parseInt(dot(data,"calcs.sumQuantity")) || 0;
      let cost = parseInt(dot(data,"calcs.sumTotalCost")) || 0;
      ttQty += qty;
      ttCost += cost;
      return {
        name: name,
        qty: qty,
        cost: cost
      }
    });

    // Check if sorting is needed
    let srt = this.state[`sortskus`];
    if (srt) { this.sortDataArray(fstats, srt.col, srt.asc); }

    return (<table className="Card StickyHeader" key={"sku-stats"} animate={status}>
      <thead>
        <tr>
          <th className="SKU" {...this.genSortProps("skus","name")}>SKU <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Quantity" {...this.genSortProps("skus","qty")}>Quantity <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Cost" {...this.genSortProps("skus","cost")}>Revenue <FontAwesomeIcon icon={["far","sort-up"]}/></th>
        </tr>
      </thead>
      <tbody>
      {fstats.map((data, index)=>{
        return (
        <tr key={`stats-${index}`} data-blank={!data.name}>
          <td className="SKU">{data.name || "–"}</td>
          <td className="Quantity">{commafy(data.qty)}</td>
          <td className="Cost">{financial(data.cost)}</td>
        </tr>);
      })}
      </tbody>
      <tfoot>
        <tr>
          <td className="Name">Totals</td>
          <td className="Quantity">{commafy(ttQty)}</td>
          <td className="Cost">{financial(ttCost)}</td>
        </tr>
      </tfoot>
    </table>);
  }

  genSortProps(table,col) {
    let srt = this.state[`sort${table}`] || {};
    return {
      onClick: ()=>{this.toggleSort(table,col)},
      'data-sort': srt.col == col ? (srt.asc ? 'asc' : 'desc') : undefined
    }
  }

  toggleSort(table, col) {
    let handle = `sort${table}`;
    let srt = this.state[handle] || {};
    if (srt.col == col) {
      this.setState({[handle]:{col:col, asc:!srt.asc}});
    }
    else {
      this.setState({[handle]:{col:col, asc:true}});
    }
  }

  sortDataArray(arr, col, asc = true) {
    arr.sort((a,b)=>{
      if (a[col] < b[col]) {
        return asc ? -1 : 1;
      }
      else if ((a[col] > b[col])) {
        return asc ? 1 : -1;
      }
      return 0;
    });
  }


  onExpand(evt, history) {
    let expanded = !this.state.expanded;
    if (this.props.onExpand) {
      this.props.onExpand(expanded);
    }
    this.setState({expanded:expanded});
  }

  render() {
    let props = this.props;

    // Get the docs
    return (
      <div className="Page SearchPage OrderStatsPage">
        <PageHeaderOutline title={this.props.title || "Order Stats"} top={this._backButton()}>
        </PageHeaderOutline>
        <Section className="SearchSection" expanded={this.state.expanded}>
          <PaddedArea className="QuerySubsection">
            <QueryLine type={TYPE_NAME} typeAlias={TYPE_ALIAS} throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QuerySummaryLine type={TYPE_NAME} typeAlias={TYPE_ALIAS} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QueryOptionsPresentation className="Settings" type={TYPE_NAME} typeAlias={TYPE_ALIAS} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          </PaddedArea>
        </Section>
        <div className="StatTables NarrowStats">
          {this.renderSkuStats()}
        </div>
      </div>
    );
  }


}

const mapState = (state, props) => {
  return {stats: dot(state,["inventory",TYPE_ALIAS])}
};

const mapDispatch = (dispatch) => {
  return {
    meldItems: opts => dispatch(meldItems(opts)),
    queryItems: opts => dispatch(queryItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderStatsPage)
