import React from 'react';
import { withRouter } from 'react-router'
import Element from './Element';
import './Readout.css';

class Readout extends Element {

  constructor(props) {
    super(props);
    this.state = {focused:false, filled:false};
  }

  onClick(evt) {
    console.log("Clicked Readout");
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {

    // Check for label
    let label = <div/>
    if (this.props.label) {
      label = <div className="Label">{this.props.label}</div>
    }

    // Check if readout is clickable
    let clickable = this.props.onClick !== undefined;

    // Build class name
    let className = this.props.className || "";
    return (
      <div className={`Readout ${className}`} data-type={this.props.type || "text"} ref="root" onClick={(evt)=>{this.onClick(evt)}} data-clickable={clickable}>
        {label}
        <div className="Field">{this.props.children}</div>
      </div>
    );
  }
}

const RoutedReadout = withRouter(Readout)
export default RoutedReadout;
