import React from 'react';
import Element from './Element';
import './Select.css';

import { _ } from '../../lib/underscore';

class Select extends Element {

  constructor(props) {
    super(props);
    let value = this.props.defaultValue;
    this.state = {
      focused:false,
      filled: value != null && value.toString().length > 0,
      value: value,
      orig: value
    };
  }

  onFocus(evt, type) {
    this.setState({focused:true, orig:this.state.value});
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  }

  onBlur(evt, type) {
    let orig = this.state.orig;
    this.setState({focused:false, orig:this.state.value});
    if (this.props.onBlur) {
      this.props.onBlur(evt, this.state.value, orig);
    }
  }

  onChange(evt, type) {


    let value = evt.target.value;
    console.log("onChange", value);

    // Update State
    this.setState({
      value: value,
      filled: value !== null && value !== undefined && value.toString().length > 0
    });

    // Check for onChange callback
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {

    // Get the input type
    let type = this.props.type || "text";

    // Check for label
    let label;
    if (this.props.label && !this.props.placeholder) {
      label = <div className="Label">{this.props.label}</div>
    }

    let className = this.props.className || "";
    return (
      <div className={`Select ${className}`} ref="root"
        data-labeled={label ? true : undefined}
        data-focused={this.state.focused}
        data-lock={this.props.lock}
        data-error={this.props.error}
        data-clearable={this.props.clearable}
        data-type={this.props.type || "text"}
        >
        {label}
        <select key="field" className="Field"
          name={this.props.name}
          onChange={(evt)=>this.onChange(evt, type)}
          defaultValue={this.props.defaultValue}
          data-hype={this.props.hype}
          >
          {this.props.children}
        </select>
      </div>
    );
  }
}

export default Select;
