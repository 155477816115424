import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import PaddedArea from '../areas/PaddedArea';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';

import ModelLayout from '../layouts/ModelLayout';
import DateVal from '../containers/DateVal';
import RectButton from '../elements/RectButton';

import Section from '../elements/Section';
import QueryLine from '../layouts/QueryLine';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRODUCT_SEARCH } from '../../constants/inventory/Product'
import { setItemField, pushItem } from '../../actions/InventoryActions';
import { meldItems, setItemQuery, saveItem } from '../../actions/InventoryActions';
import { dot, deepCopy } from '../../lib/obj';

import { _ } from '../../lib/underscore';
import { queryToWhere, configToNestedQuery } from '../../lib/inventory-util';

import './Page.css';
import './SearchPage.scss';
import './StubsPage.scss';

export const STUB_STATES = [
  { value:"active",   name: "Active",   desc: "Stub initial state"},
  { value:"spent",    name: "Spent",    desc: "Stub has been used"},
  { value:"refunded", name: "Refuned",  desc: "Stub has been refunded"}
];

const STUBS_SEARCH = {
  type: "stubs",
  limit: 100,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["email","code1","code2","code3"]
  },
  filters: [
    {
      label: "State",
      type: "check",
      field: "state",
      options: STUB_STATES
    }
  ],
  sort: [
    { field: "code1",           name: "Code 1 (A-Z)",     direction:"asc"},
    { field: "code1",           name: "Code 1 (Z-A)",     direction:"desc"},
    { field: "code2",           name: "Code 2 (A-Z)",     direction:"asc"},
    { field: "code2",           name: "Code 2 (Z-A)",     direction:"desc"},
    { field: "createdAt",       name: "Created (Asc)",    direction:"asc",  selected: true},
    { field: "createdAt",       name: "Created (Desc)",   direction:"desc"},
    { field: "updatedAt",       name: "Updated (Asc)",    direction:"asc"},
    { field: "updatedAt",       name: "Updated (Desc)",   direction:"desc"},
  ]
}

const STATUS_WORKING = {
  creating: true,
  saving: true,
  deleting: true
}

class StubsPage extends Page {

  componentWillMount() {
    let props = this.props;
    let searchConfig = deepCopy(STUBS_SEARCH)
    props.setItemQuery({
      type: "stubs",
      query: searchConfig
    });
    this.loadStubsWithConfig(searchConfig, [{deleted:{neq:1}}]);
  }

  onItemsLoaded(items) {
    if (items.length > 0) {
      this.loadOrders(items);
    }
  }

  loadOrders(items, onDone) {
    let idc = [];
    items.forEach((item, i) => {
      if (item.data.extId) {
        idc.push({id:{eq:dot(item.data.extId)}});
      }
    });
    let query = {
      where: [
        {deleted: {neq: 1}},
        {or: idc}
      ]
    };
    this.props.meldItems({
      type: "orders",
      typeAlias: "stub-orders",
      query: query
    }).then(()=>{
      if (onDone) { onDone(); }
    });
  }

  // Get the query config
  onConfigChange(config, throttle) {
    this.loadStubsWithConfig(config, [{deleted:{neq:1}}], throttle);
  }

  loadStubsWithConfig(config, conditions, throttle) {
    // Build the stats queries
    let query = configToNestedQuery(deepCopy(config), deepCopy(conditions));
    query.limit = config.limit;
    this.props.meldItems({
      type: "stubs",
      query: query
    }).then((docs)=>{
      if (throttle) { setTimeout(()=>{throttle.done();},100) }
      this.loadOrders(docs["stubs"] || []);
    });
    this.setState({sci: null, notes: {}});
  }

  genOrderDiv(order, index) {
    if (order) {
      let shopId = order.data.externalId ? order.data.externalId.split("-")[1] : null;
      let shopUrl = `https://1lss-store.myshopify.com/admin/orders/${shopId}`;
      return <details className="FloatDetails" open={this.state.sci == index && this.state.scf == "order"}>
        <summary onClick={(e)=>this.onControlToggle(e, index, 'order')}>{ order.data.name }</summary>
        <div className="DetailsList">
          <h4><a className="ShopifyIcon" href={shopUrl} target="_blank"><FontAwesomeIcon icon={["fab","shopify"]} /></a>&nbsp;&nbsp; Order Info</h4>
          <ModelLayout>
            <div className="Name">{order.data.email}</div>
            <div className="Label">{order.data.name}</div>
            <DateVal className="Sublabel">{order.data.createdAt}</DateVal>
          </ModelLayout>
          {/*
          <RectButton theme="blue" onClick={(e)=>this.onSelectOrder(index, order)}>Change</RectButton>
          <RectButton theme="bw" onClick={(e)=>this.onControlToggle(e, index, 'order')}>Close</RectButton>
          */}
        </div>
      </details>
    } else {
      return <div className="Order"> &ndash; </div>
    }
  }

  onStubStateClick(evt) {
    console.log("State clicked!!");
  }


  onEditField(field, value, index) {
    let id = dot(this.props.stubs,[index,"data","id"]);
    console.log("onEditField: ", field, value, index, id);
    this.props.saveItem({
      type: "stubs",
      id: id,
      index: index,
      docs: {
        stub: {sets:{[field]:value}}
      }
    }).then((res)=>{

    });
  }

  saveField(field, value, index) {
    this.softEditField(field, value, index);
    let id = dot(this.props.stubs,[index,"data","id"]);
    let data = deepCopy(dot(this.props.stubs,[index,"data"])) || {};
    data[field] = value;
    this.props.pushItem({
      type: "stubs",
      id: id,
      index: index,
      docs: {
        stub: {sets:{[field]:value}, data:data}
      }
    });
  }

  softEditField(field, value, index) {
    let id = dot(this.props.stubs,[index,"data","id"]);
    this.props.setItemField({
      type:"stubs",
      id:id,
      index: index,
      field:field,
      value:value
    });
  }

  render() {
    let lim = this.props.locationItemsMap;
    let ordersById = this.props.ordersById;
    let stubs = this.props.stubs;

    let header = <tr>
      <th className="Stub">Stub</th>
      <th className="State">State</th>
      <th className="Number">Number</th>
      <th className="Email">Email</th>
      <th className="Name">Name</th>
      <th className="Quantity">Quantity</th>
      <th className="Order">Order</th>
    </tr>;

    const renderItem = (item, index)=>{
      let data = item.data;
      let createdAt = data.createdAt ? data.createdAt.substr(0,10) : null;
      let extId = item.data.extId;
      let order = extId ? ordersById[extId] : null;
      return <tr key={`item${index}-${data.id || "0"}`} data-working={STATUS_WORKING[item.status]} data-creating={data.id == null} data-expanded={this.state.sci == index}>
        <td className="Stub">
          { dot(item,"data.code2") }
        </td>
        <td className="State">
          <select defaultValue={data.state} onChange={(e)=>{this.saveField("state",e.target.value,index)}} data-hype={data.state || 'unknown'}>
            {STUB_STATES.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="Number">
          { dot(item,"data.code1") }
        </td>
        <td className="Email">
          { dot(item,"data.email") }
        </td>
        <td className="Name">
          { dot(item,"data.code3") }
        </td>
        <td className="Quantity">
          { dot(item,"data.quantity") }
        </td>
        <td className="Order">
          {this.genOrderDiv(order)}
        </td>
      </tr>
    };

    // Get the docs
    return (
      <div className="Page SearchPage StubsPage">
        <PageHeaderOutline title={this.props.title || "Stubs"} top={this._backButton()}>
        </PageHeaderOutline>
        <Section className="SearchSection" expanded={this.state.expanded}>
          <PaddedArea className="QuerySubsection">
            <QueryLine type="stubs" throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QuerySummaryLine type="stubs" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QueryOptionsPresentation className="Settings" type="stubs" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          </PaddedArea>
        </Section>
        <table className="Card StickyHeader Results" animate={this.props.stubsList.status}>
          <thead>
            {header}
          </thead>
          <tbody>
          {stubs.map(renderItem)}
          </tbody>
        </table>
        <QueryPaginatePresentation type="stubs" onQuerySuccess={(items)=>{this.onItemsLoaded(items)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let stubsList = dot(state.inventory,"stubs.list") || {};
  let stubDocs = dot(state.inventory,"stubs.list.docs") || [];

  let orderDocs = dot(state.inventory,"stub-orders.list.docs") || [];
  let ordersById = {};
  orderDocs.forEach((order) => { ordersById[order.data.id] = order; });

  return {
    stubs: stubDocs,
    stubsList: stubsList,
    orders: orderDocs,
    ordersById: ordersById
  }
};

const mapDispatch = (dispatch) => {
  return {
    pushItem: opts => dispatch(pushItem(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    saveItem: opts => dispatch(saveItem(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(StubsPage)
