import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import VendorForm from '../forms/VendorForm'
import { connect } from 'react-redux'

import './Popup.css';
import './VendorPopup.css';

import { closePopup } from '../../actions/PopupActions';
import { loadItem, pushItems, purgeItem } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

class VendorPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      selections: {}
    };
  }

  componentWillMount() {

  }

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    let vendor = this.props.vendor;
    this.props.pushItems([{type:"vendors",
      id: this.props.id,
      docs: {vendor:vendor},
      action: vendor.action
    }]).then((res)=>{
      this.props.closePopup();
      this.props.purgeItem({ type: "logins", id: "new" });
      let resVendor = dot(res,"0.vendor");
      this.props.onOk(resVendor);
    });
  }

  render() {
    // Check if sending the vendor
    let vendor = this.props.vendor;
    let sending = dot(vendor,"status") == "creating";

    // Get the items and set as options
    return (
      <div className="Popup VendorPopup" ref="root">
        <VendorForm className="Form" vendor={this.props.vendor}/>
        <RectButton className="Cancel" theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>
        <RectButton className="OK" theme="blue" working={sending} onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let id = props.id || "new";
  let vendor = dot(state.inventory,["vendors",id]) || {};
  return {
    id: id,
    vendor: vendor
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    pushItems: opts => dispatch(pushItems(opts)),
    purgeItem: opts => dispatch(purgeItem(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(VendorPopup)
