import React from 'react';
import Popup from './Popup';
import './BlankPopup.css';

class BlankPopup extends Popup {
  render() {
    return (
      <div className="BlankPopup" ref="root">

      </div>
    );
  }
}

export default BlankPopup;
