import React from 'react';
import Element from './Element';
import './Dropdown.css';

class Dropdown extends Element {

  constructor(props) {
    super(props);

    // Get the default value
    let value = props.value ? props.value : props.options ? props.options[0] : null;

    // Set the initial state
    this.state = {
      filled:(value != null),
      value:value
    };
  }

  onFocus(evt) {
    this.setState({focused:true});
  }

  onBlur(evt) {
    this.setState({focused:false});
  }

  onChange(evt) {
    this.setState({
      value: evt.target.value,
      filled: evt.target.value.length > 0
    });
    // Check for onChange callback
    if (this.props.onChange) {
      this.props.onChange(evt.target.value);
    }
  }

  render() {

    // Check for label
    let label = <div/>
    if (this.props.label) {
      label = <div className="Label">{this.props.label}</div>;
    }

    // Get handle to the options
    let options = this.props.options || [];

    return (
      <div className="Dropdown" ref="root" data-focused={this.state.focused} data-filled={this.state.filled}>
        {label}
        <select className="Select" name="cars" onChange={(evt)=>this.onChange(evt)} value={this.state.value}>
          {options.map((option, index)=>{
            return <option key={`option${index}`} value={option}>{option}</option>
          })}
        </select>
      </div>
    );
  }
}

export default Dropdown;
