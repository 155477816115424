import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import ItemSearchSection from '../sections/ItemSearchSection';
import FooterArea from '../areas/FooterArea';
import ActionVal from '../containers/ActionVal';
import ModelLayout from '../layouts/ModelLayout';
import DateVal from '../containers/DateVal';

import { connect } from 'react-redux'

import './Popup.css';
import './SearchPopup.scss';
import './ActionLogPopup.scss';

import { closePopup } from '../../actions/PopupActions';
import { loadItem, listItems, setItemQuery } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot, sfc } from '../../lib/obj';
import FilterLine from '../layouts/FilterLine';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

import { ACTION_SEARCH, ACTION_TYPES_MAP } from '../../constants/inventory/Action';

class ActionLonPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      selections: props.selections || {},
      expanded: false
    };
  }

  onItemClick(item, index) {
    let selections = this.state.selections;
    let id = dot(item.data,'id');
    let found = selections[id];
    if (found) {
      delete selections[id];
    } else {
      // Clear selected item
      if (this.props.radio) {
        selections = {}
      }
      selections[id] = {
        ordering: Object.keys(selections).length,
        item: item
      }
    }
    this.setState({selections:selections});
  }

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    if (this.props.onOk) {
      let selections = this.state.selections;
      let ids = Object.keys(selections).sort((a,b)=>{
        return selections[a].ordering - selections[b].ordering
      });
      let items = ids.map((a)=>{return selections[a].item;});
      this.props.onOk(items);
    }
    this.props.closePopup();
  }

  onClear(evt) {
    this.setState({selections:{}});
  }

  // Called when the Other option is clicked
  onOther(evt) {
    this.props.closePopup();
    if(this.props.other && this.props.other.onClick) {
      this.props.other.onClick(evt);
    }
  }

  onRenderItem(item,index,offset=0, pinned = false) {

    // Check if items is selected
    let selections = this.state.selections;
    let selected = selections[dot(item,"data.id")] !== undefined;

    // Render the item
    let itemInfo;
    let optionProps = {};
    if (this.props.renderItem) {
      itemInfo = this.props.renderItem(item, index, optionProps);
      itemInfo = Array.isArray(itemInfo) ? itemInfo : [itemInfo];
    } else {
      let data = dot(item,"data") || {};
      let user = dot(data,"user.data.firstName") || dot(data,"user.data.lastName") || dot(data,"user.data.email");
      let subject = this.props.renderActionSubject ? this.props.renderActionSubject(item) : <span>{ data.subjectType } { data.subjectId }</span>;
      itemInfo = <ModelLayout>
          <div className="Name">
            {this.genDiffDiv(data)}
          </div>
          <div className="Label"><b>{ user }</b> &nbsp;<span data-hype={data.type}>{ACTION_TYPES_MAP[data.type]}</span>&nbsp; {subject}</div>
          <DateVal className="Sublabel">{data.createdAt}</DateVal>
        </ModelLayout>;
    }

    return <div key={`action${index}`} className="ActionItem">
        {itemInfo}
    </div>
  }

  genDiffDiv(data) {
    let nv = sfc(data.newValues, JSON.parse);
    let ov = sfc(data.oldValues, JSON.parse);
    switch (data.type) {
      case "create": {
        let props = _.keys(nv);
        return <div className="TinySummary">
        {props.map((p,i)=>{
          let val = nv[p];
          if (val == null) { return; }
          return <div key={`prop${i}`} className="ValSet">
            <span className="Prop">{ p }</span> = <span className="Val">{ val }</span>
          </div>
        })}
        </div>
      }
      case "update": {
        let props = _.keys(nv);
        return <div className="TinySummary">
        {props.map((p,i)=>{
          let val = nv[p];
          if (val == null) { return; }
          let oldVal;
          if (ov[p] != null) {
            oldVal = <span className="OldVal">({ ov[p] })</span>
          }
          return <div key={`prop${i}`} className="ValSet">
            <span className="Prop">{ p }</span> = <span className="Val">{ val }</span>&nbsp; {oldVal}
          </div>
        })}
        </div>
      }
      case "delete":
        return;
    }
  }

  renderPinItems() {
    let pinItems = this.props.pinItems;
    if (!pinItems) { return; }
    return pinItems.map((item, index)=>this.onRenderItem(item,index))
  }

  onExpand(expanded) {
    this.setState({expanded:expanded});
  }

  render() {
    let props = this.props;
    let query = props.query || ACTION_SEARCH;

    // Get the selections and other options
    const selections = this.state.selections;
    let limit = this.props.limit || dot(this.props.query,"limit");
    let offset = dot(this.props.query,"offset");

    // setup paginate control
    let paginate = <QueryPaginatePresentation
      type={props.type}
      typeAlias={props.typeAlias}
      conditions={props.conditions}
      limit={limit}
      ignoreDelete={true}
    />

    // Setup other options if necessary
    let other = <div/>
    if(this.props.other) {
      other = <ActionVal onClick={()=>{this.onOther();}}>{this.props.other.label || "Other"}</ActionVal>
    }

    // Build the footer
    let footer = <FooterArea>
        {other}
        <RectButton className="OK" theme="bw" onClick={(evt,history)=>{this.onOk(evt, history)}}>Close</RectButton>
      </FooterArea>

    return (
      <div className="Popup SearchPopup ActionLogPopup" ref="root" data-type={props.type} data-expanded={this.state.expanded}>
        <ItemSearchSection
          type={props.type}
          typeAlias={props.typeAlias}
          query={query}
          conditions={props.conditions}
          ignoreDelete={props.ignoreDelete}
          limit={limit}
          reload={true}
          renderItem={(item, index)=>this.onRenderItem(item,index,dot(props.pinItems,'length')||0)}
          onExpand={(expanded)=>this.onExpand(expanded)}
          prependItems={this.renderPinItems()} footer={footer}>
          {this.props.title}
          {paginate}
        </ItemSearchSection>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {}
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ActionLonPopup)
