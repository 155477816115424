class Contract {

  /***
   * Takes in an array of tasks
   * where each item is a function(resolve, reject, ctx);
  **/
  constructor(tasks=[]) {
    this._tasks = tasks;
  }

  /***
   * Starts the execution of tasks in series.
  **/
  start() {
    this._index = 0;
    return new Promise((resolve, reject)=>{
      this._next(resolve, reject);
    });
  }

  /***
   * Helps process the next task.
  **/
  _next(resolve, reject) {
    let task = this._tasks[this._index++];
    if (task) {
      return new Promise(task).then(()=>{
        this._next(resolve, reject);
      }).catch((e)=>{
        console.log("Contract-_next - Error:",e)
        reject();
      });
    }
    else if (this._index < this._tasks.length) {
      this._next(resolve, reject);
    }
    else {
      resolve();
    }
  }

}
export default Contract;
