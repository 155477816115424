import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import RectButton from '../elements/RectButton';
import TableSearchSection from '../wrappers/TableSearchSection';

import { PRODUCT_SEARCH, PRODUCT_TYPES } from '../../constants/inventory/Product'
import { dot, deepCopy } from '../../lib/obj';

import './Page.css';
import './KPIPage.scss';

// The max number of produts to list
export const KPI_SEARCH = {
  type: "order-resolutions",
  limit: 50,
  links: ["completedBy"],
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["completedBy.firstName","completedBy.lastName","completedBy.email"],
    keymap: {
      first: "completedBy.firstName",
      last: "completedBy.lastName",
      email: "completedBy.email",
      agent: "completedBy.email",
    }
  },
  filters: [
    {
      label: "Range",
      type: "daterange",
      radio: true,
      field: "completedAt",
      options: [
        { value:"custom", name: "Custom",        desc: "Custom count"},
        { value:"today",  name: "Today",         desc: "Today's count"},
        { value:"days7",  name: "Last 7 days",   desc: "Week's count"},
        { value:"days30", name: "Last 30 days",  desc: "Month's count"},
        { value:"days90", name: "Last 90 days",  desc: "Month's count"},
        { value:"week",   name: "This Week",     desc: "Week's count", selected: true},
        { value:"month",  name: "This Month",    desc: "Month's count"},
        { value:"year",   name: "This Year",     desc: "Month's count"},

      ]
    }
  ],
  sort: [
    { field: "completedBy.email",       name: "Email (A-Z)",  direction:"asc", selected: true},
    { field: "completedBy.email",       name: "Email (Z-A)",  direction:"desc"},
    { field: "completedBy.firstName",   name: "Name (A-Z)",   direction:"asc"},
    { field: "completedBy.firstName",   name: "Name (Z-A)",   direction:"desc"},
  ],
  group: ["completedById"],
  calcs: ["count.id"]
}

class KPIPage extends Page {

  render() {

    // Get the docs
    return (
      <div className="Page KPIPage">
        <PageHeaderOutline title={this.props.title || "Products"} top={this._backButton()}>
        </PageHeaderOutline>
        <TableSearchSection label="Completions" type="order-resolutions" typeAlias="resolutions-kpi"
        query={deepCopy(KPI_SEARCH)}
        checkable={true}
        reload={true}
        layout={[
          {title:"Email",   field:"completedBy.data.email", type: "plain", default: "-"},
          {title:"Name",    type:"custom", render: (e)=>`${dot(e,"data.completedBy.data.firstName")||""} ${dot(e,"data.completedBy.data.lastName")||""}`},
          {title:"Total",   field: "calcs.countId" }
        ]}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {}
};

const mapDispatch = (dispatch) => { return {} };

export default connect(
  mapState,
  mapDispatch
)(KPIPage)
