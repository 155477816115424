/**
 * Gets or sets a nested value
 * NOTE: Updated to handle empty keys
 * NOTE: Updated to return final set value
**/
export const dot = function(obj, key, val, {build=true, forceSet=false}={}) {
  // Validation
  if (obj === undefined || obj === null ) {
    return obj;
  }

  // Getting
  if (!forceSet && val === undefined) {
    let lev = obj;
    walk(key, (step) => {
      lev = lev[step];
      return lev !== undefined && lev !== null;
    });
    return lev;
  }
  // Setting
  else if (key !== undefined && key !== null && key !== "") {
    let lev = obj;
    walk(key, (step, left) => {
      if (left.length === 0) {
        lev[step] = val;
        val = lev[step];
      }
      else {
        let loc = lev[step];
        if (build && (loc === undefined || loc === null)) { lev[step] = {}; }
        lev = lev[step];
        return lev !== undefined && lev !== null;
      }
    });
    return val;
  }
};

/**
 * Checks to see if the given objects contins
 * the given key.
 * NOTE: The key can exist and have an undefined or null value
**/
export const contains = function(obj, key) {
  let hasKey = false;

  // Validation
  if (obj === undefined || obj === null ) {
    return hasKey;
  }

  // Checking
  let lev = obj;
  walk(key, (step) => {
    hasKey = step in lev;
    lev = lev[step];
    return lev !== undefined && lev !== null;
  });
  return hasKey;
};

/**
 * Walks the given dot path
 */
export const walk = function(path, cb) {
  if (cb && path != null) {
    let steps = Array.isArray(path) ? path : path.toString().split(".");
    for (var i = 0; i < steps.length; i++) {
      if ( cb(steps[i],steps.slice(i+1)) === false) {
        break;
      }
    }
  }
}

/**
 * Creates a deep copy of the given object
 * NOTE: Only supports plain ojects, arrays and native types
 */
export const deepCopy = function(obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}


/*
 * Lists the items from the map in the given order provided
**/
export const arrange = (map={}, order)=>{
  let parts = []
  for (var i = 0; i < order.length; i++) {
    let step = order[i];
    let val = map[step];
    if (val !== undefined && val !== null) {
      parts.push(val);
    }
    else {
      break
    }
  }
  return parts;
}

/**
 * Registers the given value if it doesn't already exist in the object.
 * returns the resulting value under the given path
**/
export const reg = (obj, path, val) => {
  let existing = dot(obj,path);
  if (existing === undefined) {
    dot(obj,path, val);
    return val;
  }
  else {
    return existing;
  }
}

/**
 * Check if the path has the given start
**/
export const isPathStart = function(path, start) {
  if (path && start && path.length > start.length) {
    return path.indexOf(`${start}.`) === 0;
  }
  return false;
}


/**
 * Purges the nested value
**/
export const purge = function(obj, key) {
  // Validation
  if (obj === undefined || obj === null ) {
    return obj;
  }

  let lev = obj;
  let val = undefined;
  walk(key, (step, left) => {
    if (left.length === 0) {
      val = lev[step];
      delete lev[step]
    }
    else {
      let loc = lev[step];
      if (loc === undefined || loc === null) { lev[step] = {}; }
      lev = lev[step];
      return lev !== undefined && lev !== null;
    }
  });
  return val;
};


/**
 * Trakes the array and converts it to a hash
**/
export const mappify = function(arr, cb) {
  let map = {};
  if (!arr || !cb) { return map; }
  for (var i = 0; i < arr.length; i++) {
    let val = arr[i];
    let key = cb(val);
    map[key] = val;
  }
  return map;
};

/**
 * Generates a map by mapping first key value to the second key value of each
 * of the items in the array
**/
export const mapk2k = function(arr, kk, vk = null) {
  let map = {};
  if (!arr) { return map; }
  for (var i = 0; i < arr.length; i++) {
    let item = arr[i];
    let k = item[kk] !== undefined ? item[kk].toString() : undefined;
    if (k) { map[k] = vk ? item[vk] : item }
  }
  return map;
};

/**
 * Looks at the oject and gets the first key available
**/
export const onlykey = function(obj) {
  if (!obj) { return undefined };
  return Object.keys(obj)[0];
};

/**
 * Looks at the oject and gets the first key available
**/
export const hasKeys = function(obj) {
  if (!obj) { return false };
  return Object.keys(obj).length > 0;
};

/**
 * Gets the first path available and returns an array of keys
**/
export const arrpath = function(obj, {depth=100}={}) {
  let arr = [];
  let key = onlykey(obj);
  let deep = 0;
  let lastObj = undefined;
  while (key !== undefined && deep < depth && lastObj != obj) {
    arr.push(key);
    lastObj = obj;
    obj = obj[key];
    key = onlykey(obj);
    ++deep;
  }
  return arr;
};


/**
 * Traverses the array the object with the matching property value. Returns the value
 * of the given key
**/
export const locate = function(arr, prop, val, key) {
  if (!arr) { return; }
  for (var i = 0; i < arr.length; i++) {
    let obj = arr[i];
    if (obj[prop] === val) { return obj[key]; }
  }
};


/**
 * SFC = Safe Function Call
 * Performs the callback on the given object only if the object is not null or undefined
**/
export const sfc = function(obj, cb, onCatch) {
  if (obj === null || obj === undefined) {return obj;}
  try {
    return cb(obj);
  } catch (e) {
    if (onCatch) { return onCatch(e); }
  }
}
