import React from 'react';
import { mappify } from '../../lib/obj'

export const PAYMENT_TYPES = [
  { value:"unknown",  name: "Unknown" },
  { value:"card",     name: "Card" },
  { value:"cash",     name: "Cash" },
  { value:"check",    name: "Check" },
  { value:"paypal",   name: "PayPal" },
  { value:"venmo",    name: "Venmo" },
  { value:"wire",     name: "Wire" }
];

export const PAYMENT_TYPE_MAP = mappify(PAYMENT_TYPES,(i)=>{return i.value});
