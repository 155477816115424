import * as ACTIONS from './ActionTypes';

const NOTIFICATION_TIMEOUT = 10000;

// Keeps track of the notifications
var _notificationCtr = 0;

export const setAppField = (field, value) => {
  return {
    type: ACTIONS.SET_APP_FIELD,
    field: field,
    value: value
  }
}

export const incAppField = (field, value) => {
  return {
    type: ACTIONS.INC_APP_FIELD,
    field: field,
    value: value
  }
}

export const clearAppField = (field) => {
  return {
    type: ACTIONS.CLEAR_APP_FIELD,
    field: field
  }
}

export const toggleAppField = (field) => {
  return {
    type: ACTIONS.TOGGLE_APP_FIELD,
    field: field
  }
}

export const pushAppNotification = (message, opts={}) => {
  return ((dispatch, getState) => {
    let id = opts.id || _notificationCtr++;
    // Dispatch new notification
    dispatch({
      type: ACTIONS.PUSH_APP_NOTIFICATION,
      id: id,
      message: message,
      details: opts.details,
      kind: opts.kind
    });

    // Set timeout
    let timeout = opts.timeout || NOTIFICATION_TIMEOUT;
    if (timeout > 0) {
      setTimeout(()=>{
        dispatch({
          type: ACTIONS.CLEAR_APP_NOTIFICATION,
          id: id
        });
      },timeout);
    }
  });
}

export const clearAppNotification = (opts={}) => {
  return {
    type: ACTIONS.CLEAR_APP_NOTIFICATION,
    id: opts.id,
    force: true
  }
}

export const keepAppNotification = (opts={}) => {
  return {
    type: ACTIONS.KEEP_APP_NOTIFICATION,
    id: opts.id
  }
}
