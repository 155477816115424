import React from 'react';
import { connect } from 'react-redux'
import ActionLogPopup from './ActionLogPopup'
import BlankPopup from './BlankPopup'
import FormPopup from './FormPopup'
import LocationItemPopup from './LocationItemPopup'
import LoginPopup from './LoginPopup'
import NewPOItemPopup from './NewPOItemPopup'
import POItemPopup from './POItemPopup'
import ConfirmPopup from './ConfirmPopup'
import POPopup from './POPopup'
import SelectPopup from './SelectPopup'
import UploadPopup from './UploadPopup'
import VendorPopup from './VendorPopup'
import PaymentPopup from './PaymentPopup'
import SearchPopup from './SearchPopup'
import ItemEditPopup from './ItemEditPopup'
import ListPopup from './ListPopup'
import { openPopup, closePopup } from '../../actions/PopupActions';
import './Popups.css';

class Popups extends React.Component {

  onClose(e) {
    this.props.closePopup();
  }

  render() {

    const popups = this.props.popups;
    const populated = popups.length > 0;

    return (
      <div className="Popups" data-populated={populated}>
        <div className="BG" onClick={(e)=>{this.onClose(e)}}></div>
        {popups.map((popup)=>{
          let props = popup.props || {};
          switch (popup.name) {
            case "ActionLog":
              return <ActionLogPopup key={`popup${popup.id}`} {...props}/>
            case "NewPOItem":
              return <NewPOItemPopup key={`popup${popup.id}`} {...props}/>
            case "POItem":
              return <POItemPopup key={`popup${popup.id}`} {...props}/>
            case "Confirm":
              return <ConfirmPopup key={`popup${popup.id}`} {...props}/>
            case "LocationItem":
              return <LocationItemPopup key={`popup${popup.id}`} {...props}/>
            case "Login":
              return <LoginPopup key={`popup${popup.id}`} {...props}/>
            case "Payment":
              return <PaymentPopup key={`popup${popup.id}`} {...props}/>
            case "PO":
              return <POPopup key={`popup${popup.id}`} {...props}/>
            case "Select":
              return <SelectPopup key={`popup${popup.id}`} {...props}/>
            case "Upload":
              return <UploadPopup key={`popup${popup.id}`} {...props}/>
            case "Vendor":
              return <VendorPopup key={`popup${popup.id}`} {...props}/>
            case "Search":
              return <SearchPopup key={`popup${popup.id}`} {...props}/>
            case "ItemEdit":
              return <ItemEditPopup key={`popup${popup.id}`} {...props}/>
            case "List":
              return <ListPopup key={`popup${popup.id}`} {...props}/>
            case "Form":
              return <FormPopup key={`popup${popup.id}`} {...props}/>
            default:
              return <BlankPopup key={`popup${popup.id}`} {...props}/>
          }
        })}
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    popups: state.popups
  }
};

const mapDispatch = (dispatch) => {
  return {
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(Popups)
