import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import SelectorControl from '../controls/SelectorControl';

import { financial } from '../../lib/formats';
import { docForLoc } from '../../lib/inventory-util';
import { dot } from '../../lib/obj';

import { setItemField } from '../../actions/InventoryActions';

import { COUNTRIES, countryForCode } from '../../constants/misc/Countries';

import './Form.css';
import './LoginForm.css';

class LoginForm extends Form {

  // Edit the given field
  editField(field, value) {
    let id = dot(this.props.login,"data.id");
    if (id) {
      this.props.setItemField({type:"logins",id:id,field:field,value:value});
    }
    else {
      this.props.setItemField({type:"logins",id:"new",field:field,value:value});
    }
  }

  onEnter(evt) {
    if (this.props.onEnter) { this.props.onEnter(evt); }
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.login,"data") || {};
    return (
      <div className="Form LoginForm">
        <Input label="Email" className="Email" value={dot(data,"email")} onChange={(value)=>{this.editField("email",value)}}/>
        <Input label="Password" className="Password" value={dot(data,"password")} type="password" onChange={(value)=>{this.editField("password",value)}} onEnter={(evt)=>{this.onEnter(evt)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let login = props.login;
  if (!login) {
    login = dot(state.inventory,"logins.new");
  }
  return {
    inventory: state.inventory,
    login:login
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LoginForm)
