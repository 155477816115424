import React from 'react';
import Container from './Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CheckBoxVal.css';

class CheckBoxVal extends Container {
  onClick(e) {
    if (this.props.onClick) { this.props.onClick(e); }
  }
  render() {
    let box;
    if (this.props.selected) {
      let icon = this.props.radio ? "dot-circle" : "check-square";
      box = <FontAwesomeIcon icon={["far",icon]} />;
    } else {
      let icon = this.props.radio ? "circle" : "square";
      box = <FontAwesomeIcon icon={["far",icon]} />;
    }
    let className = this.props.className || "";
    return (
      <div className={`CheckBoxVal Container ${className}`} data-selected={this.props.selected} onClick={(e)=>this.onClick(e)}>
        {box}
      </div>
    );
  }
}

export default CheckBoxVal;
