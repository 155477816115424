import React from 'react';
import { connect } from 'react-redux'
import Popup from './Popup';
import './POItemPopup.css';
import Section from '../elements/Section';
import Input from '../elements/Input';
import FileInput from '../elements/FileInput';
import Dropdown from '../elements/Dropdown';
import LineTitle from '../elements/LineTitle';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import Readout from '../elements/Readout';

import { dot, deepCopy } from '../../lib/obj';
import { financial } from '../../lib/formats';

import { closePopup } from '../../actions/PopupActions';
import { startForm, clearForm, editForm } from '../../actions/FormActions';

class NewPOItemPopup extends Popup {

  componentWillMount() {
    // Make sure to start the new PO form
    this.props.startForm({name:this.props.formName});
  }

  close() {
    this.props.closePopup();
  }

  add() {
    // Get handle to the item form
    let form = this.props.form;

    // Check to see if any items are already exist
    let items = dot(this.props.po,"items");
    if (items) {
      this.props.editForm({
        name:this.props.poName,
        field: ["items", items.length],
        value: deepCopy(form)
      });
    }
    else {
      this.props.editForm({
        name:this.props.poName,
        field: "items",
        value: [deepCopy(form)]
      });
    }

    this.close();
  }

  // Edit the given field
  editField(field, value) {
    this.props.editForm({
      name: this.props.formName,
      field: field,
      value: value
    });
  }

  render() {

    // Handle to the form
    const form = this.props.form;

    // Stub dropdown
    const elementOptions = [
      "Magnet - Case - 1.2mm",
      "Magnet - MagStick - Middle",
      "Sticker - Case - Black",
      "TPU Case - XMax - Black"
    ];

    // Handle to prop values
    let part = dot(form,"part") || elementOptions[0];
    let price = dot(form,"price");
    let quantity = dot(form,"quantity");
    let comment = dot(form,"comment");
    let total = price * quantity;

    return (
      <div className="NewPOItemPopup" ref="root">
        <Section say="New PO Item" shout="Details">
          <Dropdown label="Part" options={elementOptions} value={part} onChange={(value)=>{this.editField("part",value)}}/>
          <Row>
            <Input label="Unit Price" type="number" value={price} onChange={(value)=>{this.editField("price",value)}}/>
            <Input label="Quantity" type="number" value={quantity} onChange={(value)=>{this.editField("quantity",value)}}/>
          </Row>
          <Input label="Comment" value={comment} onChange={(value)=>{this.editField("comment",value)}}/>
          <Row>
            <Readout></Readout>
            <Readout label="Total Price">{financial(total)}</Readout>
          </Row>
        </Section>
        <Section>
          <Row>
            <RectButton theme="bw" onClick={()=>{this.close()}}>Cancel</RectButton>
            <RectButton theme="softpurlple" onClick={()=>{this.add()}}>Add</RectButton>
          </Row>
        </Section>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let poName = props.poName || "poCreate";
  let formName = props.form || "poItemCreate";
  return {
    poName: poName,
    po: state.forms[poName],
    formName: formName,
    form: state.forms[formName],
    popups: state.popups
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    startForm: opts => dispatch(startForm(opts)),
    clearForm: opts => dispatch(clearForm(opts)),
    editForm: opts => dispatch(editForm(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(NewPOItemPopup)
