import React from 'react';
import Layout from './Layout';

import './ModelLayout.css';

class ModelLayout extends Layout {
  render() {
    let children = this.children || [];
    let className = this.props.className || "";
    return (
      <div className={`Layout ModelLayout ${className}`} data-image={this.props.image}>
        {this.props.children}
      </div>
    );
  }
}

export default ModelLayout;
