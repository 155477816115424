import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import PaddedArea from '../areas/PaddedArea';
import Input from '../elements/Input';
import Readout from '../elements/Readout';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import Form from '../forms/Form'
import ProductLayout from '../layouts/ProductLayout';
import ImageLayout from '../layouts/ImageLayout';
import { connect } from 'react-redux'

import './Popup.css';
import './LocationItemPopup.css';

import { closePopup } from '../../actions/PopupActions';
import { setItemField, saveItem, purgeItem } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot } from '../../lib/obj';
import { capitalize, abbr } from '../../lib/formats';

class LocationItemPopup extends Popup {

  // Edit the given field
  editField(field, value) {
    // Handle to the set item field options
    let opts = {
      type: "locations",
      id: dot(this.props.locationItem,"data.locationId"),
      childType: "items",
      childTypeAlias: this.props.childTypeAlias,
      childId: dot(this.props.locationItem,"data.id"),
      field:field,
      value:value
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemField(opts);
  }

  onCancel(evt, history) {
    this.props.closePopup();
  }

  onViewProduct(evt, history, item) {
    if (item) {
      history.push(`/products/${item.data.id}`);
      this.props.closePopup();
    }
  }

  onOk(evt, history) {
    let locationItem = this.props.locationItem;
    let locationId = dot(locationItem,"data.locationId");
    let id = dot(this.props.locationItem,"data.id");
    this.props.saveItem({
      type: "locations",
      id: locationId,
      childType: "items",
      childTypeAlias: this.props.childTypeAlias,
      childId: id,
      docs: {
        locationItem: locationItem
      }
    }).then((res)=>{
      if (this.props.onSent) { this.props.onSent(res.locationItem); }
      this.props.closePopup();
    });
  }

  render() {

    // Check if sending the locationItem
    let locationItem = this.props.locationItem;
    let product = this.props.product;
    let sending = dot(locationItem,"status") == "saving";

    // Handle to prop values
    let action = dot(locationItem,"action");

    let name = dot(product,"data.name");
    let sku = dot(product,"data.sku");
    let price = parseFloat(dot(locationItem,"data.unitCost")) || 0;
    let totalExpected = parseInt(dot(locationItem,"data.totalExpected")) || 0;
    let totalReceived = parseInt(dot(locationItem,"data.totalReceived")) || 0;
    let totalSent = parseInt(dot(locationItem,"data.totalSent")) || 0;
    let quantity = parseInt(dot(locationItem,"data.quantity")) || 0;
    let comment = dot(locationItem,"data.comment");

    // Get the product name
    let type = dot(locationItem,"data.item.type") || dot(product,"data.type");
    let productName = dot(locationItem,"data.item.name") || dot(product,"data.name");
    let productLabel = sku ? `${capitalize(type)} • ${sku}` : capitalize(type);


    // Setup buttons
    let cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>
      {this.props.cancelLabel || "Cancel"}
    </RectButton>;

    // Setup buttons
    let okButton = <RectButton theme={this.props.okTheme || "blue"} working={sending} onClick={(evt,history)=>{this.onOk(evt, history)}}>
      { sending ? "Sending..." : "Save" }
    </RectButton>;

    return (
      <div className="LocationItemPopup" ref="root">
        <div className="Content">
          <Section>
            <PaddedArea>
              <div className="Title">{dot(locationItem,"data.location.data.name") || this.props.title || "Update Item"}</div>
              <Separator/>
              <Form>
                <ImageLayout className="Image" file={this.props.image}/>
                <ProductLayout product={product} onClick={(e,h)=>this.onViewProduct(e,h,product)}/>
                <Input className="Quantity" label="Quantity" type="number" value={quantity} onChange={(val)=>{this.editField("quantity",parseFloat(val))}}/>
              </Form>
              <Separator/>
              <Row align="right">
                {cancelButton}
                {okButton}
              </Row>
            </PaddedArea>
          </Section>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let locationItem = props.locationItem;
  let inventory = state.inventory;

  // Get the location item and ref item
  let product = props.product || dot(locationItem,"data.item");

  let locationId = dot(locationItem,"data.locationId");
  let id = dot(locationItem,"data.id");
  let childType = props.childTypeAlias || 'items';
  locationItem = dot(inventory,['locations', locationId, childType, id]);

  let image = dot(product,"data.image");
  if (!image) {
    let imageId = dot(product,"data.imageId");
    image = dot(inventory,['files', imageId]);
  }
  
  return {
    inventory: inventory,
    locationItem: locationItem,
    product: product,
    image: image
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    saveItem: opts => dispatch(saveItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    setSessionLogin: opts => dispatch(setSessionLogin(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LocationItemPopup)
