import React from 'react';

class Container extends React.Component {
  render() {
    return (
      <div className="Container">Hello container!</div>
    );
  }
}

export default Container;
