import React from 'react';
import Container from './Container';

import './Container.css';
import './TitleVal.css';

class TitleVal extends Container {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`TitleVal Container ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default TitleVal;
