import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import TransferForm from '../forms/TransferForm';
import TransferItemForm from '../forms/TransferItemForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import DropControl from '../controls/DropControl';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NameVal from '../containers/NameVal';
import TypeVal from '../containers/TypeVal';
import SubVal from '../containers/SubVal';

import { financial, capitalize } from '../../lib/formats';
import { docForLoc, docHasEdits } from '../../lib/inventory-util';
import { dot, deepCopy, hasKeys } from '../../lib/obj';
import { loadItem, listItems, pushItems, pushItemsInSeries, deleteItem, startDraftItem, setItemAction } from '../../actions/InventoryActions';
import { openPopup } from '../../actions/PopupActions';

import { PRODUCT_SEARCH } from '../../constants/inventory/Product'

import './Page.css';
import './TransferPage.css';

class TransferPage extends Page {

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      this.props.loadItem({type:"transfers",id:id});
      this.props.listItems({
        type:"transfers",
        id:id,
        childType:"items",
        query: {
          order: "item.type"
        }
      });

      // Determine the limiting transfers
      this.props.listItems({
        type:"transfers",
        id:id,
        childType:"items",
        childTypeAlias:"limits",
        query: {
          "where-item.type-eq": "item",
          symbol: "capacity",
          order: "symbol.capacity",
          direction: "asc",
          limit: 3
        }
      })
    }
  }

  onSave (evt, history) {

    // 1st, attempt to push the changes
    let transfer = this.props.transfer;
    this.props.pushItems([{type:"transfers",
      id: this.props.id,
      docs: {transfer:transfer},
      action: transfer.action
    }]).then((res)=>{

      // Get the id
      let resTransfer = dot(res,"0.transfer");
      let id = dot(resTransfer,"data.id") || this.props.id;

      // Add the items to the array
      let items = dot(this.props.transfer, "items.list.docs") || [];
      let arr = items.map((item, index)=>{
        let itemId = dot(item,"data.id");
        let version = dot(item,"data.version");
        let action = item.action ? item.action : (version >= 1 ? undefined : "create" );
        return {
          type:"transfers",
          id: id != "new" ? id : undefined,
          childType: "items",
          childId: itemId != "new" ? itemId : undefined,
          docs:{transferItem:item},
          action: action,
          index: index
        };
      });

      // 2nd, push the item changes
      this.props.pushItemsInSeries(arr).then((res)=>{
        // Check if page needs to be reloaded
        if (dot(resTransfer,"data.version") == 1) {
          history.replace(`/transfers/${id}`);
        }
        else {
          this.props.loadItem({type:"transfers",id:id});
          this.props.listItems({type:"transfers",id:id,childType:"items"});
        }
      });
    });
  }

  needsSave() {
    // Check po for sets
    if( hasKeys(dot(this.props.transfer,"sets")) ) {
      return true;
    }

    // Check if any of the items have changes
    let items = dot(this.props.transfer, "items.list.docs") || [];
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      if( docHasEdits(item) ) {
        return true;
      }
    }
    return false;
  }

  onCancel(evt,history) {
    history.goBack();
  }

  onDelete(evt,history) {
    this.props.openPopup({name:"Confirm",
      props:{
        title: "Delete Transfer",
        message: "Are you sure you want to delete this Transfer? You will NOT be able to undo this action.",
        onOk:()=>{
          this.props.deleteItem({type:"transfers", id: this.props.id}).then(()=>{
            history.replace("/transfers");
          });
        },
        okTheme: "red",
        okLabel: "Delete Transfer"
    }});
  }

  onAddItem() {
    let search = deepCopy(PRODUCT_SEARCH);
    let transfer = this.props.transfer;
    let conditions;
    let renderItem;
    // if (transfer.data.poId) {
    //   search.links = ["poItem"];
    //   conditions = [{"poItem.poId":{eq:transfer.data.poId}}];
    //   renderItem = (item, index)=>{
    //     return [
    //       <NameVal key="name">{dot(item,"data.name")}</NameVal>,
    //       <SubVal key="sku">{dot(item,"data.sku") || "-"}</SubVal>,
    //       <SubVal key="qty">{dot(item,"data.poItem.data.quantity") || "0"} total</SubVal>,
    //       <TypeVal key="type" type="products">{dot(item,"data.type")}</TypeVal>
    //     ]
    //   };
    // }
    // else if (transfer.data.sourceId) {
    //   search.links = ["locationItem"];
    //   conditions = [{"locationItem.locationId":{eq:transfer.data.sourceId}}];
    //   renderItem = (item, index)=>{
    //     return [
    //       <NameVal key="name">{dot(item,"data.name")}</NameVal>,
    //       <SubVal key="sku">{dot(item,"data.sku") || "-"}</SubVal>,
    //       <SubVal key="qty">{dot(item,"data.locationItem.data.quantity") || "0"} total</SubVal>,
    //       <TypeVal key="type" type="products">{dot(item,"data.type")}</TypeVal>
    //     ]
    //   };
    // }
    // else {
    //   renderItem = (item, index)=>{
    //     return [
    //       <NameVal key="name">{dot(item,"data.name")}</NameVal>,
    //       <SubVal key="sku">{dot(item,"data.sku") || "-"}</SubVal>,
    //       <SubVal key="qty">{dot(item,"data.totalAvailable") || "0"} total</SubVal>,
    //       <TypeVal key="type" type="products">{dot(item,"data.type")}</TypeVal>
    //     ]
    //   };
    // }

    renderItem = (item, index)=>{
      return [
        <NameVal key="name">{dot(item,"data.name")}</NameVal>,
        <SubVal key="sku">{dot(item,"data.sku") || "-"}</SubVal>,
        <SubVal key="qty">{dot(item,"data.totalAvailable") || "0"} total</SubVal>,
        <TypeVal key="type" type="products">{dot(item,"data.type")}</TypeVal>
      ]
    };

    this.props.openPopup({name:"Search",
      props:{
        title: "Select products.",
        type: "products",
        typeAlias: `transfer${transfer.data.id || "new"}`,
        query: search,
        conditions: conditions,
        renderItem: renderItem,
        other: {
          label: "+ New Product",
          onClick: () => {
            this._createProduct();
          }
        },
        onOk:(items)=>{
          this._addItems(items)
        }
    }});
  }

  _createProduct() {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props: {
        title: "New Product",
        opts: {
          type:"products",
          id: "new"
        },
        onPush:(product)=>{
          this._addItems([product]);
        }
      }
    });
  }

  _addItems(items) {
    let existing = dot(this.props.transfer, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft items for each of the new items
    items.forEach((item, index)=>{
      let data = item.data || {};
      this.props.startDraftItem({
        type:"transfers",
        id: id,
        childType: "items",
        index: count + index,
        data: {
          transferId: id,
          type: data.type,
          itemId: data.id,
          item: data
        }
      });
    });
  }

  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";

    // Check if we need to save
    let needsSave = this.needsSave();

    // Get handle to the Items
    let transfer = this.props.transfer;
    let items = dot(transfer, "items.list.docs") || [];
    let itemsStatus = dot(transfer, "items.list.status");
    let limits = dot(transfer, "limits.list.docs") || [];
    let type = dot(transfer, "data.type") || "transfer";

    // Check to see if sending transfer
    let status = dot(transfer, "status");
    let isSending = status == "saving" || status == "creating";
    if (!isSending) {
      for (var i = 0; i < items.length; i++) {
        status = dot(items[i],"status");
        isSending = status == "saving" || status == "creating";
        if (isSending) { break; }
      }
    }

    // Setup buttons
    let cancelButton = null;
    let saveButton = null;
    if (isNew) {
      cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}}>Create</RectButton>;
    }
    else {
      cancelButton = <RectButton theme="red" onClick={(evt,history)=>{this.onDelete(evt,history)}}>Delete</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}} lock={!needsSave}>Save</RectButton>;
    }

    return (
      <div className="Page TransferPage">
        <Prompt
          when={needsSave}
          message='Are you sure you want to leave? You have unsaved changes.'
        />
        <PageHeaderOutline title={dot(transfer,"data.name") || "Transfer"} aside={saveButton} top={this._backButton()}></PageHeaderOutline>
        <Section key="details" shout="Details">
          <HeaderLine><div>Details</div></HeaderLine>
          <LoadableArea working={status=="loading"}>
            <TransferForm opts={{type:"transfers",id:id}}/>
          </LoadableArea>
        </Section>
        <Section key="items">
          <HeaderLine>Items</HeaderLine>
          <LoadableArea working={itemsStatus == "loading"}>
            {items.map((item, index)=>{
              return <div className="Item" key={`item${index}`}>
                <TransferItemForm transferItem={item} listIndex={index}/>
              </div>
            })}
            <PaddedArea>
              <Row align="right">
                <PlainButton theme="blue" layout="auto" onClick={()=>{this.onAddItem();}}>Add items</PlainButton>
              </Row>
            </PaddedArea>
          </LoadableArea>
        </Section>
        <Separator/>
        <Row align="space-between">
          {cancelButton}
          {saveButton}
        </Row>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let transfer = dot(state.inventory,["transfers",props.id]) || {};
  return {
    transferName: dot(transfer,"data.name"),
    transfer: transfer,
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    loadItem: opts => dispatch(loadItem(opts)),
    listItems: opts => dispatch(listItems(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    pushItems: arr => dispatch(pushItems(arr)),
    pushItemsInSeries: arr => dispatch(pushItemsInSeries(arr)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    setItemAction: opts => dispatch(setItemAction(opts)),
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(TransferPage)
