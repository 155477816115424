import * as ACTIONS from '../actions/ActionTypes';

/**
 * Prepares border quadrant style based percentage (0-100)
 */
export const popups = function(state = [], action = {}) {
  let newState = [...state];
  switch (action.type) {
    case ACTIONS.OPEN_POPUP:
      newState.push(action.opts);
      break;
    case ACTIONS.CLOSE_POPUP:
    let id = action.opts.id;
      if (id) {
        newState = newState.filter((popup) => {return popup.id !== id});
      }
      else {
        newState.pop();
      }
      break;
    default:
      /* Nothing for now */
  }
  return newState;
};
