import React from 'react';
import { withRouter } from 'react-router'
import Moment from 'react-moment';
import Layout from './Layout';

import './Line.css';
import './PaymentLine.css';

import { financial, capitalize, abbr, datify } from '../../lib/formats';
import { dot } from '../../lib/obj';

import { PAYMENT_TYPE_MAP } from '../../constants/inventory/Payment.js';

class PaymentLine extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let data = dot(this.props.payment,"data") || {};
    let typeName = dot(PAYMENT_TYPE_MAP,[data.type,'name']);
    return (
      <div className="Line PaymentLine" data-type={data.mime} data-status={data.status} onClick={(evt)=>{this.onClick(evt)}} >
        <div className="Name">{data.name || '-'}</div>
        <div className="Type">{typeName || '-'}</div>
        <div className="Amount">{financial(data.amount)}</div>
        <div className="Paid">{data.paidAt ? datify(data.paidAt) : '-'}</div>
      </div>
    );
  }
}

const RoutedPaymentLine = withRouter(PaymentLine)
export default RoutedPaymentLine;
