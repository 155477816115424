import React from 'react';

class Presentation extends React.Component {
  render() {
    return (
      <div>Hello presentation!</div>
    );
  }
}

export default Presentation;
