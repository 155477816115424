import React from 'react';
import ReactDOM from 'react-dom';
import Control from './Control';
import './PaginateControl.css';
import { dot } from '../../lib/obj';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PaginateControl extends Control {

  constructor(props) {
    super(props);
    this.state = {
      limit: props.limit,
      offset: props.offset
    };
  }

  getOffset() {
    let offset = this.props.slave ? this.props.offset : this.state.offset;
    return offset || 0;
  }

  getLimit()  {
    let limit = this.props.slave ? this.props.limit : this.state.limit;
    return limit || 0;
  }

  getCount()  {
    let count = this.props.slave ? this.props.count : this.state.count;
    return count || 0;
  }

  onPrev(evt) {
    let offset = this.getOffset();
    let limit = this.getLimit();
    offset = Math.max(offset-limit, 0);
    if (this.props.onPrev) {
      this.props.onPrev(offset, limit);
    }
    this.onOffset(offset);
    this.setState({offset:offset});
  }

  onNext(evt) {
    let offset = this.getOffset();
    let limit = this.getLimit();
    offset = offset + limit;
    if (this.props.onNext) {
      this.props.onNext(offset, limit);
    }
    this.onOffset(offset);
    this.setState({offset:offset});
  }

  onOffset(offset) {
    if (this.props.onOffset) {
      this.props.onOffset(offset);
    }
  }

  render() {
    let less = this.getOffset() > 0;
    let more = (this.getOffset() + this.getLimit()) < this.getCount();
    let className = this.props.className || "";
    let center;
    if (this.props.children) {
      center = <div className="Center">{this.props.children}</div>
    }
    return (
      <div className={`PaginateControl Control ${className}`} ref="root" data-less={less} data-more={more} data-border="true">
        <div className="Prev" onClick={evt=>this.onPrev(evt)}><FontAwesomeIcon icon={["far","chevron-left"]} /></div>
        {center}
        <div className="Next" onClick={evt=>this.onNext(evt)}><FontAwesomeIcon icon={["far","chevron-right"]} /></div>
      </div>
    );
  }
}

export default PaginateControl
