import React from 'react';
import { connect } from 'react-redux'
import Form from './Form';
import Input from '../elements/Input';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import PlainButton from '../elements/PlainButton';
import SelectorControl from '../controls/SelectorControl';
import ActionVal from '../containers/ActionVal';
import TypeVal from '../containers/TypeVal';
import ProductLayout from '../layouts/ProductLayout';
import ImageLayout from '../layouts/ImageLayout';

import { dot } from '../../lib/obj';
import { capitalize, abbr } from '../../lib/formats';
import { setItemField, setItemAction } from '../../actions/InventoryActions';
import { stateForDoc } from '../../lib/inventory-util';

import './Form.css';
import './LocationItemForm.scss';


class LocationItemForm extends Form {

  // Edit the given field
  editField(field, value) {
    let opts = this.props.opts;
    this.props.setItemField({field:field, value:value, ...opts});
  }

  render() {
    // Get the location item and ref item
    let locationItem = this.props.locationItem;
    let product = this.props.product;

    // Handle to prop values
    let action = dot(locationItem,"action");

    let name = dot(product,"data.name");
    let sku = dot(product,"data.sku");
    let price = parseFloat(dot(locationItem,"data.unitCost")) || 0;
    let totalExpected = parseInt(dot(locationItem,"data.totalExpected")) || 0;
    let totalReceived = parseInt(dot(locationItem,"data.totalReceived")) || 0;
    let totalSent = parseInt(dot(locationItem,"data.totalSent")) || 0;
    let quantity = parseInt(dot(locationItem,"data.quantity")) || 0;
    let comment = dot(locationItem,"data.comment");

    // Get the product name
    let type = dot(locationItem,"data.item.type") || dot(product,"data.type");
    let productName = dot(locationItem,"data.item.name") || dot(product,"data.name");
    let productLabel = <div><TypeVal type="products" bare="true">{type}</TypeVal> {sku}</div>

    return (
      <div className="Form LocationItemForm" ref="root" data-action={action}>
        <ImageLayout className="Icon" file={this.props.image}/>
        <ProductLayout className="Name" product={this.props.product} onClick={(e,h)=>{this.onProductClick(e,h)}}/>
        <Readout className="Expected" label="Expected">{abbr(totalExpected)}</Readout>
        <Input className="Quantity" label="Quantity" type="number" value={quantity} onChange={(val)=>{this.editField("quantity",parseFloat(val))}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let inventory = state.inventory;
  let opts = props.opts || {};
  let locationItem = stateForDoc(state.inventory,{
    type: "locations",
    id: "new",
    childType: "items",
    ...opts
  });
  let location = dot(locationItem,"data.location");
  let productId = dot(locationItem,"data.itemId")
  let product = dot(locationItem,"data.item") || dot(inventory,["products",productId]);
  return {
    opts: opts,
    inventory: inventory,
    locationItem: locationItem,
    location: location,
    product: product,
    image:  dot(locationItem,"data.image") || dot(product,"data.image")
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts => dispatch(setItemField(opts)),
    setItemAction: opts => dispatch(setItemAction(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LocationItemForm)
