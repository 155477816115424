import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import Page from './Page';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Input from '../elements/Input';
import Separator from '../elements/Separator';
import ProductForm from '../forms/ProductForm';
import Draft from '../../lib/draft.js';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderLine from '../layouts/HeaderLine';
import NameVal from '../containers/NameVal';
import SubVal from '../containers/SubVal';
import MoneyVal from '../containers/MoneyVal';
import ProductLayout from '../layouts/ProductLayout';
import FileLayout from '../layouts/FileLayout';
import FileUploadLayout from '../layouts/FileUploadLayout';
import GridArea from '../areas/GridArea';
import FooterArea from '../areas/FooterArea';
import TableSearchSection from '../wrappers/TableSearchSection';

import { financial, capitalize } from '../../lib/formats';
import { docForLoc, docHasEdits } from '../../lib/inventory-util';
import { dot, hasKeys, deepCopy } from '../../lib/obj';
import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

import { loadItem, loadItemSuccess, listItems, pushItem, pushItems, createItem, pushItemsInSeries, deleteItem, startDraftItem, purgeDraftItem, setItemAction, purgeItem, setItemQuery, meldItems } from '../../actions/InventoryActions';
import { openPopup, closePopup } from '../../actions/PopupActions';

import { PRODUCT_SEARCH, PRODUCT_COMPONENT_SEARCH, PRODUCT_PROCESS_SEARCH,
         PRODUCT_TYPE_MAP, PRODUCT_ITEM_SEARCH, PRODUCT_TYPES,
         PRODUCT_LOCATION_SEARCH, PRODUCT_VENDOR_SEARCH, PRODUCT_INTEGRATION_SEARCH } from '../../constants/inventory/Product';
import { VENDOR_SEARCH } from '../../constants/inventory/Vendor';
import { LOCATION_SEARCH } from '../../constants/inventory/Location';

import './Page.css';
import './ProductPage.scss';


class ProductPage extends Page {

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      // Load the product
      this.props.loadItem({type:"products",id:id});
      // Load files
      this.props.listItems({type:"products",id:id,childType:"files"});
    }
  }

  onSave (evt, history) {
    let id = this.props.id;
    let isNew = !id || id === "new";
    let product = this.props.product;
    this.props.pushItem({
      type:"products",
      id: id,
      docs: {product:product},
      action: isNew ? "create" : undefined
    }).then((res)=>{
      if (isNew) {
        this.props.purgeDraftItem({type:"products",id:"new"});
        setTimeout(()=>{
          let newId = dot(res,"product.data.id")
          history.replace(`/products/${newId}`);
        },50);
      }
    });
  }

  needsSave() {
    // Check po for sets
    if( hasKeys(dot(this.props.product,"sets")) ) {
      return true;
    }
    return false;
  }

  onViewIntegrations() {
    let id = this.props.id;
    this.props.openPopup({
      name:"Search",
      props:{
        title: "View Integrations",
        type: "product-items",
        typeAlias: "product",
        idAlias: id,
        childTypeAlias: "integrations",
        limit: 25,
        query: deepCopy(PRODUCT_INTEGRATION_SEARCH),
        conditions: [{itemId:{eq:id}}],
        reload: true,
        selector: "none",
        renderItem: (item, index, optionProps)=>{
          let type = dot(item,"data.type");
          optionProps.image = dot(item,"data.productImage") || {};
          return [
            <ProductLayout key="product" product={dot(item,"data.product")}/>,
            <SubVal>{dot(item,"data.quantity")}</SubVal>
          ]
        },
        onItemClick: (item, index, history) => {
          if (item.data.productId) { history.push(`/products/${item.data.productId}`); }
          this.props.closePopup();
        },
        onOk: (items)=>{
          this.props.closePopup();
        }
      }
    });
  }

  onCancel(evt,history) {
    history.goBack();
  }

  onDelete(evt,history) {
    this.props.openPopup({name:"Confirm",
      props:{
        title: "Delete Product",
        message: "Are you sure you want to delete this Product? You will NOT be able to undo this action.",
        onOk:()=>{
          this.props.deleteItem({type:"products", id: this.props.id}).then(()=>{
            history.replace("/products");
          });
        },
        okTheme: "red",
        okLabel: "Delete Product"
    }});
  }

  onAddItem(addDraft) {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select products to Add",
        type: "products",
        typeAlias: "products-parts",
        limit: 50,
        query: PRODUCT_SEARCH,
        conditions: [{id:{neq:this.props.id}}],
        renderItem: (item, index, optionProps)=>{
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        other: {
          label: "+ New Product",
          onClick: () => {
            this._createProduct(addDraft);
          }
        },
        onOk:(items)=>{
          addDraft(items.map((selected,i)=>{
            return {
              productId: this.props.id,
              item: selected,
              image: selected.data.image,
              itemId: selected.data.id,
              type: "product"
            }
          }));
        }
      }
    });
  }

  onCopyItem() {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Product to Copy",
        type: "products",
        typeAlias: "products-copy",
        limit: 25,
        query: PRODUCT_SEARCH,
        radio: true,
        renderItem: (item, index, optionProps)=>{
          let type = dot(item,"data.type");
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        onOk:(items)=>{
          this.props.closePopup();
          let id = dot(items,"0.data.id");
          if (!id) { return; }
          // Load product items
          this.props.meldItems({
            type: "product-items",
            typeAlias: "products",
            idAlias: id,
            childTypeAlias: "copies",
            query: {
              links: ["item","image"],
              where:[
                {productId:{eq: id}},
                {deleted:{neq:1}},
                {type:{eq: "product"}},
              ]
            }
          }).then((res)=>{
            let ps = res.productItems;
            if (!ps) {return;}
            this._copyProductItems(ps);
          });
        }
      }
    });
  }

  _createProduct(addDraft) {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props: {
        title: "New Product",
        opts: {
          type:"products",
          id: "new"
        },
        onPush:(product)=>{
          addDraft({
            productId: this.props.id,
            item: product,
            image: product.data.image,
            itemId: product.data.id,
            type: "product"
          });
        }
      }
    });
  }

  _addItems(items) {
    let existing = dot(this.props.product, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft items for each of the new items
    items.forEach((item, index)=>{
      let data = item.data || {};
      this.props.startDraftItem({
        type:"products",
        id: id,
        childType: "items",
        //index: count + index,
        index: -1,
        data: {
          productId: id,
          quantity: 1,
          itemId: data.id,
          item: item,
          type: "product"
        }
      });
    });
  }

  _copyProductItems(productItems) {
    let existing = dot(this.props.product, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft items for each of the new items
    productItems.forEach((pi, index)=>{
      let data = pi.data || {};
      this.props.startDraftItem({
        type:"products",
        id: id,
        childType: "items",
        index: count + index,
        data: {
          productId: id,
          type: data.type,
          itemId: data.itemId,
          item: data.item,
          image: data.image,
          quantity: data.quantity,
          type: "product"
        }
      });
    });
  }

  onAddProcess(addDraft) {
    addDraft({ productId: this.props.id, type: "process" });
  }

  onFileUpload(files) {
    this._addFiles(files);
  }

  _addFiles(files) {
    let existing = dot(this.props.product, "files.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft files for each of the new files
    files.forEach((file, index)=>{
      let data = file.data || {};
      this.props.startDraftItem({
        type:"products",
        id: id,
        childType: "files",
        index: count + index,
        data: {
          poId: id,
          type: data.type,
          fileId: data.id,
          file: data
        }
      });
    });

    // Save the product files reference
    this.props.pushItems(files.map((file)=>{
      return { type:"products",
        id: this.props.id,
        childType: "files",
        docs: {
          productFile:{
            data: {
              fileId: dot(file,"data.id")
            }
          }
        },
        action: "create"
      };
    })).then((res)=>{
      console.log("Push Files: ", res);
      this.props.closePopup();
    });
  }

  onAddLocation(addDraft) {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Location",
        type: "locations",
        typeAlias: "products-locations",
        limit: 25,
        query: deepCopy(LOCATION_SEARCH),
        renderItem: (item, index)=>{
          return [
            <NameVal>{dot(item,"data.name")}</NameVal>,
            <SubVal>{dot(item,"data.address1")}</SubVal>
          ]
        },
        onOk:(items)=>{
          if (items.length > 0) {
            addDraft(items.map((selected,i)=>{
              return {
                locationId: selected.data.id,
                location: selected,
                itemId: this.props.id
              }
            }));
          }
        }
      }
    });
  }

  onAddVendor(addVendorDraft) {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Vendor",
        type: "vendors",
        typeAlias: "products-vendors",
        limit: 25,
        radio: true,
        query: deepCopy(VENDOR_SEARCH),
        renderItem: (item, index)=>{
          return [
            <NameVal>{dot(item,"data.name")}</NameVal>,
            <SubVal>{dot(item,"data.pocFirstName")} {dot(item,"data.pocLastName")}</SubVal>
          ]
        },
        other: {
          label: "+ New Vendor",
          onClick: () => {
            this.onCreateVendor();
          }
        },
        onOk:(items)=>{
          let selected = items[0];
          if (selected) {
            addVendorDraft({
              vendorId: selected.data.id,
              vendor: selected,
              itemId: this.props.id
            });
          }
        }
      }
    });
  }

  onEditVendorItem(vendorItem, index) {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props:{
        title: "Edit Vendor Item",
        formType: "vendor-item",
        opts: {
          type: "vendors",
          typeAlias: "products",
          id: dot(vendorItem,"data.vendorId"),
          idAlias: this.props.id,
          childType: "items",
          childTypeAlias: "vendor-items",
          index: index,
          childId: dot(vendorItem,"data.id"),
          query: { links: "vendor" }
        },
        onDelete: () => {
          this.props.purgeItem({type:"products", id: id, childType: "vendor-items", index: index});
        }
      }
    });
  }

  onCreateVendor() {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props: {
        title: "New Vendor",
        opts: {
          type:"vendors",
          id: "new"
        },
        onPush:(vendor)=>{
          this.addVendorItem(vendor);
        }
      }
    });
  }

  addVendorItem(vendor) {
    let id = this.props.id;
    let vendorId = dot(vendor,"data.id");

    // Populate initial data
    this.props.startDraftItem({
      type:"products",
      id: id,
      childType: "vendor-items",
      index: -1,
      data: {
        vendorId: vendorId,
        itemId: id,
        vendor: vendor
      }
    });

    // Send the info to the server
    setTimeout(()=>{
      this.props.createItem({
        type:"vendors",
        id: vendorId,
        childType: "items",
        typeAlias: "products",
        idAlias: id,
        childTypeAlias:"vendor-items",
        index: 0, // Since it is the first
        query: { links: "vendor" },
        docs: {
          vendorItem:{
            data: {
              vendorId: vendorId,
              itemId: id
            }
          }
        }
      });
    },50);
  }

  onViewFile(file, history) {
    if (file) {
      if (file.data.type == "book") {
        window.open(file.data.presignedS3GetUrl, '_blank');
      }
    }
  }

  onDeleteProductFile(productFile) {
    console.log("Deleting product file: ",productFile);
    let id = this.props.id;
    if (productFile) {
      this.props.deleteItem({type:"products", id:id, childType:"files", childId:productFile.data.id}).then(()=>{
        // Reload files
        this.props.listItems({type:"products",id:id,childType:"files"});
      });
    }

  }

  // Get the query config
  onConfigChange(config) {
    let id = this.props.id;
    console.log("CONFIG: ", config);
    if (!this._queryThrottle) {
      this._queryThrottle = _.enqueue(() => {
        let query = queryToWhere(config);
        query.order = "item.name";
        query.direction = "asc"
        this.props.listItems({
          type:"products",
          id: id,
          childType: "items",
          query: query
        }).then(()=>{
          this._queryThrottle.done();
        });
      }, 5000);
    }
    this._queryThrottle(config)
  }

  onViewItem(item, history, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (item && item.data.itemId) {
      history.push(`/products/${item.data.itemId}`);
    }
  }

  onViewLoaction(location, history, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (location) {
      history.push(`/locations/${location.data.id}`);
    }
  }

  onViewVendor(vendor, history, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (vendor) {
      history.push(`/vendors/${vendor.data.id}`);
    }
  }

  onCopy(evt,history) {
    let data = dot(this.props.product, "data") || {};
    this.props.openPopup({
      name: "Form",
      props: {
        title: "Duplicate Product",
        onRender: (popup) =>{
          return (<React.Fragment>
            <label>Enter new product information</label>
            <Input name="name" label="Name" value={`Duplicate of ${data.name}`}/>
            <Input name="sku" label="Sku" value={data.sku}/>
            <div className="ComponentCheck" style={{textAlign:"left"}}>
              <input type="checkbox" id="items" name="items" defaultChecked={true}/>
              <label for="items">Copy Components</label>
            </div>
            <div className="ProcessesCheck" style={{textAlign:"left"}}>
              <input type="checkbox" id="processes" name="processes" defaultChecked={true}/>
              <label for="processes">Copy Processes</label>
            </div>
            <div className="FilesCheck" style={{textAlign:"left"}}>
              <input type="checkbox" id="files" name="files" defaultChecked={true}/>
              <label for="files">Copy Files</label>
            </div>
            <div className="VendorCheck" style={{textAlign:"left"}}>
              <input type="checkbox" id="vendors" name="vendors" defaultChecked={true}/>
              <label for="vendors">Copy Vendors</label>
            </div>
          </React.Fragment>)
        },
        onOk: (data, popup)=>{
          popup.setState({working:true});
          this.onConfirmCopy(history, data);
        }
      }
    });
  }

  onConfirmCopy(history, predata) {
    let id = this.props.id;

    // Check which items to copy
    let copyItems = ["items","processes","files","vendors"].filter((kind)=>{
      return predata[kind] == "on";
    });

    // Prepare the query
    let query = {promptError: true};
    if (copyItems.length > 0) {
      query.copyId = id;
      query.copyItems = copyItems.join(",");
    }

    this.props.pushItem({
      type: "products",
      action: "create",
      docs: {
        product: {
          data: {
            type: dot(this.props.product,"data.type"),
            name: predata.name,
            sku: predata.sku,
            imageId: dot(this.props.product,"data.imageId"),
            cogs: dot(this.props.product,"data.cogs"),
            productionCost: dot(this.props.product,"data.productionCost")
          }
        }
      },
      query: query
    }).then((res)=>{
      let newId = dot(res,"product.data.id");

      // Jump right away if not copying items
      if (!query.copyItems) {
        this.props.closePopup();
        history.push(`/products/${newId}`);
        return;
      }

      // Listen for version changes
      var ctr = 0;
      this._copyInterval = setInterval(()=>{
        this.props.loadItem({
          type: "products",
          id: newId
        }).then((lires)=>{
          if (dot(lires,"product.data.version") > 1 || ctr++ > 10) {
            clearInterval(this._copyInterval);
            this._copyInterval = null;
            this.props.closePopup();
            history.push(`/products/${newId}`);
          }
        });
      },1500);
    });
  }

  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";
    let product = this.props.product;

    // Check if we need to save
    let needsSave = this.needsSave()

    // Get handle to the Items
    let items = dot(product, "items.list.docs") || [];
    let processes = dot(product, "processes.list.docs") || [];
    let itemsStatus = dot(product, "items.list.status");
    let type = dot(product, "data.type") || "product";

    // Get handle ot the product files
    let productFiles = dot(this.props.product, "files.list.docs") || [];
    let productFilesStatus = dot(this.props.product, "files.list.status");

    // Check to see if sending product
    let status = dot(product, "status");
    let isSending = status == "saving" || status == "creating";
    if (!isSending) {
      let allItems = items.concat(processes);
      for (var i = 0; i < allItems.length; i++) {
        status = dot(allItems[i],"status");
        isSending = status == "saving" || status == "creating";
        if (isSending) { break; }
      }
    }

    // Setup buttons
    let cancelButton = null;
    let saveButton = null;
    if (isNew) {
      cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}}>Create</RectButton>;
    }
    else {
      cancelButton = <RectButton theme="red" onClick={(evt,history)=>{this.onDelete(evt,history)}}>Delete</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}} lock={!needsSave}>Save</RectButton>;
    }

    // Files section
    let filesSection = <Section key="files" locked={isNew}>
      <PaddedArea>
        <LoadableArea working={productFilesStatus == "loading"}>
          <GridArea fit="med">
            {productFiles.map((productFile, index)=>{
              let file = dot(this.props.files, dot(productFile,"data.fileId"));
              return <FileLayout key={`file${index}`} onClick={(evt,history)=>{this.onViewFile(file,history)}} onDelete={()=>{this.onDeleteProductFile(productFile);}} file={file}/>
            })}
            <FileUploadLayout onUpload={files=>this.onFileUpload(files)}/>
          </GridArea>
        </LoadableArea>
      </PaddedArea>
    </Section>

    // Inventory list
    let locationConditions = id ? [{itemId:{eq:id}}] : undefined;

    // Vendor list
    let vendorConditions = id ? [{itemId:{eq:id}}] : undefined;

    // Set the different page actions
    let actions = null;
    if (!isNew) {
      actions = <React.Fragment>
        <PlainButton theme="blue" onClick={(evt,history)=>{this.onCopy(evt,history)}}><FontAwesomeIcon icon="copy" /> Duplicate</PlainButton>
        <PlainButton theme="blue" onClick={(evt,history)=>{this.onViewIntegrations(evt,history)}}><FontAwesomeIcon icon={["far","puzzle"]} /> Integrations</PlainButton>
      </React.Fragment>
    }

    return (
      <div className="Page ProductPage">
        <Prompt
          when={needsSave}
          message='Are you sure you want to leave? You have unsaved changes.'
        />
        <PageHeaderOutline title={`${dot(product,"data.name") || capitalize(type)}`} aside={saveButton} top={this._backButton()}>
          {actions}
        </PageHeaderOutline>
        <Section key="details">
          <HeaderLine>Details</HeaderLine>
          <LoadableArea working={status == "loading"}>
            <ProductForm opts={{type:"products",id:id}}/>
          </LoadableArea>
          <PaddedArea>
            <FooterArea>
              <div><span data-hype="strong">COGs</span>&nbsp; <MoneyVal>{dot(product,"data.cogs")}</MoneyVal></div>
            </FooterArea>
          </PaddedArea>
        </Section>

        { dot(product,"data.type") != "part" ?
        <TableSearchSection label="Components" className="Components" controllable="true"
        type="product-items" typeAlias="products" idAlias={id} childTypeAlias="items"
        query={deepCopy(PRODUCT_COMPONENT_SEARCH)}
        conditions={id ? [{productId:{eq:id}}] : undefined}
        reload={true}
        onAddItem={(f)=>{this.onAddItem(f)}}
        layout={[
          {title:" ",         field:"image.data.presignedS3GetUrl", type:"image", className:"Icon"},
          {title:"Name",      type:"custom", render:(o,i)=>{return <ProductLayout key="product" product={o.data.item} onClick={(e,h)=>this.onViewItem(o,h,e)}/> } },
          {title:"Price",     field:"item.data.cogs", type:"plain", default:"0" },
          {title:"Quantity",  field:"quantity", type:"number" },
          {title:"Total",     type:"custom", render:(o,i)=>{return ((dot(o,"data.item.data.cogs") || 0) * (dot(o,"data.quantity") || 0)).toFixed(2) } }
        ]}/>
        : "" }

        <TableSearchSection label="Processes" className="Processes" controllable="true"
        type="product-items" typeAlias="products" idAlias={id} childTypeAlias="processes"
        query={deepCopy(PRODUCT_PROCESS_SEARCH)}
        conditions={id ? [{productId:{eq:id}}] : undefined}
        reload={true}
        onAddItem={(f)=>{this.onAddProcess(f)}}
        layout={[
          {title:"Name",      field:"name", type: "string"},
          {title:"Price",     field:"unitCost", type:"number" },
          {title:"Quantity",  field:"quantity", type:"number" },
          {title:"Total",     type:"custom", render:(o,i)=>{return ((dot(o,"data.unitCost") || 0) * (dot(o,"data.quantity") || 0)).toFixed(2) } }
        ]}/>

        {filesSection}

        <TableSearchSection label="Inventory" className="Inventory" controllable="true"
        type="location-items" typeAlias="products" idAlias={id} childTypeAlias="location-items"
        query={deepCopy(PRODUCT_LOCATION_SEARCH)}
        conditions={locationConditions}
        reload={true}
        onAddItem={(f)=>{this.onAddLocation(f)}}
        layout={[
          {title:"Name",      field:"location.data.name", type:"action", onClick:(e,h,i)=>{this.onViewLoaction(i.data.location,h,e)} },
          {title:"Address",   field:"location.data.address1", type:"plain", hype:"whisper"},
          {title:"Expected",  field:"totalExpected", type:"action", onClick:(e,h,i)=>{this.onViewLoaction(i.data.location,h,e)} },
          {title:"Quantity",  field:"quantity", type:"number" }
        ]}/>

        <TableSearchSection label="Vendors" className="Vendors" controllable="true"
        type="vendor-items" typeAlias="products" idAlias={id} childTypeAlias="vendor-items"
        query={deepCopy(PRODUCT_VENDOR_SEARCH)}
        conditions={vendorConditions}
        reload={true}
        onAddItem={(f)=>{this.onAddVendor(f)}}
        layout={[
          {title:"Name",      field:"vendor.data.name",   type:"action", onClick:(e,h,i)=>{this.onViewVendor(i.data.vendor,h,e)} },
          {title:"Contact",   render:(o,i)=>`${dot(o,"data.vendor.data.pocFirstName")||""} ${dot(o,"data.vendor.data.pocLastName")||""}`, type:"custom" },
          {title:"Lead Time", field:"leadTime", type:"number" },
          {title:"Cost",      field:"unitCost", type:"number" }
        ]}/>

        <Separator/>
        <Row align="space-between">
          {cancelButton}
          {saveButton}
        </Row>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let product = dot(state.inventory,["products",props.id]) || {};
  let files = dot(state.inventory,"files") || {};
  return {
    productName: dot(product,"data.name"),
    product: product,
    inventory: state.inventory,
    files: files
  }
};

const mapDispatch = (dispatch) => {
  return {
    loadItem: opts => dispatch(loadItem(opts)),
    loadItemSuccess: (opts, docs) => dispatch(loadItemSuccess(opts, docs)),
    listItems: opts => dispatch(listItems(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    createItem: opts => dispatch(createItem(opts)),
    pushItem: arr => dispatch(pushItem(arr)),
    pushItems: arr => dispatch(pushItems(arr)),
    pushItemsInSeries: arr => dispatch(pushItemsInSeries(arr)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts)),
    setItemAction: opts => dispatch(setItemAction(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ProductPage)
