import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';

import './FileLineHeader.css';

class FileLineHeader extends Layout {
  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }
  render() {
    return (
      <div className="FileLineHeader" onClick={(evt)=>{this.onClick(evt)}} >
        <div className="Name">{this.props.col1 || "Name"}</div>
        <div className="Type">Type</div>
        <div className="Size">Size</div>
        <div className="Icon">Icon</div>
        <div className="CreatedAt">Created</div>
      </div>
    );
  }
}

const RoutedFileLineHeader = withRouter(FileLineHeader)
export default RoutedFileLineHeader;
