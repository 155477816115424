import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import DropControl from '../controls/DropControl';
import DateControl from '../controls/DateControl';
import Input from '../elements/Input';

import { dot } from '../../lib/obj';
import { setItemField } from '../../actions/InventoryActions';
import { stateForDoc } from '../../lib/inventory-util';

import { PAYMENT_TYPES, PAYMENT_TYPE_MAP } from '../../constants/inventory/Payment.js';

import './Form.css';
import './PaymentForm.css';

class PaymentForm extends Form {

  // Edit the given field
  editField(field, value) {
    let opts = this.props.opts;
    this.props.setItemField({field:field, value:value, ...opts});
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.payment,"data") || {};
    let paidAt = data.paidAt ? data.paidAt.split(" ")[0] : null;
    return (
      <div className="Form PaymentForm">
      <DropControl className="Type" layout="field" label="Type" value={dot(PAYMENT_TYPE_MAP,[data.type,"name"])} options={PAYMENT_TYPES} onChange={(option)=>{this.editField("type",option.value)}}/>
      <Input className="Name" label="Name" type="text" value={data.name} onChange={(val)=>{this.editField("name",val)}}/>
      <Input className="Description" label="Description" type="text" value={data.description} onChange={(val)=>{this.editField("description",val)}}/>
      <Input className="PaidAt" label="Paid At" type="date" value={paidAt} onChange={(val)=>{this.editField("paidAt",val)}}/>
      <Input className="Amount" label="Amount" type="number" value={data.amount} onChange={(val)=>{this.editField("amount",val)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let opts = props.opts || {}
  let payment = stateForDoc(state.inventory,{
    type: "payments",
    id: "new",
    ...opts
  });
  return {
    opts: opts,
    inventory: state.inventory,
    payment: payment
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(PaymentForm)
