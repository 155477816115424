import React from 'react';
import { connect } from 'react-redux'
import Form from './Form';
import Input from '../elements/Input';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import PlainButton from '../elements/PlainButton';
import SelectorControl from '../controls/SelectorControl';
import ActionVal from '../containers/ActionVal';
import TypeVal from '../containers/TypeVal';


import './Form.css';
import './OrderItemForm.scss';

import { dot } from '../../lib/obj';
import { financial, capitalize } from '../../lib/formats';
import { docForLoc, groupNameForType } from '../../lib/inventory-util';
import { listItems, setItemField, setItemAction } from '../../actions/InventoryActions';


class OrderItemForm extends Form {

  // Edit the given field
  editField(field, value) {
    // Handle to the set item field options
    let opts = {
      type:"orders",
      id: dot(this.props.orderItem,"data.orderId"),
      childType: "items",
      childId: dot(this.props.orderItem,"data.itemId"),
      field:field,
      value:value
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemField(opts);
  }

  onDeleteClick(evt) {
    console.log("onDeleteClick");
    // Handle to the set item field options
    let opts = {
      type:"orders",
      id: dot(this.props.orderItem,"data.orderId"),
      childType: "items",
      childId: dot(this.props.orderItem,"data.itemId"),
      action: "delete"
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemAction(opts);
  }

  onUndoClick(evt) {
    // Handle to the set item field options
    let opts = {
      type:"orders",
      id: dot(this.props.orderItem,"data.orderId"),
      childType: "items",
      childId: dot(this.props.orderItem,"data.itemId"),
      action: ""
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemAction(opts);
  }


  render() {
    // Get the order item and ref item
    let orderItem = this.props.orderItem;
    let product = this.props.product;

    // Handle to prop values
    let action = dot(orderItem,"action");
    let name = dot(product,"data.name");
    let sku = dot(product,"data.sku");
    let price = parseFloat(dot(orderItem,"data.unitCost")) || 0;
    let quantity = parseFloat(dot(orderItem,"data.quantity")) || 0;
    let comment = dot(orderItem,"data.comment");

    // Prepare the choices for the item selector
    let type = dot(orderItem,"data.item.type") || dot(product,"data.type");
    let productName = dot(orderItem,"data.item.name") || dot(product,"data.name");
    let productLabel = <div><TypeVal type="products" bare="true">{type}</TypeVal> {sku}</div>

    let control = null;
    if (action == "delete") {
      control = <ActionVal className="Control" onClick={(evt)=>{this.onUndoClick(evt)}}>&#8635;</ActionVal>
    } else {
      control = <ActionVal className="Control" theme="red" onClick={(evt)=>{this.onDeleteClick(evt)}}>&#x2715;</ActionVal>
    }

    // Determine the name field
    let nameField = null;
    if (type) {
      nameField = <Readout className="Name" label={productLabel} onClick={(e,h)=>{this.onProductClick(e,h)}}>{productName}</Readout>;
    }
    else {
      nameField = <Input className="Name" label="Name" value={dot(orderItem,"data.name")} onChange={(val)=>{this.editField("name",val)}}/>
    }

    return (
      <div className="Form OrderItemForm" ref="root" data-action={action}>
        {nameField}
        <Input className="Quantity" label="Quantity" type="number" value={quantity} onChange={(val)=>{this.editField("quantity",parseFloat(val))}}/>
        <Input className="Price" label="Price" type="number" value={price} onChange={(val)=>{this.editField("unitCost",parseFloat(val))}}/>
        <Readout className="Total"  label="Total">{financial(price*quantity)}</Readout>
        {control}
      </div>
    );
  }
}

const mapState = (state, props) => {
  let itemId = dot(props.orderItem,"data.itemId");
  let product = itemId ? (dot(state.inventory,["products",itemId]) || {}) : {};
  return {
    inventory: state.inventory,
    product: product
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts => dispatch(setItemField(opts)),
    listItems: opts => dispatch(listItems(opts)),
    setItemAction: opts => dispatch(setItemAction(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderItemForm)
