import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';

import PaddedArea from '../areas/PaddedArea';
import FooterArea from '../areas/FooterArea';

import './Popup.css';
import './ConfirmPopup.css';

import { connect } from 'react-redux'
import { closePopup } from '../../actions/PopupActions';

class ConfirmPopup extends Popup {

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    console.log("ConfirmPopup: onOk");
    if (this.props.onOk) {
      this.props.onOk(evt, history);
    }
    this.props.closePopup();
  }

  render() {
    // Setup buttons
    let cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>
      {this.props.cancelLabel || "Cancel"}
    </RectButton>;

    // Setup buttons
    let okButton = <RectButton theme={this.props.okTheme || "blue"} onClick={(evt,history)=>{this.onOk(evt, history)}}>
      {this.props.okLabel || "Ok"}
    </RectButton>;

    return (
      <div className="Popup ConfirmPopup" ref="root">
        <Section>
          <PaddedArea>
            <div className="Title">
              {this.props.title}
            </div>
            <Separator/>
            <div className="Message">
              {this.props.message}
            </div>
            <Separator theme="white"/>
            <FooterArea>
              <div/>
              {cancelButton}
              {okButton}
            </FooterArea>
          </PaddedArea>
        </Section>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {}
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ConfirmPopup)
