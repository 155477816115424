import React from 'react';

class Layout extends React.Component {
  render() {
    return (
      <div>Hello layout!</div>
    );
  }
}

export default Layout;
