import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import PaddedArea from '../areas/PaddedArea';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import LoginForm from '../forms/LoginForm'
import { connect } from 'react-redux'
import { closePopup } from '../../actions/PopupActions';
import { createItem, purgeItem } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot } from '../../lib/obj';

import './Popup.css';
import './LoginPopup.css';


class LoginPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      unauthorized:false,
      error: false
    };
  }

  onCancel(evt, history) {
    this.props.purgeItem({ type: "logins", id: "new" });
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    this.sendLogin();
  }

  onEnter(evt) {
    this.sendLogin();
  }

  sendLogin() {
    this.props.createItem({
      type: "logins",
      id: "new",
      docs: {
        login: this.props.login
      }
    }).then((res)=>{
      if (res.login) {
        this.props.purgeItem({ type: "logins", id: "new" });
        this.props.setSessionLogin(res);
        this.props.closePopup();
      }
      else if (res.unauthorized) {
        this.setState({unauthorized:true});
      }
      else {
        this.setState({error:true});
      }
    });
  }

  render() {

    // Check if sending the login
    let unauthorized = this.state.unauthorized;
    let error = this.state.error;
    let login = this.props.login;
    let sending = dot(login,"status") == "creating";

    // Setup buttons
    let cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>
      {this.props.cancelLabel || "Cancel"}
    </RectButton>;

    // Setup buttons
    let okButton = <RectButton theme={this.props.okTheme || "blue"} working={sending} onClick={(evt,history)=>{this.onOk(evt, history)}}>
      { sending ? "Sending..." : "Sign In" }
    </RectButton>;

    // Determine the message to display
    let message = null;
    if (unauthorized) {
      message = "Wrong email or password. Please try again.";
    }
    else if (error) {
      message = "Unable to login now. Please try again later.";
    }
    else {
      message = "Enter your email and password to continue.";
    }

    return (
      <div className="Popup LoginPopup" ref="root" data-unauthorized={unauthorized} data-error={error}>
        <Section>
          <PaddedArea>
            <div className="Title">
               Sign in
            </div>
            <Separator/>
            <div className="Message">
              {message}
            </div>
            <Separator theme="white"/>
            <LoginForm onEnter={(evt)=>{this.onEnter(evt)}}/>
            <Separator theme="white"/>
            <Row align="right">
              {cancelButton}
              {okButton}
            </Row>
          </PaddedArea>
        </Section>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    login: dot(state.inventory,"logins.new")
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    createItem: opts => dispatch(createItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    setSessionLogin: opts => dispatch(setSessionLogin(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LoginPopup)
