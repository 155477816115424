import * as ACTIONS from './ActionTypes';

export const expandMenu = ({name=null}={}) => {
  return {
    type: ACTIONS.EXPAND_MENU,
    name: name
  }
}

export const collapseMenu = ({name=null}={}) => {
  return {
    type: ACTIONS.COLLAPSE_MENU,
    name: name
  }
}
