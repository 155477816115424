import React from 'react';
import Element from './Element';
import './Separator.css';

class Separator extends Element {
  render() {
    return (
      <div className="Separator" ref="root" data-theme={this.props.theme} data-display={this.props.display} data-collapsed={this.props.collapsed}></div>
    );
  }
}

export default Separator;
