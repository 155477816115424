import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import PaddedArea from '../areas/PaddedArea';
import FooterArea from '../areas/FooterArea';
import ActionVal from '../containers/ActionVal';
import TitleVal from '../containers/TitleVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FileForm from '../forms/FileForm';
import LocationItemForm from '../forms/LocationItemForm';
import PaymentForm from '../forms/PaymentForm';
import ProductForm from '../forms/ProductForm';
import ProductItemForm from '../forms/ProductItemForm';
import TransferForm from '../forms/TransferForm';
import VendorForm from '../forms/VendorForm';
import VendorItemForm from '../forms/VendorItemForm';

import './Popup.css';
import './ItemEditPopup.css';

import { connect } from 'react-redux'
import { openPopup, closePopup } from '../../actions/PopupActions';
import { pushItem, deleteItem, purgeItem, revertItemEdits } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot, hasKeys } from '../../lib/obj';
import { stateForDoc } from '../../lib/inventory-util';
import { singularType } from '../../constants/inventory/Types'

class ItemEditPopup extends Popup {

  isNewItem() {
    let id = dot(this.props.item,"data.id");
    return !id || id == "new";
  }

  onCancel(evt, history) {
    let opts = this.props.opts;
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.revertItemEdits(opts);
    this.props.closePopup();
  }

  onPush() {
    let opts = this.props.opts;

    let docType = this.props.singularType || singularType(opts);
    this.props.pushItem({
      action: this.isNewItem() ? "create" : undefined,
      docs: {
        [docType]: this.props.item
      },
      ...opts
    }).then((res)=>{
      if (this.isNewItem()) {
        this.props.purgeItem(opts);
      }
      if (this.props.onPush) { this.props.onPush(res[docType]); }
      this.props.closePopup();
    });
  }

  onDelete() {
    let opts = this.props.opts;
    if (this.isNewItem()) {
      this.props.purgeItem(opts);
      this.props.closePopup();
    }
    else {
      this.props.closePopup();
      this.props.openPopup({name:"Confirm",
        props:{
          title: "Confirm delete",
          message: "Are you sure you want to continue? You will NOT be able to undo this action.",
          onOk:()=>{
            let docType = this.props.singularType || singularType(opts);
            this.props.deleteItem(opts).then((res)=>{
              if (this.props.onDelete) { this.props.onDelete(this.props.item); }
              this.props.closePopup();
            });
          },
          okTheme: "red",
          okLabel: "Delete"
        }
      });
    }
  }

  getForm() {
    let opts = this.props.opts;
    let formType = this.props.formType;
    if (!formType) {
      let type = opts.typeAlias || opts.type;
      let childType = opts.childTypeAlias || opts.childType;
      formType = childType ? `${type}:${childType}` : type;
    }
    switch (formType) {
      case "files":
        return <FileForm layout="popup" opts={this.props.opts}/>;
      case "locations:items":
      case "location-item":
        return <LocationItemForm layout="popup" opts={this.props.opts}/>;
      case "payments":
        return <PaymentForm layout="popup" opts={this.props.opts}/>;
      case "products":
        return <ProductForm layout="popup" opts={this.props.opts}/>;
      case "transfers":
        return <TransferForm layout="popup" opts={this.props.opts}/>;
      case "vendors":
        return <VendorForm layout="popup" opts={this.props.opts}/>;
      case "vendors:items":
      case "vendor-item":
        return <VendorItemForm layout="popup" opts={this.props.opts}/>;
      case "products:items":
      case "product-item":
        return <ProductItemForm layout="popup" opts={this.props.opts}/>;
      default:
        return <div className="Form">No valid form found</div>
    }
  }

  render() {
    // Check if sending the item
    let item = this.props.item;
    let sending = dot(item,"status") == "creating" || dot(item,"status") == "saving" ;

    // Delete button
    let deleteButton = <ActionVal className="Delete IconAction Left" theme="red" onClick={evt=>this.onDelete(evt)} >
      <FontAwesomeIcon className="Icon" icon={["far","trash-alt"]}/> Delete
    </ActionVal>;

    // Setup buttons
    let cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>
      {this.props.cancelLabel || "Cancel"}
    </RectButton>;

    // Setup buttons
    let needsSave = this.props.needsSave || this.isNewItem();
    let okButton = <RectButton theme="blue" working={sending} onClick={(evt,history)=>{this.onPush(evt, history)}} lock={!needsSave}>
      { sending ? "Sending..." : (this.isNewItem() ? "Create" : "Save") }
    </RectButton>;

    return (
      <div className="Popup ItemEditPopup" ref="root">
        <Section>
          <PaddedArea>
            <TitleVal className="Title">{this.props.title || "Edit Item"}</TitleVal>
            <Separator/>
            {this.getForm()}
            <Separator/>
            <FooterArea>
              {deleteButton}
              {cancelButton}
              {okButton}
            </FooterArea>
          </PaddedArea>
        </Section>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let opts = props.opts || {};
  let item = stateForDoc(state.inventory, opts);
  return {
    opts: opts,
    item: item,
    needsSave: hasKeys(dot(item,"sets"))
  }
};

const mapDispatch = (dispatch) => {
  return {
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    revertItemEdits: opts => dispatch(revertItemEdits(opts)),
    setSessionLogin: opts => dispatch(setSessionLogin(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ItemEditPopup)
