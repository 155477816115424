import React from 'react';
import { withRouter } from 'react-router'
import Element from './Element';
import './PlainButton.css';

class PlainButton extends Element {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt,this.props.history);
    }
  }

  render() {
    let className = this.props.className || "";
    return (
      <div className={`PlainButton ${className}`} ref="root" data-layout={this.props.layout} data-theme={this.props.theme} onClick={(evt)=>{this.onClick(evt)}}>{this.props.children}</div>
    );
  }
}

const RountedPlainButton = withRouter(PlainButton)
export default RountedPlainButton;
