import * as ACTIONS from '../actions/ActionTypes';
import { createReducer } from 'redux-starter-kit'
import { dot, purge, deepCopy, reg } from '../lib/obj';

const NOTIFICATION_HIDE_DELTA = 3000;

/**
 * Sets an App field value
**/
const setAppField = function(state, action) {
  dot(state, action.field, action.value);
}

/**
 * Increments an App field value
**/
const incAppField = function(state, action) {
  let amount = parseInt(action.value) || 0;
  let current = parseInt(dot(state, action.field)) || 0;
  dot(state, action.field, (current + amount));
}

/**
 * Clears an App field value
**/
const clearAppField = function(state, action) {
  purge(state, action.field);
}

/**
 * Toggles an App field value
**/
const toggleAppField = function(state, action) {
  dot(state, action.field, dot(state, action.field) === false);
}

/**
 * Pushes an app notificatoin
**/
const pushAppNotification = function(state, action) {
  let notifications = reg(state, "notifications", []);
  notifications.push({message:action.message, id:action.id, details:action.details, kind:action.kind});
}

/**
 * Clears an app notification
**/
const clearAppNotification = function(state, action) {
  let notifications = state.notifications;
  if (!notifications || !notifications.filter) { return; }
  let stamp = Date.now();
  state.notifications.forEach((notification, i) => {
    if(notification.id == action.id && (notification.hide !== false || action.force)) {
      notification.hide = true;
      notification.hiddenAt = stamp;
    }
  });
  state.notifications = notifications.filter((notification)=>{
    return !notification.hiddenAt || (stamp - notification.hiddenAt) <= NOTIFICATION_HIDE_DELTA;
  });
}

/**
 * Keep an app notification
**/
const keepAppNotification = function(state, action) {
  let notifications = state.notifications;
  if (!notifications || !notifications.forEach) { return; }
  state.notifications.forEach((notification, i) => {
    if(notification.id == action.id) {
      notification.hide = false;
    }
  });
}

/*
 * Main inventory reducer
 * NOTE: Users Immer to more easily handle immutable state updates
 * Ref: https://redux-starter-kit.js.org/api/createreducer
**/
export const app = createReducer({}, {
  [ACTIONS.SET_APP_FIELD]: setAppField,
  [ACTIONS.INC_APP_FIELD]: incAppField,
  [ACTIONS.CLEAR_APP_FIELD]: clearAppField,
  [ACTIONS.TOGGLE_APP_FIELD]: toggleAppField,
  [ACTIONS.PUSH_APP_NOTIFICATION]: pushAppNotification,
  [ACTIONS.CLEAR_APP_NOTIFICATION]: clearAppNotification,
  [ACTIONS.KEEP_APP_NOTIFICATION]: keepAppNotification
})
