import React from 'react';
import Container from './Container';
import './WeakVal.css';

class WeakVal extends Container {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`WeakVal Container ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default WeakVal;
