import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import LoginForm from '../forms/LoginForm'
import { connect } from 'react-redux'

import './Popup.css';
import './SelectPopup.css';

import { closePopup } from '../../actions/PopupActions';
import { loadItem, listItems, setItemQuery } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot } from '../../lib/obj';
import FilterLine from '../layouts/FilterLine';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

class SelectPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      selections: {}
    };
  }

  componentWillMount() {
    let search = this.props.search;
    let query = queryToWhere(search,{"where-deleted-neq":1});
    query.order = "name";
    query.direction = "asc"
    this.props.listItems({
      type: search.type,
      query: query
    });

    // Start new item query
    this.props.setItemQuery({
      type: search.type,
      query: search
    });
  }

  onOptionClick(index, option) {
    let selections = this.state.selections;
    let id = dot(option.data,'id');
    let found = selections[id];
    if (found) {
      delete selections[id];
    } else {

      // Clear selected options
      if (this.props.type == "radio") {
        selections = {}
      }

      selections[id] = {
        ordering: Object.keys(selections).length,
        item: option
      }
    }
    this.setState({selections:selections});
  }

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    if (this.props.onOk) {
      let selections = this.state.selections;
      let ids = Object.keys(selections).sort((a,b)=>{
        return selections[a].ordering - selections[b].ordering
      });
      let items = ids.map((a)=>{return selections[a].item;});
      this.props.onOk(items);
    }
    this.props.closePopup();
  }

  // Get the query config
  onConfigChange(config) {
    if (!this._queryThrottle) {
      this._queryThrottle = _.enqueue(() => {
        let query = queryToWhere(config,{"where-deleted-neq":1});
        query.order = "name";
        query.direction = "asc"
        this.props.listItems({
          type: config.type,
          query: query
        }).then(()=>{
          this._queryThrottle.done();
        });
      }, 15000);
    }
    this._queryThrottle(config);
  }

  // Called when the Other option is clicked
  onOther(evt) {
    this.props.closePopup();
    if(this.props.other && this.props.other.onClick) {
      this.props.other.onClick(evt);
    }
  }

  render() {

    // Check if sending the login
    let login = this.props.login;
    let sending = dot(login,"status") == "creating";

    // Set the query if we have one
    let query = null;
    if (this.props.list.query) {
      query = <FilterLine className="Filter" label={this.props.filterLabel} config={this.props.list.query} onConfigChange={(config)=>{this.onConfigChange(config)}}/>
    }

    // Set the other option if we have one
    let other = null;
    if (this.props.other) {
      other = <div className="Option Other" key={`optionOther`} onClick={()=>{this.onOther();}}>
        <span className="Check"></span>
        <span className="Entry">{this.props.other.label || "Other"}</span>
      </div>;
    }


    // Get the items and set as options
    const options = this.props.items || [];
    const selections = this.state.selections;
    const status = this.props.status;
    return (
      <div className="Popup SelectPopup" ref="root" data-type={this.props.type}>
        {query}
        <div className="Options" data-working={status == "loading"}>
          {options.map((option, index)=>{
            return (
              <div className="Option" key={`option${index}`} data-selected={selections[option.data.id] != null} onClick={()=>{this.onOptionClick(index, option)}}>
                <span className="Check"></span>
                <span className="Entry">{dot(option,"data.name")}</span>
              </div>
            );
          })}
          {other}
          <div className="Indicator"></div>
        </div>
        <RectButton className="Cancel" theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>
        <RectButton className="OK" theme="blue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let search = props.search || {};
  let list = dot(state.inventory,[search.type,"list"]) || {};
  return {
    type: props.type || "check",
    list: list,
    search: search,
    status: list.status,
    items: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    listItems: opts => dispatch(listItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(SelectPopup)
