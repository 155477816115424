import * as ACTIONS from './ActionTypes';
import { pushAppNotification } from './AppActions';
import { lssGet, lssPost, lssPut, lssDelete } from '../apis/Lss';
import { _ } from '../lib/underscore'
import { dot, reg, arrange } from '../lib/obj'
import Draft from '../lib/draft.js';
import Contract from '../lib/contract.js';
import { singularType } from '../constants/inventory/Types'

/**
 * POST PUT REQUEST QUEUE
**/
const PPQ = [];

/**
 * Purges the item
**/
export const purgeItem = (opts={})=>{
  return {
    type: ACTIONS.PURGE_ITEM,
    opts: opts
  }
};

/**
 * Purges the items list
**/
export const purgeItemList = (opts={})=>{
  return {
    type: ACTIONS.PURGE_ITEM_LIST,
    opts: opts
  }
};

/**
 * Starts an item draft
**/
export const startDraftItem = (opts={})=>{
  return {
    type: ACTIONS.START_DRAFT_ITEM,
    opts: opts
  }
};

/**
 * Purges the item draft
**/
export const purgeDraftItem = (opts={})=>{
  return {
    type: ACTIONS.PURGE_DRAFT_ITEM,
    opts: opts
  }
};

/**
 * Sets the item field value
**/
export const setItemField = (opts={})=>{
  return {
    type: ACTIONS.SET_ITEM_FIELD,
    opts: opts
  }
};

/**
 * Increments the item field value
**/
export const incItemField = (opts={})=>{
  return {
    type: ACTIONS.INC_ITEM_FIELD,
    opts: opts
  }
};

/**
 * Clears the item field value
**/
export const clearItemField = (opts={})=>{
  return {
    type: ACTIONS.CLEAR_ITEM_FIELD,
    opts: opts
  }
};


/**
 * Purges the item field edits
**/
export const revertItemEdits = (opts={})=>{
  return {
    type: ACTIONS.REVERT_ITEM_EDITS,
    opts: opts
  }
};

/**
 * Stages the item action
**/
export const setItemAction = (opts={})=>{
  return {
    type: ACTIONS.SET_ITEM_ACTION,
    opts: opts
  }
};

/**
 * Stages the item query
**/
export const setItemQuery = (opts={})=>{
  console.log("setItemQuery OPTS: ", opts);
  return {
    type: ACTIONS.SET_ITEM_QUERY,
    opts: opts
  }
};

/**
 * Clears the item query
**/
export const clearItemQuery = (opts={})=>{
  return {
    type: ACTIONS.CLEAR_ITEM_QUERY,
    opts: opts
  }
};

/*
 * Gets an item
**/
export const loadItem = (opts={})=>{
  return ((dispatch, getState) => {

    // Get the current state
    let state = getState();

    // Get the default values
    opts.path = opts.path || genItemPath(opts);

    // Set the default credentials
    reg(opts, "book", dot(state,"session.book"));
    reg(opts, "login", dot(state,"session.login"));

    // Dispatch action
    dispatch({
      type: ACTIONS.LOAD_ITEM,
      opts: opts
    });

    // Get the data from the server
    return lssGet({path:`/inventory/${opts.path}`,
      user: opts.user,
      login: opts.login,
      book: opts.book,
      query: opts.query
    })
    .then((res)=>{
      opts.resType = res.type;
      dispatch({
        type: ACTIONS.LOAD_ITEM_SUCCESS,
        opts: opts,
        docs: res.docs
      });
      return res.docs;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.LOAD_ITEM_ERROR,
        opts: opts,
        error: err
      });
      dispatch(pushAppNotification("Load item error...",{details: JSON.stringify(err, null,"  "), kind: "error", timeout: 5000}))
    });
  });
}

/*
 * Notes dcos are loaded
**/
export const loadItemSuccess = (opts={}, docs = {})=>{
  return {
    type: ACTIONS.LOAD_ITEM_SUCCESS,
    opts: opts,
    docs: docs
  };
};

/*
 * Lists the items
**/
export const listItems = (opts={})=>{
  return ((dispatch, getState) => {

    // Get the current state
    let state = getState();

    // Get the default values
    opts.path = opts.path || genItemPath(opts);

    // Set the default credentials
    reg(opts, "book", dot(state,"session.book"));
    reg(opts, "login", dot(state,"session.login"));

    // Dispatch action
    dispatch({
      type: ACTIONS.LIST_ITEMS,
      opts: opts
    });

    // Get the data from the server
    return lssGet({path:`/inventory/${opts.path}`,
      user: opts.user,
      login: opts.login,
      book: opts.book,
      query: opts.query
    })
    .then((res)=>{
      opts.resType = res.type;
      dispatch({
        type: ACTIONS.LIST_ITEMS_SUCCESS,
        opts: opts,
        docs: res.docs
      });
      return res.docs;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.LIST_ITEMS_ERROR,
        opts: opts,
        error: err
      });
      dispatch(pushAppNotification("List items error...",{details: JSON.stringify(err, null,"  "), kind: "error", timeout: 5000}))
    });
  });
}


/*
 * Meld the items
**/
export const meldItems = (opts={})=>{
  return ((dispatch, getState) => {

    // Get the current state
    let state = getState();

    // Get the default values
    opts.path = opts.path || genItemPath(opts);

    // Set the default credentials
    reg(opts, "book", dot(state,"session.book"));
    reg(opts, "login", dot(state,"session.login"));

    // Dispatch action
    dispatch({
      type: ACTIONS.MELD_ITEMS,
      opts: opts
    });

    // Get the data from the server
    return lssPost({path:`/meld/${opts.path}`,
      user: opts.user,
      login: opts.login,
      book: opts.book,
      data: opts.query
    })
    .then((res)=>{
      opts.resType = res.type;
      dispatch({
        type: ACTIONS.MELD_ITEMS_SUCCESS,
        opts: opts,
        docs: res.docs
      });
      return res.docs;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.MELD_ITEMS_ERROR,
        opts: opts,
        error: err
      });
      dispatch(pushAppNotification("Meld items error...",{details: JSON.stringify(err, null,"  "), kind: "error", timeout: 5000}))
    });
  });
}

/*
 * Query the items
**/
export const queryItems = (opts={})=>{
  return ((dispatch, getState) => {

    // Get the current state
    let state = getState();

    // Get the default values
    opts.path = opts.path || genItemPath(opts);

    // Set the default credentials
    reg(opts, "book", dot(state,"session.book"));
    reg(opts, "login", dot(state,"session.login"));

    // Dispatch action
    dispatch({
      type: ACTIONS.QUERY_ITEMS,
      opts: opts
    });

    // Get the data from the server
    return lssPost({path:`/query`,
      user: opts.user,
      login: opts.login,
      book: opts.book,
      data: opts.queries
    })
    .then((res)=>{
      dispatch({
        type: ACTIONS.QUERY_ITEMS_SUCCESS,
        opts: opts,
        results: res.results
      });
      return res.results;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.QUERY_ITEMS_ERROR,
        opts: opts,
        error: err
      });
      dispatch(pushAppNotification("Query items error...",{details: JSON.stringify(err, null,"  "), kind: "error", timeout: 5000}))
    });
  });
}


/*
 * Creates the items
**/
export const createItem = (opts={})=>{
  return ((dispatch, getState) => {

    // Get the current state
    let state = getState();

    // Get the default values
    opts.path = opts.path || genItemPath(opts);

    // Set the default credentials
    reg(opts, "book", dot(state,"session.book"));
    reg(opts, "login", dot(state,"session.login"));

    // Dispatch action
    dispatch({
      type: ACTIONS.CREATE_ITEM,
      opts: opts
    });

    // Get the data from the server
    return lssPost({path:`/inventory/${opts.path}`,
      user: opts.user,
      login: opts.login,
      book: opts.book,
      query: opts.query,
      data: { docs: opts.docs }
    })
    .then((res)=>{
      opts.resType = res.type;
      dispatch({
        type: ACTIONS.CREATE_ITEM_SUCCESS,
        opts: opts,
        docs: res.docs
      });
      return res.docs;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.CREATE_ITEM_ERROR,
        opts: opts,
        error: err.error
      });
      dispatch(pushAppNotification("Create item error...",{details: JSON.stringify(err, null,"  "), kind: "error", timeout: 5000}))
      return {...err.error, isError: true};
    });
  });
}

/*
 * Saves the item
**/
export const saveItem = (opts={})=>{
  return ((dispatch, getState) => {

    // Get the current state
    let state = getState();

    // Get the default values
    opts.path = opts.path || genItemPath(opts);

    // Set the default credentials
    reg(opts, "book", dot(state,"session.book"));
    reg(opts, "login", dot(state,"session.login"));

    // Dispatch action
    dispatch({
      type: ACTIONS.SAVE_ITEM,
      opts: opts
    });

    // Get the data from the server
    return lssPut({path:`/inventory/${opts.path}`,
      user: opts.user,
      login: opts.login,
      book: opts.book,
      query: opts.query,
      data: { docs: opts.docs }
    })
    .then((res)=>{
      opts.resType = res.type;
      dispatch({
        type: ACTIONS.SAVE_ITEM_SUCCESS,
        opts: opts,
        docs: res.docs
      });
      return res.docs;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.SAVE_ITEM_ERROR,
        opts: opts,
        error: err
      });
      dispatch(pushAppNotification("Save item error...",{details: JSON.stringify(err, null,"  "), kind: "error", timeout: 5000}))
      return {...err.error, isError: true};
    });
  });
}

/*
 * Pushes the item to the server
**/
export const pushItem = (opts)=>{
  return ((dispatch, getState) => {
    let singluar = singularType(opts);
    if (!singluar) { console.warn("No singular doc type found. Please define in Inventory Types"); }

    let draft = Draft.fromDoc(opts.docs[singluar]);

    // Make sure there is something to update
    if (opts.action) {
      if (opts.action === "delete") {
        if (!isNewItem(opts)) {
          return dispatch(deleteItem(opts));
        }
        else {
          return null;
        }
      }
      else if (opts.action === "create") {
        return dispatch(createItem(opts));
      }
    }
    else if (draft.updatesList().length > 0) {
      // Check if creating
      if (isNewItem(opts)) {
        return dispatch(createItem(opts));
      }
      else {
        return dispatch(saveItem(opts));
      }
    }
    else {
      return null;
    }
  });
};


/*
 * Pushes the item to the server but queues requests
**/
export const qItem = (opts)=>{
  return ((dispatch, getState) => {
    PPQ.push(opts);
    if (PPQ.length == 1) {
      let _qih = (o) => {
        dispatch(pushItem(o)).then((res)=>{
          if (o.after) { o.after(res); }
          PPQ.shift();
          if (PPQ.length > 0) {
            _qih(PPQ[0]);
          }
        });
      }
      _qih(PPQ[0]);
    }
  });
};

/*
 * Deletes the item
**/
export const deleteItem = (opts={})=>{
  return ((dispatch, getState) => {

    // Get the current state
    let state = getState();

    // Get the default values
    opts.path = opts.path || genItemPath(opts);

    // Set the default credentials
    reg(opts, "book", dot(state,"session.book"));
    reg(opts, "login", dot(state,"session.login"));

    // Dispatch action
    dispatch({
      type: ACTIONS.DELETE_ITEM,
      opts: opts
    });

    // Get the data from the server
    return lssDelete({path:`/inventory/${opts.path}`,
      user: opts.user,
      login: opts.login,
      book: opts.book
    })
    .then((res)=>{
      opts.resType = res.type;
      dispatch({
        type: ACTIONS.DELETE_ITEM_SUCCESS,
        opts: opts,
        docs: res.docs
      });
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.DELETE_ITEM_ERROR,
        opts: opts,
        error: err
      });
      dispatch(pushAppNotification("Delete item error...",{details: JSON.stringify(err, null,"  "), kind: "error", timeout: 5000}))
    });
  });
}


/*
 * Pushes the array of items to the server
 * Expect each item to be a list of options
**/
export const pushItems = (pushes = [])=>{
  return ((dispatch, getState) => {
    // Get the opts for each of the pushes
    let promises = pushes.map((opts)=>{
      let singluar = singularType(opts);
      let draft = Draft.fromDoc(opts.docs[singluar]);

      // Make sure there is something to update
      if (opts.action) {
        if (opts.action === "delete") {
          if (!isNewItem(opts)) {
            return dispatch(deleteItem(opts));
          }
          else {
            return null;
          }
        }
        else if (opts.action === "create") {
          return dispatch(createItem(opts));
        }
      }
      else if (draft.updatesList().length > 0) {
        // Check if creating
        if (isNewItem(opts)) {
          return dispatch(createItem(opts));
        }
        else {
          return dispatch(saveItem(opts));
        }
      }
      else {
        return null;
      }
    });

    return Promise.all(promises);
  });
};

/*
 * Pushes the array of items to the server
 * Expect each item to be a list of options
**/
export const pushItemsInSeries = (pushes = [])=>{
  return ((dispatch, getState) => {

    // Handle to the ist of push results
    let results = [];

    // Generate the contrac tasks
    let tasks = pushes.map((opts)=>{
      return (resolve, reject) => {
        let singluar = singularType(opts);
        let draft = Draft.fromDoc(opts.docs[singluar]);

        // Make sure there is something to update
        let promise = null;
        if (opts.action) {
          if (opts.action === "delete") {
            if (!isNewItem(opts)) {
              promise = dispatch(deleteItem(opts));
            }
          }
          else if (opts.action === "create") {
            promise = dispatch(createItem(opts));
          }
        }
        else if (draft.updatesList().length > 0) {
          // Check if creating
          if (isNewItem(opts)) {
            promise = dispatch(createItem(opts));
          }
          else {
            promise = dispatch(saveItem(opts));
          }
        }

        // Additional handling for promise execution
        if (promise) {
          promise.then((res)=>{
            results.push(res);
            resolve();
          }).catch(reject);
        }
        else {
          resolve();
          results.push(null);
        }
      }
    });

    // Create and kickoff the contract
    let contract = new Contract(tasks);
    return contract.start().then(()=>{
      return results;
    }).catch((err)=>{console.log("Contract error: ",err)});
  });
};

/*
 * Gets the item path from the options
**/
export const genItemPath = (opts={})=>{
  if (opts.id == "new") {
    return opts.type;
  }
  if (opts.childId == "new" || opts.action == "create") {
    return arrange(opts,['type','id','childType']).join('/');
  }
  return arrange(opts,['type','id','childType','childId']).join('/');
}


/*
 * Check to see if the item is a new item (needs to be created)
**/
export const isNewItem = (opts={})=>{
  // Check child item
  if (opts.childType && (!opts.childId || opts.childId == "new") ) {
    return true;
  }
  // Check group item
  if (opts.type && (!opts.id || opts.id == "new")) {
    return true;
  }
  return false;
}
