import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import SelectorControl from '../controls/SelectorControl';

import { financial } from '../../lib/formats';
import { dot } from '../../lib/obj';

import { COUNTRIES, countryForCode } from '../../constants/misc/Countries';


import { listItems, setItemField } from '../../actions/InventoryActions';

import './Form.css';
import './LocationForm.css';

class LocationForm extends Form {

  // Edit the given field
  editField(field, value) {
    let id = dot(this.props.location,"data.id") || "new";
    this.props.setItemField({type:"locations",id:id,field:field,value:value});
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.location,"data") || {};
    let country = countryForCode(dot(data,"addressCountry"));
    return (
      <div className="Form LocationForm">
      <Input className="Name" label="Name" value={dot(data,"name")} onChange={(value)=>{this.editField("name",value)}}/>
      <Input className="POCFirst" label="POC First" value={dot(data,"pocFirstName")} onChange={(value)=>{this.editField("pocFirstName",value)}}/>
      <Input className="POCLast" label="POC Last" value={dot(data,"pocLastName")} onChange={(value)=>{this.editField("pocLastName",value)}}/>
      <Input className="Street1" label="Street1" value={dot(data,"address1")} onChange={(value)=>{this.editField("address1",value)}}/>
      <Input className="Street2" label="Street2" value={dot(data,"address2")} onChange={(value)=>{this.editField("address2",value)}}/>
      <Input className="Street3" label="Street3" value={dot(data,"address3")} onChange={(value)=>{this.editField("address3",value)}}/>
      <Input className="City" label="City" value={dot(data,"addressCity")} onChange={(value)=>{this.editField("addressCity",value)}}/>
      <Input className="Province" label="Province" value={dot(data,"addressProvince")} onChange={(value)=>{this.editField("addressProvince",value)}}/>
      <Input className="Zip" label="Zipcode" value={dot(data,"addressZipcode")} onChange={(value)=>{this.editField("addressZipcode",value)}}/>
      <SelectorControl className="Country" label="Country" value={dot(country,"name")} options={COUNTRIES} onChange={(value)=>{this.editField("addressCountry",value.code)}}/>
      <Input className="Phone" label="Phone" value={dot(data,"pocPhone")} onChange={(value)=>{this.editField("pocPhone",value)}}/>
      <Input className="Email" label="Email" value={dot(data,"pocEmail")} onChange={(value)=>{this.editField("pocEmail",value)}}/>
      <Input className="Description" label="Description" value={dot(data,"description")} onChange={(value)=>{this.editField("description",value)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    listItems: opts => dispatch(listItems(opts)),
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LocationForm)
