import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import POForm from '../forms/POForm';
import POItemForm from '../forms/POItemForm';
import POPaymentForm from '../forms/POPaymentForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import DropControl from '../controls/DropControl';
import Draft from '../../lib/draft.js';
import PaymentLine from '../layouts/PaymentLine';
import PaymentLineHeader from '../layouts/PaymentLineHeader';
import FileLayout from '../layouts/FileLayout';
import FileUploadLayout from '../layouts/FileUploadLayout';
import FileLineHeader from '../layouts/FileLineHeader';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import TabControl from '../controls/TabControl';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import NameVal from '../containers/NameVal';
import TypeVal from '../containers/TypeVal';
import SubVal from '../containers/SubVal';
import StateVal from '../containers/StateVal';
import DateVal from '../containers/DateVal';
import MoneyVal from '../containers/MoneyVal';
import ActionVal from '../containers/ActionVal';
import GridArea from '../areas/GridArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BareInput from '../elements/BareInput';
import ModelSearchSection from '../sections/ModelSearchSection';

import { financial, money } from '../../lib/formats';
import { docForLoc, docHasEdits } from '../../lib/inventory-util';
import { dot, hasKeys, deepCopy } from '../../lib/obj';

import { loadItem, listItems, pushItem, pushItems, pushItemsInSeries, createItem, deleteItem, startDraftItem, setItemAction, setItemField } from '../../actions/InventoryActions';
import { openPopup, closePopup } from '../../actions/PopupActions';
import { queryToWhere, andsToWhere, conditionsToQuery } from '../../lib/inventory-util';

import { PRODUCT_SEARCH, PRODUCT_TYPE_MAP } from '../../constants/inventory/Product';
import { PAYMENT_TYPES, PAYMENT_TYPE_MAP } from '../../constants/inventory/Payment';
import { STATUS_WORKING } from '../../constants/app/Remote';
import { PO_PAYMENT_SEARCH } from '../../constants/inventory/PO';

import './Page.css';
import './POPage.css';

const DOC_TYPE = "pos";

class POPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      tabs: [{name:"Items 🏷", id:0, selected: true}],
      selectedTab: null
    };
  }

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      // this.props.loadItem({type:"pos",id:id});
      // this.props.listItems({type:"pos",id:id,childType:"items"});
      // this.props.listItems({type:"pos",id:id,childType:"files"});
      //this.props.listItems({type:"pos",id:id,childType:"payments"});
    }
  }

  onSavePO (evt, history) {

    // 1st, attempt to push the PO changes
    let po = this.props.po;
    this.props.pushItems([{type:"pos",
      id: this.props.id,
      docs: {po:po},
      action: po.action
    }]).then((res)=>{

      // Get th epo id
      let resPo = dot(res,"0.po");
      let id = dot(resPo,"data.id") || this.props.id;

      // Add the items to the array
      let items = dot(this.props.po, "items.list.docs") || [];
      let arr = items.map((item, index)=>{
        let itemId = dot(item,"data.id");
        let version = dot(item,"data.version");
        let action = item.action ? item.action : (version >= 1 ? undefined : "create" );
        return {
          type:"pos",
          id: id != "new" ? id : undefined,
          childType: "items",
          childId: itemId != "new" ? itemId : undefined,
          docs:{poItem:item},
          action: action,
          index: index
        };
      });

      // 2nd, push the item changes
      this.props.pushItemsInSeries(arr).then((res)=>{
        // Check if page needs to be reloaded
        if (dot(resPo,"data.version") == 1) {
          history.replace(`/pos/${id}`);
        }
        else {
          this.props.loadItem({type:"pos",id:id});
          this.props.listItems({type:"pos",id:id,childType:"items"});
        }
      });
    });
  }

  needsSave() {
    // Check po for sets
    if( hasKeys(dot(this.props.po,"sets")) ) {
      return true;
    }

    // Check if any of the items have changes
    let items = dot(this.props.po, "items.list.docs") || [];
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      if( docHasEdits(item) ) {
        return true;
      }
    }
    return false;
  }

  onCancel(evt,history) {
    history.goBack();
  }

  onDelete(evt,history) {
    this.props.openPopup({name:"Confirm",
      props:{
        title: "Delete PO",
        message: "Are you sure you want to delete this PO? You will NOT be able to undo this action.",
        onOk:()=>{
          this.props.deleteItem({type:"pos", id: this.props.id}).then(()=>{
            history.replace("/pos");
          });
        },
        okTheme: "red",
        okLabel: "Delete PO"
    }});
  }

  onAddItem() {
    this.props.openPopup({name:"Search",
      props:{
        title: "Select products.",
        type: "products",
        query: deepCopy(PRODUCT_SEARCH),
        renderItem: (item, index)=>{
          let type = dot(item,"data.type");
          return [
            <NameVal key="name">{dot(item,"data.name")}</NameVal>,
            <SubVal key="sku">{dot(item,"data.sku")}</SubVal>,
            <SubVal key="qty">{dot(item,"data.totalAvailable") || "0"} pcs</SubVal>,
            <TypeVal key="type" val={type}>{dot(PRODUCT_TYPE_MAP[type],"name")}</TypeVal>
          ]
        },
        other: {
          label: "+ New Product",
          onClick: () => {
            this._createProduct();
          }
        },
        onOk:(items)=>{
          this._addItems(items)
        }
    }});
  }

  _createProduct() {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props: {
        title: "New Product",
        opts: {
          type:"products",
          id: "new"
        },
        onPush:(product)=>{
          this._addItems([product]);
        }
      }
    });
  }

  onAddLine() {
    let existing = dot(this.props.po, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;
    this.props.startDraftItem({
      type:"pos",
      id: id,
      childType: "items",
      index: count,
      data: {
        poId: id
      }
    });
  }

  onFileUpload(files) {
    this._addFiles(files);
  }

  _addItems(items) {
    let existing = dot(this.props.po, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft items for each of the new items
    items.forEach((item, index)=>{
      let data = item.data || {};
      this.props.startDraftItem({
        type:"pos",
        id: id,
        childType: "items",
        index: count + index,
        data: {
          poId: id,
          type: data.type,
          itemId: data.id,
          item: data
        }
      });
    });
  }

  _addFiles(files) {
    let existing = dot(this.props.po, "files.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft files for each of the new files
    files.forEach((file, index)=>{
      let data = file.data || {};
      this.props.startDraftItem({
        type:"pos",
        id: id,
        childType: "files",
        index: count + index,
        data: {
          poId: id,
          type: data.type,
          fileId: data.id,
          file: data
        }
      });
    });

    // Save the PO files reference
    this.props.pushItems(files.map((file)=>{
      return { type:"pos",
        id: this.props.id,
        childType: "files",
        query: { links: "file" },
        docs: {
          poFile:{
            data: {
              fileId: dot(file,"data.id")
            }
          }
        },
        action: "create"
      };
    })).then((res)=>{
      console.log("Push Files: ", res);
      this.props.closePopup();
    });
  }

  onViewFile(file, history) {
    if (file) {
      if (file.data.type == "book") {
        window.open(file.data.presignedS3GetUrl, '_blank');
      }
    }
  }

  savePaymentField(field, value, orig, index) {
    let id = this.props.id;
    // Check for updates
    if (value == orig || (value == "" && orig == null)) { return; }

    // Help prevent double entries
    if (dot(this.props.payments,[index,"status"]) == "creating") { return; }

    // Update values right away
    this.props.setItemField({type:"pos", id: id, childType:"payments", index: index, field: field, value: value });

    // Set draft changes in case of multiple edits
    let draft = Draft.deep(dot(this.props.po,["payments","list","docs",index,"data"]));
    draft.set(field, value);

    this.props.pushItem({
      type: "po-payments",
      id: draft.val("id"),
      typeAlias: "pos", idAlias: id, childTypeAlias: "payments", index: index,
      docs: { poPayment: draft.updates },
      query: { links: "po", promptError:true }
    });
  }

  savePaidAtField(value, index) {
    let id = this.props.id;

    // Update values right away
    this.props.setItemField({type:"pos", id: id, childType:"payments", index: index, field: "paidAt", value: value });

    // Get draft changes in case of multiple edits
    let draft = Draft.deep(dot(this.props.po,["payments","list","docs",index,"data"]));

    /// Check if setting or clearing
    if (value && value.length > 0) {
      let date = Date.parseYMDStr(value);
      draft.val("paidAt",date.srvstr({utc:true}));
    }
    else {
      draft.clear("paidAt");
    }

    // Push the changes
    this.props.pushItem({
      type: "po-payments",
      id: draft.val("id"),
      typeAlias: "pos", idAlias: id, childTypeAlias: "payments", index: index,
      docs: { poPayment: draft.updates },
      query: { links: "po", promptError:true }
    });
  }


  onAddPayment() {
    let id = this.props.id;
    this.props.startDraftItem({
      type:"po-payments",
      typeAlias:"pos",
      idAlias: id,
      childTypeAlias: "payments",
      index: -1,
      data: {
        poId: id
      }
    });
  }

  renderPaymentsHeader() {
    return <tr>
      <th className="Controls"><FontAwesomeIcon className="ControlIcon" icon={["fal","plus"]} onClick={(e)=>{this.onAddPayment(e)}}/></th>
      {/*<th className="Created">Created</th>*/}
      <th className="Name">Name</th>
      <th className="Type">Type</th>
      <th className="Amount">Amount</th>
      <th className="Paid">Paid</th>
      <th className="Notes">Notes</th>
    </tr>;
  }

  renderPaymentsItem(item, index) {
    let state = this.state;
    let data = item.data;
    let paidAt = Date.utcTransDateInput(data.paidAt);
    return <tr key={`item${index}-${data.id || "0"}-${data.version}`}
      data-deleted={data.deleted}
      data-working={STATUS_WORKING[item.status]}
      data-creating={data.id == null}
      >
      <td className="Controls">
        ...
      </td>
      {/*<td className="Created">
        <DateVal>{data.createdAt}</DateVal>
      </td>*/}
      <td className="Name">
        <BareInput defaultValue={ data.name } onBlurOrEnter={(e)=>{this.savePaymentField("name", e.target.value, data.name, index)}}/>
      </td>
      <td className="Type">
        <select defaultValue={data.type} onChange={(e)=>{this.savePaymentField("type", e.target.value, data.type, index)}} data-hype={data.type || 'undefined'}>
          {PAYMENT_TYPES.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
        </select>
      </td>
      <td className="Amount">
        <BareInput type="number" defaultValue={ money(data.amount) } onBlurOrEnter={(e)=>{this.savePaymentField("amount", e.target.value, data.amount, index)}}/>
      </td>
      <td className="Paid">
        <BareInput type="date" data-hype={paidAt ? "" : "blank"} defaultValue={ paidAt } onChange={(e)=>{this.savePaidAtField(e.target.value, index)}}/>
      </td>
      <td className="Notes">
        {data.notes}
      </td>
    </tr>
  }

  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";
    let tabs = this.state.tabs;

    // Check if we need to save
    let needsSave = this.needsSave()

    // Get handle to the po Items
    let items = dot(this.props.po, "items.list.docs") || [];
    let itemsStatus = dot(this.props.po, "items.list.status");

    // Get handle ot the po files
    let poFiles = dot(this.props.po, "files.list.docs") || [];
    let poFilesStatus = dot(this.props.po, "files.list.status");

    let poPayments = dot(this.props.po, "payments.list.docs") || [];
    let poPaymentsStatus = dot(this.props.po, "payments.list.status");

    // Check to see if sending po
    let status = dot(this.props.po, "status");
    let isSending = status == "saving" || status == "creating";
    if (!isSending) {
      for (var i = 0; i < items.length; i++) {
        status = dot(items[i],"status");
        isSending = status == "saving" || status == "creating";
        if (isSending) { break; }
      }
    }

    // Based on state
    let cancelButton = null;
    let saveButton = null;
    if (isNew) {
      // Setup buttons
      cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSavePO(evt, history)}}>
        {isSending ? "Creating" : "Create"}
      </RectButton>;
    }
    else {
      // Setup buttons
      cancelButton = <RectButton theme="red" onClick={(evt,history)=>{this.onDelete(evt,history)}}>Delete</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSavePO(evt, history)}} lock={!needsSave}>
        {isSending ? "Saving" : "Save"}
      </RectButton>;
    }

    // Items section
    let itemsSection = <Section key="items" shout="Items" >
      <HeaderLine><div>Items</div></HeaderLine>
      <LoadableArea working={itemsStatus=="loading"}>
        {items.map((item, index)=>{
          return <div className="Item" key={`item${index}`}>
            <POItemForm poItem={item} listIndex={index}/>
          </div>
        })}
        <PaddedArea>
          <Row align="right">
            <PlainButton theme="blue" layout="auto" onClick={()=>{this.onAddLine();}}>Add line</PlainButton>
            <span style={{color:"#DDD"}}>|</span>
            <PlainButton theme="blue" layout="auto" onClick={()=>{this.onAddItem();}}>Add items</PlainButton>
          </Row>
        </PaddedArea>
      </LoadableArea>
    </Section>;

    // Files section
    let filesSection = <Section key="files" locked={isNew}>
      <PaddedArea>
        <LoadableArea working={poFilesStatus == "loading"}>
          <GridArea fit="med">
            {poFiles.map((poFile, index)=>{
              let file = dot(this.props.files, dot(poFile,"data.fileId"));
              return <FileLayout key={`file${index}`} onClick={(evt,history)=>{this.onViewFile(file,history)}} file={file}/>
            })}
            <FileUploadLayout onUpload={files=>this.onFileUpload(files)}/>
          </GridArea>
        </LoadableArea>
      </PaddedArea>
    </Section>

    return (
      <div className="Page POPage">
        <Prompt
          when={needsSave}
          message='Are you sure you want to leave? You have unsaved changes.'
        />
        <PageHeaderOutline title={`${dot(this.props.po,"data.name") || "PO"}`} aside={saveButton} top={this._backButton()}></PageHeaderOutline>
        <Section key="details" shout="Details">
          <HeaderLine><div>Details</div></HeaderLine>
          <LoadableArea working={status=="loading"}>
            <POForm po={this.props.po}/>
          </LoadableArea>
        </Section>
        {itemsSection}
        {filesSection}

        <ModelSearchSection
          type="po-payments"
          typeAlias="pos"
          idAlias={id}
          childTypeAlias="payments"
          query={PO_PAYMENT_SEARCH}
          conditions={[{poId:{eq:id}}]}
          renderHeader={()=>this.renderPaymentsHeader()}
          renderItem={(item,i)=>this.renderPaymentsItem(item,i)}
          reload={true}
          label="Payments"
          disableSearch="true"
        />

        <Separator/>
        <Row align="space-between">
          {cancelButton}
          {saveButton}
        </Row>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let po = dot(state.inventory,["pos",props.id]) || {};
  let vendor = dot(state.inventory,["vendors",po.vendorId]) || {};
  let locations = dot(state.inventory,"locations") || {};
  let files = dot(state.inventory,"files") || {};
  let payments = dot(po.payments,"list.docs") || [];
  return {
    po: po,
    vendor: vendor,
    locations: locations,
    inventory: state.inventory,
    files: files,
    payments: payments
  }
};

const mapDispatch = (dispatch) => {
  return {
    loadItem: opts => dispatch(loadItem(opts)),
    listItems: opts => dispatch(listItems(opts)),
    createItem: opts => dispatch(createItem(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    pushItems: arr => dispatch(pushItems(arr)),
    pushItemsInSeries: arr => dispatch(pushItemsInSeries(arr)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    setItemAction: opts => dispatch(setItemAction(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(POPage)
