import * as ACTIONS from '../actions/ActionTypes';
import { elements } from './inventory/ElementsReducer.js'
import { pos } from './inventory/POsReducer.js'
import { dot, reg } from '../lib/obj.js'
import { createReducer } from 'redux-starter-kit'
import { _ } from '../lib/underscore'
import Draft from '../lib/draft.js'

// Maps the plural words to singular versions
const SINGULAR = {
  pos: "po",
  items: "item"
}

// Maps the child types to singular versions
const CHILD_SINGULAR = {
  pos: {
    items: "poItem"
  }
}

// Maps the child types to singular versions
const CHILD_PLURAL = {
  pos: {
    items: "poItems"
  }
}

// Maps the child types to singular versions
const DOC_KEY_OPTS = {
  action: { type: "actions" },
  actions: { type: "actions" },
  build: { type: "builds" },
  builds: { type: "builds" },
  buildItem: { type: "buildItems" },
  buildItems: { type: "buildItems" },
  collection: { type: "collections" },
  collections: { type: "collections" },
  collectionItem: { type: "collections", childType: "items" },
  collectionItems: { type: "collections", childType: "items" },
  file: { type: "files" },
  files: { type: "files" },
  group: { type: "groups" },
  groups: { type: "groups" },
  groupItem: { type: "groupItems" },
  groupItems: { type: "groupItems" },
  location: { type: "locations" },
  locations: { type: "locations" },
  locationItem: { type: "locations",  childType: "items" },
  locationItems: { type: "locations",  childType: "items" },
  login: { type: "logins" },
  logins: { type: "logins" },
  order: { type: "orders" },
  orders: { type: "orders" },
  orderItem: { type: "orderItems" },
  orderItems: { type: "orderItems" },
  orderResolution: { type: "orderResolutions" },
  orderResolutions: { type: "orderResolutions" },
  orderReturn: { type: "orderReturns" },
  orderReturns: { type: "orderReturns" },
  payment: { type: "payments" },
  payments: { type: "payments" },
  po: { type: "pos" },
  pos: { type: "pos" },
  poItem: { type: "pos", childType: "items" },
  poItems: { type: "pos", childType: "items" },
  poPayment: { type: "pos", childType: "payments" },
  poPayments: { type: "pos", childType: "payments" },
  product: { type: "products" },
  products: { type: "products" },
  productPart: { type: "products", childType: "parts" },
  productParts: { type: "products", childType: "parts" },
  productItem: { type: "products", childType: "items" },
  productItems: { type: "products", childType: "items" },
  query: { type: "queries" },
  queries: { type: "queries" },
  stub: { type: "stubs" },
  stubs: { type: "stubs" },
  transfer: { type: "transfers" },
  transfers: { type: "transfers" },
  user: { type: "users" },
  users: { type: "users" },
  vendor: { type: "vendors" },
  vendors: { type: "vendors" },
  vendorItem: { type: "vendors", childType: "items" },
  vendorItems: { type: "vendors", childType: "items" }
}

/*
 * Helps get the name item map
**/
const namify = function(group, child) {
  if (child !== undefined) {
    return dot(CHILD_PLURAL, [group,child]);
  }
  return SINGULAR[group];
};

/*
 * Helps get the name item map
**/
const plurafy = function(group, child) {
  if (child !== undefined) {
    return dot(CHILD_SINGULAR, [group,child]);
  }
  return group
};

/**
 * Handles when an item is purged
**/
const purgeItem = function (state, action) {
  let opts = action.opts;
  let type = opts.typeAlias || opts.type;
  if (!type) { return };
  let group = dot(state, type);
  let id = opts.idAlias || opts.id;

  // Check if deleting a child
  let childType = opts.childTypeAlias || opts.childType;
  if (childType) {
    if (id === undefined) { return; }
    let groupItem = dot(group, id);
    if (!groupItem) { return; }
    // Check if deleting child in a list
    if (opts.index !== undefined) {
      let docs = dot(groupItem,[childType,"list","docs"]);
      if (docs) {
        docs.splice(opts.index, 1)
      }
    }
    // Check if deleting child under a type
    else {
      let childGroup = dot(groupItem, childType);
      let childId = opts.childIdAlias || opts.childId;
      if (childGroup && childId !== undefined) {
        delete childGroup[childId];
      }
    }
  }
  // Check if deleting item in a list
  else if (opts.index !== undefined) {
    let docs = dot(group,"list.docs");
    docs.splice(opts.index, 1)
  }
  // Check if deleting item by id
  else if (id !== undefined) {
    delete group[id];
  }

};

/**
 * Handles when an item is purged
**/
const purgeItemList = function (state, action) {
  let opts = action.opts || {};
  let type = opts.typeAlias || opts.type;
  if (type) {
    let group = reg(state, type, {});
    let id = opts.idAlias || opts.id;
    let childType = opts.childTypeAlias || opts.childType;
    if (id && childType) {
      let groupItem = reg(group,id,{});
      delete groupItem[childType];
    }
    else {
      group.list = null;
    }
  }
};

/**
 * Handles when an item is sent to be created
**/
const createItem = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "creating"
  }
};

/**
 * Handles when an item is created
**/
const createItemSuccess = function (state, action) {
  placeDocs(state, action, "success");
};

/**
 * Handles when an item fails to be created
**/
const createItemError = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "error"
  }
};

/**
 * Handles when an item is sent to be saved
**/
const saveItem = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "saving"
  }
};

/**
 * Handles when an item is saved
**/
const saveItemSuccess = function (state, action) {
  placeDocs(state, action, "success");
};

/**
 * Handles when an item fails to be saved
**/
const saveItemError = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "error";
    docState.error = action.error;
  }
};

/**
 * Handles when an item is sent to be deleted
**/
const deleteItem = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "deleting"
  }
};

/**
 * Handles when an item is deleted
**/
const deleteItemSuccess = function (state, action) {
  placeDocs(state, action, "success");
};

/**
 * Handles when an item is going to be loaded
**/
const loadItem = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "loading"
  }
};

/**
 * Handles when an item is loaded
**/
const loadItemSuccess = function (state, action) {
  placeDocs(state, action, "success");
};


/**
 * Handles when an items are requested
**/
const listItems = function (state, action) {
  let opts = action.opts || {};
  let list = stateForDocList(state, opts);
  list.status = "loading";
};

/**
 * Handles when an items are loaded
**/
const listItemsSuccess = function (state, action) {
  let docs = action.docs || {};
  let opts = action.opts || {};
  Object.keys(docs).forEach((key)=>{
    let doc = docs[key];
    if (Array.isArray(doc)) {
      if (key === opts.resType) {
        let listState = stateForDocList(state, opts);
        listState.status = "success";
        // Filter out any draft docs from curren state
        let lsDocs = reg(listState,"docs",[]).filter((d)=>!dot(d,"data.id"));
        // Combine and set docs from response
        dot(listState,"docs",lsDocs.concat(doc.map((singleDoc)=>{
          return {
            data: singleDoc.data,
            role: singleDoc.role
          }
        })));
      }
      else {
        doc.forEach((singleDoc)=>{
          let docOpts = optsForDoc(singleDoc, key);
          placeDoc(state, singleDoc, docOpts);
        });
      }
    }
    else {
      let docOpts = optsForDoc(doc, key);
      if (docOpts) {
        placeDoc(state, doc, docOpts);
      }
    }
  });
};


/**
 * Handles when list items fails
**/
const listItemsError = function (state, action) {
  let opts = action.opts || {};
  let list = stateForDocList(state, opts);
  list.status = "error";
};

/**
 * Handles when an items are queried
**/
const queryItems = function (state, action) {
  let opts = action.opts || {};
  let queries = opts.queries || [];
  queries.forEach((query, i) => {
    let list = stateForDocList(state, query);
    list.status = "loading";
  });
};

/**
 * Handles when an items are loaded
**/
const queryItemsSuccess = function (state, action) {
  let opts = action.opts || {};
  let queries = opts.queries || [];
  let results = action.results || [];
  for (var i = 0; i < queries.length; i++) {
    let docs = dot(results,[i,"docs"]) || [];
    let listState = stateForDocList(state, queries[i]);
    listState.status = "success"; // Todo - Fernando Mora: set status for individual queries
    dot(listState,"docs",docs.map((doc)=>{
      return {
        data: doc.data,
        role: doc.role
      }
    }));
  }
};

/**
 * Handles when query items fails
**/
const queryItemsError = function (state, action) {
  let opts = action.opts || {};
  let queries = opts.queries || [];
  for (var i = 0; i < queries.length; i++) {
    let listState = stateForDocList(state, queries[i]);
    listState.status = "error"; // Todo - Fernando Mora: set status for individual queries
  }
};

/**
 * Helps save the item into its given designated group placement
**/
const placeDocs = function(state, action, status) {
  let docs = action.docs || {};
  let opts = action.opts || {};
  Object.keys(docs).forEach((key)=>{
    let doc = docs[key];
    if (Array.isArray(doc)) {
      doc.forEach((singleDoc)=>{
        let docOpts = optsForDoc(singleDoc, key);
        placeDoc(state, singleDoc, docOpts, status, action);
      });
    }
    else {
      let docOpts = optsForDoc(doc, key);
      if (docOpts) {
        docOpts.isMain = key == opts.resType;
        docOpts.index = opts.index;
        placeDoc(state, doc, docOpts, status, action);
      }
    }
  });
};

/**
 * Helps place a single doc
**/
const placeDoc = function(state, doc, opts, status, action) {
  if (opts) {
    if (opts.isMain) {
      let docOpts = dot(action.opts,"id") == "new" ? opts : action.opts;
      let docState = stateForDoc(state, docOpts);
      dot(docState,"status",status);
      dot(docState,"data", doc.data);
      dot(docState,"docs", doc.docs);
      dot(docState,"role", doc.role);

      // Cleanup depending on action
      switch (action.type) {
        case ACTIONS.SAVE_ITEM_SUCCESS:
        case ACTIONS.LOAD_ITEM_SUCCESS:
          delete docState.sets;
          delete docState.clear;
          delete docState.incs;
          delete docState.orig;
          break;
      }
    }
    else {
      let docState = stateForDoc(state, opts);
      reg(docState,"status",status);
      reg(docState,"data", doc.data);
      reg(docState,"docs", doc.docs);
      reg(docState,"role", doc.role);
    }
  }
};


/**
 * Helps get the full opts for the given doc
**/
const optsForDoc = function(doc, key) {
  let opts = DOC_KEY_OPTS[key];
  if (opts) {
    let docOpts = {
      id: dot(doc,"data.id"),
      type: opts.type
    };
    if (opts.childType) {
      docOpts.childType = opts.childType;
      docOpts.childId = dot(doc,"data.id");
    }
    return docOpts;
  }
  return undefined;
}

/**
 * Gets the nested state the represents the doc for the given options
**/
const stateForDoc = function (state, opts) {
  let type = opts.typeAlias || opts.type;
  if (!type) { return; }
  let group = reg(state, type, {});
  let id = opts.idAlias || opts.id;

  // Check if getting a child
  let childType = opts.childTypeAlias || opts.childType;
  if (childType) {
    if (id === undefined) { return; }
    let groupItem = reg(group,[id],{});
    // Check if getting child in a list
    if (opts.index !== undefined) {
      let docs = reg(groupItem,[childType,"list","docs"],[]);
      return opts.insert ? arrin(docs,opts.index,{}) : arreg(docs,opts.index,{});
    }
    // Check if getting child under a type
    else {
      let childId = opts.childIdAlias || opts.childId;
      return reg(groupItem,[childType, childId],{});
    }
  }
  // Check if getting item in a list
  else if (opts.index !== undefined) {
    let docs = reg(group,["list","docs"],[]);
    return opts.insert ? arrin(docs,opts.index,{}) : arreg(docs,opts.index,{});
  }
  // Check if getting item by id
  else if (id !== undefined) {
    return reg(group,[id],{})
  }
};

/**
 * Help register the item in the array at the given place
 * If index is negative, will add item to the beggining of the array
**/
const arreg = function(arr, index, item) {
  if (!arr || !item) { return; }
  if (arr[index] !== undefined) { return arr[index] }
  if (index < 0) { arr.unshift(item) }
  else { arr[index] = item; }
  return item;
}

/**
 * Helps insert the item at the given index. Pushes items after back by 1
**/
const arrin = function(arr, index, item) {
  if (!arr || !item) { return; }
  if (index <= 0) { arr.unshift(item) }
  else if (index >= arr.length) { arr.push(item) }
  else { arr.splice(index,0,item); }
  return item;
}

/**
 * Gets the nested state the represents the list of documents
**/
const stateForDocList = function (state, opts) {
  let type = opts.typeAlias || opts.type;
  if (type) {
    let group = reg(state, type, {});
    let id = opts.idAlias || opts.id;
    let childType = opts.childTypeAlias || opts.childType;
    if (id && childType) {
      let groupItem = reg(group,id,{});
      return reg(groupItem,[childType,"list"],{});
    }
    else {
      return reg(group,"list",{});
    }
  }
};

/**
 * Gets the nested state the represents the list of documents
**/
const setItemField = function(state, action) {
  // Get corresponding doc
  let opts = action.opts;
  let field = opts.field;
  let docState = stateForDoc(state, opts) || {};

  let orig = reg(docState,"orig",{});
  let data = reg(docState,"data",{});
  let sets = reg(docState,"sets",{});

  // Set orig value and data
  if (field && dot(orig,field) === undefined) {
    //dot(orig, field, dot(data,field) || null, {build:true, forceSet:true});
    orig[field] = dot(data, field);
  }
  dot(data, opts.field, opts.value, {build:true});
  dot(sets, opts.field, opts.value, {build:true});
}

/**
 * Reverts the item edits
**/
const revertItemEdits = function(state, action) {
  // Get corresponding doc
  let opts = action.opts;
  let docState = stateForDoc(state, opts) || {};

  let orig = reg(docState,"orig",{});
  let data = reg(docState,"data",{});

  delete docState.sets;
  delete docState.orig;

  _.keys(orig).forEach((field, i) => {
    dot(data, field, dot(orig,field),{forceSet:true, build:true});
  });
}

/**
 * Starts a draft item (will register it)
**/
const startDraftItem = function(state, action) {
  let opts = action.opts || {};
  let docState = stateForDoc(state, opts);
  docState.action = opts.action;
  reg(docState,'data',opts.data);
}

/**
 * Starts a draft item (will register it)
**/
const setItemAction = function(state, action) {
  let opts = action.opts || {};
  let docState = stateForDoc(state, opts);
  dot(docState,'action',opts.action);
}

/**
 * Sets or updates an item query
**/
const setItemQuery = function(state, action) {
  let opts = action.opts || {};
  let list = stateForDocList(state, opts);
  list.query = opts.query;
}

/**
 * clears an item query
**/
const clearItemQuery = function(state, action) {
  let opts = action.opts || {};
  let list = stateForDocList(state, opts);
  delete list.query;
}

/*
 * Main inventory reducer
 * NOTE: Users Immer to more easily handle immutable state updates
 * Ref: https://redux-starter-kit.js.org/api/createreducer
**/
export const inventory = createReducer({}, {
  [ACTIONS.PURGE_ITEM]: purgeItem,
  [ACTIONS.PURGE_ITEM_LIST]: purgeItemList,
  [ACTIONS.CREATE_ITEM]: createItem,
  [ACTIONS.CREATE_ITEM_SUCCESS]: createItemSuccess,
  [ACTIONS.CREATE_ITEM_ERROR]: createItemError,
  [ACTIONS.SAVE_ITEM]: saveItem,
  [ACTIONS.SAVE_ITEM_SUCCESS]: saveItemSuccess,
  [ACTIONS.SAVE_ITEM_ERROR]: saveItemError,
  [ACTIONS.DELETE_ITEM]: deleteItem,
  [ACTIONS.DELETE_ITEM_SUCCESS]: deleteItemSuccess,
  [ACTIONS.LOAD_ITEM]: loadItem,
  [ACTIONS.LOAD_ITEM_SUCCESS]: loadItemSuccess,
  [ACTIONS.LIST_ITEMS]: listItems,
  [ACTIONS.LIST_ITEMS_SUCCESS]: listItemsSuccess,
  [ACTIONS.LIST_ITEMS_ERROR]: listItemsError,
  [ACTIONS.MELD_ITEMS]: listItems,
  [ACTIONS.MELD_ITEMS_SUCCESS]: listItemsSuccess,
  [ACTIONS.MELD_ITEMS_ERROR]: listItemsError,
  [ACTIONS.QUERY_ITEMS]: queryItems,
  [ACTIONS.QUERY_ITEMS_SUCCESS]: queryItemsSuccess,
  [ACTIONS.QUERY_ITEMS_ERROR]: queryItemsError,
  [ACTIONS.SET_ITEM_FIELD]: setItemField,
  [ACTIONS.REVERT_ITEM_EDITS]: revertItemEdits,
  [ACTIONS.START_DRAFT_ITEM]: startDraftItem,
  [ACTIONS.PURGE_DRAFT_ITEM]: purgeItem,
  [ACTIONS.SET_ITEM_ACTION]: setItemAction,
  [ACTIONS.SET_ITEM_QUERY]: setItemQuery,
  [ACTIONS.CLEAR_ITEM_QUERY]: clearItemQuery
})
