import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './components/Main';

import { library } from '@fortawesome/fontawesome-svg-core'
// import { faAlien, faEye, faCheckSquare, faCoffee } from '@fortawesome/pro-light-svg-icons'
import {
  faBandage,
  faCopy,
  faChevronDown,
  faCheck,
  faDownload,
  faEye,
  // faFilter,
  //faHourglass,
  faPrint,
  faPlusCircle,
  faTachometer,
  faIndustryAlt,
  faShoppingCart,
  faTruck,
  faGlobeAmericas,
  faObjectGroup,
  faTag,
  // faTimes,
  faLayerMinus,
  faLayerPlus,
  faMoneyCheckAlt,
  faHome,
  faFile,
  faScrewdriverWrench,
  faSearch,
  faSortAlt,
  faWarehouse,
  faWineGlassCrack,
} from '@fortawesome/pro-solid-svg-icons'

import {
  // faCheck,
  faHourglass,
  faSpinnerThird,
  faEllipsisV,
  faPlus
} from '@fortawesome/pro-light-svg-icons'

import {
  faBox,
  faBoxes,
  faCaretLeft,
  faCaretRight,
  faChartLine,
  faChevronLeft,
  faChevronRight,
  faCheckSquare,
  faCircle,
  faClipboard,
  faCloudUploadAlt,
  faCube,
  faCubes,
  faDotCircle,
  faExclamationTriangle,
  faExternalLink,
  faFilePdf,
  faFilePlus,
  faFilter,
  faImage,
  faKey,
  faLink,
  faListTimeline,
  faMemoPad,
  faPaste,
  faPencil,
  faPuzzle,
  faRedoAlt,
  faSort,
  faSortUp,
  faSquare,
  faSquareList,
  faSquareMinus,
  faSquarePlus,
  faTimes,
  faThumbsUp,
  faTrashAlt,
  faUser
} from '@fortawesome/pro-regular-svg-icons'


import {
  faShopify
} from '@fortawesome/free-brands-svg-icons'


library.add(
  faBandage,
  faBox,
  faBoxes,
  faCaretLeft,
  faCaretRight,
  faCircle,
  faCopy,
  faChartLine,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faCheckSquare,
  faClipboard,
  faCloudUploadAlt,
  faCube,
  faCubes,
  faDotCircle,
  faDownload,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEllipsisV,
  faFilter,
  faFile,
  faFilePdf,
  faFilePlus,
  faHourglass,
  faImage,
  faKey,
  faLayerMinus,
  faLayerPlus,
  faLink,
  faListTimeline,
  faPrint,
  faPlusCircle,
  faTachometer,
  faIndustryAlt,
  faShoppingCart,
  faGlobeAmericas,
  faObjectGroup,
  faMemoPad,
  faMoneyCheckAlt,
  faHome,
  faPaste,
  faPencil,
  faPlus,
  faPuzzle,
  faRedoAlt,
  faScrewdriverWrench,
  faSearch,
  faShopify,
  faSort,
  faSortAlt,
  faSortUp,
  faSpinnerThird,
  faSquare,
  faSquareList,
  faSquareMinus,
  faSquarePlus,
  faTag,
  faTimes,
  faThumbsUp,
  faTrashAlt,
  faTruck,
  faUser,
  faWarehouse,
  faWineGlassCrack,
)


function App() {
  return (
    <Main/>
  );
}

export default App;
