import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import DateVal from '../containers/DateVal';
import MoneyVal from '../containers/MoneyVal';
import QtyVal from '../containers/QtyVal';
import StateVal from '../containers/StateVal';
import TypeVal from '../containers/TypeVal';
import NameVal from '../containers/NameVal';
import SubVal from '../containers/SubVal';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import ItemSearchSection from '../sections/ItemSearchSection';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';

import { TRANSFER_SEARCH } from '../../constants/inventory/Transfer'
import { startDraftItem, purgeDraftItem } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';

import './Page.css';
import './TransfersPage.css';

class TransfersPage extends Page {

  onAdd(evt, history) {
    this.props.purgeDraftItem({type:"transfers",id:"new"});
    this.props.startDraftItem({
      type:"transfers",
      id: "new",
      data: {
        type: "move",
        status: "new"
      }
    });
    history.push("/transfers/new");
  }

  onView(evt, history, index) {
    let data = dot(this.props.transfers, [index,'data']);
    if (data) {
      history.push(`/transfers/${data.id}`);
    }
  }

  render() {
    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add Transfer</RectButton>;

    // Get the docs
    return (
      <div className="Page TransfersPage">
        <PageHeaderOutline title={this.props.title || "Transfers"} aside={addButton} top={this._backButton()}/>
        <ItemSearchSection type="transfers" query={TRANSFER_SEARCH} renderItem={(item, index)=>{
          let country = dot(item,"data.addressCountry");
          let province = dot(item,"data.addressProvince");
          let address = country && province ? `${province}, ${country}` : country || province || "-";
          return <ItemLine key={`item${index}`} onClick={(evt,history)=>this.onView(evt,history,index)}>
            <NameVal>{dot(item,"data.name")}</NameVal>
            <TypeVal type="transfers">{dot(item,"data.type")}</TypeVal>
            <SubVal>{dot(item,"data.source.data.name") || '-'}</SubVal>
            <SubVal>{dot(item,"data.destination.data.name") || '-'}</SubVal>
            <StateVal type="transfers">{dot(item,"data.status")}</StateVal>
            <DateVal>{dot(item,"data.createdAt")}</DateVal>
          </ItemLine>
        }}>
          <div>Name</div>
          <div>Type</div>
          <div>Source</div>
          <div>Destination</div>
          <div>Status</div>
          <div>Created</div>
        </ItemSearchSection>
        <QueryPaginatePresentation type="transfers"/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = dot(state.inventory,"transfers.list") || {};
  return {
    list: list,
    status: list.status,
    transfers: list.docs || [],
    pos: dot(state.inventory,"pos") || {},
    locations: dot(state.inventory,"locations") || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(TransfersPage)
