import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import SelectorControl from '../controls/SelectorControl';

import { financial } from '../../lib/formats';
import { dot } from '../../lib/obj';

import { listItems, setItemField } from '../../actions/InventoryActions';

import './Form.css';
import './OrderForm.css';

class OrderForm extends Form {

  // Edit the given field
  editField(field, value) {
    let id = dot(this.props.order,"data.id") || "new";
    this.props.setItemField({type:"orders",id:id,field:field,value:value});
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.order,"data") || {};
    return (
      <div className="Form OrderForm">
        <Input className="Number" label="Number" value={dot(data,"number")} onChange={(value)=>{this.editField("number",value)}}/>
        <Input className="Name" label="Name" value={dot(data,"name")} onChange={(value)=>{this.editField("name",value)}}/>
        <Input className="Email" label="Email" value={dot(data,"email")} onChange={(value)=>{this.editField("email",value)}}/>
        <Input className="Notes" label="Notes" value={dot(data,"notes")} onChange={(value)=>{this.editField("notes",value)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    listItems: opts => dispatch(listItems(opts)),
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderForm)
