import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './ItemOptionLine.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ItemOptionLine extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let children = this.props.children;
    children = Array.isArray(children) ? children : [children];
    let selected;
    if (this.props.selected) {
      let icon = this.props.radio ? "dot-circle" : "check-square";
      selected = <FontAwesomeIcon icon={["far",icon]} />;
    } else {
      let icon = this.props.radio ? "circle" : "square";
      selected = <FontAwesomeIcon icon={["far",icon]} />;
    }
    return (
      <div className="ItemOptionLine Line" data-values={children.length} data-selected={this.props.selected} onClick={(evt)=>{this.onClick(evt)}}>
        <span className="Check">
          {selected}
        </span>
        {children.map((child, index)=>{
          return (
            <div className={`Value Value${index+1}`} key={`value${index+1}`}>
              {child}
            </div>
          )
        })}
      </div>
    );
  }
}

export default withRouter(ItemOptionLine);
