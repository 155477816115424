import React from 'react';
import Container from './Container';
import { withRouter } from 'react-router'
import { abbr, commafy } from '../../lib/formats';

import './Container.css';
import './QtyVal.css';

class QtyVal extends Container {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let str = this.props.expand ? commafy(this.props.children) : abbr(this.props.children);

    // Check if readout is clickable
    let clickable = this.props.onClick !== undefined;

    let className = this.props.className || "";
    return (
      <div className={`QtyVal Container ${className}`} onClick={(evt)=>{this.onClick(evt)}} data-clickable={clickable} data-hype={this.props.hype}>
        {str}
      </div>
    );
  }
}

const RoutedQtyVal= withRouter(QtyVal)
export default RoutedQtyVal;
