import React from 'react';
import { mapk2k } from '../../lib/obj'


export const FULFILLMENT_STATUS = [
  { value: null,       name: "Pending",    desc: "Pending fulfillment", condition: {null:true} },
  { value:"fulfilled", name: "Fulfilled",  desc: "Urgent priority"}
];

// Mainly used for stats
export const ORDER_ITEM_BASE_SEARCH = {
  type: "order-items",
  links: ["item","order"],
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["item.name","item.sku"],
    keymap: {
      sku: "item.sku",
      email: "mainOrder.email",
      product: "item.name",
      fulfillment: "fulfillmentStatus"
    }
  },
  filters: [
    {
      label: "Fulfillment",
      type: "check",
      field: "fulfillmentStatus",
      options: FULFILLMENT_STATUS
    },
    {
      label: "Created At",
      type: "daterange",
      radio: true,
      field: "createdAt",
      options: [
        { value:"custom", name: "Custom",        desc: "Custom count"},
        { value:"today",  name: "Today",         desc: "Today's count", selected: true},
        { value:"days7",  name: "Last 7 days",   desc: "Week's count"},
        { value:"days21", name: "Last 21 days",  desc: "21 past days"},
        { value:"days30", name: "Last 30 days",  desc: "30 past days"},
        { value:"days90", name: "Last 90 days",  desc: "90 past days"},
        { value:"week",   name: "This Week",     desc: "Week's count"},
        { value:"month",  name: "This Month",    desc: "Month's count"},
        { value:"year",   name: "This Year",     desc: "Years's count"}
      ]
    }
  ],
  where:[]
}
