import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import ProductLayout from '../layouts/ProductLayout';
import TableSearchSection from '../wrappers/TableSearchSection';

import { LOCATION_ITEM_SEARCH } from '../../constants/inventory/Location'
import { dot, deepCopy } from '../../lib/obj';

import './Page.css';
import './InventoryPage.css';

const LOCATION_ITEM_SEARCH_COPY = deepCopy(LOCATION_ITEM_SEARCH);
LOCATION_ITEM_SEARCH_COPY.links.push("location");

class InventoryPage extends Page {

  onAdd(evt, history) {
    this.props.purgeDraftItem({type:"products",id:"new"});
    history.push("/products/new");
  }


  onViewProduct(evt, history, doc) {
    if (doc) {
      history.push(`/products/${dot(doc,"data.item.data.id")}`);
    }
  }

  onViewLocation(evt, history, doc) {
    if (doc) {
      history.push(`/locations/${dot(doc,"data.location.data.id")}`);
    }
  }

  render() {
    // Get the docs
    return (
      <div className="Page InventoryPage">
        <PageHeaderOutline title={this.props.title || "Products"} top={this._backButton()}>
        </PageHeaderOutline>
        <TableSearchSection
        type="location-items"
        query={LOCATION_ITEM_SEARCH_COPY}
        layout={[
          {title:"",          field:"image.data.presignedS3GetUrl", type:"image", className:"Icon"},
          {title:"Product",   type:"custom", render:(d,i)=>{return <ProductLayout product={d.data.item} onClick={(e,h)=>this.onViewProduct(e,h,d)}/> } },
          {title:"Location",  field:"location.data.name", type: "action", onClick:(e,h,d)=>{this.onViewLocation(e,h,d)}},
          {title:"Quantity",  field:"quantity", type: "number"}
        ]}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = dot(state.inventory,"products.list") || {};
  return {
    list: list,
    products: list.docs || []
  }
};

const mapDispatch = (dispatch) => { return {} };

export default connect(
  mapState,
  mapDispatch
)(InventoryPage)
