import React from 'react';

class Widget extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>Hello widget!</div>
    );
  }
}

export default Widget;
