import React from 'react';
import Control from './Control';
import './SelectorControl.css';
import Input from '../elements/Input';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import RoundButton from '../elements/RoundButton';
import Separator from '../elements/Separator';
import { dot } from '../../lib/obj';

class SelectorControl extends Control {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      focused: false,
    };
  }

  onOptionClick(option, index) {
    let value = option.name || option;
    this.setState({value:value, filter:value});
    if (this.props.onChange) {
      this.props.onChange(option);
    }
    if (this.props.onOptionClick) {
      this.props.onOptionClick(option, index);
    }
  }

  // Edit the given field
  editFilter(value) {
    //this.setState({[field]:value});
    this.setState({filter:value});
    if (this.props.onFilterEdit) {
      this.props.onFilterEdit(value)
    }
    else {
      let options = this.props.options;
      if (value != null && value.length == 0) {
         this.setState({options: options});
      }
      else if (value == this.state.value) {
        this.setState({options: options});
      }
      else {
        this.setState({
          options: options.filter((option)=>{
            let name = option.name || option.toString();
            return name.toLowerCase().indexOf(value.toLowerCase()) >=0;
          })
        });
      }
    }
  }

  onAddOption(evt) {
    console.log("Add Option");
    if (this.props.onAddOption) {
      this.props.onAddOption(evt);
    }
  }

  onFocus(evt) {
    this.setState({focused:true});
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  }

  onBlur(evt) {
    // setTimeout(()=>{
      this.setState({focused:false, filter:this.state.value});
      if (this.props.onBlur) {
        this.props.onBlur(evt);
      }
    // },500);
  }


  render() {

    // Handle to the options
    let options = this.state.options || this.props.options;
    let filter = this.state.filter === undefined ? this.props.value : this.state.filter;

    let addOption = <div/>
    if (this.props.onAddOption) {
      addOption = <div className="Option">
        <div className="Entry" onClick={(evt)=>{this.onAddOption(evt)}}>
          Add new
        </div>
      </div>
    }

    let className = this.props.className || "";
    return (
      <div className={`SelectorControl ${className}`} ref="root" data-focused={this.state.focused}>
        <div className="Header">
          <Input label={this.props.label || "Select"} type="text" value={filter}
            lock={this.props.lock}
            onChange={(value)=>{this.editFilter(value)}}
            onFocus={(evt)=>{this.onFocus(evt);}}
            onBlur={(evt)=>{this.onBlur(evt);}}/>
        </div>
        <div className="Options">
          {options.map((option, index)=>{
            return (<div className="Option" key={`option${index}`} onClick={()=>{this.onOptionClick(option,index)}}>
              <div className="Entry">
                {option.name}
              </div>
            </div>);
          })}
          {addOption}
        </div>
      </div>
    );
  }
}



export default SelectorControl
