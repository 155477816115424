import React from 'react';
import Area from './Area';
import './GridArea.scss';

class GridArea extends React.Component {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`GridArea Area ${className}`} data-fit={this.props.fit}>
        {this.props.children}
      </div>
    );
  }
}

export default GridArea;
