import React from 'react';
import { connect } from 'react-redux'
import Section from '../elements/Section';
import './ItemSearchSection.scss';

import QueryLine from '../layouts/QueryLine';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';
import ActionVal from '../containers/ActionVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { meldItems, setItemQuery, clearItemQuery } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';
import { configToNestedQuery, stateForList } from '../../lib/inventory-util';

class ItemSearchSection extends Section  {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  componentWillMount() {
    let props = this.props;
    let config = dot(props,"list.query")
    let loadOnMount = (props.reload || !config) && props.query;

    // Load helper
    let ld = (q = props.query)=>{
      let modelProps = this.modelProps();
      this.props.setItemQuery({
        ...modelProps,
        query: q
      });
      this.onConfigChange(props.query);
      this.setState({delayed: false});
    }

    // Check if delaying load
    if (props.delayLoad) {
      this.setState({delayed: true});
      props.delayLoad(ld);
    }
    else if (loadOnMount) {
      ld();
    }
  }

  componentWillUnmount() {
    let props = this.props;
    if (props.reload || props.delayLoad) { 
      this.props.clearItemQuery(this.modelProps());
    }
  }

  // Get the query config
  onConfigChange(config, throttle) {
    let props = this.props;

    // Check for additional attached conditions
    let conditions = this.props.ignoreDelete ? [] : [{deleted:{neq:1}}];
    if (props.conditions && Array.isArray(props.conditions)) {
      for (var i = 0; i < props.conditions.length; i++) {
        conditions.push(props.conditions[i]);
      }
    }

    // Prepare the query
    let modelProps = this.modelProps();
    let query = configToNestedQuery(config, conditions);
    query.limit = this.props.limit || config.limit;
    query.offset = this.props.offset || config.offset;
    this.props.meldItems({
      ...modelProps,
      query: query
    }).then(()=>{
      if (throttle) { throttle.done(); }
      switch (this.props.status) {
        case "success":
          if (this.props.onQuerySuccess) {this.props.onQuerySuccess(this.props.items)}
          break;
      }
    });
  }

  onExpand(evt, history) {
    let expanded = !this.state.expanded;
    if (this.props.onExpand) {
      this.props.onExpand(expanded);
    }
    this.setState({expanded:expanded});
  }

  modelProps() {
    let props = this.props;
    return {
      type: props.type,
      typeAlias: props.typeAlias,
      id: props.id,
      idAlias: props.idAlias,
      childType: props.childType,
      childTypeAlias: props.childTypeAlias,
      childId: props.childId
    }
  }

  render() {
    // Handle to the props
    let props = this.props;

    // Get the items
    let prependItems = props.prependItems;
    let items = props.items || [];
    let appendItems = props.appendItems;
    let status = props.status;

    // Check if there are any items to show
    let hasResults = prependItems != undefined || items.length > 0 || appendItems != undefined;

    // Get the headers
    let headers;
    if (props.children) {
      if (this.props.children.length > 1) {
        headers = <HeaderLine>
          {props.children}
        </HeaderLine>
      }
      else {
        headers = props.children;
      }
    }

    // Get the footer
    let footer;
    if (props.footer) {
      footer = <PaddedArea className="Footer">
        {props.footer}
      </PaddedArea>
    }

    let noResultsMessage;
    if (!hasResults) {
      noResultsMessage = <PaddedArea><span data-hype="unknown">No items found</span></PaddedArea>
    }

    // Build the section
    let modelProps = this.modelProps();
    let className = this.props.className || "";
    return (
      <div className={`Section ItemSearchSection ${className}`} data-results={hasResults} ref="root"  data-theme={this.props.theme} data-size={dot(this.state,"size")} data-expanded={this.state.expanded} data-locked={this.props.locked}>
        {props.hat}
        <PaddedArea className="QuerySubsection">
          <QueryLine {...modelProps} throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          <QuerySummaryLine {...modelProps} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          <QueryOptionsPresentation {...modelProps} className="Settings" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
        </PaddedArea>
        <div className="ScrollRail">
          <LoadableArea className="Items" working={status=="loading" || this.state.delayed}>
            {headers}
            {prependItems}
            {items.map((item, index)=>{
              return this.props.renderItem(item,index);
            })}
            {appendItems}
            {noResultsMessage}
          </LoadableArea>
        </div>
        <PaddedArea className="Expanse">
          <ActionVal className="Collapse" onClick={(evt, history)=>{this.onExpand(evt, history)}}><FontAwesomeIcon icon={["far","chevron-left"]} /> Back to results</ActionVal>
        </PaddedArea>
        {footer}
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = stateForList(state.inventory, props) || {};
  return {
    list: list,
    status: props.status || list.status,
    items: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    meldItems: opts => dispatch(meldItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    clearItemQuery: opts => dispatch(clearItemQuery(opts)),
  }
};

export default connect(
  mapState,
  mapDispatch
)(ItemSearchSection);
