import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import PaymentForm from '../forms/PaymentForm'
import { connect } from 'react-redux'
import './PaymentPopup.css';
import { closePopup } from '../../actions/PopupActions';
import { createItem, purgeItem } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot } from '../../lib/obj';


class PaymentPopup extends Popup {

  onCancel(evt, history) {
    this.props.purgeItem({ type: "payments", id: "new" });
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    this.sendPayment();
  }

  onEnter(evt) {
    this.sendPayment();
  }

  sendPayment() {
    this.props.createItem({
      type: "payments",
      id: "new",
      docs: {
        payment: this.props.payment
      }
    }).then((res)=>{
      this.props.purgeItem({ type: "payments", id: "new" });
      if (this.props.onSent) { this.props.onSent(res.payment); }
      this.props.closePopup();
    });
  }

  render() {

    // Check if sending the payment
    let payment = this.props.payment;
    let sending = dot(payment,"status") == "creating";

    // Setup buttons
    let cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>
      {this.props.cancelLabel || "Cancel"}
    </RectButton>;

    // Setup buttons
    let okButton = <RectButton theme={this.props.okTheme || "blue"} working={sending} onClick={(evt,history)=>{this.onOk(evt, history)}}>
      { sending ? "Sending..." : "Add Payment" }
    </RectButton>;

    return (
      <div className="PaymentPopup" ref="root">
        <div className="Content">
          <Section>
            <div className="Title">
               Add Payment
            </div>
            <Separator/>
            <PaymentForm onEnter={(evt)=>{this.onEnter(evt)}}/>
            <Separator theme="white"/>
            <Row align="right">
              {cancelButton}
              {okButton}
            </Row>
          </Section>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    payment: dot(state.inventory,"payments.new")
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    createItem: opts => dispatch(createItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    setSessionLogin: opts => dispatch(setSessionLogin(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(PaymentPopup)
