import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';
import Layout from './Layout';
import SubVal from '../containers/SubVal';
import ActionVal from '../containers/ActionVal';
import DateVal from '../containers/DateVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Layout.css';
import './ImageLayout.css';

import { openPopup, closePopup } from '../../actions/PopupActions';
import { connect } from 'react-redux'
import { dot } from '../../lib/obj';
import { createItem } from '../../actions/InventoryActions';
import { awsPostS3File } from '../../apis/AWS';
import { abbr } from '../../lib/formats';

class ImageLayout extends Layout {

  constructor(props) {
    super(props);
    this.state = {
      status: null,
      working: false,
      registering: false,
      uploading:false,
      loaded: 0,
      total: 0,
    };
  }

  onClick(evt) {
    if (this.props.onUpload && this.refs.input && !this.isWorking()) {
      let elem = ReactDOM.findDOMNode(this.refs.input);
      elem.click();
    }
  }

  onStatus(item,status) {
    this.setState({status:status});
  }

  onProgress(item, loaded, total) {
    this.setState({loaded:loaded, total: total});
  }

  onUpload(file) {
    if (this.props.onUpload) {
      this.props.onUpload(file);
    }
  }

  onChange(evt) {
    // Get handle tot he file (only one at a time for now )
    let file = evt.target.files[0] || {};
    this.setFile(file);
  }

  setFile(file) {
    console.log("onChange",file);
    // Update state
    this.setState({
      registering:true,
      status: "registering",
      loaded: 0,
      total:file.size
    });

    // Register and upload file
    this.props.createItem({
      type: "files",
      id: "new",
      docs: {
        file: {
          data: {
            type: "book",
            name: file.name,
            size: file.size,
            mime: file.type
          }
        }
      }
    }).then((res)=>{
      console.log("RES:",res);
      let item = res.item;
      this.setState({uploading:true});
      awsPostS3File({
        file: file,
        prereq: dot(res,"file.data.prereq"),
        onStart: ()=>{this.onStatus(item,"uploading")},
        onAbort: ()=>{this.onStatus(item,"aborted")},
        onError: ()=>{this.onStatus(item,"failed")},
        onLoad: ()=> {
          // Wait a second before completing the upload
          setTimeout(()=>{
            this.setState({status:"uploaded"});
            this.onUpload(res.file);
          });
        },
        onProgress: (evt)=>{this.onProgress(item,evt.loaded, evt.total)},
      });
    });
  }


  isWorking() {
    let status = this.state.status;
    return status == "registering" || status == "uploading";
  }

  getIcon() {
    let data = dot(this.props.file,"data") || {};
    if (this.isWorking()) {
      return <FontAwesomeIcon className="Icon" icon={["fal","spinner-third"]} onClick={evt=>this.onClick(evt)} />;
    }
    else {
      switch (data.mime) {
        case "image/jpeg":
        case "image/png":
        case "image/jpg":
          return <img className="Icon" onClick={evt=>this.onClick(evt)}  src={data.presignedS3GetUrl}/>;
        default:
          return <FontAwesomeIcon className="Icon" icon={["far","image"]} onClick={evt=>this.onClick(evt)} />;
      }
    }
  }

  onDragEnter(evt) {
    console.log("onDragEnter")
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDragOver(evt) {
    console.log("onDragOver")
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDragLeave(evt) {
    console.log("onDragLeave")
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDrop(evt) {
    console.log("onDrop")
    evt.preventDefault();
    evt.stopPropagation();

    // Get handle tot he file (only one at a time for now )
    let file = evt.dataTransfer.files[0] || {};
    this.setFile(file);
  }

  render() {
    let data = dot(this.props.file,"data") || {};
    let className = this.props.className || "";
    let upload = this.props.onUpload ? true : false;
    return (
      <div className={`Layout ImageLayout ${className}`} data-type={data.mime} data-status={this.state.status} data-upload={upload}
        onDragEnter={(evt)=>{this.onDragEnter(evt)}}
        onDragOver={(evt)=>{this.onDragOver(evt)}}
        onDragLeave={(evt)=>{this.onDragLeave(evt)}}
        onDrop={(evt)=>{this.onDrop(evt)}}
        >
        <input ref="input" className="Input" type="file" name="+" onChange={(evt)=>this.onChange(evt)}/>
        {this.getIcon()}
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {};
};

const mapDispatch = (dispatch) => {
  return {
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts)),
    createItem: opts => dispatch(createItem(opts))
  }
};


export default connect(
  mapState,
  mapDispatch
)(ImageLayout)
