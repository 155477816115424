import React from 'react';
import { withRouter } from 'react-router'
import Moment from 'react-moment';
import Layout from './Layout';

import './Line.css';
import './TransferLine.css';

import { financial, capitalize, abbr, datify } from '../../lib/formats';
import { dot } from '../../lib/obj';

class TransferLine extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let data = dot(this.props.transfer,"data") || {};
    let srcData = data.type == "po" ? dot(this.props.po,"data") || {} : dot(this.props.source,"data") || {};
    let destData = dot(this.props.destination,"data") || {};
    return (
      <div className="Line TransferLine" data-type={data.type} data-status={data.status} onClick={(evt)=>{this.onClick(evt)}} >
        <div className="Name">{data.name}</div>
        <div className="Path">
          {srcData.name || "Ext"} &#x2794; {destData.name || "Ext"}
        </div>
        <div className="Type">
          <div className="Highlight">{capitalize(data.type)}</div>
        </div>
        <div className="Status">
          <div className="Highlight">{capitalize(data.status || "blank")}</div>
        </div>
        <div className="Cost">
          {financial(0)}
        </div>
        <div className="createdAt">{datify(data.createdAt)}</div>
      </div>
    );
  }
}

const RoutedTransferLine = withRouter(TransferLine)
export default RoutedTransferLine;
