import React from 'react';
import Area from './Area';
import './PaddedArea.scss';

class PaddedArea extends React.Component {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`PaddedArea Area ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default PaddedArea;
