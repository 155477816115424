import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Presentation from './Presentation';
import PaginateControl from '../controls/PaginateControl';
import ActionVal from '../containers/ActionVal';
import ItemSearchSection from '../sections/ItemSearchSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './QueryPaginatePresentation.css';

import { setItemQuery, meldItems } from '../../actions/InventoryActions';
import { _ } from '../../lib/underscore';
import { financial, capitalize } from '../../lib/formats';
import { dot, deepCopy } from '../../lib/obj';
import { configToNestedQuery, stateForList } from '../../lib/inventory-util';

class QueryPaginatePresentation extends Presentation {

  onOffset(offset, limit) {
    let props = this.props;
    let config = deepCopy(props.config);
    config.offset = offset;
    this.onConfigChange(config);
  }

  onReload() {
    let props = this.props;
    let config = deepCopy(props.config);
    config.offset = 0;
    this.onConfigChange(config);
  }

  onConfigChange(config) {
    // Set the item query
    this.props.setItemQuery({
      type: this.props.type,
      typeAlias: this.props.typeAlias,
      query: config
    });

    // Report config chagne
    if (this.props.throttle) {
      if (!this._queryThrottle) {
        this._queryThrottle = _.enqueue((config) => {
          if (this.props.onConfigChange) {
            this.props.onConfigChange(config, this._queryThrottle);
          } else {
            this.runQuery(config, this._queryThrottle);
          }
        }, this.props.timeout || 5000);
      }
      this._queryThrottle(config)
    }
    else {
      if (this.props.onConfigChange) {
        this.props.onConfigChange(config, this._queryThrottle);
      } else {
        this.runQuery(config);
      }
    }
  }

  runQuery(config, throttle) {
    let props = this.props;

    // Check for additional attached conditions
    let conditions = this.props.ignoreDelete ? [] : [{deleted:{neq:1}}];
    if (props.conditions && Array.isArray(props.conditions)) {
      for (var i = 0; i < props.conditions.length; i++) {
        conditions.push(props.conditions[i]);
      }
    }

    // Prepare query
    let modelProps = this.modelProps();
    let query = configToNestedQuery(config, conditions);
    query.limit = this.props.limit || config.limit;
    query.offset = config.offset;
    this.props.meldItems({
      ...modelProps,
      query: query
    }).then(()=>{
      if (throttle) { throttle.done(); }
      switch (this.props.status) {
        case "success":
          if (this.props.onQuerySuccess) {this.props.onQuerySuccess(this.props.items)}
          break;
      }
    });
  }

  modelProps() {
    let props = this.props;
    return {
      type: props.type,
      typeAlias: props.typeAlias,
      id: props.id,
      idAlias: props.idAlias,
      childType: props.childType,
      childTypeAlias: props.childTypeAlias,
      childId: props.childId
    }
  }

  render() {
    let config = this.props.config;
    let limit = this.props.limit !== undefined ? this.props.limit : config.limit;
    let offset = config.offset || 0;
    let count = offset + this.props.items.length + 1;
    let className = this.props.className || "";
    return (
      <div className={`QueryPaginatePresentation Presentation ${className}`} ref="root" data-status={this.props.status}>
        <PaginateControl slave="true" limit={limit} offset={config.offset} count={count} onOffset={(offset)=>{this.onOffset(offset)}}>
          <ActionVal onClick={evt=>this.onReload()}><FontAwesomeIcon icon={["far","redo-alt"]} /></ActionVal>
        </PaginateControl>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = stateForList(state.inventory, props) || {};
  return {
    status: list.status,
    items: list.docs || [],
    config: dot(list,"query") || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    meldItems: opts => dispatch(meldItems(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(QueryPaginatePresentation)
