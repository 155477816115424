import React from 'react';
import Layout from './Layout';
import './HeaderLine.scss';

class HeaderLine extends Layout {
  render() {
    let children = this.props.children;
    children = Array.isArray(children) ? children : [children];
    return (
      <div className="HeaderLine Line" data-headers={children.length} data-faint={this.props.faint} data-hide={this.props.hide}>
        {children.map((child, index)=>{
          return (
            <div className={`Header Header${index+1}`} key={`header${index}`}>
              {child}
            </div>
          )
        })}
      </div>
    );
  }
}

export default HeaderLine;
