import React from 'react';
import Container from './Container';
import { datify } from '../../lib/formats';

class DateVal extends Container {
  render() {
    let props = this.props;
    let className = this.props.className || "";
    let str = this.props.fallback;
    if (this.props.children) {
      let parts = [];
      if (this.props.prefix) {parts.push(props.prefix)};
      parts.push(datify(this.props.children));
      if (this.props.suffix) {parts.push(props.suffix)};
      str = parts.join(" ")
    }
    return (
      <div data-raw={this.props.raw} className={`DateVal Container ${className}`}>
        {str}
      </div>
    );
  }
}

export default DateVal;
