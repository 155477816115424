import React from 'react';
import { connect } from 'react-redux'
import Menu from './Menu';
import MenuItemLayout from '../layouts/MenuItemLayout';
import PlainButton from '../elements/PlainButton';
import RectButton from '../elements/RectButton';
import Separator from '../elements/Separator';
import Input from '../elements/Input';
import DropControl from '../controls/DropControl';

import DateVal from '../containers/DateVal';
import ModelLayout from '../layouts/ModelLayout';

import { LEFT_MENU } from '../../constants/menus/LeftMenu';
import { expandMenu } from '../../actions/MenuActions';
import { openPopup } from '../../actions/PopupActions';
import { dot } from '../../lib/obj';
import { logoutSession } from '../../actions/SessionActions';

import { pushItem, startDraftItem, purgeItem, setItemField } from '../../actions/InventoryActions';
import { setAppField, toggleAppField } from '../../actions/AppActions';

import './TopMenu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TopMenu extends Menu {

  onToggleMenu() {
    this.props.toggleAppField("menu.left.expand");
  }

  onUserOption(option) {
    switch (option.name) {
      case "Sign Out":
        this.props.logoutSession();
        break;
      default:
    }
  }

  onSignin() {
    this.props.openPopup({ name:"Login" });
  }

  onSignout() {
    this.props.logoutSession();
  }

  onChangePasswordField(v, popup) {
    if (popup.state.error) {
      popup.setState({error:null, field:null});
    }
  }

  onChangePassword(e) {
    let userId = dot(this.props.session,"user.id");
    this.props.openPopup({
      name: "Form",
      props: {
        title: "Change Password",
        onRender: (popup) =>{
          let props = popup.props;
          let state = popup.state;
          let message = <label>Enter old and new password infomation</label>;
          if (state.error) {
            message = <label data-hype="error">{ state.error }</label>
          }
          return (<React.Fragment>
            {message}
            <Input type="password" lock={state.working} error={state.field == "oldPassword"} name="oldPassword" label="Current password" onChange={(v)=>{this.onChangePasswordField(v,popup)}}/>
            <Input type="password" lock={state.working} error={state.field == "password"} name="password" label="New password" onChange={(v)=>{this.onChangePasswordField(v,popup)}}/>
            <Input type="password" lock={state.working} error={state.field == "reenter"} name="reenter" label="Re-Enter new passwod" onChange={(v)=>{this.onChangePasswordField(v,popup)}}/>
          </React.Fragment>)
        },
        onOk: (data, popup)=>{
          if (!data.oldPassword) {
            popup.setState({error:"Enter password", field: "oldPassword"});
          }
          else if (!data.password) {
            popup.setState({error:"Enter new password", field: "password"});
          }
          else if (!data.reenter) {
            popup.setState({error:"Re-Enter new passwod", field: "reenter"});
          }
          else if (data.password != data.reenter) {
            popup.setState({error:"New password mismatch", field: "reenter"});
          }
          else {
            popup.setState({working:true});
            this.props.pushItem({
              type: "users",
              id: userId,
              docs: { user: {sets:data} }
            }).then((res)=>{
              popup.setState({working:false});
              if (res.isError) {
                popup.setState({error:"Incorrect password", field: "oldPassword"});
              }
              else {
                popup.props.closePopup();
              }
            });
          }
        }
      }
    });
  }

  onSetUserField(field, value) {
    const session = this.props.session;
    const user = dot(session,"user");
  }

  genAccountDiv() {
    const session = this.props.session;
    const user = dot(session,"user");
    const login = dot(session,"login");
    if (login) {
      return (<details className="FloatDetails AccountDetails">
        <summary>{ user.firstName || user.lastName || user.email.split("@")[0] } &nbsp;&nbsp;<FontAwesomeIcon icon={["far","user"]} /></summary>
          <div className="DetailsList">
          <h4>Account Details</h4>
          <Separator collapsed="true"/>
          <div className="FullName">{user.firstName} {user.lastName}</div>
          <Separator collapsed="true"/>
          <ModelLayout>
            <div className="Name">{user.email}</div>
            <div className="Label">User</div>
            <DateVal className="Sublabel">{user.createdAt}</DateVal>
          </ModelLayout>
          <Separator collapsed="true"/>
          <div className="IconAction" onClick={(e)=>{this.onChangePassword(e)}}><FontAwesomeIcon icon={["far","key"]} /> Change Password</div>
          <RectButton theme="bw" onClick={(e)=>this.onSignout()}>Signout</RectButton>
        </div>
      </details>);
    }
    else {
      return <PlainButton className="Signin" onClick={()=>{this.onSignin()}} layout="plain">Sign In</PlainButton>;
    }
  }

  render() {
    return (
      <div className="TopMenu" ref="root">
          <div className="Left">
            <PlainButton ><img src="/logo-small-white.png" onClick={()=>{this.onToggleMenu()}}/></PlainButton>
          </div>
          <div className="Center">
            1Lss Inventory
          </div>
          <div className="Right">
            {this.genAccountDiv()}
          </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    session: state.session
  }
};

const mapDispatch = (dispatch) => {
  return {
    expandMenu: opts => dispatch(expandMenu(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    logoutSession: opts => dispatch(logoutSession(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    setAppField: (f,v) => dispatch(setAppField(f,v)),
    toggleAppField: (f)=> dispatch(toggleAppField(f))
  }
};

export default connect(
  mapState,
  mapDispatch
)(TopMenu)
