import React from 'react';

export const VENDOR_SEARCH = {
  type: "vendors",
  limit: 100,
  term: {
    label: "Search Vendors",
    fields: ["name","pocFirstName","pocLastName"]
  },
  sort: [
      { field: "name",     name: "Name (A-Z)",  direction:"asc", selected: true},
      { field: "name",     name: "Name (Z-A)",  direction:"desc"},
      { field: "pocFirstName",  name: "POC (A-Z)",    direction:"asc"},
      { field: "pocFirstName",  name: "POC (Z-A)",    direction:"desc"},
    ]
}
