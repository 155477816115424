import React from 'react';

class Area extends React.Component {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`Area ${className}`} data-locked={this.props.locked} data-working={this.props.working}>
        {this.props.children}
      </div>
    );
  }
}

export default Area;
