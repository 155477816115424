import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import Moment from 'react-moment';
import Layout from './Layout';
import SubVal from '../containers/SubVal';
import ActionVal from '../containers/ActionVal';
import DateVal from '../containers/DateVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Layout.css';
import './FileUploadLayout.css';

import { createItem } from '../../actions/InventoryActions';
import { awsPostS3File } from '../../apis/AWS';
import { dot } from '../../lib/obj';
import { abbr } from '../../lib/formats';

class FileUploadLayout extends Layout {

  constructor(props) {
    super(props);
    this.state = {
      status: null,
      working: false,
      registering: false,
      uploading:false,
      loaded: 0,
      total: 0,
    };
  }

  onClick(evt) {
    if (this.refs.input && !this.isWorking()) {
      let elem = ReactDOM.findDOMNode(this.refs.input);
      elem.click();
    }
  }

  onStatus(item,status) {
    this.setState({status:status});
  }

  onProgress(item, loaded, total) {
    this.setState({loaded:loaded, total: total});
  }

  onChange(evt) {
    // Get handle tot he file (only one at a time for now )
    let file = evt.target.files[0] || {};
    this.setFile();
  }

  setFile(file) {
    console.log("onChange",file);

    // Update state
    this.setState({
      registering:true,
      status: "registering",
      loaded: 0,
      total:file.size
    });

    // Register and upload file
    this.props.createItem({
      type: "files",
      id: "new",
      docs: {
        file: {
          data: {
            type: "book",
            name: file.name,
            size: file.size,
            mime: file.type
          }
        }
      }
    }).then((res)=>{
      console.log("RES:",res);
      let item = res.item;
      this.setState({uploading:true});
      awsPostS3File({
        file: file,
        prereq: dot(res,"file.data.prereq"),
        onStart: ()=>{this.onStatus(item,"uploading")},
        onAbort: ()=>{this.onStatus(item,"aborted")},
        onError: ()=>{this.onStatus(item,"failed")},
        onLoad: ()=> {
          // Wait a second before completing the upload
          setTimeout(()=>{
            this.setState({status:"uploaded"});
            this.props.onUpload([res.file]);
          });
        },
        onProgress: (evt)=>{this.onProgress(item,evt.loaded, evt.total)},
      });
    });
  }

  getTitle() {
    let title;
    switch (this.state.status) {
      case "registering":
      case "uploading":
        title = "Uploading...";
        break;
      case "aborted":
      case "failed":
        title = "Oops!";
        break;
      case "uploaded":
        title = "Done!";
        break;
      default:
        title = this.props.title || "Add file";
    }
    return title;
  }

  getSub() {
    let sub;
    switch (this.state.status) {
      case "registering":
      case "uploading":
        let loaded = abbr(this.state.loaded,{type:'data'});
        let total = abbr(this.state.total,{type:'data'});
        sub = `${loaded} of ${total}`;
        break;
      case "aborted":
      case "failed":
        sub = "Try upload again";
        break;
      case "uploaded":
        sub = "Upload more";
        break;
      default:
        sub = this.props.sub || "img, pdf, txt, etc";
    }
    return sub;
  }

  getIcon() {
    let icon;
    switch (this.state.status) {
      case "registering":
      case "uploading":
        icon = <FontAwesomeIcon className="Icon" icon={["far","cloud-upload-alt"]}/>
        break;
      case "aborted":
      case "failed":
        icon = <FontAwesomeIcon className="Icon" icon={["far","exclamation-triangle"]}/>
        break;
      case "uploaded":
        icon = <FontAwesomeIcon className="Icon" icon={["far","thumbs-up"]}/>
        break;
      default:
        icon = <FontAwesomeIcon className="Icon" icon={["far","file-plus"]}/>
    }
    return icon;
  }

  isWorking() {
    let status = this.state.status;
    return status == "registering" || status == "uploading";
  }

  onDragEnter(evt) {
    console.log("onDragEnter")
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDragOver(evt) {
    console.log("onDragOver")
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDragLeave(evt) {
    console.log("onDragLeave")
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDrop(evt) {
    console.log("onDrop")
    evt.preventDefault();
    evt.stopPropagation();

    // Get handle tot he file (only one at a time for now )
    let file = evt.dataTransfer.files[0] || {};
    this.setFile(file);
  }

  render() {
    let className = this.props.className || "";
    return (
      <div className={`Layout FileUploadLayout ${className}`} onClick={(evt)=>{this.onClick(evt)}} data-working={this.isWorking()} data-status={this.state.status}
        onDragEnter={(evt)=>{this.onDragEnter(evt)}}
        onDragOver={(evt)=>{this.onDragOver(evt)}}
        onDragLeave={(evt)=>{this.onDragLeave(evt)}}
        onDrop={(evt)=>{this.onDrop(evt)}}
        >
        <input ref="input" className="Input" type="file" name="+" onChange={(evt)=>this.onChange(evt)}/>
        {this.getIcon()}
        <ActionVal className="Title">{this.getTitle()}</ActionVal>
        <SubVal className="Sub">{this.getSub()}</SubVal>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {}
};

const mapDispatch = (dispatch) => {
  return {
    createItem: opts => dispatch(createItem(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(FileUploadLayout)
