import React from 'react';

class Popup extends React.Component {

  onClose(evt) {

  }

  render() {
    return (
      <div>Hello popup!</div>
    );
  }
}

export default Popup;
