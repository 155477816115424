import React from 'react';
import Container from './Container';
import './SubVal.css';

class SubVal extends Container {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`SubVal Container ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default SubVal;
