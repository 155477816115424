// Ref: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
import { dot, reg, arrange } from '../lib/obj'
import { fetch2 } from '../lib/fetch2'

const LSS_ENDPOINT = process.env.REACT_APP_INVENTORY_ENDPOINT || "https://wl2ksxc8tc.execute-api.us-east-1.amazonaws.com/dev";
//const LSS_ENDPOINT = process.env.REACT_APP_INVENTORY_ENDPOINT || "https://gkxh283qs1.execute-api.us-west-2.amazonaws.com/prod";

/**
 * Sends a GET request to Lss API
 */
export const lssGet = function(opts = {}) {
  const url = opts.path ? join(LSS_ENDPOINT,opts.path)  : opts.url;
  return fetch2(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      'X-Lss-Book-Loc': '1',
      'X-Lss-User-Loc': dot(opts,"login.userId"),//'1',
      'X-Lss-Login-Loc': dot(opts,"login.locator"),// 'lgn_31wQkj76ICjt8oRfjCtW12vKaKxk5BT2YXYx',
      'X-Lss-Login-Sec': dot(opts,"login.secret")//'TFTecmy9soA3sA9n'
    },
    queryParams: opts.query,
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
  });
}

/**
 * Sends a POST request to Lss API
 */
export const lssPost = function(opts = {}) {
  const url = opts.path ? join(LSS_ENDPOINT,opts.path) : opts.url;
  return fetch2(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      'X-Lss-Book-Loc': '1',
      'X-Lss-User-Loc': dot(opts,"login.userId"),//'1',
      'X-Lss-Login-Loc': dot(opts,"login.locator"),// 'lgn_31wQkj76ICjt8oRfjCtW12vKaKxk5BT2YXYx',
      'X-Lss-Login-Sec': dot(opts,"login.secret")//'TFTecmy9soA3sA9n'
    },
    queryParams: opts.query,
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(opts.data || {}), // body data type must match "Content-Type" header
  });
}

/**
 * Sends a PUT request to Lss API
 */
export const lssPut = function(opts = {}) {
  const url = opts.path ? join(LSS_ENDPOINT,opts.path) : opts.url;
  return fetch2(url, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      'X-Lss-Book-Loc': '1',
      'X-Lss-User-Loc': dot(opts,"login.userId"),//'1',
      'X-Lss-Login-Loc': dot(opts,"login.locator"),// 'lgn_31wQkj76ICjt8oRfjCtW12vKaKxk5BT2YXYx',
      'X-Lss-Login-Sec': dot(opts,"login.secret")//'TFTecmy9soA3sA9n'
    },
    queryParams: opts.query,
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(opts.data || {}), // body data type must match "Content-Type" header
  });
}

/**
 * Sends a Delete request to Lss API
 */
export const lssDelete = function(opts = {}) {
  const url = opts.path ? join(LSS_ENDPOINT,opts.path) : opts.url;
  return fetch2(url, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      'X-Lss-Book-Loc': '1',
      'X-Lss-User-Loc': dot(opts,"login.userId"),//'1',
      'X-Lss-Login-Loc': dot(opts,"login.locator"),// 'lgn_31wQkj76ICjt8oRfjCtW12vKaKxk5BT2YXYx',
      'X-Lss-Login-Sec': dot(opts,"login.secret")//'TFTecmy9soA3sA9n'
    },
    queryParams: opts.query,
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(opts.data || {}), // body data type must match "Content-Type" header
  });
}

// Helps join two paths
export const join = function() {
  let str = arguments[0].toString();
  for (var i = 1; i < arguments.length; i++){
    str += arguments[i].toString().replace(/^\/?/,"/");
  }
  return str;
}
