import React from 'react';
import { withRouter } from 'react-router'
import Moment from 'react-moment';
import Layout from './Layout';
import ImageLayout from './ImageLayout';
import SubVal from '../containers/SubVal';
import TypeVal from '../containers/TypeVal';
import ActionVal from '../containers/ActionVal';

import './Line.css';
import './ProductLayout.css';

import { financial, capitalize, abbr } from '../../lib/formats';
import { dot } from '../../lib/obj';

class ProductLayout extends Layout {

  onClick(evt, history) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
      return;
    }
  }

  render() {
    let data = dot(this.props.product,"data") || {};
    let className = this.props.className || "";
    return (
      <div className={`Layout ProductLayout ${className}`}>
        <div className="Name"><ActionVal onClick={(evt, history)=>{this.onClick(evt, history)}}>{data.name}</ActionVal></div>
        <TypeVal className="Type" type="products" bare="true">{data.type}</TypeVal>
        <SubVal className="Sku">{data.sku}</SubVal>
      </div>
    );
  }
}

const RoutedProductLayout = withRouter(ProductLayout)
export default RoutedProductLayout;
