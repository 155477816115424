import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import DateVal from '../containers/DateVal';
import MoneyVal from '../containers/MoneyVal';
import QtyVal from '../containers/QtyVal';
import StateVal from '../containers/StateVal';
import TypeVal from '../containers/TypeVal';
import NameVal from '../containers/NameVal';
import SubVal from '../containers/SubVal';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import ItemSearchSection from '../sections/ItemSearchSection';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ORDER_SEARCH, ORDER_STATUS_MAP, ORDER_ORIGINS_MAP } from '../../constants/inventory/Order'
import { startDraftItem, purgeDraftItem } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';

import './Page.css';
import './OrdersPage.css';

class OrdersPage extends Page {


  onAdd(evt, history) {
    history.push("/orders/new");
  }

  onView(evt, history, index) {
    let data = dot(this.props.orders, [index,'data']);
    if (data) {
      history.push(`/orders/${data.id}`);
    }
  }

  render() {
    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add Order</RectButton>;

    // Get the docs
    const orders = this.props.orders || [];
    const status = this.props.status;
    return (
      <div className="Page OrdersPage">
        <PageHeaderOutline title="Orders" aside={addButton} top={this._backButton()}>
          <Link theme="blue" to="/order-stats"><FontAwesomeIcon icon={["far","chart-line"]} /> Stats</Link>
        </PageHeaderOutline>
        <ItemSearchSection type="orders" query={ORDER_SEARCH} limit={100} renderItem={(item, index)=>{
          let status = item.data.fulfillmentStatus;
          return <ItemLine key={`item${index}`} onClick={(evt,history)=>this.onView(evt,history,index)}>
            <SubVal>{dot(item,"data.email")}</SubVal>
            <NameVal>{dot(item,"data.name")}</NameVal>
            <TypeVal val={status}>{dot(ORDER_STATUS_MAP[status],"name") || 'Pending'}</TypeVal>
            <div style={{color:"#96bf48"}}>{ORDER_ORIGINS_MAP['shopify'].icon}</div>
            <MoneyVal>{dot(item,"data.totalPrice") || 0}</MoneyVal>
            <DateVal>{dot(item,"data.createdAt")}</DateVal>
          </ItemLine>
        }}>
          <div>Email</div>
          <div>Number</div>
          <div>Status</div>
          <div>Origin</div>
          <div>Total</div>
          <div>Created</div>
        </ItemSearchSection>
        <QueryPaginatePresentation type="orders"/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = dot(state.inventory,"orders.list") || {};
  return {
    list: list,
    status: list.status,
    orders: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return { }
};

export default connect(
  mapState,
  mapDispatch
)(OrdersPage)
