import * as ACTIONS from './ActionTypes';

export const setSessionLogin = (opts={}) => {
  return {
    type: ACTIONS.SET_SESSION_LOGIN,
    opts: opts
  }
}

export const setSessionBook = (opts={}) => {
  return {
    type: ACTIONS.SET_SESSION_BOOK,
    opts: opts
  }
}

export const logoutSession = (opts={}) => {
  return {
    type: ACTIONS.LOGOUT_SESSION,
    opts: opts
  }
}
