import React from 'react';
import { connect } from 'react-redux'
import Form from './Form';
import Input from '../elements/Input';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import PlainButton from '../elements/PlainButton';
import SelectorControl from '../controls/SelectorControl';
import ActionVal from '../containers/ActionVal';
import TypeVal from '../containers/TypeVal';

import { dot } from '../../lib/obj';
import { capitalize, abbr } from '../../lib/formats';
import { setItemField, setItemAction } from '../../actions/InventoryActions';

import './Form.css';
import './LegacyLocationItemForm.scss';


class LegacyLocationItemForm extends Form {

  // Edit the given field
  editField(field, value) {
    // Handle to the set item field options
    let opts = {
      type:"locations",
      id: dot(this.props.locationItem,"data.locationId"),
      childType: "items",
      childId: dot(this.props.locationItem,"data.itemId"),
      field:field,
      value:value
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemField(opts);
  }

  onProductClick(evt, history) {
    let productId = dot(this.props.locationItem,"data.itemId");
    if (productId) {
      history.push(`/products/${productId}`);
    }
  }

  onDeleteClick(evt) {
    console.log("onDeleteClick");
    // Handle to the set item field options
    let opts = {
      type:"locations",
      id: dot(this.props.locationItem,"data.locationId"),
      childType: "items",
      childId: dot(this.props.locationItem,"data.itemId"),
      action: "delete"
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemAction(opts);
  }

  onUndoClick(evt) {
    // Handle to the set item field options
    let opts = {
      type:"locations",
      id: dot(this.props.locationItem,"data.locationId"),
      childType: "items",
      childId: dot(this.props.locationItem,"data.itemId"),
      action: ""
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemAction(opts);
  }

  render() {
    // Get the location item and ref item
    let locationItem = this.props.locationItem;
    let product = this.props.product;

    // Handle to prop values
    let action = dot(locationItem,"action");

    let name = dot(product,"data.name");
    let sku = dot(product,"data.sku");
    let price = parseFloat(dot(locationItem,"data.unitCost")) || 0;
    let totalExpected = parseInt(dot(locationItem,"data.totalExpected")) || 0;
    let totalReceived = parseInt(dot(locationItem,"data.totalReceived")) || 0;
    let totalSent = parseInt(dot(locationItem,"data.totalSent")) || 0;
    let quantity = parseInt(dot(locationItem,"data.quantity")) || 0;
    let comment = dot(locationItem,"data.comment");

    // Get the product name
    let type = dot(locationItem,"data.item.type") || dot(product,"data.type");
    let productName = dot(locationItem,"data.item.name") || dot(product,"data.name");
    let productLabel = <div><TypeVal type="products" bare="true">{type}</TypeVal> {sku}</div>

    let control = null;
    if (action == "delete") {
      control = <ActionVal className="Control" onClick={(evt)=>{this.onUndoClick(evt)}}>&#8635;</ActionVal>
    } else {
      control = <ActionVal theme="red" className="Control" onClick={(evt)=>{this.onDeleteClick(evt)}}>&#x2715;</ActionVal>
    }

    return (
      <div className="Form LegacyLocationItemForm" ref="root" data-action={action}>
        <Readout className="Name" label={productLabel} onClick={(e,h)=>{this.onProductClick(e,h)}}>{productName}</Readout>
        <Readout className="Expected" label="Expected">{abbr(totalExpected)}</Readout>
        {/*<Readout className="Received" label="Received">{abbr(totalReceived)}</Readout>
        <Readout className="Sent" label="Sent">{abbr(totalSent)}</Readout>*/}
        <Input className="Quantity" label="Quantity" type="number" value={quantity} onChange={(val)=>{this.editField("quantity",parseFloat(val))}}/>
        {control}
      </div>
    );
  }
}

const mapState = (state, props) => {
  let itemId = dot(props.locationItem,"data.itemId");
  let product = itemId ? (dot(state.inventory,["products",itemId]) || {}) : {};
  return {
    inventory: state.inventory,
    product: product
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts => dispatch(setItemField(opts)),
    setItemAction: opts => dispatch(setItemAction(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LegacyLocationItemForm)
