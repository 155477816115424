import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Readout from '../elements/Readout';

import { dot } from '../../lib/obj';
import { setItemField } from '../../actions/InventoryActions';
import { stateForDoc } from '../../lib/inventory-util';

import './Form.css';
import './FileForm.css';

class FileForm extends Form {

  // Edit the given field
  editField(field, value) {
    let opts = this.props.opts;
    this.props.setItemField({field:field, value:value, ...opts});
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.file,"data") || {};
    return (
      <div className="Form FileForm">
        <Readout className="Name" label="Name" type="text" onChange={(val)=>{this.editField("name",val)}}>{data.name}</Readout>
        <Input className="Description" label="Description" type="text" value={data.description} onChange={(val)=>{this.editField("description",val)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let opts = props.opts || {}
  let file = stateForDoc(state.inventory,{
    type: "files",
    id: "new",
    ...opts
  });
  return {
    opts: opts,
    inventory: state.inventory,
    file: file
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(FileForm)
