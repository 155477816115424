import React from 'react';
import { withRouter } from 'react-router'
import Container from './Container';
import './ActionVal.css';

class ActionVal extends Container {

  onClick(evt) {
    if (this.props.url) {
      window.open(this.props.url,'_blank');
    }
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let className = this.props.className || "";
    return (
      <div className={`ActionVal Container ${className}`} data-theme={this.props.theme} data-hype={this.props.hype} onClick={evt=>this.onClick(evt)}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(ActionVal);
