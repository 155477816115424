import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import POForm from '../forms/POForm'
import { connect } from 'react-redux'

import './Popup.css';
import './POPopup.css';

import { closePopup } from '../../actions/PopupActions';
import { loadItem, listItems, setItemQuery } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

class POPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      selections: {}
    };
  }

  componentWillMount() {

  }

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    this.props.closePopup();
  }

  render() {
    // Check if sending the po
    let po = this.props.po;
    let sending = dot(po,"status") == "creating";

    // Get the items and set as options
    return (
      <div className="Popup POPopup" ref="root">
        <POForm className="Form" po={this.props.po}/>
        <RectButton className="Cancel" theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>
        <RectButton className="OK" theme="blue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let id = props.id || "new";
  let po = dot(state.inventory,["pos",id]) || {};
  return {
    id: id,
    po: po
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(POPopup)
