import React from 'react';
import { withRouter } from 'react-router'
import Element from './Element';
import './RoundButton.css';

class RoundButton extends Element {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    return (
      <div className="RoundButton" ref="root" onClick={(evt)=>{this.onClick(evt)}}>{this.props.children}</div>
    );
  }
}

const RoutedRoundButton= withRouter(RoundButton)
export default RoutedRoundButton;
