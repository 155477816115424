import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Select from '../elements/Select';
import DropControl from '../controls/DropControl';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import SelectorControl from '../controls/SelectorControl';
import Separator from '../elements/Separator';
import SubVal from '../containers/SubVal';
import TypeVal from '../containers/TypeVal';
import ImageLayout from '../layouts/ImageLayout';

import { financial, capitalize } from '../../lib/formats';
import { docForLoc } from '../../lib/inventory-util';
import { dot } from '../../lib/obj';
import { PRODUCT_TYPES, PRODUCT_STAGES } from '../../constants/inventory/Product.js';
import { stateForDoc } from '../../lib/inventory-util';

import { listItems, setItemField } from '../../actions/InventoryActions';

import './Form.css';
import './ProductForm.scss';

class ProductForm extends Form {

  // Edit the given field
  editField(field, value) {
    let opts = this.props.opts;
    this.props.setItemField({field:field, value:value, ...opts});
  }

  // Edit the image
  editImage(file) {
    console.log("Product Image: ", file);
    if (file) {
      this.editField("imageId",file.data.id);
    }
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.product,"data") || {};

    return (
      <div className="Form ProductForm" data-layout={this.props.layout} data-size={dot(this.state,"size")}>
        <Select className="Type" label="Type" key={`key${data.type}`} defaultValue={data.type} onChange={(value)=>{this.editField("type",value)}} hype={data.type || 'undefined'}>
          {PRODUCT_TYPES.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
        </Select>
        {/*
        <Select className="Stage" label="Stage" key={`stage${data.stage}`} defaultValue={data.stage} onChange={(value)=>{this.editField("stage",value)}} hype={data.stage || 'undefined'}>
          {PRODUCT_STAGES.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
        </Select>
        */}
        <ImageLayout className="Image" file={this.props.image} onUpload={(file)=>this.editImage(file)}/>
        <Input className="Name" label="Name" value={dot(data,"name")} onChange={(value)=>{this.editField("name",value)}}/>
        <Input className="Sku" label="Sku" value={dot(data,"sku")} onChange={(value)=>{this.editField("sku",value)}}/>
        <Input className="Code" label="Code" value={dot(data,"code")} onChange={(value)=>{this.editField("code",value)}}/>
        <Input className="MSRP" label="MSRP" value={dot(data,"msrp")} type="number" onChange={(value)=>{this.editField("msrp",value)}}/>
        <Input className="DepletionRate" label="Daily Sales" type="number" value={dot(data,"depletionRate")} onChange={(value)=>{this.editField("depletionRate",value)}}/>
        <Input className="LeadTime" label="Lead Time" type="number" value={dot(data,"leadTime")} onChange={(value)=>{this.editField("leadTime",value)}}/>
        <Input className="Description" label="Description" value={dot(data,"description")} onChange={(value)=>{this.editField("description",value)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let opts = props.opts || {}
  let product = stateForDoc(state.inventory,{type: "products",id: "new",...opts});
  let imageId = dot(product,"data.imageId");
  let image = imageId ? dot(state.inventory,["files",imageId]) : undefined;
  return {
    opts: opts,
    inventory: state.inventory,
    product: product,
    image: image
  }
};
const mapDispatch = (dispatch) => {
  return {
    listItems: opts => dispatch(listItems(opts)),
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ProductForm)
