import React from 'react';
import Moment from 'react-moment';
import Layout from './Layout';
import SubVal from '../containers/SubVal';
import ActionVal from '../containers/ActionVal';
import DateVal from '../containers/DateVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Layout.css';
import './FileLayout.css';

import { openPopup, closePopup } from '../../actions/PopupActions';
import { connect } from 'react-redux'
import { dot } from '../../lib/obj';

class FileLayout extends Layout {

  onClick(evt, history) {
    if (this.props.onClick) {
      this.props.onClick(evt,history);
    }
  }

  onEdit(evt) {
    if (this.props.onEdit) {
      this.props.onEdit(evt)
    }
    else {
      let data = dot(this.props.file,"data") || {};
      this.props.openPopup({
        name:"ItemEdit",
        props:{
          title: "Edit File",
          docType: "file",
          onDelete: this.props.onDelete,
          opts: {
            type: "files",
            id: data.id
          }
        }
      });
    }
  }

  getIcon() {
    let data = dot(this.props.file,"data") || {};
    switch (data.mime) {
      case "application/pdf":
        return <FontAwesomeIcon className="Icon" onClick={evt=>this.onEdit(evt)} style={{color:"red"}} icon={["far","file-pdf"]} />;
      case "image/jpeg":
      case "image/png":
      case "image/jpg":
        return <img className="Icon" onClick={evt=>this.onEdit(evt)}  src={data.presignedS3GetUrl}/>;
      default:
        return <FontAwesomeIcon className="Icon" icon="file" onClick={evt=>this.onEdit(evt)} />;
    }
  }

  render() {
    let data = dot(this.props.file,"data") || {};
    let className = this.props.className || "";
    return (
      <div className={`Layout FileLayout ${className}`} data-type={data.mime} data-status={data.status} >
        <div className="Content">
          {this.getIcon()}
          <ActionVal className="Name" onClick={(evt)=>{this.onClick(evt)}}>{data.name}</ActionVal>
          <SubVal className="Info"><DateVal>{data.createdAt}</DateVal></SubVal>
        </div>
        <ActionVal className="Edit" onClick={evt=>this.onEdit(evt)}><FontAwesomeIcon className="Icon" icon={["far","pencil"]} /></ActionVal>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {};
};

const mapDispatch = (dispatch) => {
  return {
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};


export default connect(
  mapState,
  mapDispatch
)(FileLayout)
