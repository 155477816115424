import React from 'react';
import PlainButton from '../elements/PlainButton';
import { dot } from '../../lib/obj';

class Page extends React.Component {

  constructor() {
    super();
    this.state = {}; 
  }

  // Default page go back behavior
  onBack(evt, history) {
    let path = dot(this.props.back,"path");
    if (path) {
      history.replace(path);
    }
    else {
      history.goBack();
    }
  }

  // Generates the page back button
  _backButton() {
    let back = <div/>;
    if (this.props.back) {
      const label = dot(this.props.back,"label") || this.props.back;
      back = <PlainButton layout="auto" theme="blue" onClick={(evt, history)=>{this.onBack(evt, history)}}>&#10094;&nbsp;{label}</PlainButton>;
    }
    return back;
  }

  render() {
    return (
      <div>Hello page!</div>
    );
  }
}

export default Page;
