import React from 'react';
import ReactDOM from 'react-dom';
import Control from './Control';
import './CheckControl.css';
import Input from '../elements/Input';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import RoundButton from '../elements/RoundButton';
import Separator from '../elements/Separator';
import CheckBoxOption from '../containers/CheckBoxOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dot } from '../../lib/obj';

class CheckControl extends Control {

  constructor(props) {
    super(props);
    this.state = {
      options: props.options || [],
      focused: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({focused: false});
    }
  }

  onClick() {
    this.setState({focused:!this.state.focused});
  }

  onOptionClick(index) {
    let options = this.getOptions();

    // Clear selected options
    if (this.props.radio || index < 0) {
      for (var i = 0; i < options.length; i++) {
        if (i === index) { continue; }
        let option = options[i];
        option.selected = false;
      }
    }

    // Toggle the option
    if (index >= 0) {
      dot(options[index],'selected',!dot(options[index],'selected'));
    }

    // Slave means updates are contolled by props directly
    if (!this.props.slave) {
      // Update state and notify change
      this.setState({options:options});
      if (this.props.onChange) {
        this.props.onChange(options, index);
      }
    }
    else {
      if (this.props.onChange) {
        this.props.onChange(options, index);
      }
    }
  }

  getOptions() {
    // Slave means updates are contolled by props directly
    return this.props.slave ? this.props.options : this.state.options;
  }

  render() {

    // Handle to the options
    let options = this.getOptions();

    // Determine the state icon
    let icon = this.props.icon;
    if (!icon) {
      icon = <FontAwesomeIcon icon="chevron-down" />;
    }

    let className = this.props.className || "";
    return (
      <div className={`CheckControl ${className}`} ref="root"
        data-focused={this.state.focused}
        data-layout={this.props.layout || "dropdown"}
        data-theme={this.props.theme}
        data-type={this.props.type}>
        <div className="Button" onClick={(evt)=>{this.onClick(evt)}}>
          {this.props.value}
          <div className="State">{icon}</div>
        </div>
        <div className="Options">
          {options.map((option, index)=>{
            return (<CheckBoxOption className="Option" key={`option${index}`} radio={this.props.radio} selected={option.selected} onClick={()=>{this.onOptionClick(index)}}>
              {option.name}
            </CheckBoxOption>);
          })}
          <div className="Option Clear" key={`clearOption`} onClick={()=>{this.onOptionClick(-1)}}>
            <span className="Check"></span>
            <span className="Entry">Clear</span>
          </div>
        </div>
      </div>
    );
  }
}



export default CheckControl
