import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import ItemSearchSection from '../sections/ItemSearchSection';
import NameVal from '../containers/NameVal';
import ItemOptionLine from '../layouts/ItemOptionLine';
import OptionLayout from '../layouts/OptionLayout';
import ImageLayout from '../layouts/ImageLayout';
import InfoLayout from '../layouts/InfoLayout';
import ItemLine from '../layouts/ItemLine';
import Row from '../elements/Row';
import LoginForm from '../forms/LoginForm'
import PaddedArea from '../areas/PaddedArea';
import FooterArea from '../areas/FooterArea';
import ClearableVal from '../containers/ClearableVal';
import ActionVal from '../containers/ActionVal';
import { connect } from 'react-redux'

import './Popup.css';
import './SearchPopup.scss';

import { closePopup } from '../../actions/PopupActions';
import { loadItem, listItems, setItemQuery } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot } from '../../lib/obj';
import FilterLine from '../layouts/FilterLine';

import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

class SearchPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      selections: props.selections || {},
      expanded: false
    };
  }

  onItemClick(item, index, history) {
    if (this.props.onItemClick) {
      this.props.onItemClick(item, index, history);
    }

    // Check if selectable
    if (this.props.selector == "none") { return; }

    // Set selection
    let selections = this.state.selections;
    let id = dot(item.data,'id');
    let found = selections[id];
    if (found) {
      delete selections[id];
    } else {
      // Clear selected item
      if (this.props.radio) {
        selections = {}
      }
      selections[id] = {
        ordering: Object.keys(selections).length,
        item: item
      }
    }
    this.setState({selections:selections});
  }

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
    this.props.closePopup();
  }

  onOk(evt, history) {
    if (this.props.onOk) {
      let selections = this.state.selections;
      let ids = Object.keys(selections).sort((a,b)=>{
        return selections[a].ordering - selections[b].ordering
      });
      let items = ids.map((a)=>{return selections[a].item;});
      this.props.onOk(items);
    }
    this.props.closePopup();
  }

  onClear(evt) {
    this.setState({selections:{}});
  }

  // Called when the Other option is clicked
  onOther(evt) {
    this.props.closePopup();
    if(this.props.other && this.props.other.onClick) {
      this.props.other.onClick(evt);
    }
  }

  onRenderItem(item,index,offset=0, pinned = false) {

    // Check if items is selected
    let selections = this.state.selections;
    let selected = selections[dot(item,"data.id")] !== undefined;

    // Render the item
    let itemInfo;
    let optionProps = {};
    if (this.props.renderItem) {
      itemInfo = this.props.renderItem(item, index, optionProps);
      itemInfo = Array.isArray(itemInfo) ? itemInfo : [itemInfo];
    } else {
      itemInfo = [<span className="Entry">{dot(item,"data.name") || dot(item,"data.id")}</span>];
    }

    let isOption = this.props.selector != "none";
    return <OptionLayout key={`item${index}`} option={isOption} selected={selected} radio={this.props.radio} pinned={pinned} onClick={(evt,history)=>this.onItemClick(item, index, history)} {...optionProps}>
      <InfoLayout>
        {itemInfo}
      </InfoLayout>
    </OptionLayout>;
  }

  renderPinItems() {
    let pinItems = this.props.pinItems;
    if (!pinItems) { return; }
    return pinItems.map((item, index)=>this.onRenderItem(item,index))
  }

  onExpand(expanded) {
    this.setState({expanded:expanded});
  }

  render() {
    let props = this.props;

    // Get the selections and other options
    const selections = this.state.selections;
    let limit = this.props.limit || dot(this.props.query,"limit");
    let offset = dot(this.props.query,"offset");

    // Prepare the stats area
    let stats;
    let count = Object.keys(selections).length;
    if (count > 0) {
      stats = <div key="selectedCount"><ClearableVal onClear={(evt)=>this.onClear(evt)}>{count} Selected</ClearableVal></div>
    }

    // setup paginate control
    let paginate = <QueryPaginatePresentation
      type={props.type}
      typeAlias={props.typeAlias}
      conditions={props.conditions}
      limit={limit}
    />

    // Setup other options if necessary
    let other = <div/>
    if(this.props.other) {
      other = <ActionVal key="other" onClick={()=>{this.onOther();}}>{this.props.other.label || "Other"}</ActionVal>
    }

    // Build the footer
    let footer =[
      stats,
      <FooterArea key="footer">
        {other}
        <RectButton key="cancel" className="Cancel" theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>
        <RectButton key="ok"     className="OK" theme="blue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
      </FooterArea>
    ]

    return (
      <div className="Popup SearchPopup" ref="root" data-type={props.type} data-expanded={this.state.expanded}>
        <ItemSearchSection
          type={props.type}
          typeAlias={props.typeAlias}
          query={props.query}
          conditions={props.conditions}
          ignoreDelete={props.ignoreDelete}
          reload={props.reload}
          delayLoad={props.delayLoad}
          limit={limit}
          renderItem={(item, index)=>this.onRenderItem(item,index,dot(props.pinItems,'length')||0)}
          onExpand={(expanded)=>this.onExpand(expanded)}
          prependItems={this.renderPinItems()} footer={footer}>
          {this.props.title}
          {paginate}
        </ItemSearchSection>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {}
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(SearchPopup)
