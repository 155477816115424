import React from 'react';
import { mappify, mapk2k } from '../../lib/obj';
import { PRODUCT_TYPES } from './Product'

export const BUILD_SEARCH = {
  type: "builds",
  limit: 50,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["name"]
  },
  sort: [
    { field: "createdAt",       name: "Created (Oldest to Newest)",    direction:"asc"},
    { field: "createdAt",       name: "Created (Newest to Oldest)",    direction:"desc",  selected: true},
    { field: "updatedAt",       name: "Updated (Oldest to Newest)",    direction:"asc"},
    { field: "updatedAt",       name: "Updated (Newest to Oldest)",    direction:"desc"}
  ]
}

export const BUILD_STATUS = [
  { value:"pending",      name: "Pending",          desc: "New build, no actions yet"},
  { value:"partial",      name: "Partial",          desc: "Build is partially ordered"},
  { value:"complete",     name: "Complete",         desc: "Build is fully ordered"},
];

export const BUILD_ITEM_SEARCH = {
  type: "build-items",
  links: ["item","image"],
  limit: 100,
  term: {
    label: "Products",
    placeholder: "Search",
    fields: ["item.name","item.sku"],
    keymap: {
      sku: "item.sku",
      name: "item.name"
    }
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "item.type",
      options: PRODUCT_TYPES
    }
  ],
  sort: [
    { field: "createdAt",        name: "Created (Asc)",   direction:"asc"},
    { field: "createdAt",        name: "Created (Desc)",  direction:"desc", selected: true},
    { field: "item.name",        name: "Name (A-Z)",      direction:"asc"},
    { field: "item.name",        name: "Name (Z-A)",      direction:"desc"},
    { field: "item.sku",         name: "SKU (A-Z)",       direction:"asc"},
    { field: "item.sku",         name: "SKU (Z-A)",       direction:"desc"},
    { field: "item.type",        name: "Type (A-Z)",      direction:"asc"},
    { field: "item.type",        name: "Type (Z-A)",      direction:"desc"}
  ]
}


export const BUILD_STATUS_MAP = mapk2k(BUILD_STATUS,"value", "name");
