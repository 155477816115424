import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './InfoLayout.scss';

import { _ } from '../../lib/underscore';

class InfoLayout extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let children = this.props.children;
    children = Array.isArray(children) ? _.flatten(children) : [children];
    let className = this.props.className || "";
    return (
      <div className={`InfoLayout Layout ${className}`} data-values={children.length} onClick={(evt)=>{this.onClick(evt)}} data-clickable={this.props.onClick != undefined}>
        {children.map((child, index)=>{
          return (
            <div className={`Value Value${index+1}`} key={`value${index}`}>
              {child}
            </div>
          )
        })}
      </div>
    );
  }
}

export default withRouter(InfoLayout);
