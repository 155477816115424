import React from 'react';
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Dashboard from './pages/Dashboard';
import HomePage from './pages/HomePage';
import Popups from './popups/Popups';
import BuildsPage from './pages/BuildsPage';
import BuildPage from './pages/BuildPage';
import CollectionsPage from './pages/CollectionsPage';
import CollectionPage from './pages/CollectionPage';
import InventoryPage from './pages/InventoryPage';
import KPIPage from './pages/KPIPage';
import LocationsPage from './pages/LocationsPage';
import LocationPage from './pages/LocationPage';
import LocationPreviewPage from './pages/LocationPreviewPage';
import Notifier from './widgets/Notifier';
import OrdersPage from './pages/OrdersPage';
import OrderPage from './pages/OrderPage';
import OrderStatsPage from './pages/OrderStatsPage';
import ResolutionsPage from './pages/ResolutionsPage';
import ResolutionStatsPage from './pages/ResolutionStatsPage';
import ReturnsPage from './pages/ReturnsPage';
import ReturnStatsPage from './pages/ReturnStatsPage';
import StubsPage from './pages/StubsPage';
import POPage from './pages/POPage';
import POsPage from './pages/POsPage';
import ProductPage from './pages/ProductPage';
import ProductsPage from './pages/ProductsPage';
import ReturnsKPIPage from './pages/ReturnsKPIPage';
import TransferPage from './pages/TransferPage';
import TransfersPage from './pages/TransfersPage';
import VendorPage from './pages/VendorPage';
import VendorsPage from './pages/VendorsPage';
import LeftMenu from './menus/LeftMenu';
import TopMenu from './menus/TopMenu';

import './Main.scss';
import { openPopup } from '../actions/PopupActions';
import { dot } from '../lib/obj';

const STAGE = process.env.REACT_APP_STAGE || "local";

class Main extends React.Component {

  componentWillMount() {
    if (dot(this.props.login,"state") != "active") {
      this.props.openPopup({ name:"Login" });
    }
  }

  componentDidMount() {
    document.addEventListener('click', function(e) {
      var details = [...document.querySelectorAll('details')];
      if (!details.some(f => f.contains(e.target))) {
        details.forEach(f => f.removeAttribute('open'));
      } else {
        details.forEach(f => !f.contains(e.target) ? f.removeAttribute('open') : '');
      }
    });
  }

  render() {
    let login = this.props.login;
    let loggedIn = dot(login,"state") == "active";
    let pages = loggedIn ? this.loggedInUI() : <div/>;
    return (
      <Router>
        <div className="Frame" data-stage={STAGE}>
          <Notifier/>
          <Popups/>
          <div className="TopSpace"/>
          <TopMenu/>
          {pages}
        </div>
      </Router>
    );
  }

  /**
   * Helps render the logged in UI
  **/
  loggedInUI() {
    const app = this.props.app;
    return (
      <div className="loggedInUI">
        <LeftMenu expanded={dot(app,"menu.left.expand")}/>
        <div className="Pages">
          <Route exact path="/" component={HomePage} />
          <Route exact path="/dashboard" render={({match})=>{
            return <Dashboard key="dashboard" title="Dashboard"/>
          }} />
          <Route exact path="/builds" render={({match})=>{
            return <BuildsPage key="all" title="Builds"/>
          }} />
          <Route exact path="/builds/:id" render={({match})=>{
            return <BuildPage key={`viewBuild${match.params.id}`} {...match.params} back="Back"/>
          }} />
          <Route exact path="/collections" render={({match})=>{
            return <CollectionsPage key="all" title="Collections"/>
          }} />
          <Route exact path="/collections/:id" render={({match})=>{
            if (match.params.id == "new") {
              return  <CollectionPage key="newCollection" {...match.params} back="Cancel"/>
            } else {
              return  <CollectionPage key="viewCollection" {...match.params} back="Back"/>
            }
          }} />
          <Route exact path="/inventory" render={({match})=>{
            return <InventoryPage key="inventory" title="Inventory"/>
          }} />
          <Route exact path="/locations" render={({match})=>{
            return <LocationsPage key="all" title="Locations"/>
          }} />
          <Route exact path="/locations/:id" render={({match})=>{
            if (match.params.id == "new") {
              return  <LocationPage key="newLocation" {...match.params} back="Cancel"/>
            } else {
              return  <LocationPage key="viewLocation" {...match.params} back="Back"/>
            }
          }} />
          <Route exact path="/locations/:id/preview" render={({match})=>{
            return <LocationPreviewPage key="all" {...match.params} title="Location Preview" back="Back"/>
          }} />
          <Route exact path="/orders" render={({match})=>{
            return <OrdersPage key="all" title="Orders"/>
          }} />
          <Route exact path="/order-stats" render={({match})=>{
            return  <OrderStatsPage key="OrderStats" {...match.params} back="Back"/>
          }} />
          <Route exact path="/orders/:id" render={({match})=>{
            if (match.params.id == "new") {
              return  <OrderPage key="newOrder" {...match.params} back="Cancel"/>
            } else {
              return  <OrderPage key="viewOrder" {...match.params} back="Back"/>
            }
          }} />
          <Route exact path="/zeros" render={({match})=>{
            return <ResolutionsPage key="all" title="Zeros"/>
          }} />
          <Route exact path="/zeros/stats" render={({match})=>{
            return <ResolutionStatsPage key="all" title="Zero Stats" back="Back"/>
          }} />
          <Route exact path="/zeros/kpi" render={({match})=>{
            return <KPIPage key="all" title="Zeros KPI Stats" back="Back"/>
          }} />
          <Route exact path="/returns" render={({match})=>{
            return <ReturnsPage key="all" title="Returns"/>
          }} />
          <Route exact path="/returns/stats" render={({match})=>{
            return <ReturnStatsPage key="all" title="Return Stats" back="Back"/>
          }} />
          <Route exact path="/returns/kpi" render={({match})=>{
            return <ReturnsKPIPage key="all" title="Returns KPI Stats" back="Back"/>
          }} />
          <Route exact path="/stubs" render={({match})=>{
            return <StubsPage key="all" title="Stubs"/>
          }} />
          <Route exact path="/pos" render={({match})=>{
            return <POsPage key="all" title="POs"/>
          }} />
          <Route exact path="/pos/:id" render={({match})=>{
            if (match.params.id == "new") {
              return  <POPage key="newPO" {...match.params} back="Cancel"/>
            } else {
              return  <POPage key="viewPO" {...match.params} back="Back"/>
            }
          }} />
          <Route exact path="/products" render={({match})=>{
            return <ProductsPage key="products" title="Products"/>
          }} />
          <Route exact path="/products/:id" render={({match})=>{
            if (match.params.id == "new") {
              return  <ProductPage key="newProduct" {...match.params} back="Cancel"/>
            } else {
              return  <ProductPage key={`viewProduct${match.params.id}`} {...match.params} back="Back"/>
            }
          }} />
          <Route exact path="/transfers" render={({match})=>{
            return <TransfersPage key="transfers" title="Transfers"/>
          }} />
          <Route exact path="/transfers/:id" render={({match})=>{
            if (match.params.id == "new") {
              return  <TransferPage key="newTransfer" {...match.params} back="Cancel"/>
            } else {
              return  <TransferPage key="viewTransfer" {...match.params} back="Back"/>
            }
          }} />
          <Route exact path="/vendors" render={({match})=>{
            return <VendorsPage/>
          }} />
          <Route exact path="/vendors/:id" render={({match})=>{
            if (match.params.id == "new") {
              return  <VendorPage key="newVendor" {...match.params} back="Cancel"/>
            } else {
              return  <VendorPage key="viewVendor" {...match.params} back="Back"/>
            }
          }} />
        </div>
      </div>
    );
  }

}

const mapState = (state, props) => {
  return {
    app: state.app,
    inventory: state.inventory,
    login: dot(state.session,"login")
  }
};

const mapDispatch = (dispatch) => {
  return {
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(Main)
