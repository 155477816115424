import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import SelectorControl from '../controls/SelectorControl';

import { financial } from '../../lib/formats';
import { docForLoc } from '../../lib/inventory-util';
import { dot } from '../../lib/obj';
import { stateForDoc } from '../../lib/inventory-util';

import { listItems, setItemField } from '../../actions/InventoryActions';

import { COUNTRIES, countryForCode } from '../../constants/misc/Countries';

import './Form.css';
import './VendorForm.scss';

class VendorForm extends Form {

  // Edit the given field
  editField(field, value) {
    let opts = this.props.opts;
    this.props.setItemField({field:field, value:value, ...opts});
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.vendor,"data") || {};
    return (
      <div className="Form VendorForm" data-size={dot(this.state,"size")} data-layout={this.props.layout} >
        <Input className="Name" label="Name" value={dot(data,"name")} onChange={(value)=>{this.editField("name",value)}}/>
        <Input className="Website" label="Website" value={dot(data,"website")} onChange={(value)=>{this.editField("website",value)}}/>
        <Input className="POCFirst" label="POC First" value={dot(data,"pocFirstName")} onChange={(value)=>{this.editField("pocFirstName",value)}}/>
        <Input className="POCLast" label="POC Last" value={dot(data,"pocLastName")} onChange={(value)=>{this.editField("pocLastName",value)}}/>
        <Input className="Street1" label="Street1" value={dot(data,"address1")} onChange={(value)=>{this.editField("address1",value)}}/>
        <Input className="Street2" label="Street2" value={dot(data,"address2")} onChange={(value)=>{this.editField("address2",value)}}/>
        <Input className="City" label="City" value={dot(data,"addressCity")} onChange={(value)=>{this.editField("addressCity",value)}}/>
        <Input className="Province" label="Province" value={dot(data,"addressProvince")} onChange={(value)=>{this.editField("addressProvince",value)}}/>
        <Input className="Zip" label="Zipcode" value={dot(data,"addressZipcode")} onChange={(value)=>{this.editField("addressZipcode",value)}}/>
        <select className="Country" key={`country${data.version}`} data-hype={data.addressCountry || "unknown"} defaultValue={dot(data,"addressCountry")} onChange={(e)=>{this.editField("addressCountry",e.target.value)}}>
          <option key={`option`} value="unknown">Country</option>
          {COUNTRIES.map((v,i)=>{return <option key={`option${i}`} value={v.code}>{v.name}</option>})}
        </select>
        <Input className="Fax" label="Fax" value={dot(data,"pocFax")} onChange={(value)=>{this.editField("pocFax",value)}}/>
        <Input className="Phone" label="Phone" value={dot(data,"pocPhone")} onChange={(value)=>{this.editField("pocPhone",value)}}/>
        <Input className="Email" label="Email" value={dot(data,"pocEmail")} onChange={(value)=>{this.editField("pocEmail",value)}}/>
        <Input className="Description" label="Description" value={dot(data,"description")} onChange={(value)=>{this.editField("description",value)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let opts = props.opts || {}
  let vendor = stateForDoc(state.inventory,{type: "vendors",id: "new",...opts});
  return {
    opts: opts,
    inventory: state.inventory,
    vendor: vendor
  }
};


const mapDispatch = (dispatch) => {
  return {
    listItems: opts => dispatch(listItems(opts)),
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(VendorForm)
