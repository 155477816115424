import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Dropdown from '../elements/Dropdown';
import DropControl from '../controls/DropControl';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import SelectorControl from '../controls/SelectorControl';
import Separator from '../elements/Separator';
import PlainButton from '../elements/PlainButton';

import { financial, capitalize, abbr } from '../../lib/formats';
import { docForLoc } from '../../lib/inventory-util';
import { dot } from '../../lib/obj';

import { setItemField, setItemAction } from '../../actions/InventoryActions';

import { PAYMENT_TYPES } from '../../constants/inventory/Payment.js';

import './Form.css';
import './POPaymentForm.css';

class POPaymentForm extends Form {

  // Edit the given field
  editField(field, value) {
    // Handle to the set item field options
    let opts = {
      type:"pos",
      id: dot(this.props.poPayment,"data.poId"),
      childType: "payments",
      childId: dot(this.props.poPayment,"data.id"),
      field:field,
      value:value
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemField(opts);
  }

  onDeleteClick(evt) {
    // Handle to the set item field options
    let opts = {
      type:"pos",
      id: dot(this.props.poPayment,"data.poId"),
      childType: "payments",
      childId: dot(this.props.poPayment,"data.id"),
      action: "delete"
    };
    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemAction(opts);
  }

  onUndoClick(evt) {
    // Handle to the set item field options
    let opts = {
      type:"pos",
      id: dot(this.props.poPayment,"data.poId"),
      childType: "payments",
      childId: dot(this.props.poPayment,"data.id"),
      action: ""
    };

    // Check if editing inline
    if (this.props.listIndex !== undefined) {
      opts.index = this.props.listIndex;
    }
    this.props.setItemAction(opts);
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.poPayment,"data") || {};
    let paymentData = data.payment || {};

    // Get handle to the action to determine control
    let action = dot(this.props.poPayment,"action")
    let control = null;
    if (action == "delete") {
      control = <PlainButton className="Control" onClick={(evt)=>{this.onUndoClick(evt)}}>&#8635;</PlainButton>
    } else {
      control = <PlainButton className="Control" onClick={(evt)=>{this.onDeleteClick(evt)}}>&#x2715;</PlainButton>
    }

    return (
      <div className="Form POPaymentForm" data-action={action} ref="main" data-size={dot(this.state,"size")}>
        <DropControl className="Type" layout="field" label="Type" value={capitalize(paymentData.type)} options={PAYMENT_TYPES} onChange={(value)=>{this.editField("payment.type",value.type)}}/>
        <Input className="PaidAt" label="Paid At" type="date" value={paymentData.paidAt} onChange={(val)=>{this.editField("payment.paidAt",val)}}/>
        <Input className="Amount" label="Amount" type="number" value={paymentData.amount} onChange={(val)=>{this.editField("payment.amount",val)}}/>
        {control}
      </div>
    );
  }
}

const mapState = (state, props) => {
  let paymentId = dot(props.poPayment,"data.paymentId");
  let payment = paymentId ? (dot(state.inventory,["payments",paymentId]) || {}) : {};
  return {
    inventory: state.inventory,
    poPayment: props.poPayment,
    payment: payment
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts=> dispatch(setItemField(opts)),
    setItemAction: opts => dispatch(setItemAction(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(POPaymentForm)
