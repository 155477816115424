import React from 'react';
import Container from './Container';
import CheckBoxVal from './CheckBoxVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CheckBoxOption.css';

class CheckBoxOption extends Container {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`CheckBoxOption Container ${className}`} data-selected={this.props.selected} onClick={this.props.onClick}>
        <CheckBoxVal className="Check" selected={this.props.selected} radio={this.props.radio}/>
        <div className="Entry">{this.props.children}</div>
      </div>
    );
  }
}

export default CheckBoxOption;
