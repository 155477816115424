import React from 'react';
import Container from './Container';
import './StrongVal.css';

class StrongVal extends Container {
  render() {
    let className = this.props.className || "";
    return (
      <div className={`StrongVal Container ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default StrongVal;
