import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './SheetLayout.scss';

import { _ } from '../../lib/underscore';

class SheetLayout extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let children = this.props.children;
    children = Array.isArray(children) ? _.flatten(children) : [children];
    let className = this.props.className || "";
    return (
      <div className={`SheetLayout Layout ${className}`}
      data-values={children.length}
      onClick={(evt)=>{this.onClick(evt)}}
      data-clickable={this.props.onClick != undefined}
      data-working={this.props.working}
      data-creating={this.props.creating}>
        {children.map((child, index)=>{
          return (
            <div className={`Value Value${index+1} ${child.props ? child.props.className : ""}`} key={`value${index}`}>
              {child}
            </div>
          )
        })}
      </div>
    );
  }
}

export default withRouter(SheetLayout);
