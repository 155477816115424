import React from 'react';
import { mappify } from '../../lib/obj'

export const TRANSFER_TYPES = [
  { value:"po",          name: "PO"},
  { value:"move",        name: "Move" },
  { value:"stock",       name: "Stock" },
  { value:"fulfillment", name: "Fulfillment" },
  { value:"return",      name: "Return" },
  { value:"discard",     name: "Discard" },
];

export const TRANSFER_STATUSES = [
  { value:"new",       name: "New"},
  { value:"sent",      name: "Sent" },
  { value:"received",  name: "Received" },
  { value:"canceled",  name: "Canceled" },
];

export const TRANSFER_SEARCH = {
  type: "transfers",
  limit: 100,
  links: ["po","source","destination"],
  term: {
    placeholder: "Search",
    field: "name"
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "type",
      options: TRANSFER_TYPES
    },
    {
      label: "Status",
      type: "check",
      field: "status",
      options: TRANSFER_STATUSES
    }
  ],
  sort: [
    { field: "name",        name: "Name (A-Z)",     direction:"asc"},
    { field: "name",        name: "Name (Z-A)",     direction:"desc"},
    { field: "createdAt",   name: "Created (1-9)",  direction:"asc"},
    { field: "createdAt",   name: "Created (9-1)",  direction:"desc", selected: true},
  ]
}

export const TRANSFER_TYPE_MAP = mappify(TRANSFER_TYPES,(i)=>{return i.value});
export const TRANSFER_STATUS_MAP = mappify(TRANSFER_STATUSES,(i)=>{return i.value});
