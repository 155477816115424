import * as ACTIONS from './ActionTypes';
import { awsPostS3File } from '../apis/AWS';
import { dot } from '../lib/obj'

export const postFile = (opts={}) => {
  return ((dispatch, getState) => {

    // Dispatch action
    dispatch({
      type: ACTIONS.POST_BUCKET_FILE,
      opts: opts
    });

    // Get the info
    let file = opts.file || {};
    let prereq = opts.prereq || {};
    let path = prereq.path || "misc/files";

    // Prepare the formdata for the upload
    var body = new FormData();
    body.append("Content-Type", file.type);
    body.append("key",`${path}/${file.name}`);
    body.append("policy", dot(prereq,"fields.Policy"));
    body.append("x-amz-algorithm", dot(prereq,"fields.X-Amz-Algorithm"));
    body.append("x-amz-credential", dot(prereq,"fields.X-Amz-Credential"));
    body.append("x-amz-date", dot(prereq,"fields.X-Amz-Date"));
    body.append("x-amz-security-token", dot(prereq,"fields.X-Amz-Security-Token"));
    body.append("x-amz-signature", dot(prereq,"fields.X-Amz-Signature"));
    body.append("file",file);

    console.log("POSTING BODY: ",body);

    // Get the data from the server
    return awsPostS3File({url:prereq.url,
      body: body
    })
    .then((res)=>{
      dispatch({
        type: ACTIONS.POST_BUCKET_FILE_SUCCESS,
        opts: opts,
        docs: res.docs
      });
      return res;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.POST_BUCKET_FILE_ERROR,
        opts: opts,
        error: err
      });
    });
  });
}
