import { app } from './AppReducer';
import { forms } from './FormsReducer';
import { inventory } from './InventoryReducer';
import { menus } from './MenusReducer';
import { popups } from './PopupsReducer';
import { session } from './SessionReducer';


/**
 * Prepares border quadrant style based percentage (0-100)
 */
const root = function(state = {}, action = {}) {
  // Process new State
  let newState = {
    app: app(state.app, action),
    forms: forms(state.forms, action),
    inventory: inventory(state.inventory, action),
    menus: menus(state.menus, action),
    popups: popups(state.popups, action),
    session: session(state.session, action)
  }
  console.log("Action:",action)
  console.log("State:",newState);
  return newState;
};

export default root;
