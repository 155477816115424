import React from 'react';
import Area from './Area';
import StrongVal from '../containers/StrongVal';
import WeakVal from '../containers/WeakVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LoadableArea.scss';



class LoadableArea extends React.Component {
  render() {
    let className = this.props.className || "";

    let empty;
    if (!this.props.children || this.props.children.length == 0 || this.props.empty) {
      empty = <div className="Empty">
        <div className="Icon"><FontAwesomeIcon icon="search" /></div>
        <StrongVal>No items found!</StrongVal>
        <div><WeakVal>Try different search options.</WeakVal></div>
      </div>;
    }

    return (
      <div className={`LoadableArea Area ${className}`} data-locked={this.props.locked} data-working={this.props.working}>
        {this.props.children}
        {empty}
        <div className="Indicator"></div>
      </div>
    );
  }
}

export default LoadableArea;
