import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';

import { dot } from '../../lib/obj';
import { setItemField } from '../../actions/InventoryActions';

import './Form.css';
import './CollectionForm.css';

class CollectionForm extends Form {

  // Edit the given field
  editField(field, value) {
    let id = dot(this.props.collection,"data.id");
    if (id) {
      this.props.setItemField({type:"collections",id:id,field:field,value:value});
    }
    else {
      this.props.setItemField({type:"collections",id:"new",field:field,value:value});
    }
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.collection,"data") || {};

    return (
      <div className="Form CollectionForm" ref="main" data-size={dot(this.state,"size")}>
        <Input className="Name" label="Name" value={dot(data,"name")} onChange={(value)=>{this.editField("name",value)}}/>
        <Input className="Code" label="Code" value={dot(data,"code")} onChange={(value)=>{this.editField("code",value)}}/>
        <Input className="Description" label="Description" value={dot(data,"description")} onChange={(value)=>{this.editField("description",value)}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts=> dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(CollectionForm)
