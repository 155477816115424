import * as ACTIONS from '../actions/ActionTypes';
import { dot, deepCopy } from '../lib/obj';

// Helps get a deep menu copy
const deepMenu = function(newState, name){
  let menu = newState[name] ? deepCopy(newState[name]) : {};
  newState[name] = menu;
  return menu;
};


/**
 * Prepares border quadrant style based percentage (0-100)
 */
export const menus = function(state = {}, action = {}) {
  switch (action.type) {
    case ACTIONS.EXPAND_MENU: {
      let newState = {...state};
      let menu = deepMenu(newState, action.name);
      dot(menu,"expanded", true);
      return newState;
    }
    case ACTIONS.COLLAPSE_MENU: {
      let newState = {...state};
      let menu = deepMenu(newState, action.name);
      dot(menu,"expanded", false);
      return newState;
    }
    default:
      return state;
  }
};
