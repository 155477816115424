import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Separator from '../elements/Separator';
import VendorForm from '../forms/VendorForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import HeaderLine from '../layouts/HeaderLine';
import LoadableArea from '../areas/LoadableArea';
import ItemSearchSection from '../sections/ItemSearchSection';

import HeaderLayout from '../layouts/HeaderLayout';
import InfoLayout from '../layouts/InfoLayout';
import OptionLayout from '../layouts/OptionLayout';

import ItemLine from '../layouts/ItemLine';
import NameVal from '../containers/NameVal';
import TypeVal from '../containers/TypeVal';
import SubVal from '../containers/SubVal';
import QtyVal from '../containers/QtyVal';
import MoneyVal from '../containers/MoneyVal';
import ActionVal from '../containers/ActionVal';
import TitleVal from '../containers/TitleVal';
import ProductLayout from '../layouts/ProductLayout';
import PaddedArea from '../areas/PaddedArea';
import FooterArea from '../areas/FooterArea';

import { financial } from '../../lib/formats';
import { docForLoc } from '../../lib/inventory-util';
import { dot, hasKeys } from '../../lib/obj';

import { loadItem, pushItems, deleteItem, purgeItem, setItemAction, meldItems, startDraftItem } from '../../actions/InventoryActions';
import { openPopup } from '../../actions/PopupActions';

import { PRODUCT_SEARCH, PRODUCT_TYPES } from '../../constants/inventory/Product'

import './Page.css';
import './VendorPage.css';

const VENDOR_ITEM_SEARCH = {
  type: "ventor-items",
  links: ["vendor","item","image"],
  limit: 50,
  term: {
    label: "Vendors",
    placeholder: "Search",
    fields: ["item.name","item.sku"],
    keymap: { // TODO - Fernando Mora: Implement this functionality
      sku: "item.sku",
      name: "item.name"
    }
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "item.type",
      options: PRODUCT_TYPES
    }
  ]
}

class VendorPage extends Page {

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      this.props.loadItem({type:"vendors",id:id});
      //this.loadVendorItems();
    }
  }

  onSave (evt, history) {
    let vendor = this.props.vendor;
    this.props.pushItems([{type:"vendors",
      id: this.props.id,
      docs: {vendor:vendor},
      action: vendor.action
    }]).then((res)=>{
      let resVendor = dot(res,"0.vendor");
      let id = dot(resVendor,"data.id") || this.props.id;
      if (dot(resVendor,"data.version") == 1) {
        this.props.purgeItem({ type: "vendors", id: "new" });
        history.replace(`/vendors/${id}`);
      }
    });
  }

  needsSave() {
    // Check po for sets
    if( hasKeys(dot(this.props.vendor,"sets")) ) {
      return true;
    }
    return false;
  }

  onCancel(evt,history) {
    history.goBack();
  }

  onDelete(evt,history) {
    this.props.openPopup({name:"Confirm",
      props:{
        title: "Delete Vendor",
        message: "Are you sure you want to delete this Vendor? You will NOT be able to undo this action.",
        onOk:()=>{
          this.props.deleteItem({type:"vendors", id: this.props.id}).then(()=>{
            history.replace("/vendors");
          });
        },
        okTheme: "red",
        okLabel: "Delete Vendor"
    }});
  }

  loadVendorItems() {
    let id = this.props.id;
    this.props.meldItems({
      type: "vendor-items",
      typeAlias: "vendors",
      idAlias: id,
      childTypeAlias: "items",
      query: {
        links: ["vendor","item","image"],
        where:[{vendorId:{eq: id}}]
      }
    });
  }

  onAddItem() {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select Products to Add",
        type: "products",
        typeAlias: "vendor-products-search",
        limit: 25,
        query: PRODUCT_SEARCH,
        renderItem: (item, index, optionProps)=>{
          let type = dot(item,"data.type");
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        onOk:(items)=>{
          this._addItems(items)
        }
      }
    });
  }

  _addItems(items) {
    let existing = dot(this.props.vendor, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft items for each of the new items
    items.forEach((item, index)=>{
      let data = item.data || {};
      this.props.startDraftItem({
        type:"vendors",
        id: id,
        childType: "items",
        index: count + index,
        data: {
          vendorId: id,
          itemId: data.id,
          item: item
        }
      });
    });

    let createItems = items.map((item, index)=>{
      let data = item.data || {};
      return {
        type:"vendor-items",
        typeAlias: "vendors",
        idAlias: this.props.id,
        childTypeAlias: "items",
        index: count + index,
        docs: {
          vendorItem: {data:{vendorId: id,itemId:data.id}}
        },
        action: "create",
        query: {links: "vendor,item,image", promptError:true}
      };
    });

    this.props.pushItems(createItems).then((res)=>{console.log("DONE!");});
  }

  onEditVendorItem(vendorItem, index) {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props:{
        title: "Edit Vendor Item",
        opts: {
          type: "vendor-items",
          id: dot(vendorItem,"data.id"),
          typeAlias: "vendors",
          idAlias: id,
          childTypeAlias: "items",
          index: index,
          product: dot(vendorItem,"data.item"),
          query: {links: "item,image", promptError:true}
        },
        onDelete: () => {
          this.props.purgeItem({type:"vendors", id: id, childType: "items", index: index});
        }
      }
    });
  }

  onViewItem(item, history, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (item && item.data.itemId) {
      history.push(`/products/${item.data.itemId}`);
    }
  }


  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";

    // Check if we need to save
    let needsSave = this.needsSave();

    // Check to see if sending
    let vendor = this.props.vendor;
    let status = dot(vendor, "status");
    let isSending = status == "saving" || status == "creating";

    // Setup buttons
    let cancelButton = null;
    let saveButton = null;
    if (isNew) {
      cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}}>
        {isSending ? "Creating" : "Create"}
      </RectButton>;
    }
    else {
      cancelButton = <RectButton theme="red" onClick={(evt,history)=>{this.onDelete(evt,history)}}>Delete</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSave(evt, history)}} lock={!needsSave}>
        {isSending ? "Saving" : "Save"}
      </RectButton>;
    }

    // Vendor items list
    let productConditions = id ? [{vendorId:{eq:id}}] : undefined;
    let productList = <ItemSearchSection type="vendor-items" typeAlias="vendors" idAlias={id} childTypeAlias="items" query={VENDOR_ITEM_SEARCH} reload={true} conditions={productConditions}
      hat={<HeaderLine><div>Products</div></HeaderLine>}
      renderItem={(item, index)=>{
        let data = item.data || {};
        let unitCost = data.unitCost || 0;
        let product = data.item || {};
        let leadTime = data.leadTime;
        return <OptionLayout key={`item${data.id}I${index}`} className="RowLine" image={item.data.image || dot(product,"data.image") || {}} space={{image:true}} onClick={()=>{this.onEditVendorItem(item,index)}} status={item.status}>
          <InfoLayout>
            <ProductLayout key="product" product={product} onClick={(evt,history)=>this.onViewItem(item,history,evt)}/>
            <SubVal key="leadTime">{leadTime ? `${leadTime} days` : "-"}</SubVal>
            <MoneyVal key="price" expand="true">{unitCost}</MoneyVal>
          </InfoLayout>
        </OptionLayout>
        }}
      footer={<FooterArea>
        <PlainButton className="OK" theme="blue" onClick={(evt,history)=>{this.onAddItem(evt, history)}}>Add items</PlainButton>
      </FooterArea>}
    >
      <OptionLayout className="TitleLine" space={{image:true}}>
        <HeaderLayout>
          <div>Name</div>
          <div>Lead Time</div>
          <div>Price</div>
        </HeaderLayout>
      </OptionLayout>
    </ItemSearchSection>

    return (
      <div className="Page VendorPage">
        <Prompt
          when={needsSave}
          message='Are you sure you want to leave? You have unsaved changes.'
        />
        <PageHeaderOutline title={"View Vendor"} aside={saveButton} top={this._backButton()}></PageHeaderOutline>
        <Section key="details" shout="Details">
          <HeaderLine><div>Details</div></HeaderLine>
          <LoadableArea working={status == "loading"}>
            <VendorForm opts={{type:"vendors",id:id}}/>
          </LoadableArea>
        </Section>
        {productList}
        <Separator/>
        <Row align="space-between">
          {cancelButton}
          {saveButton}
        </Row>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let vendor = dot(state.inventory,["vendors",props.id]) || {};
  return {
    vendor: vendor,
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    loadItem: opts => dispatch(loadItem(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    pushItems: arr => dispatch(pushItems(arr)),
    meldItems: arr => dispatch(meldItems(arr)),
    setItemAction: opts => dispatch(setItemAction(opts)),
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(VendorPage)
