import React from 'react';
import { connect } from 'react-redux'
import Widget from './Widget';
import { dot } from '../../lib/obj';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { clearAppNotification, keepAppNotification } from '../../actions/AppActions';

import './Notifier.scss';

class Notifier extends Widget {

  onClose(msg, e) {
    e.stopPropagation();
    this.props.clearAppNotification({id:msg.id});
  }

  onClick(msg, e) {
    e.stopPropagation();
    this.props.keepAppNotification({id:msg.id});
  }

  render() {
    const genNotification = (msg, index)=>{
      let deets;
      if (msg.details) {
        deets = <details>
          <summary>
            {msg.message}
          </summary>
          <p>{msg.details}</p>
        </details>
      }
      else {
        deets = msg.message;
      }
      return <div className="Notification" key={`msg${msg.id}`} data-kind={msg.kind} data-hidden={msg.hide} onClick={(e)=>this.onClick(msg, e)}>
          <div className="Close" onClick={(e)=>this.onClose(msg, e)}><FontAwesomeIcon icon={["far","times"]} /></div>
          {deets}
        </div>
    }

    let notifications = this.props.notifications;
    return (
      <div className="Notifier">
        {notifications.map(genNotification)}
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    notifications: dot(state,"app.notifications") || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    clearAppNotification: (opts) => dispatch(clearAppNotification(opts)),
    keepAppNotification: (opts) => dispatch(keepAppNotification(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(Notifier);
