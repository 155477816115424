import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import LocationForm from '../forms/LocationForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dot, reg, mappify, deepCopy } from '../../lib/obj';
import { mDownload } from '../../lib/file';
import { openPopup } from '../../actions/PopupActions';
import { loadItem, listItems, pushItems, deleteItem, startDraftItem, purgeDraftItem, setItemField, setItemQuery } from '../../actions/InventoryActions';

import './Page.css';
import './LocationPreviewPage.css';

import { ACTION_SEARCH } from '../../constants/inventory/Action';

const LOCATION_ACTION_SEARCH = deepCopy(ACTION_SEARCH);
LOCATION_ACTION_SEARCH.links.push("locationItem");

class LocationPreviewPage extends Page {

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      this.props.loadItem({type:"locations",id:id});
      this.props.listItems({
        type:"locations",
        id:id,
        childType:"items",
        query:{
          "links": "item",
          "order": "item.name",
          "where-deleted-neq": 1
        }
      });
    }
  }

  onShowLog(title) {
    let id = this.props.id;
    let itemsById = this.props.itemsById;
    this.props.openPopup({
      name:"ActionLog",
      props:{
        title: title || "Return Actions Log",
        type: "actions",
        typeAlias: "locations",
        idAlias: id,
        childAlias: "actions",
        limit: 100,
        ignoreDelete: true,
        query: LOCATION_ACTION_SEARCH,
        conditions: [{subjectType: {eq:"locationItem"}},{"locationItem.locationId":{eq:id}}],
        renderActionSubject: (action) => {
          let li = itemsById[action.data.subjectId];
          let product = li && li.data ? dot(this.props.inventory,["products",li.data.itemId]) : null;
          return <React.Fragment>
            <div data-hype="whisper">{dot(product,"data.sku")}</div>
            <div>{dot(product,"data.name")}</div>
          </React.Fragment>
        }
      }
    });
  }

  onPrint(){
    console.log( ReactDOM.findDOMNode(this.refs.root).style);

    let elem = ReactDOM.findDOMNode(this.refs.root);
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title>'+document.title+'</title>');
    mywindow.document.write(`
      <style type="text/css">

        body {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
        }

        .LocationPreviewPage {
          max-width: 934px;
        }

        .LocationPreviewPage .HeaderTop {
          display: none;
        }

        .LocationPreviewPage .HeaderMiddle .Title {
          font-size: 3em;
        }

        .LocationPreviewPage .HeaderBottom {
          display: none;
        }

        .LocationPreviewPage > .Section .Groups {
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          vertical-align:top
        }

        .LocationPreviewPage > .Section .Group {
          display: block;
          position: relative;
          margin: 1em;
          border: 1px solid black;
        }

        .LocationPreviewPage > .Section .Group .Name {
          width: 100%;
          font-weight: bold;
          padding: 0.25em 1em;
          /*background-color: grey;*/
          /*color: white;*/
          box-sizing: border-box;
          border-bottom: 1px solid black;
        }

        .LocationPreviewPage > .Section .Group .Items {
          display: block;
          width: 100%;
        }

        .LocationPreviewPage > .Section .Group .Items .Item {
          display: grid;
          width: 100%;
          grid-template-columns: 4fr 1fr;
          grid-template: "sku quanity";
          grid-column-gap: 1em;
          padding: 0.2em 1em;
          box-sizing: border-box;
          font-size: 0.8em;
        }

        .LocationPreviewPage > .Section .Group .Items .Item .Sku {
          grid-area: sku;
          display: inline-block;
        }
        .LocationPreviewPage > .Section .Group .Items .Item .Quantity {
          grid-area: quanity;
          display: inline-block;
          text-align: right;
        }
      </style>`);
    mywindow.document.write('</head><body class="LocationPreviewPage" >');
    mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(elem.innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }

  onDownload() {
    let date = new Date();
    // Lets group items
    let loc = this.props.location;
    let items = dot(loc, "items.list.docs") || [];
    let groupsByName = {};

    let csvContent = items.map((item, index)=>{
      let itemData = item.data || {};
      let product = itemData.product || dot(this.props.inventory,["products",itemData.itemId]);
      let productData = product && product.data ? product.data : {};
      return '"' + [
        loc.data.name,
        productData.name,
        productData.sku,
        itemData.quantity,
        productData.depletionRate
      ].join('","') + '"';
    });
    csvContent.unshift('"location","product","sku","quantity","day rate"');
    let fname = `${loc.data.name} ${date.toDateString()} ${date.toTimeString().split(" ")[0]}.csv`;
    mDownload(csvContent.join("\n"),fname,'text/csv;encoding:utf-8');
  }

  onItemClick(item, index, evt) {
    let product = item.data.product || dot(this.props.inventory,["products",item.data.itemId]);
    this.props.startDraftItem({
      type: "locations",
      id: dot(item,"data.locationId"),
      childType: "items",
      childTypeAlias: "draftItems",
      childId: dot(item,"data.id"),
      data: item.data
    });

    let imageId = dot(product,"data.imageId");
    if (imageId) {
      this.props.loadItem({type:"files",id:imageId});
    }

    this.props.openPopup({
      name:"LocationItem",
      props:{
        locationItem: item,
        product: product,
        childTypeAlias: "draftItems",
        onSent: (locationItem)=>{
          if (locationItem) {
            this.props.setItemField({
              type: "locations",
              id: item.data.locationId,
              childTypeAlias: "items",
              index: index,
              field: "quantity",
              value: dot(locationItem,'data.quantity')
            });
          }
        }
      }
    });
  }

  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";

    // Handle to the location data
    let data = this.props.location && this.props.location.data ? this.props.location.data : {};

    // Lets group items
    let items = dot(this.props.location, "items.list.docs") || [];
    let groupsByName = {};
    items.forEach((item, index)=>{
      let itemData = item.data;
      let product = itemData.item || dot(this.props.inventory,["products",itemData.itemId]);
      let productData = product && product.data ? product.data : {};

      // Get the base variant names from the name
      let parts = `${productData.name || "Unknown"}`.split(" - ");

      let base = parts.length > 2 ? `${parts[0]} - ${parts[1]}` : parts[0];
      let variant = parts[parts.length-1];

      // Group by the base name
      reg(groupsByName, base, []).push({
        sku: (productData.sku),
        variant: parts.length > 1 ? variant : "-",
        quantity: itemData.quantity,
        locationItem: item,
        index: index
      });
    });

    // Sort the group names
    let groupNames = Object.keys(groupsByName).sort();

    // Get handle to the location Items
    return (
      <div ref="root" className="Page LocationPreviewPage">
        <PageHeaderOutline title={data.name} top={this._backButton()}>
          <PlainButton theme="blue" onClick={(evt,history)=>{this.onPrint(evt,history)}}><FontAwesomeIcon icon="print" /> Print</PlainButton>
          <PlainButton theme="blue" onClick={(evt,history)=>{this.onDownload(evt,history)}}><FontAwesomeIcon icon="download" /> CSV</PlainButton>
          <Link theme="blue" to="#" onClick={()=>{this.onShowLog()}}><FontAwesomeIcon icon={["far","square-list"]} /> Log</Link>
        </PageHeaderOutline>
        <Section key="items" shout="Stock" theme="clear">
          <div className="Groups">
            {groupNames.map((groupName, index)=>{
              let name = groupName;
              let items = groupsByName[groupName];
              return <div className="Group" key={`group${index}`}>
                <div className="Name">{groupName}</div>
                <div className="Items">
                  {items.map((item, itemIndex)=>{
                    return  <div className="Item" key={`item${itemIndex}`} onClick={(evt)=>this.onItemClick(item.locationItem, item.index, evt)}>
                      <div className="Sku">{item.sku || item.variant}</div>
                      <div className="Quantity">{item.quantity}</div>
                    </div>
                  })}
                </div>
              </div>
            })}
          </div>
        </Section>
        <Separator/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let location = dot(state.inventory,["locations",props.id]);
  let items = dot(location, "items.list.docs") || [];
  let itemsById = mappify(items,(i)=>dot(i,"data.id"));
  return {
    inventory: state.inventory,
    location: location,
    itemsById: itemsById
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemField: opts => dispatch(setItemField(opts)),
    loadItem: opts => dispatch(loadItem(opts)),
    listItems: opts => dispatch(listItems(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LocationPreviewPage)
