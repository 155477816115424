import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mappify } from '../../lib/obj';

export const LEFT_MENU = [
  {
    icon: <FontAwesomeIcon icon="home" />,
    label: "Home",
    url: "/"
  },
  {
    icon: <FontAwesomeIcon icon="tag" />,
    label: "Products",
    url: "/products",
    items: [
      {
        label: "All Products",
        url: "/products"
      },
      {
        label: "Inventory",
        url: "/inventory"
      },
      {
        label: "Collections",
        url: "/collections"
      }
    ]
  },
  {
    icon: <FontAwesomeIcon icon="globe-americas" />,
    label: "Locations",
    url: "/locations"
  },
  {
    icon: <FontAwesomeIcon icon="truck" />,
    label: "Transfers",
    url: "/transfers"
  },
  {
    label: "Production",
    header: true
  },
  {
    icon: <FontAwesomeIcon icon="money-check-alt" />,
    label: "POs",
    url: "/pos",
  },
  {
    icon: <FontAwesomeIcon icon="screwdriver-wrench" />,
    label: "Builds",
    url: "/builds"
  },
  {
    icon: <FontAwesomeIcon icon="industry-alt" />,
    label: "Vendors",
    url: "/vendors"
  },
  {
    label: "Support",
    header: true
  },
  {
    icon: <FontAwesomeIcon icon="shopping-cart" />,
    label: "Orders",
    url: "/orders",
    items: [
      {
        label: "All Orders",
        url: "/orders"
      },
      {
        label: "Stubs",
        url: "/stubs"
      }
    ]
  },
  {
    icon: <FontAwesomeIcon icon="wine-glass-crack" />,
    label: "Returns",
    url: "/returns"
  },
  {
    icon: <FontAwesomeIcon icon="bandage" style={{transform: "rotate(-30deg)"}}/>,
    label: "Zeros",
    url: "/zeros"
  },
];
