import React from 'react';
import Element from './Element';
import './Row.css';

class Row extends Element {
  render() {
    let children = this.props.children;
    children = Array.isArray(children) ? children : [children];

    // Align within row col
    var style = {textAlign: this.props.align || "inherit"};

    return (
      <div className="Row" ref="root" data-align={this.props.align}>
        {children.map((child, index)=>{
          return (
            <div className="Col" key={`col${index}`} style={style}>
              {child}
            </div>
          )
        })}
      </div>
    );
  }
}

export default Row;
