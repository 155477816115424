import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import ProductLayout from '../layouts/ProductLayout';
import TableSearchSection from '../wrappers/TableSearchSection';

import { LOCATION_SEARCH } from '../../constants/inventory/Location';
import { COUNTRIES } from '../../constants/misc/Countries';
import { dot, deepCopy } from '../../lib/obj';

import './Page.css';
import './LocationsPage.scss';

export const USER_VIEWS = [
  { value:"view",  name: "View",  layout: "Read only view"},
  { value:"edit",  name: "Edit",  desc: "Edit products view"}
];

class LocationsPage extends Page {

  onView(evt, history, doc) {
    if (doc) {
      history.push(`/locations/${dot(doc,"data.id")}`);
    }
  }

  render() {
    let view = this.state.view || USER_VIEWS[0].value;

    // Get the docs
    return (
      <div className="Page LocationsPage">
        <PageHeaderOutline title={this.props.title || "Products"} top={this._backButton()}>
          <select className="ViewSelector" defaultValue={view} onChange={(e)=>{this.setState({view:e.target.value})}}>
            {USER_VIEWS.map((v,i)=>{return <option key={`view${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </PageHeaderOutline>
        <TableSearchSection
        type="locations"
        query={deepCopy(LOCATION_SEARCH)}
        readonly={view=="view"}
        controllable="true"
        layout={[
          {title:"ID",      field:"id",           type: "action", onClick:(e,h,d)=>{this.onView(e,h,d)}},
          {title:"Name",    field:"name",         type: "string", onClick:(e,h,d)=>{this.onView(e,h,d)}},
          {title:"Address", field:"address1",     type: "string"},
          {title:"City",    field:"addressCity",     type: "string"},
          {title:"State",   field:"addressProvince", type: "string"},
          {title:"Country", field:"addressCountry",  type: "dropdown", options: COUNTRIES },
        ]}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = dot(state.inventory,"products.list") || {};
  return {
    list: list,
    products: list.docs || []
  }
};

const mapDispatch = (dispatch) => { return {} };

export default connect(
  mapState,
  mapDispatch
)(LocationsPage)
