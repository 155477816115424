import React from 'react';
import ReactDOM from 'react-dom';

class Form extends React.Component {
  render() {
    return (
      <div className="Form">{this.props.children}</div>
    );
  }
}

export default Form;
