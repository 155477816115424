import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import DateVal from '../containers/DateVal';
import MoneyVal from '../containers/MoneyVal';
import QtyVal from '../containers/QtyVal';
import StateVal from '../containers/StateVal';
import TypeVal from '../containers/TypeVal';
import NameVal from '../containers/NameVal';
import SubVal from '../containers/SubVal';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import ItemSearchSection from '../sections/ItemSearchSection';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';

import { VENDOR_SEARCH } from '../../constants/inventory/Vendor'
import { startDraftItem, purgeDraftItem } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';
import { capitalize } from '../../lib/formats';

import './Page.css';
import './VendorsPage.css';

class VendorsPage extends Page {

  onAdd(evt, history) {
    history.push("/vendors/new");
  }

  onView(evt, history, index) {
    let data = dot(this.props.vendors, [index,'data']);
    if (data) {
      history.push(`/vendors/${data.id}`);
    }
  }

  render() {
    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add Vendor</RectButton>;

    // Get the docs
    const vendors = this.props.vendors || [];
    const status = this.props.status;
    return (
      <div className="Page VendorsPage">
        <PageHeaderOutline title="Vendors" aside={addButton} top={this._backButton()}/>
        <ItemSearchSection type="vendors" query={VENDOR_SEARCH} limit={100} renderItem={(item, index)=>{
          let country = dot(item,"data.addressCountry");
          let province = dot(item,"data.addressProvince");
          let address = country && province ? `${province}, ${country}` : country || province || "-";
          return <ItemLine key={`item${index}`} onClick={(evt,history)=>this.onView(evt,history,index)}>
            <NameVal>{dot(item,"data.name")}</NameVal>
            <SubVal>{capitalize(dot(item,"data.pocFirstName"))} {capitalize(dot(item,"data.pocLastName"))}</SubVal>
            <SubVal>{address}</SubVal>
            <DateVal>{dot(item,"data.createdAt")}</DateVal>
          </ItemLine>
        }}>
          <div>Name</div>
          <div>POC</div>
          <div>Address</div>
          <div>Created</div>
        </ItemSearchSection>
        <QueryPaginatePresentation type="vendors"/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = dot(state.inventory,"vendors.list") || {};
  return {
    list: list,
    status: list.status,
    vendors: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(VendorsPage)
