import React from 'react';
import { mappify } from '../../lib/obj';
import { PRODUCT_TYPES } from './Product';


export const COLLECTION_SEARCH = {
  type: "collections",
  limit: 100,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["name"]
  }
}

export const COLLECTION_ITEM_SEARCH = {
  type: "collection-items",
  links: ["item","image"],
  limit: 50,
  term: {
    label: "Products",
    placeholder: "Search",
    fields: ["item.name","item.sku"],
    keymap: { // TODO - Fernando Mora: Implement this functionality
      sku: "item.sku",
      name: "item.name"
    }
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "item.type",
      options: PRODUCT_TYPES
    }
  ],
  sort: [
    { field: "createdAt",        name: "Created (Asc)",   direction:"asc"},
    { field: "createdAt",        name: "Created (Desc)",  direction:"desc", selected: true},
    { field: "item.name",        name: "Name (A-Z)",      direction:"asc"},
    { field: "item.name",        name: "Name (Z-A)",      direction:"desc"},
    { field: "item.sku",         name: "SKU (A-Z)",       direction:"asc"},
    { field: "item.sku",         name: "SKU (Z-A)",       direction:"desc"},
    { field: "item.type",        name: "Type (A-Z)",      direction:"asc"},
    { field: "item.type",        name: "Type (Z-A)",      direction:"desc"}
  ]
}
