import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import Dropdown from '../elements/Dropdown';
import DropControl from '../controls/DropControl';
import PlainButton from '../elements/PlainButton';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import SelectorControl from '../controls/SelectorControl';
import DateControl from '../controls/DateControl';
import Separator from '../elements/Separator';
import NameVal from '../containers/NameVal';
import SubVal from '../containers/SubVal';
import TypeVal from '../containers/TypeVal';
import StatusVal from '../containers/TypeVal';


import { financial, capitalize } from '../../lib/formats';
import { docForLoc } from '../../lib/inventory-util';
import { dot } from '../../lib/obj';
import { TRANSFER_TYPES, TRANSFER_STATUSES } from '../../constants/inventory/Transfer.js';
import { PO_SEARCH } from '../../constants/inventory/PO.js';
import { LOCATION_SEARCH } from '../../constants/inventory/Location.js';

import { listItems, setItemField, loadItemSuccess } from '../../actions/InventoryActions';
import { openPopup } from '../../actions/PopupActions';
import { stateForDoc } from '../../lib/inventory-util';

import './Form.css';
import './TransferForm.scss';

class TransferForm extends Form {

  // Edit the given field
  editField(field, value) {
    let id = dot(this.props.transfer,"data.id");
    if (id) {
      this.props.setItemField({type:"transfers",id:id,field:field,value:value});
    }
    else {
      this.props.setItemField({type:"transfers",id:"new",field:field,value:value});
    }
  }

  onLocationFocus(evt) {
    let locations = dot(this.props.inventory,"locations.list");
    if (dot(locations,"status") != 'success') {
      this.props.listItems({type:"locations"});
    }
  }

  onEditPO() {
    // this.props.openPopup({
    //   name:"Select",
    //   props:{
    //     type: "radio",
    //     search: PO_SEARCH,
    //     onOk:(items)=>{
    //       let selected = items[0];
    //       if (selected) {
    //         this.editField("poId",dot(selected,"data.id"));
    //         this.props.loadItemSuccess({type:"pos"},{pos:selected});
    //       }
    //     }
    //   }
    // });

    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a PO",
        type: "pos",
        limit: 25,
        radio: true,
        query: PO_SEARCH,
        renderItem: (item, index)=>{
          return [
            <NameVal>{dot(item,"data.name")}</NameVal>,
            <SubVal>{dot(item,"data.addressCountry")}</SubVal>
          ]
        },
        onOk:(items)=>{
          let selected = items[0];
          if (selected) {
            this.editField("poId",dot(selected,"data.id"));
            this.props.loadItemSuccess({type:"pos"},{pos:selected});
          }
        }
      }
    });
  }

  onEditLocation(field) {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Location",
        type: "locations",
        limit: 25,
        radio: true,
        query: LOCATION_SEARCH,
        renderItem: (item, index)=>{
          return [
            <NameVal>{dot(item,"data.name")}</NameVal>,
            <SubVal>{dot(item,"data.addressCountry")}</SubVal>
          ]
        },
        onOk:(items)=>{
          let selected = items[0];
          if (selected) {
            this.editField(field,dot(selected,"data.id"));
            this.props.loadItemSuccess({type:"locations"},{locations:selected});
          }
        }
      }
    });
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.transfer,"data") || {};
    let isNew = !data.version;

    // Prepare the choices for the item selector
    let locationChoices = ( dot(this.props.inventory,"locations.list.docs") || [] ).map((doc)=>{return doc.data;});
    let poName = dot(this.props.po,"data.name");
    let srcName = dot(this.props.source,"data.name");
    let destName = dot(this.props.destination,"data.name");

    // Get the
    let sentAt = data.sentAt ? data.sentAt.split(" ")[0] : null;
    let expectedAt = data.expectedAt ? data.expectedAt.split(" ")[0] : null;
    let receivedAt = data.receivedAt ? data.receivedAt.split(" ")[0] : null;

    // Get the cost
    let cost = data.cost || 0;

    let typeField = null;
    if (isNew) {
      typeField = <DropControl className="Type" layout="field" label="Type" value={capitalize(data.type)} options={TRANSFER_TYPES} onChange={(value)=>{this.editField("type",value.value)}}/>;
    }
    else {
      typeField = <Readout className="Type" label="Type">{capitalize(data.type)}</Readout>
    }

    let sourceSelect = null;
    if (data.type == "po") {
      sourceSelect = <Input className="Source" label="Source PO" value={poName} onFocus={(evt)=>{evt.target.blur();this.onEditPO();}}/>
    } else {
      //sourceSelect = <SelectorControl className="Source" label="Source" value={srcName} options={locationChoices} onFocus={(evt)=>{this.onLocationFocus(evt)}} onChange={(value)=>{this.editField("sourceId",value.id)}}/>
      sourceSelect = <Input className="Source" label="Source" value={srcName} onFocus={(evt)=>{evt.target.blur();this.onEditLocation("sourceId");}}/>
    }

    return (
      <div className="Form TransferForm" data-layout={this.props.layout}>
        {typeField}
        <DropControl className="Status" layout="field" label="Status" value={capitalize(data.status)} options={TRANSFER_STATUSES} onChange={(value)=>{this.editField("status",value.value)}}/>
        <Input className="Name" label="Name" value={data.name} onChange={(value)=>{this.editField("name",value)}}/>
        { sourceSelect }
        <Input className="Destination" label="Destination" value={destName} onFocus={(evt)=>{evt.target.blur();this.onEditLocation("destinationId");}}/>
        <Input className="Carrier" label="Carrier" value={data.carrier} onChange={(value)=>{this.editField("carrier",value)}}/>
        <Input className="Tracking" label="Tracking" value={data.tracking} onChange={(value)=>{this.editField("tracking",value)}}/>
        <Input className="SentAt" label="Sent At" type="date" value={sentAt} onChange={(value)=>{this.editField("sentAt",value)}}/>
        <Input className="ExpectedAt"label="Expected At" type="date" value={expectedAt} onChange={(value)=>{this.editField("expectedAt",value)}}/>
        <Input className="ReceivedAt" label="Received At" type="date" value={receivedAt} onChange={(value)=>{this.editField("receivedAt",value)}}/>
        <Input className="Cost" label="Cost" type="number" value={cost} onChange={(val)=>{this.editField("cost",parseFloat(val))}}/>
      </div>
    );
  }
}


const oldMapState = (state, props) => {
  let poId = dot(props.transfer,"data.poId");
  let po = poId ? (dot(state.inventory,["pos",poId]) || {}) : {};
  let sourceId = dot(props.transfer,"data.sourceId");
  let destinationId = dot(props.transfer,"data.destinationId");
  let source = sourceId ? (dot(state.inventory,["locations",sourceId]) || {}) : {};
  let destination = destinationId ? (dot(state.inventory,["locations",destinationId]) || {}) : {};
  return {
    po: po,
    source: source,
    destination: destination,
    inventory: state.inventory
  }
};

const mapState = (state, props) => {
  let opts = props.opts || {}
  let transfer = stateForDoc(state.inventory,{
    type: "transfer",
    id: "new",
    ...opts
  });

  // Get PO
  let po = dot(transfer,"data.po");
  if (!po) {
    let poId = dot(transfer,"data.poId");
    po = poId ? (dot(state.inventory,["pos",poId]) || {}) : {};
  }

  // Get source
  let source = dot(transfer,"data.source");
  if (!source) {
    let sourceId = dot(transfer,"data.sourceId");
    source = sourceId ? (dot(state.inventory,["locations",sourceId]) || {}) : {};
  }

  // Get destination
  let destination = dot(transfer,"data.destination");
  if (!destination) {
    let destinationId = dot(transfer,"data.destinationId");
    destination = destinationId ? (dot(state.inventory,["locations",destinationId]) || {}) : {};
  }

  return {
    transfer: transfer,
    po: po,
    source: source,
    destination: destination,
    inventory: state.inventory
  }
};



const mapDispatch = (dispatch) => {
  return {
    listItems: opts => dispatch(listItems(opts)),
    setItemField: opts=> dispatch(setItemField(opts)),
    loadItemSuccess: (opts, docs) => dispatch(loadItemSuccess(opts, docs)),
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(TransferForm)
