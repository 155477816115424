import React from 'react';
import { connect } from 'react-redux'
import './ModelSearchSection.scss';

import ActionVal from '../containers/ActionVal';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import QueryLine from '../layouts/QueryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import Section from '../elements/Section';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { meldItems, setItemQuery } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';
import { configToNestedQuery, stateForList } from '../../lib/inventory-util';

class ModelSearchSection extends Section  {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  renderHeader() {
    return this.props.renderHeader && this.props.renderHeader();
  }

  renderItem(item, index) {
    return this.props.renderItem && this.props.renderItem(item, index);
  }

  componentWillMount() {
    let props = this.props;
    let config = dot(props,"list.query")
    let loadOnMount = (props.reload || !config) && props.query;
    if (loadOnMount) {
      let modelProps = this.modelProps();
      this.props.setItemQuery({
        ...modelProps,
        query: props.query
      });
      this.onConfigChange(props.query);
    }
  }

  // Get the query config
  onConfigChange(config, throttle) {
    let props = this.props;

    // Check for additional attached conditions
    let conditions = this.props.ignoreDelete ? [] : [{deleted:{neq:1}}];
    if (props.conditions && Array.isArray(props.conditions)) {
      for (var i = 0; i < props.conditions.length; i++) {
        conditions.push(props.conditions[i]);
      }
    }

    // Prepare the query
    let modelProps = this.modelProps();
    let query = configToNestedQuery(config, conditions);
    query.limit = this.props.limit || config.limit;
    query.offset = this.props.offset || config.offset;
    this.props.meldItems({
      ...modelProps,
      query: query
    }).then(()=>{
      if (throttle) { throttle.done(); }
      switch (this.props.status) {
        case "success":
          if (this.props.onQuerySuccess) {this.props.onQuerySuccess(this.props.items)}
          break;
      }
    });
  }

  onExpand(evt, history) {
    let expanded = !this.state.expanded;
    if (this.props.onExpand) {
      this.props.onExpand(expanded);
    }
    this.setState({expanded:expanded});
  }

  modelProps() {
    let props = this.props;
    return {
      type: props.type,
      typeAlias: props.typeAlias,
      id: props.id,
      idAlias: props.idAlias,
      childType: props.childType,
      childTypeAlias: props.childTypeAlias,
      childId: props.childId
    }
  }

  render() {
    // Handle to the props
    let props = this.props;
    let modelProps = this.modelProps();

    // Get the items
    let prependItems = props.prependItems;
    let items = props.items || [];
    let appendItems = props.appendItems;
    let status = props.status;

    // Check for label
    let label;
    if (this.props.label) {
      label = <HeaderLine><div>{this.props.label}</div></HeaderLine>;
    }

    // Check for search controls are disabled
    let searchBar;
    let pageBar;
    if (!this.props.disableSearch) {
      searchBar = <PaddedArea>
        <QueryLine {...modelProps} throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
        <QuerySummaryLine {...modelProps} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
        <QueryOptionsPresentation {...modelProps} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
      </PaddedArea>;
      pageBar = <QueryPaginatePresentation {...modelProps} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
    }

    let noResultsMessage;
    if (items.length == 0) {
      noResultsMessage = <caption className="Submessage">
        <FontAwesomeIcon icon={["fas","search"]}/>
        <div>None found</div>
      </caption>
    }

    // Build the section
    let className = this.props.className || "";
    return (
      <div className={`Section ModelSearchSection ${className}`} ref="root" data-size={dot(this.state,"size")} data-expanded={this.state.expanded} data-locked={this.props.locked}>
        {label}
        {searchBar}
        <table data-sticky={true} className="Card StickyHeader Results" animate={props.list.status} data-empty={!!noResultsMessage}>
          <thead>
            {this.renderHeader()}
          </thead>
          <tbody>
            {items.map((item,index)=>this.renderItem(item,index))}
          </tbody>
          {noResultsMessage}
        </table>
        {pageBar}
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = stateForList(state.inventory, props) || {};
  return {
    list: list,
    status: props.status || list.status,
    items: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    meldItems: opts => dispatch(meldItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ModelSearchSection);
