import React from 'react';

class Element extends React.Component {
  render() {
    return (
      <div>Hello element!</div>
    );
  }
}

export default Element;
