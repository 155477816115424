import React from 'react';
import { connect } from 'react-redux'
import Menu from './Menu';
import './LeftMenu.css';
import MenuItemLayout from '../layouts/MenuItemLayout';
import PlainButton from '../elements/PlainButton';

import { LEFT_MENU } from '../../constants/menus/LeftMenu';
import { setAppField, toggleAppField } from '../../actions/AppActions';
import { dot } from '../../lib/obj';

const APP_STAGE   = process.env.REACT_APP_STAGE || "local";
const APP_VERSION = process.env.REACT_APP_VERSION || "*.*.*";

class LeftMenu extends Menu {

  onToggleMenu() {
    this.props.toggleAppField("menu.left.expand");
  }

  onMenuItem() {
    this.props.setAppField("epoc",Date.now());
  }

  render() {
    // Handle to the menu
    const app = this.props.app;
    return (
      <div className="LeftMenu" ref="root" data-expanded={dot(app,"menu.left.expand")} onClick={()=>{this.onToggleMenu()}}>
        <div className="Content" onClick={(evt)=>{evt.stopPropagation();}}>
          <div className="Controls">
            <PlainButton className="Close" onClick={()=>{this.onToggleMenu()}}>&times;</PlainButton>
          </div>
          <div className="MenuTop">
          </div>
          <div className="MenuMiddle">
            {LEFT_MENU.map((item, index)=>{
              if (item.header) {
                return <div className="Header" key={`item${index}`}>{item.label}</div>
              } else {
                return <MenuItemLayout key={`item${index}`} activePath={window.location.pathname} onNavigate={()=>{this.onMenuItem()}} {...item} />
              }
            })}
          </div>
          <div className="MenuBottom">
            <div className="AppVersion">Version {APP_VERSION}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    app: state.app
  }
};

const mapDispatch = (dispatch) => {
  return {
    setAppField: (f,v) => dispatch(setAppField(f,v)),
    toggleAppField: (f)=> dispatch(toggleAppField(f))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LeftMenu)
