import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import DropControl from '../controls/DropControl';
import RectButton from '../elements/RectButton';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import SelectorControl from '../controls/SelectorControl';
import DateControl from '../controls/DateControl';
import Separator from '../elements/Separator';
import NameVal from '../containers/NameVal';
import SubVal from '../containers/SubVal';
import MoneyVal from '../containers/MoneyVal';

import { financial, capitalize } from '../../lib/formats';
import { docForLoc } from '../../lib/inventory-util';
import { dot } from '../../lib/obj';
import { trim } from '../../lib/formats';

import { PO_STATES, PO_FULFILLMENT_STATUSES } from '../../constants/inventory/PO.js';
import { VENDOR_SEARCH } from '../../constants/inventory/Vendor'

import { listItems, setItemField, loadItemSuccess } from '../../actions/InventoryActions';
import { openPopup } from '../../actions/PopupActions';

import './Form.css';
import './POForm.scss';

class POForm extends Form {

  // Edit the given field
  editField(field, value) {
    let id = dot(this.props.po,"data.id");
    if (id) {
      this.props.setItemField({type:"pos",id:id,field:field,value:value});
    }
    else {
      this.props.setItemField({type:"pos",id:"new",field:field,value:value});
    }
  }

  onVendorFocus(evt) {
    let vendors = dot(this.props.inventory,"vendors.list");
    if (dot(vendors,"status") != 'success') {
      this.props.listItems({type:"vendors"});
    }
  }

  onEditVendor() {
    let vendor = this.props.vendor;
    let vendorId = dot(vendor,"data.id");
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Vendor",
        type: "vendors",
        limit: 25,
        radio: true,
        query: VENDOR_SEARCH,
        selections: vendorId ? {[vendorId]:vendor} : undefined,
        renderItem: (item, index)=>{
          return [
            <NameVal>{dot(item,"data.name")}</NameVal>,
            <SubVal>{dot(item,"data.pocFirstName")} {dot(item,"data.pocLastName")}</SubVal>
          ]
        },
        other: {
          label: "+ New Vendor",
          onClick: () => {
            this.onCreateVendor();
          }
        },
        onOk:(items)=>{
          let selected = items[0];
          console.log("SELECTED", selected);
          if (selected) {
            this.editField("vendorId",dot(selected,"data.id"));
            this.props.loadItemSuccess({type:"vendors"},{vendors:[selected]});
          }
        }
      }
    });
  }

  onCreateVendor() {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props: {
        title: "New Vendor",
        opts: {
          type:"vendors",
          id: "new"
        },
        onPush:(vendor)=>{
          this.editField("vendorId",dot(vendor,"data.id"));
        }
      }
    });
  }

  render() {
    // Get handle to the form we are working with
    let data = dot(this.props.po,"data") || {};

    // Handle to prop values
    let name = dot(data,"name");
    let state = dot(data,"state");
    let fulfillmentStatus = dot(data,"fulfillmentStatus");
    let deposit = dot(data,"totalDeposited") || 0;

    // Get handle to the po Items
    let items = dot(this.props.po, "items.list.docs") || [];
    let total = 0;
    items.forEach((item)=>{
      total+= (dot(item,"data.unitCost") * dot(item,"data.quantity")) || 0;
    });
    let owed = total-deposit;

    // Prepare the choices for the item selector
    let vendor = this.props.vendor;
    let poc = trim(`${dot(vendor,"data.pocFirstName") || ''} ${dot(vendor,"data.pocLastName") || ''}`);
    let vendorName =  dot(vendor,"data.name");

    // Get the po dates
    let depositedAt = data.depositedAt ? data.depositedAt.split(" ")[0] : null;
    let paidAt = data.paidAt ? data.paidAt.split(" ")[0] : null;
    let expectedAt = data.expectedAt ? data.expectedAt.split(" ")[0] : null;

    return (
      <div className="Form POForm" ref="main" data-size={dot(this.state,"size")}>
        <DropControl className="State" layout="field" label="State" value={capitalize(state)} options={PO_STATES} onChange={(value)=>{this.editField("state",value.value)}}/>
        <DropControl className="Fulfillment" layout="field" label="Fulfillment" value={capitalize(fulfillmentStatus)} options={PO_FULFILLMENT_STATUSES} onChange={(value)=>{this.editField("fulfillmentStatus",value.value)}}/>
        <Input className="Name" label="Name" value={name} onChange={(value)=>{this.editField("name",value)}}/>
        <Input className="Vendor" label="Vendor" value={poc || vendorName} onFocus={(evt)=>{evt.target.blur();this.onEditVendor();}}/>
        <Input className="Description" label="Description" value={data.description} onChange={(value)=>{this.editField("description",value)}}/>
        {/*<Input className="Expected"label="Expected At" type="date" value={expectedAt} onChange={(value)=>{this.editField("expectedAt",value)}}/>*/}
        <Input type="date" className="Expected" label="Expected At" value={expectedAt} onChange={(value)=>{this.editField("expectedAt",value)}}/>
        <Readout className="Total" label="Total"><MoneyVal>{total}</MoneyVal></Readout>
        <Readout className="Paid" label="Paid"><MoneyVal>{deposit}</MoneyVal></Readout>
        <Readout className="Owed" label="Balance"><MoneyVal>{owed}</MoneyVal></Readout>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let vendorId = dot(props.po,"data.vendorId");
  let vendor = vendorId ? (dot(state.inventory,["vendors",vendorId]) || {}) : {};
  return {
    vendor: vendor,
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    listItems: opts => dispatch(listItems(opts)),
    loadItemSuccess: (opts, docs) => dispatch(loadItemSuccess(opts, docs)),
    setItemField: opts=> dispatch(setItemField(opts)),
    openPopup: opts=> dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(POForm)
