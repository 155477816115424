import React from 'react';

class Control extends React.Component {
  render() {
    return (
      <div>Hello control!</div>
    );
  }
}

export default Control;
