import React from 'react';
import { mappify } from '../../lib/obj';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ORDER_TYPES = [
  { value:"bundle",    name: "Bundle"},
  { value:"package",   name: "Package"},
  { value:"assembly",  name: "Assembly"},
  { value:"part",      name: "Part"}
];

export const ORDER_STATUSES = [
  { value:"fulfilled",  name: "Fulfilled"},
  { value:"pending",    name: "Pending"}
];

export const ORDER_ORIGINS = [
  { value:"shopify",  name: "Shopify", icon: <div className="Icon" onClick={(evt)=>this.onClear(evt)}><FontAwesomeIcon icon={["fab","shopify"]} /></div>},
  { value:"direct",   name: "Direct"}
];

export const ORDER_TYPE_MAP = mappify(ORDER_TYPES,(i)=>i.value);
export const ORDER_STATUS_MAP = mappify(ORDER_STATUSES,(i)=>i.value);
export const ORDER_ORIGINS_MAP = mappify(ORDER_ORIGINS,(i)=>i.value);

export const ORDER_SEARCH = {
  type: "orders",
  limit: 50,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["name","number","email"]
  },
  sort: [
    { field: "name",       name: "Name (A-Z)",     direction:"asc"},
    { field: "name",       name: "Name (Z-A)",     direction:"desc"},
    { field: "createdAt",  name: "Created (1-9)",  direction:"asc"},
    { field: "createdAt",  name: "Created (9-1)",  direction:"desc", selected: true},
  ]
}
