import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import ImageLayout from './ImageLayout';
import ActionVal from '../containers/ActionVal';
import './OptionLayout.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class OptionLayout extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let props = this.props;

    // Dynamic grid css handlign
    let gridAreas = [];
    let gridCols = [];
    let space = props.space || {};

    // Check to see if this is an option row
    let check;
    if (props.option) {
      let selected;
      if (props.selected) {
        let icon = props.radio ? "dot-circle" : "check-square";
        selected = <FontAwesomeIcon icon={["far",icon]} />;
      } else {
        let icon = props.radio ? "circle" : "square";
        selected = <FontAwesomeIcon icon={["far",icon]} />;
      }
      check = <div className="Check">
        {selected}
      </div>;
    }

    if (check || space.check) {
      gridCols.push("1em");
      gridAreas.push("Check");
    }

    // Check to see if this is an option row
    let image;
    if (props.image) {
      image = <ImageLayout className="Image" file={props.image}/>
    }

    if (image || space.image) {
      gridCols.push("2em");
      gridAreas.push("Image");
    }

    // Check to see if this is an option row
    let content;
    if (props.children) {
      content = <div className="Content">
        {props.children}
      </div>
    }

    if (content || space.content) {
      gridCols.push("1fr");
      gridAreas.push("Content");
    }

    // Check to see if this is an option row
    let config;
    if (props.onConfig) {
      config = <ActionVal className="Config">
        <FontAwesomeIcon className="Icon" icon={["fal","ellipsis-v"]} onClick={evt=>this.onClick(evt)}/>
      </ActionVal>
      gridCols.push("0.5em");
      gridAreas.push("Config");
    }

    // Prepare the grid styles
    let style = {
      gridTemplateColumns: gridCols.join(" "),
      gridTemplateAreas: `"${gridAreas.join(" ")}"`
    };

    let className = props.className || "";
    return (
      <div className={`OptionLayout Layout ${className}`} onClick={(evt)=>{this.onClick(evt)}} data-selected={props.selected} data-pinned={props.pinned} style={style} data-status={props.status} data-creating={this.props.creating}>
        {check}
        {image}
        {content}
        {config}
      </div>
    );
  }
}

export default withRouter(OptionLayout);
