import React from 'react';
import Popup from './Popup';
import Section from '../elements/Section';
import Separator from '../elements/Separator';
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import NameVal from '../containers/NameVal';
import ItemOptionLine from '../layouts/ItemOptionLine';
import OptionLayout from '../layouts/OptionLayout';
import InfoLayout from '../layouts/InfoLayout';
import ItemLine from '../layouts/ItemLine';
import HeaderLine from '../layouts/HeaderLine';
import Row from '../elements/Row';
import LoginForm from '../forms/LoginForm'
import PaddedArea from '../areas/PaddedArea';
import FooterArea from '../areas/FooterArea';
import LoadableArea from '../areas/LoadableArea';
import ClearableVal from '../containers/ClearableVal';
import { connect } from 'react-redux'

import './Popup.css';
import './ListPopup.scss';

import { closePopup } from '../../actions/PopupActions';
import { meldItems, loadItem, listItems, setItemQuery } from '../../actions/InventoryActions';
import { setSessionLogin } from '../../actions/SessionActions';
import { dot } from '../../lib/obj';
import { configToNestedQuery } from '../../lib/inventory-util';
import { _ } from '../../lib/underscore';

class ListPopup extends Popup {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  componentWillMount() {
    let props = this.props;
    if (props.query) {
      this.props.setItemQuery({
        type: props.type,
        typeAlias: props.typeAlias,
        query: props.query
      });
      this.onConfigChange(props.query);
    }
  }

  // Get the query config
  onConfigChange(config, throttle) {
    let props = this.props;

    // Check for additional attached conditions
    let conditions = [{deleted:{neq:1}}];
    if (props.conditions && Array.isArray(props.conditions)) {
      for (var i = 0; i < props.conditions.length; i++) {
        conditions.push(props.conditions[i]);
      }
    }

    // Prepare the query
    let query = config;
    console.log("QUERY: ", query);
    query.limit = this.props.limit || config.limit;
    query.offset = this.props.offset || config.offset;
    this.props.meldItems({
      type: props.type,
      typeAlias: props.typeAlias,
      query: query
    }).then(()=>{
      if (throttle) { throttle.done(); }
      switch (this.props.status) {
        case "success":
          if (this.props.onQuerySuccess) {this.props.onQuerySuccess(this.props.items)}
          break;
      }
    });
  }

  onItemClick(item, index) {
    if (this.props.onItemClick) {this.props.onItemClick(item, index); }
  }

  onCancel(evt, history) {
    if (this.props.onCancel) { this.props.onCancel(evt, history); }
    this.props.closePopup();
  }

  onOk(evt, history) {
    this.props.closePopup();
  }

  onRenderItem(item,index) {
    // Render the item
    let itemInfo;
    let optionProps = {};
    if (this.props.renderItem) {
      itemInfo = this.props.renderItem(item, index, optionProps);
      itemInfo = Array.isArray(itemInfo) ? itemInfo : [itemInfo];
    } else {
      itemInfo = [<span className="Entry">{dot(item,"data.name") || dot(item,"data.id")}</span>];
    }
    return <InfoLayout>{itemInfo}</InfoLayout>
  }

  onExpand(expanded) {
    this.setState({expanded:expanded});
  }

  render() {
    // Handle to the props
    let props = this.props;

    // Get the items
    let items = props.items || [];
    let status = props.status;

    // Check if there are any items to show
    let hasResults = items.length > 0 || this.props.appendItems != undefined;

    // Get the selections and other options
    let limit = this.props.limit || dot(this.props.query,"limit");

    // setup paginate control
    let paginate = <QueryPaginatePresentation
      type={props.type}
      typeAlias={props.typeAlias}
      conditions={props.conditions}
      limit={limit}
    />

    return (
      <div className="Popup ListPopup" ref="root" data-type={props.type} data-expanded={this.state.expanded}>
        <Section className="List">
          <HeaderLine>
            {this.props.title}
            {paginate}
          </HeaderLine>
          <LoadableArea className="Items" working={status=="loading"} empty={items.length == 0}>
            {items.map((item, index)=>{
              return this.onRenderItem(item,index);
            })}
            {this.props.appendItems}
          </LoadableArea>
        </Section>
        <Section className="Footer">
          <PaddedArea>
            <FooterArea>
              <div></div>
              <div></div>
              <RectButton className="OK" theme="blue" onClick={(evt,history)=>{this.onOk(evt, history)}}>Close</RectButton>
            </FooterArea>
          </PaddedArea>
        </Section>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let type = props.typeAlias || props.type;
  let list = dot(state.inventory, [type,"list"]) || {};
  return {
    list: list,
    status: list.status,
    items: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ListPopup)
