import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';

import './TransferLineHeader.css';

class TransferLineHeader extends Layout {
  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }
  render() {
    return (
      <div className="TransferLineHeader" onClick={(evt)=>{this.onClick(evt)}} >
        <div className="Name">{this.props.col1 || "Name"}</div>
        <div className="Type">Type</div>
        <div className="Status">Status</div>
        <div className="Cost">Cost</div>
        <div className="CreatedAt">Created</div>
      </div>
    );
  }
}

const RoutedTransferLineHeader = withRouter(TransferLineHeader)
export default RoutedTransferLineHeader;
