import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from './Page'

import BareInput from '../elements/BareInput';
import DateVal from '../containers/DateVal';
import Draft from '../../lib/draft';
import ModelLayout from '../layouts/ModelLayout';
import ModelSearchSection from '../sections/ModelSearchSection';
import PaddedArea from '../areas/PaddedArea';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import QueryLine from '../layouts/QueryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import RectButton from '../elements/RectButton';
import Section from '../elements/Section';
import Separator from '../elements/Separator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { startDraftItem, purgeItem, pushItem, setItemField } from '../../actions/InventoryActions';
import { dot, deepCopy, mapk2k, mappify, sfc } from '../../lib/obj';
import { configToNestedQuery } from '../../lib/inventory-util';
import { commafy, money } from '../../lib/formats';

import './Page.css';
import './SearchPage.scss';
import './POsPage.scss';

import { PO_SEARCH, PO_STATE_MAP, PO_STATES } from '../../constants/inventory/PO';

const STATUS_WORKING = {
  creating: true,
  saving: true,
  deleting: true
}

const DEFAULT_PO_DATA = {
  state: PO_STATES[0].value
};

class POsPage extends Page {

  constructor() {
    super();
    this.state.notes = {};
  }

  onAdd(evt) {
    this.setState({sci: null, notes: {}});
    this.props.startDraftItem({
      type: "pos",
      index: -1,
      data: deepCopy(DEFAULT_PO_DATA)
    });
  }

  onDeleteItem(e, id, index) {
    if (id > 0) {
      this.props.deleteItem({type:"pos",id: id,index: index})
      .then(()=>{
        this.props.purgeItem({type:"pos", id: id, index: index});
      });
    }
    else {
      this.props.purgeItem({type:"pos", index: index});
    }
    this.setState({sci: null, notes: {}});
  }

  saveField(field, value, index) {
    this.softEditField(field, value, index);
    let draft = Draft.deep(dot(this.props.pos,[index,"data"]));
    draft.set(field, value);
    this.props.pushItem({
      type: "pos", id: draft.val("id"), index: index,
      docs: { po: draft.updates },
      query: { promptError:true }
    });
  }

  softEditField(field, value, index) {
    let id = dot(this.props.pos,[index,"data","id"]);
    this.props.setItemField({ type:"pos", id:id, index: index, field: field, value: value });
  }

  saveTextField(field, value, orig, index) {
    if (value == orig || (value == "" && orig == null)) { return; }
    this.saveField(field, value, index);
  }

  genControlsDiv(item, index) {
    let data = item.data || {};
    let softActions;
    if (data.id > 0) {
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="ControlIcon" icon={["fal","ellipsis-v"]}/>
        </summary>
        <div className="DetailsList">
          <div className="Header"><h4>Actions</h4><aside>{index+1}</aside></div>
          <Separator collapsed={true}/>
          <ModelLayout>
            <div className="Name">{data.name || "..."}</div>
            <div className="Label">ID: {data.id}</div>
            <DateVal className="Sublabel">{data.createdAt}</DateVal>
          </ModelLayout>
          <Separator collapsed={true}/>
          <RectButton working={item.status == "deleting"} theme="red" onClick={(e,history)=>{this.onDeleteItem(e, item.data.id, index)}}>Delete</RectButton>
        </div>
      </details>
    } else {
      return <FontAwesomeIcon className="ControlIcon" icon={["far","times"]} onClick={(e)=>{this.onDeleteItem(e, item.data.id, index)}}/>
    }
  }

  genNotesDiv(item, index) {
    if (item) {
      let data = item.data;
      let notes = dot(this.state,['notes',index]);
      let edited = notes != null && notes != data.notes;
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="Icon" icon={["far","memo-pad"]} data-filled={data.notes ? true : false} />
        </summary>
        <div className="DetailsList">
          <h4>PO Notes</h4>
          <textarea name="notes" style={{width:"100%",minHeight:"10em"}} defaultValue={data.notes} onChange={(e)=>this.onNotesChange(e,index)}></textarea>
          <RectButton theme="blue" lock={!edited} working={STATUS_WORKING[item.status]} onClick={(e)=>this.saveField("notes",notes,index)}>Save</RectButton>
        </div>
      </details>
    }
  }

  saveExpectedField(value, index) {
    this.softEditField("expectedAt", value, index);
    let draft = Draft.deep(dot(this.props.pos,[index,"data"]));
    // Check if setting or clearing
    if (value && value.length > 0) {
      let date = Date.parseYMDStr(value);
      draft.val("expectedAt",date.srvstr({utc:true}));
    }
    else {
      draft.clear("expectedAt");
    }
    // Push the changes
    this.props.pushItem({
      type: "pos", id: draft.val("id"), index: index,
      docs: { po: draft.updates },
      query: { promptError:true}
    });
  }

  onNotesChange(e, index) {
    let notes = this.state.notes;
    notes[index] = e.target.value;
    this.setState({notes:notes});
  }

  renderHeader() {
    return <tr>
      <th className="Controls"><FontAwesomeIcon className="ControlIcon" icon={["fal","plus"]} onClick={(e)=>{this.onAdd(e)}}/></th>
      <th className="Created">Created</th>
      <th className="Name">Name</th>
      <th className="Vendor">Vendor</th>
      <th className="State">State</th>
      <th className="Expected">Expected</th>
      <th className="Notes">Notes</th>
    </tr>;
  }

  renderItem(item, index) {
    let state = this.state;
    let data = item.data;
    let pin = data.id ? data.id : `${index}i`;
    let expectedAt = Date.utcTransDateInput(data.expectedAt);
    return <tr key={`item${index}-${data.id || "0"}-${data.version}`}
      data-deleted={data.deleted}
      data-working={STATUS_WORKING[item.status]}
      data-creating={data.id == null}
      data-pinned={this.state.pin == pin}
      >
      <td className="Controls">
        {this.genControlsDiv(item, index)}
      </td>
      <td className="Created">
        <Link theme="blue" to={`/pos/${data.id}`}> <DateVal>{data.createdAt}</DateVal></Link>
      </td>
      <td className="Name">
        <BareInput defaultValue={ data.name } onEnter={(e)=>{this.saveTextField("name", e.target.value, data.name, index)}} onBlur={(e)=>{this.saveTextField("name", e.target.value, data.name, index)}}/>
      </td>
      <td className="Vendor">
        <span data-hype="whisper">{dot(data,"vendor.data.pocFirstName") || dot(data,"vendor.data.name")}</span>
      </td>
      <td className="State">
        <select defaultValue={data.state} onChange={(e)=>{this.saveField("state", e.target.value, index)}} data-hype={data.state || 'undefined'}>
          {PO_STATES.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
        </select>
      </td>
      <td className="Expected">
        <BareInput type="date" data-hype={expectedAt ? "" : "blank"} defaultValue={ expectedAt } onChange={(e)=>{this.saveExpectedField(e.target.value, index)}}/>
      </td>
      <td className="Notes">
        {this.genNotesDiv(item, index)}
      </td>
    </tr>
  }

  render() {
    let posList= this.props.posList;
    let pos = this.props.pos;

    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add PO</RectButton>;
    return (
      <div className="Page SearchPage POsPage">
        <PageHeaderOutline title={this.props.title} aside={addButton} top={this._backButton()}></PageHeaderOutline>
        <ModelSearchSection type="pos" query={deepCopy(PO_SEARCH)} renderHeader={()=>this.renderHeader()} renderItem={(item,i)=>this.renderItem(item,i)} reload={true}/>
      </div>
    )
  }
}

const mapState = (state, props) => {
  let posList = dot(state.inventory,"pos.list") || {};
  return {
    posList: posList,
    pos: posList.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    purgeItem: opts => dispatch(purgeItem(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
  }
};

export default connect(
  mapState,
  mapDispatch
)(POsPage)
