import React from 'react';
import ReactDOM from 'react-dom';
import Element from './Element';
import './Section.css';

import { dot } from '../../lib/obj';
import { _ } from '../../lib/underscore';

class Section extends Element {

  constructor(props) {
    super(props);
    this.state = {
      size: "xsmall"
    };
  }

  updateDimensions() {
    if (!this._sizeThrottle) {
      this._sizeThrottle = _.throttle(() => {
        if (this.refs.root) {
          let elem = ReactDOM.findDOMNode(this.refs.root);
          let width = elem.offsetWidth;
          let size = this.state.size;
          if (width < 400 ) {
            size="xsmall";
          }
          else if (width < 600) {
            size="small";
          }
          else if (width < 800) {
            size="medium";
          }
          else if (width < 1024) {
            size="large";
          }
          else if (width < 1200) {
            size="xlarge";
          }
          else {
            size="xxlarge";
          }
          this.setState({size:size});
        }
      }, 1000);
    }
    this._sizeThrottle()
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize",this.updateDimensions.bind(this));
  }

  render() {
    const say = this.props.say;
    const shout = this.props.shout;
    let className = this.props.className || "";
    return (
      <div className={`Section ${className}`} ref="root"  data-theme={this.props.theme} data-size={dot(this.state,"size")} data-locked={this.props.locked} data-expanded={this.props.expanded}>
        {this.props.children}
      </div>
    );
  }
}

export default Section;
