import React from 'react';
import Container from './Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PRODUCT_TYPE_MAP } from '../../constants/inventory/Product.js';
import { TRANSFER_TYPE_MAP } from '../../constants/inventory/Transfer.js';
import { dot } from '../../lib/obj';

import './Container.css';
import './TypeVal.css';

class TypeVal extends Container {

  getIcon(val) {
    let icon;
    switch (val) {
      case "part":
        icon = <FontAwesomeIcon icon={["far","cube"]} />
        break;
      case "assembly":
        icon = <FontAwesomeIcon icon={["far","cubes"]} />
        break;
      case "package":
        icon = <FontAwesomeIcon icon={["far","box"]} />
        break;
      case "bundle":
        icon = <FontAwesomeIcon icon={["far","boxes"]} />
        break;
      default:
        icon = <FontAwesomeIcon icon={["far","chevron-left"]} />
    }
    return icon;
  }

  getVal() {
    return this.props.val || this.props.children;
  }

  getName() {
    let value = this.props.children;
    let type = this.props.type;
    switch (type) {
      case "product":
      case "products":
        return dot(PRODUCT_TYPE_MAP,[value,"name"]);
      case "transfer":
      case "transfers":
        return dot(TRANSFER_TYPE_MAP,[value,"name"]);
      default:
        return value;
    }
  }

  render() {
    // let icon = this.getIcon(this.props.val)
    let className = this.props.className || "";
    return (
      <div className={`TypeVal Container ${className}`} data-val={this.getVal()} data-bare={this.props.bare}>
        {this.getName()}
      </div>
    );
  }
}

export default TypeVal;
