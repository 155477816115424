import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import LocationForm from '../forms/LocationForm';
import LegacyLocationItemForm from '../forms/LegacyLocationItemForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import DropControl from '../controls/DropControl';
import Draft from '../../lib/draft.js';
import TransferLine from '../layouts/TransferLine';
import TransferLineHeader from '../layouts/TransferLineHeader';

import { Link } from 'react-router-dom'
import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import NameVal from '../containers/NameVal';
import TypeVal from '../containers/TypeVal';
import SubVal from '../containers/SubVal';
import QtyVal from '../containers/QtyVal';
import StateVal from '../containers/StateVal';
import DateVal from '../containers/DateVal';
import ActionVal from '../containers/ActionVal';
import TitleVal from '../containers/TitleVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ItemSearchSection from '../sections/ItemSearchSection';
import HeaderLayout from '../layouts/HeaderLayout';
import InfoLayout from '../layouts/InfoLayout';
import OptionLayout from '../layouts/OptionLayout';
import FooterArea from '../areas/FooterArea';
import ProductLayout from '../layouts/ProductLayout';
import TableSearchSection from '../wrappers/TableSearchSection';


import { financial } from '../../lib/formats';
import { docForLoc, docHasEdits } from '../../lib/inventory-util';
import { dot, hasKeys, deepCopy, mappify, sfc } from '../../lib/obj';
import { _ } from '../../lib/underscore';
import { queryToWhere } from '../../lib/inventory-util';

import { loadItem, listItems, meldItems, pushItems, pushItemsInSeries, deleteItem, startDraftItem, purgeDraftItem, purgeItem, setItemAction, setItemQuery } from '../../actions/InventoryActions';
import { openPopup } from '../../actions/PopupActions';

import { PRODUCT_SEARCH } from '../../constants/inventory/Product';
import { LOCATION_ITEM_SEARCH } from '../../constants/inventory/Location';
import { ACTION_SEARCH } from '../../constants/inventory/Action';
import { TRANSFER_SEARCH, TRANSFER_STATUSES } from '../../constants/inventory/Transfer';

import './Page.css';
import './LocationPage.scss';

const LOCATION_ACTION_SEARCH = deepCopy(ACTION_SEARCH);
LOCATION_ACTION_SEARCH.links.push("locationItem");

class LocationPage extends Page {

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      this.props.loadItem({type:"locations",id:id});
    }
  }

  onShowLog(title) {
    let id = this.props.id;
    let itemsById = this.props.itemsById;
    this.props.openPopup({
      name:"ActionLog",
      props:{
        title: title || "Return Actions Log",
        type: "actions",
        typeAlias: "locations",
        idAlias: id,
        childTypeAlias: "actions",
        limit: 100,
        ignoreDelete: true,
        query: LOCATION_ACTION_SEARCH,
        conditions: [{subjectType: {eq:"locationItem"}},{"locationItem.locationId":{eq:id}}],
        renderActionSubject: (action) => {
          let li = itemsById[action.data.subjectId];
          return <span>{dot(li,"data.item.data.name")}</span>
        }
      }
    });
  }

  onSaveLocation (evt, history) {

    // 1st, attempt to push the Location changes
    let location = this.props.location;
    this.props.pushItems([{type:"locations",
      id: this.props.id,
      docs: {location:location},
      action: location.action
    }]).then((res)=>{

      // Get th epo id
      let resLocation = dot(res,"0.location");
      let id = dot(resLocation,"data.id") || this.props.id;

      // Add the items to the array
      let items = dot(this.props.location, "items.list.docs") || [];
      let arr = items.map((item, index)=>{
        let itemId = dot(item,"data.id");
        let version = dot(item,"data.version");
        let action = item.action ? item.action : (version >= 1 ? undefined : "create" );
        return {
          type:"locations",
          id: id != "new" ? id : undefined,
          childType: "items",
          childId: itemId != "new" ? itemId : undefined,
          docs:{locationItem:item},
          action: action,
          index: index
        };
      });
      console.log("ITEMS: ", arr);
      // 2nd, push the item changes
      this.props.pushItemsInSeries(arr).then((res)=>{
        // Check if page needs to be reloaded
        if (dot(resLocation,"data.version") == 1) {
          history.replace(`/locations/${id}`);
        }
        else {
          this.props.loadItem({type:"locations",id:id});
          this.props.listItems({type:"locations",id:id,childType:"items"});
        }
      });
    });
  }

  needsSave() {
    // Check po for sets
    if( hasKeys(dot(this.props.location,"sets")) ) {
      return true;
    }
    return false;
  }

  onCancel(evt,history) {
    history.goBack();
  }

  onDelete(evt,history) {
    this.props.openPopup({name:"Confirm",
      props:{
        title: "Delete Location",
        message: "Are you sure you want to delete this Location? You will NOT be able to undo this action.",
        onOk:()=>{
          this.props.deleteItem({type:"locations", id: this.props.id}).then(()=>{
            history.replace("/locations");
          });
        },
        okTheme: "red",
        okLabel: "Delete Location"
    }});
  }

  onAddItem(addDraft) {
    this.props.openPopup({name:"Search",
      props:{
        title: "Select products to add",
        type: "products",
        typeAlias: "location-items",
        limit: 50,
        query: deepCopy(PRODUCT_SEARCH),
        renderItem: (item, index, optionProps)=>{
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        other: {
          label: "+ New Product",
          onClick: () => {
            this._createProduct(addDraft);
          }
        },
        onOk:(items)=>{
          addDraft(items.map((selected,i)=>{
            return {
              locationId: this.props.id,
              item: selected,
              image: selected.data.image,
              itemId: selected.data.id
            }
          }));
        }
    }});
  }

  _createProduct(addDraft) {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props: {
        title: "New Product",
        opts: {
          type:"products",
          id: "new"
        },
        onPush:(product)=>{
          addDraft({
            locationId: this.props.id,
            item: product,
            image: product.data.image,
            itemId: product.data.id
          });
        }
      }
    });
  }

  _addItems(items) {
    let existing = dot(this.props.location, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft items for each of the new items
    items.forEach((item, index)=>{
      let data = item.data || {};
      this.props.startDraftItem({
        type:"locations",
        id: id,
        childType: "items",
        //index: count + index,
        index: -1,
        data: {
          locationId: id,
          itemId: data.id,
          item: item
        }
      });
    });
  }

  onEditLocationItem(locationItem, index) {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props:{
        title: "Edit location item",
        opts: {
          type: "locations",
          id: id,
          childType: "items",
          childId: dot(locationItem,"data.id"),
          index: index,
          locationItem: locationItem,
          query: { links: "item,image" }
        },
        onDelete: () => {
          this.props.purgeItem({type:"locations", id: id, childType: "items", index: index});
        }
      }
    });
  }

  onViewItem(item, history, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (item && item.data.itemId) {
      history.push(`/products/${item.data.itemId}`);
    }
  }

  onViewTransfer(transfer, history) {
    if (transfer) {
      history.push(`/transfers/${transfer.data.id}`);
    }
  }

  onPreview(evt,history) {
    let id = this.props.id;
    history.push(`/locations/${id}/preview`);
  }

  onLocationItemsLoaded(items) {
    let id = this.props.id;

    let itemsConditon = items.map((item) => {
      return {itemId:{eq:item.data.id}};
    });

    let query = {
      links:["transfer"],
      fields: ["bookId","id","itemId","transfer.id","transfer.bookId","transfer.status","transfer.destinationId"],
      group:['itemId'],
      where: [
        {deleted: {neq: 1}},
        {'transfer.deleted': {neq: 1}},
        {or:itemsConditon},
        {'transfer.destinationId':{eq:id}},
        {'transfer.status':{eq:"sent"}}
      ],
      calcs: [
        "sum.quantity"
      ]
    };

    this.props.meldItems({
      type: "transfer-items",
      typeAlias: "locations",
      idAlias: id,
      childTypeAlias:"incoming",
      query: query
    });
  }


  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";

    // Check if we need to save
    let needsSave = this.needsSave()

    // Get handle to the location Items
    let items = dot(this.props.location, "items.list.docs") || [];
    let itemsStatus = dot(this.props.location, "items.list.status");
    let incoming = this.props.incoming;
    let incomingById = this.props.incomingById;

    // Get handle to the po transfers
    let transfers = dot(this.props.location, "transfers.list.docs") || [];
    let transfersStatus = dot(this.props.location, "transfers.list.status");

    // Check to see if sending location
    let status = dot(this.props.location, "status");
    let isSending = status == "saving" || status == "creating";
    if (!isSending) {
      for (var i = 0; i < items.length; i++) {
        status = dot(items[i],"status");
        isSending = status == "saving" || status == "creating";
        if (isSending) { break; }
      }
    }

    // Setup buttons
    let cancelButton = null;
    let saveButton = null;
    if (isNew) {
      cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSaveLocation(evt, history)}}>
        {isSending ? "Creating" : "Create"}
      </RectButton>;
    }
    else {
      cancelButton = <RectButton theme="red" onClick={(evt,history)=>{this.onDelete(evt,history)}}>Delete</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSaveLocation(evt, history)}} lock={!needsSave}>
        {isSending ? "Saving" : "Save"}
      </RectButton>;
    }

    // Set the different page actions
    let actions = null;
    if (!isNew) {
      actions = <div>
        <PlainButton theme="blue" onClick={(evt,history)=>{this.onPreview(evt,history)}}><FontAwesomeIcon icon="eye" /> Preview</PlainButton>
      </div>
    }

    return (
      <div className="Page LocationPage">
        <Prompt
          when={needsSave}
          message='Are you sure you want to leave? You have unsaved changes.'
        />
        <PageHeaderOutline title={dot(this.props.location,"data.name") || "Location"} aside={saveButton} top={this._backButton()}>
          {actions}
          <Link theme="blue" to="#" onClick={()=>{this.onShowLog()}}><FontAwesomeIcon icon={["far","square-list"]} /> Log</Link>
        </PageHeaderOutline>
        <Section key="details" shout="Details" working={status == "loading"}>
          <HeaderLine>Details</HeaderLine>
          <LoadableArea working={status == "loading"}>
            <LocationForm location={this.props.location}/>
          </LoadableArea>
        </Section>

        <TableSearchSection label="Products" className="Products" controllable="true"
        type="location-items" typeAlias="locations" idAlias={id} childTypeAlias="items"
        query={deepCopy(LOCATION_ITEM_SEARCH)}
        conditions={id ? [{locationId:{eq:id}}] : undefined}
        reload={true}
        onAddItem={(f)=>{this.onAddItem(f)}}
        onQuerySuccess={(items)=>{this.onLocationItemsLoaded(items)}}
        layout={[
          {title:" ",         field:"image", type:"image", className:"Icon", saveField:"imageId" },
          {title:"Name",      type:"custom", render:(d,i)=>{return <ProductLayout key="product" product={d.data.item} onClick={(e,h)=>this.onViewItem(d,h,e)}/> } },
          {title:"Incoming",  type:"custom", hype:"whisper", render:(d,i)=>{
            return sfc(incomingById[d.data.itemId],(o)=>{
              return dot(o,"data.calcs.sumQuantity");
            }) || "-"
          }
          },
          {title:"Stock",  field:"quantity", type:"number" },
        ]}/>

        <TableSearchSection label="Incoming Transfers" className="Transfers" controllable="true"
        type="transfers" typeAlias="locations" idAlias={id} childTypeAlias="transfers"
        query={deepCopy(TRANSFER_SEARCH)}
        conditions={id ? [{destinationId:{eq:id}}] : undefined}
        reload={true}
        onAddItem={(f)=>{this.onAddItem(f)}}
        onQuerySuccess={(items)=>{this.onLocationItemsLoaded(items)}}
        layout={[
          {title:"Name",     field:"name", type:"string"},
          {title:"Source",   field:"source.data.name", default:"-"},
          {title:"Status",   field:"status", type: "dropdown", options: TRANSFER_STATUSES },
          {title:"Expected", field:"incomingAt", type: "date" },
          {title:"Received", field:"receivedAt", type: "date" }
        ]}/>

        <Separator/>
        <Row align="space-between">
          {cancelButton}
          {saveButton}
        </Row>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let location = dot(state.inventory,["locations",props.id]) || {};
  let items = dot(location, "items.list.docs") || [];
  let itemsById = mappify(items,(i)=>dot(i,"data.id"));
  let incoming = dot(location, "incoming.list.docs") || [];
  let incomingById = mappify(incoming,(i)=>dot(i,"data.id"));
  return {
    location: location,
    items: items,
    itemsById: itemsById,
    incoming: incoming,
    incomingById: incomingById
  }
};

const mapDispatch = (dispatch) => {
  return {
    loadItem: opts => dispatch(loadItem(opts)),
    listItems: opts => dispatch(listItems(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    pushItems: arr => dispatch(pushItems(arr)),
    pushItemsInSeries: arr => dispatch(pushItemsInSeries(arr)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    setItemAction: opts => dispatch(setItemAction(opts)),
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LocationPage)
