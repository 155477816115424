import React from 'react';
import { mappify } from '../../lib/obj';

export const PRODUCT_FIELDS = [
  { name: "id",             type: "bigint",    primaryKey: true, autoIncrement: true},
  { name: "version",        type: "bigint"      },
  { name: "createdAt",      type: "timestamp", default: "currentTimestamp"},
  { name: "updatedAt",      type: "timestamp", default: "currentTimestamp", onUpdate: "currentTimestamp"},
  { name: "deleted",        type: "boolean",   default: "false"},
  { name: "deletedAt",      type: "timestamp"   },
  { name: "bookId",         type: "bigint",    notNull: true },
  { name: "type",           type: "varchar",   length:  255 },
  { name: "revision",       type: "varchar",   length:  255 },
  { name: "code",           type: "varchar",   length:  255 },
  { name: "sku",            type: "varchar",   length:  255 },
  { name: "name",           type: "varchar",   length:  255 },
  { name: "description",    type: "text" },
  { name: "msrp",           type: "decimal",   precision:19, scale:4, default:0 },
  { name: "cogs",           type: "decimal",   precision:19, scale:4, default:0 },
  { name: "productionCost", type: "decimal",   precision:19, scale:4, default:0 },
  { name: "totalExpected",  type: "bigint",    default:0  },
  { name: "totalReceived",  type: "bigint",    default:0  },
  { name: "totalSent",      type: "bigint",    default:0  },
  { name: "totalAvailable", type: "bigint",    default:0  },
  { name: "totalCapacity",  type: "bigint",    default:0  },
  { name: "depletionRate",  type: "decimal",   precision:19, scale:4, default:0 },
  { name: "leadTime",       type: "int"        }, // days
  { name: "imageId",        type: "bigint"     }
];

export const PRODUCT_TYPES = [
  { value:"bundle",    name: "Bundle",    desc: "Group of packages for sale"},
  { value:"package",   name: "Package",   desc: "Single boxed item for sale"},
  { value:"assembly",  name: "Assembly",  desc: "Assembled group of parts"},
  { value:"part",      name: "Part",      desc: "Single part unit"}
];

export const PRODUCT_STAGES = [
  { value:"dev",        name: "Dev",        desc: "Product is under development"},
  { value:"active",     name: "Active",     desc: "Product is active"},
  { value:"deprecated", name: "Deprecated", desc: "Product is deprecated and should not be referenced in new projects"},
  { value:"archived",   name: "Archived",   desc: "Product has been archived and should not appear in searches"}
];

export const PRODUCT_TYPE_MAP = mappify(PRODUCT_TYPES,(i)=>i.value);
export const PRODUCT_STAGE_MAP = mappify(PRODUCT_STAGES,(i)=>i.value);

export const PRODUCT_SEARCH = {
  type: "products",
  limit: 100,
  links: ["image"],
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["name","sku"],
    keymap: { // TODO - Fernando Mora: Implement this functionality
      sku: "sku",
      name: "name",
      id: { field:"id", op:"eq" }
    }
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "type",
      options: PRODUCT_TYPES
    }
  ],
  sort: [
    { field: "name",            name: "Name (A-Z)",       direction:"asc", selected: true},
    { field: "name",            name: "Name (Z-A)",       direction:"desc"},
    { field: "sku",             name: "SKU (A-Z)",        direction:"asc"},
    { field: "sku",             name: "SKU (Z-A)",        direction:"desc"},
    { field: "createdAt",       name: "Created (Asc)",    direction:"asc"},
    { field: "createdAt",       name: "Created (Desc)",   direction:"desc"},
    { field: "totalAvailable",  name: "Available (1-9)",  direction:"asc"},
    { field: "totalAvailable",  name: "Available (9-1)",  direction:"desc"},
  ]
}

export const PRODUCT_PART_SEARCH = {
  type: "products",
  term: {
    label: "Search Products",
    placeholder: "Search",
    fields: ["part.name","part.sku"],
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "part.type",
      options: [
        { type:"assembly",  name: "Assembly"},
        { type:"part",      name: "Part"}
      ]
    }
  ]
}

export const PRODUCT_ITEM_SEARCH = {
  type: "products",
  limit: 100,
  term: {
    label: "Search Products",
    placeholder: "Search",
    fields: ["item.name","item.sku"]
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "item.type",
      options: PRODUCT_TYPES
    }
  ]
}

export const LOCATION_PRODUCT_SEARCH = {
  type: "products",
  links: ["location","item"],
  limit: 100,
  term: {
    label: "Search Products",
    placeholder: "Search",
    fields: ["item.name","item.sku"]
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "item.type",
      options: PRODUCT_TYPES
    }
  ],
  sort: [
    { field: "item.name",            name: "Product (A-Z)",    direction:"asc", selected: true},
    { field: "item.name",            name: "Product (Z-A)",    direction:"desc"},
    { field: "location.name",        name: "Location (A-Z)",   direction:"asc"},
    { field: "location.name",        name: "Location (Z-A)",   direction:"desc"},
    { field: "item.totalAvailable",  name: "Available (1-9)",  direction:"asc"},
    { field: "item.totalAvailable",  name: "Available (9-1)",  direction:"desc"},
  ]
}

export const PRODUCT_COMPONENT_SEARCH = {
  type: "product-items",
  links: ["item","image"],
  limit: 50,
  term: {
    label: "Products",
    placeholder: "Search",
    fields: ["item.name","item.sku"],
    keymap: { // TODO - Fernando Mora: Implement this functionality
      sku: "item.sku",
      name: "item.name"
    }
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "item.type",
      options: PRODUCT_TYPES
    }
  ],
  sort: [
    { field: "createdAt",        name: "Created (Asc)",   direction:"asc"},
    { field: "createdAt",        name: "Created (Desc)",  direction:"desc", selected: true},
    { field: "item.name",        name: "Name (A-Z)",      direction:"asc"},
    { field: "item.name",        name: "Name (Z-A)",      direction:"desc"},
    { field: "item.sku",         name: "SKU (A-Z)",       direction:"asc"},
    { field: "item.sku",         name: "SKU (Z-A)",       direction:"desc"},
    { field: "item.type",        name: "Type (A-Z)",      direction:"asc"},
    { field: "item.type",        name: "Type (Z-A)",      direction:"desc"}
  ],
  conditions: [{type:{eq:"product"}}]
}

export const PRODUCT_PROCESS_SEARCH = {
  type: "product-items",
  limit: 50,
  term: {
    label: "Processes",
    placeholder: "Search",
    fields: ["name"],
  },
  sort: [
    { field: "createdAt",   name: "Created (Asc)",   direction:"asc"},
    { field: "createdAt",   name: "Created (Desc)",  direction:"desc", selected: true},
    { field: "name",        name: "Name (A-Z)",      direction:"asc"},
    { field: "name",        name: "Name (Z-A)",      direction:"desc"},
  ],
  conditions: [{type:{eq:"process"}}]
}

export const PRODUCT_LOCATION_SEARCH = {
  type: "location-items",
  links: ["location","item","image"],
  limit: 50,
  term: {
    label: "Locations",
    placeholder: "Search",
    fields: ["location.name"],
  }
}

export const PRODUCT_VENDOR_SEARCH = {
  type: "vendor-items",
  links: ["vendor"],
  limit: 50,
  term: {
    label: "Vendors",
    placeholder: "Search",
    fields: ["vendor.name"],
  },
  sort: [
    { field: "createdAt",        name: "Created (Asc)",   direction:"asc"},
    { field: "createdAt",        name: "Created (Desc)",  direction:"desc", selected: true},
    { field: "vendor.name",        name: "Name (A-Z)",      direction:"asc"},
    { field: "vendor.name",        name: "Name (Z-A)",      direction:"desc"}
  ],
}

export const PRODUCT_INTEGRATION_SEARCH = {
  type: "product-items",
  links: ["product","productImage"],
  limit: 50,
  term: {
    label: "Products",
    placeholder: "Search",
    fields: ["product.name","product.sku"],
    keymap: { // TODO - Fernando Mora: Implement this functionality
      sku: "product.sku",
      name: "product.name"
    }
  },
  filters: [
    {
      label: "Type",
      type: "check",
      field: "product.type",
      options: PRODUCT_TYPES
    }
  ],
  sort: [
    { field: "createdAt",        name: "Created (Asc)",   direction:"asc", selected: true},
    { field: "createdAt",        name: "Created (Desc)",  direction:"desc"},
    { field: "item.name",        name: "Name (A-Z)",      direction:"asc"},
    { field: "item.name",        name: "Name (Z-A)",      direction:"desc"},
    { field: "item.sku",         name: "SKU (A-Z)",       direction:"asc"},
    { field: "item.sku",         name: "SKU (Z-A)",       direction:"desc"},
    { field: "item.type",        name: "Type (A-Z)",      direction:"asc"},
    { field: "item.type",        name: "Type (Z-A)",      direction:"desc"}
  ],
  conditions: [{type:{eq:"product"}}]
}
