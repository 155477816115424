
// Matches preceeding and trailing white space
const REGEX_STRIP = /^\s+|\s+$/g;

// Matches keywords in a string
const REGEX_KEWORD = /(\w+)\:/g;

/**
 * Takes in a string and returns a hash of keyword and value pairs.
 * Keywords are ended with a ":"/
 * e.g.: "some other text keyword:123  name: Pedro Fernandez order:123456"
 *        => {keyword:"123", name:"Pedro Fernandez", order:"123456", _blank:"some other text"}
**/
export const kewordify = function(str) {
  let map = {};
  if (!str || !(str.length > 0)) { return map; }
  str = str.replace(REGEX_STRIP,"");
  let parts = str.split(REGEX_KEWORD);
  map._blank = parts.shift();
  map._length = 0;
  for (var i = 0; i < parts.length; i++) {
    let key = parts[i].toLowerCase();
    map[key] = parts[++i];
    map._length++;
  }
  return map
};
