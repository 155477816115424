import React from 'react';
import { mappify } from '../../lib/obj'

export const PO_STATES = [
  { value:"new",       name: "New"},
  { value:"deposited", name: "Deposited" },
  { value:"paid",      name: "Paid" },
  { value:"canceled",  name: "Canceled" },
];

export const PO_FULFILLMENT_STATUSES = [
  { value:"none",      name: "None"},
  { value:"pending",   name: "Pending" },
  { value:"partial",   name: "Partial" },
  { value:"fulfilled", name: "Fulfilled" },
  { value:"delayed",   name: "Delayed" }
];

export const PO_SEARCH = {
  type: "pos",
  limit: 100,
  links: ["vendor"],
  term: {
    label: "Search",
    fields: ["name","vendor.name"]
  },
  filters: [
    {
      label: "State",
      type: "check",
      field: "state",
      options: PO_STATES
    }
  ],
  sort: [
    { field: "name",        name: "Name (A-Z)",     direction:"asc"},
    { field: "name",        name: "Name (Z-A)",     direction:"desc"},
    { field: "vendor.name", name: "Vendor (A-Z)",   direction:"asc"},
    { field: "vendor.name", name: "Vendor (Z-A)",   direction:"desc"},
    { field: "createdAt",   name: "Created (1-9)",  direction:"asc"},
    { field: "createdAt",   name: "Created (9-1)",  direction:"desc", selected: true},
  ]
}

export const PO_PAYMENT_SEARCH = {
  type: "po-payments",
  limit: 100,
  links: ["po"],
  term: {
    label: "Search",
    fields: ["name","po.name"]
  },
  sort: [
    { field: "name",        name: "Name (A-Z)",     direction:"asc"},
    { field: "name",        name: "Name (Z-A)",     direction:"desc"},
    { field: "createdAt",   name: "Created (1-9)",  direction:"asc"},
    { field: "createdAt",   name: "Created (9-1)",  direction:"desc", selected: true},
  ]
}


export const PO_STATE_MAP = mappify(PO_STATES,(i)=>{return i.value});
export const PO_FULFILLMENT_STATUS_MAP = mappify(PO_FULFILLMENT_STATUSES,(i)=>{return i.value});
