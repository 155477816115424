import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Presentation from './Presentation';
import PaginateControl from '../controls/PaginateControl';
import CheckControl from '../controls/CheckControl';
import ItemSearchSection from '../sections/ItemSearchSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './QueryOptionsPresentation.scss';

import { setItemQuery, meldItems } from '../../actions/InventoryActions';
import { _ } from '../../lib/underscore';
import { financial, capitalize } from '../../lib/formats';
import { dot, deepCopy } from '../../lib/obj';
import { configToNestedQuery } from '../../lib/inventory-util';

class QueryOptionsPresentation extends Presentation {

  onFilterChange(filter, options, index, filterIndex) {
    let props = this.props;
    let config = deepCopy(props.config);
    dot(config,["filters",filterIndex,"options"], options);
    this.onConfigChange(config);
  }

  onSortChange(options, index) {
    let props = this.props;
    let config = deepCopy(props.config);
    dot(config,"sort", options);
    this.onConfigChange(config);
  }

  onConfigChange(config) {
    // Make sure config offset is cleared
    delete config.offset;

    // Set the item query
    this.props.setItemQuery({
      type: this.props.type,
      typeAlias: this.props.typeAlias,
      query: config
    });

    // Report config chagne
    if (this.props.throttle) {
      if (!this._queryThrottle) {
        this._queryThrottle = _.enqueue((config) => {
          if (this.props.onConfigChange) {
            this.props.onConfigChange(config, this._queryThrottle);
          } else {
            this.runQuery(config, this._queryThrottle);
          }
        }, this.props.timeout || 5000);
      }
      this._queryThrottle(config)
    }
    else {
      if (this.props.onConfigChange) {
        this.props.onConfigChange(config, this._queryThrottle);
      } else {
        this.runQuery(config);
      }
    }
  }

  runQuery(config, throttle) {
    let props = this.props;
    let query = configToNestedQuery(config,[{deleted:{neq:1}}]);
    query.limit = this.props.limit || config.limit;
    query.offset = config.offset;
    this.props.meldItems({
      type: props.type,
      typeAlias: props.typeAlias,
      query: query
    }).then(()=>{
      if (throttle) { throttle.done(); }
    });
  }

  render() {
    let config = this.props.config;
    let filters = config.filters || [];

    let sort;
    if (config.sort && config.sort.length > 0) {
      sort = <CheckControl layout="table" key="sort" className="Sort" options={config.sort} value="Sort by" icon={<FontAwesomeIcon icon="sort-alt" />} radio={true} onChange={(options, index)=>{this.onSortChange(options, index);}}/>
    }

    let className = this.props.className || "";
    return (
      <div className={`QueryOptionsPresentation Presentation ${className}`} ref="root">
        {filters.map((filter, filterIndex)=>{
          return <CheckControl layout="table" key={`filter${filterIndex}`} className={`Filter Filter${filterIndex}`} slave={true} options={filter.options} value={filter.label} type={filter.type} radio={filter.radio} onChange={(options, index)=>{this.onFilterChange(filter, options, index, filterIndex);}}/>
        })}
        {sort}
      </div>
    );
  }
}

const mapState = (state, props) => {
  let type = props.typeAlias || props.type;
  let items = dot(state.inventory,[type,"list","docs"]) || [];
  let config = dot(state.inventory,[type,"list","query"]) || {};
  return {
    items: items,
    config: config
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    meldItems: opts => dispatch(meldItems(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(QueryOptionsPresentation)
